import Home from "./Pages/Home/index";
import Blog from "./Pages/Blog/index";
import BlogSinglePage from "./Pages/Blog/BlogSingle/SinglePage";
import About from "./Pages/About/index";
import ErrorPage from "./Pages/ErrorPage/index";
import ServiceProvider from "./Pages/ServiceProvider/index";
import ProviderDetails from "./Pages/ServiceProvider/ProviderDetails";
import ServiceList from "./Pages/ServiceList/index";
import EditProfile from "./Pages/Dashboard/EditProfile/index";
import MyOrders from "./Pages/Dashboard/MyOrders/index";
import MyHistory from "./Pages/Dashboard/MyHistory/index";
import ServiceDetails from "./Pages/ServiceDetails/index";
import CategoryListing from "./Pages/CategoryListing/index";
import Cart from "./Pages/Cart/index";
import Contact from "./Pages/Contact/index";
import Faq from "./Pages/Faq/index";
import Chat from "./Pages/Chat/index";
import Checkout from "./Pages/Checkout/index";
import Search from "./Default/search/search";
import ArticleSingle from "./Pages/ArticleSingle/ArticleSingle";
import Testimonial from "./Pages/Testimonial/Testimonial";
import Video from "./Pages/Chat/Component/Video";
import TopProvider from "./Pages/TopProviders/TopProvider";
import MorePopular from "./Pages/ArticleSingle/MorePopular";
import MoreArticle from "./Pages/MoreArticle/MoreArticle";
import CustomerService from "./Pages/CustomerService/CustomerService";
import ReportProb from "./Pages/ReportProblem/ReportProb";
import LegalNotice from "./Pages/LegalNotice/LegalNotice";
import Grievances from "./Pages/Grievances/Grievances";
import SafetyGuide from "./Pages/SafetyGuide/SafetyGuide";
import Associate from "./Pages/OurAssociate/Associate";
import OneAssociate from "./Pages/OurAssociate/OneAssociate";
import SiteMap from "./Pages/SiteMap/Site";
import Privacy from "./Pages/PrivacyPolicy/Privacy";
import ItemCondition from "./Pages/ItemCondition/ItemCondition";
import Feedback from "./Pages/Feedback/Feedback";
import Career from "./Pages/CareerWithUs/Career";
import Shop from "./Pages/Shop";
import Seller from "./Pages/Seller/index"
import Subscription from "./Pages/Seller/Subscription"
import Dashboard from "./Pages/Seller/Dashboard";
import SellerLayout from "./Layout/SellerLayout";
import Demo from "./Pages/Demo";
import Meeting from "./Pages/Seller/CreateMeet/Meeting";
const routes = [
  { path: "/", exact: true, component: Home },
  { path: "/home", name: "Home", component: Home },
  { path: "/blog", name: "Blog", component: Blog },
  // { path: "/meeting", component: Meeting },

  {
    path: "/blog-singlePage:id",
    name: "BlogSinglePage",
    component: BlogSinglePage,
  },
  { path: "/about-us", name: "About", component: About },
  { path: "/error-page", name: "ErrorPage", component: ErrorPage },
  {
    path: "/service-provider",
    name: "ServiceProvider",
    component: ServiceProvider,
  },
  {
    path: "/provider-details/:id",
    name: "ProviderDetails",
    component: ProviderDetails,
  },
  {
    path: "/services",
    name: "Shop",
    component: Shop,
  },
  { path: "/service-list/:id", name: "ServiceList", component: ServiceList },
  { path: "/edit-profile", name: "EditProfile", component: EditProfile },
  { path: "/my-orders", name: "MyOrders", component: MyOrders },
  { path: "/my-history", name: "MyHistory", component: MyHistory },
  {
    path: "/service-details/:id",
    name: "ServiceDetails",
    component: ServiceDetails,
  },
  { path: "/cart", name: "Cart", component: Cart },
  { path: "/contact", name: "Contact", component: Contact },
  { path: "/faq", name: "Faq", component: Faq },
  { path: "/chat", name: "Chat", component: Chat },
  {
    path: "/category-listing",
    name: "CategoryListing",
    component: CategoryListing,
  },
  { path: "/checkout", name: "Checkout", component: Checkout },
  { path: "/search", name: "Search", component: Search },
  {
    path: "/article-single:id",
    name: "ArticleSingle",
    component: ArticleSingle,
  },
  { path: "/testimonials", name: "Testimonial ", component: Testimonial },
  { path: "/video-call", name: "Video ", component: Video },
  { path: "/top-providers", name: "TopProviders ", component: TopProvider },
  { path: "/more-article", name: "MoreArticle ", component: MoreArticle },
  // { path: "/demo", name: "MoreArticle ", component: Demo },
  {
    path: "/customer",
    name: "CustomerService ",
    component: CustomerService,
  },
  {
    path: "/report-problem",
    name: "ReportProblem ",
    component: ReportProb,
  },
  {
    path: "/report-problem",
    name: "ReportProblem ",
    component: ReportProb,
  },
  {
    path: "/legal-notice",
    name: "LegalNotice ",
    component: LegalNotice,
  },
  {
    path: "/grievances",
    name: "Grievances ",
    component: Grievances,
  },
  {
    path: "/safety-guide",
    name: "SafetyGuide ",
    component: SafetyGuide,
  },
  {
    path: "/our-associate",
    name: "OurAssociate ",
    component: Associate,
  },
  {
    path: "/single-associate/:id",
    name: "OneAssociate ",
    component: OneAssociate,
  },
  {
    path: "/site-map",
    name: "SiteMap ",
    component: SiteMap,
  },
  {
    path: "/privacy",
    name: "Privacy ",
    component: Privacy,
  },
  {
    path: "/item-condition",
    name: "ItemCondition ",
    component: ItemCondition,
  },
  {
    path: "/feedback",
    name: "Feedback ",
    component: Feedback,
  },
  {
    path: "/career-with-us",
    name: "CareerWithUs ",
    component: Career,
  },

  {
    path: "/seller",
    name: "seller ",
    component: SellerLayout,
  },

  // {
  //   path: "/subscription",
  //   name: "subscription ",
  //   component: Subscription,
  // },

  // {
  //   path: "/dashboard",
  //   name: "dashboard ",
  //   component: Dashboard,
  // },
  
];
export default routes;
