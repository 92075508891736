import React, { useState, useEffect } from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
import Provider from "../../Component/Provider";
import HttpClient from "../../utils/HttpClient";
import AllProvider from "./AllProvider";
const TopProvider = () => {
  const [providers, setProvider] = useState([1, 2, 3, 4, 5, 6]);
  const [provider, setprovider] = useState([]);
  useEffect(() => {
    fetchProvider();
    window.scrollTo(0, 0);
  }, []);

  const fetchProvider = async (userid) => {
    let result = await HttpClient.requestData("top-sellers", "GET");
    //console.log("serviceList", result);
    if (result && result.status) {
      //console.log(result.data);
      // let cur_data = result.data.filter(
      //   (item) => item.user_id != userid
      // );
      // //console.log("data", cur_data);

      setprovider(result.data);
    } else {
    }
  };
  return (
    <div>
      <section>
        <Jumbotron heading=" Top Service Providers" img={serviceJumboBg} />
      </section>
      <div className="container my-5  ">
        <div className="row px-4">
          {/* {providers.map((item, index) => {
            return (
              <div className="col-md-4 my-3" key={index}>
                <Provider />
              </div>
            );
          })} */}
          {provider.length > 0 &&
            provider.map((item, index) => {
              return (
                <div className="col-md-4 my-3" key={index}>
                  <AllProvider data={item} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TopProvider;
