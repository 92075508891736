import Subscription from "./Pages/Seller/Subscription";
import Dashboard from "./Pages/Seller/Dashboard";
import Servicerequest from "./Pages/Seller/Servicerequest";
import Myorders from "./Pages/Seller/Myorders";
import Message from "./Pages/Seller/Message";
import Cancelorder from "./Pages/Seller/Cancelorder";
import Refunclaim from "./Pages/Seller/Refunclaim";
import Withdrawbalance from "./Pages/Seller/Withdrawbalance";
import Commission from "./Pages/Seller/Commission";
import Myshop from "./Pages/Seller/Myshop";
import Myservices from "./Pages/Seller/Myservices";
import Addservices from "./Pages/Seller/Addservices";
import Mybankdetails from "./Pages/Seller/Mybankdetails";
import Contactus from "./Pages/Seller/Contactus";
import ShopService from "./Pages/Seller/ShopService/ShopService";


import OrderHistory from "./Pages/Seller/OrderHistory/OrderHistory";
import WithdrawBalance from "./Pages/Seller/WithdrawBalance/WithdrawBalance";
import UserReview from "./Pages/Seller/UserReview/UserReview";
import OffWeekDays from "./Pages/Seller/OffWeekDays/OffWeekDays";
import Slot from "./Pages/Slot/Slot";
import ManageChat from "./Pages/ManageChat/ManageChat";
import PendingBooking from "./Pages/Seller/SellerBooking/PendingBooking";
import AcceptBooking from "./Pages/Seller/SellerBooking/AcceptBooking";
import CancelBooking from "./Pages/Seller/SellerBooking/CancelBooking";
import MyWallet from "./Pages/Seller/Wallet/MyWallet";
import SubscriptionPlan from "./Pages/Seller/SubscriptionPlan/Subscriptionpla";
import Chat from "./Pages/Seller/Chat/Chat";
import CreateMeeting from "./Pages/Seller/CreateMeet/index"
import PaymentMeeting from "./Pages/Seller/CreateMeet/PaymentMeeting";

const SellerRoutes = [
  { path: "/seller", exact: true, component: Dashboard },
  { path: "/seller/dashboard", component: Dashboard },
  { path: "/seller/shopservice-category", component: ShopService },
  { path: "/seller/subscription-plan", component: SubscriptionPlan },
  { path: "/seller/slot-manage", component: Slot },
  { path: "/seller/off-week-days-manage", component: OffWeekDays },
  {path:"/seller/order-history",  component:OrderHistory},

  //Seller Booking
  { path: "/seller/pending-booking", component: PendingBooking },
  { path: "/seller/accept-booking", component: AcceptBooking },
  { path: "/seller/cancel-booking", component: CancelBooking },
  { path: "/seller/create-meeting", component: CreateMeeting },
  // { path: "/seller/payment-meeting", component: PaymentMeeting },




  // Seller Booking

  //Wallet
  { path: "/seller/wallet", component: MyWallet },
  //Wallet
  { path: "/seller/withdraw-balance", component: WithdrawBalance },
  { path: "/seller/user-review", component: UserReview },
  { path: "/seller/chat", component: Chat },

  { path: "/seller/servicerequest", component: Servicerequest },
  { path: "/seller/myorders", component: Myorders },
  { path: "/seller/message", component: Message },
  { path: "/seller/cancelorder", component: Cancelorder },
  { path: "/seller/refundclaim", component: Refunclaim },
  { path: "/seller/withdrawbalance", component: Withdrawbalance },
  { path: "/seller/commission", component: Commission },
  { path: "/seller/myshop", component: Myshop },
  { path: "/seller/myservices", component: Myservices },
  { path: "/seller/addservices", component: Addservices },
  { path: "/seller/mybankdetails", component: Mybankdetails },
  { path: "/seller/contactus", component: Contactus },
];
export default SellerRoutes;
