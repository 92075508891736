import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";

import Jumbotron from "./Components/Jumbotron";
import SpiritualConnect from "./Components/SpiritualConnect";
import About from "./Components/About";
import ServiceProvider from "./Components/ServiceProvider";
import Quotation from "./Components/Quotation";
import Articles from "./Components/Articles";
import Sessionbar from "./Components/Sessionbar";
import Homebanner from "./Components/Homebanner";
import Subscribe from "./Components/Subscribe";
import Servicecard from "../../Component/ServiceCard"
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";

import Navbar from "../../Default/Navbar/index";
import Footer from "../../Default/Footer/index";

const Index = () => {
  const userData = reactLocalStorage.getObject("userData");
  // alert("useData", userData)
  const [prodPagi, setproductpaginator] = useState()
  const [login, setlogin] = useState(false);
  const [serviceList, setServiceList] = useState([]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getUserData();
    fetchServiceList1(
      "61122e6129890f40806a0002",
      "61618d5ddbd041128049d4bb",
      1
    )
  }, []);

  const getUserData = async () => {
    let getAccount = reactLocalStorage.getObject("userData");
    //console.log("getAccount", getAccount);
    if (
      getAccount &&
      getAccount != null &&
      Object.keys(getAccount).length > 0
    ) {
      setlogin(true);

    }
    else {

    }
  }

  const fetchServiceList1 = async (id, userid, next) => {
    //console.log("idd", id);
    // setLoading(true);
    let sendData = next ? next : "1";
    let result = await HttpClient.requestData1(
      "service-category/shop-services/" + userid + "/" + id + "/" + sendData + "?currency=" + "USD",
      "GET"
    );
    //console.log("serviceList", result);
    if (result && result.status) {
      console.log("datacur", result.data);
      if (result.data.length || Object.keys(result.data).length) {
        if (result.data.itemsList.length) {
          let cur_data = result.data.itemsList.filter(
            (item) => item.user_id != userid
          );
          console.log("datacur", cur_data);
          let newD = []
          for (let index = 0; index < cur_data.length; index++) {
            const element = cur_data[index];
            if (index <= 3) {
              newD.push(element)
            }

          }
          setServiceList(newD);
          setproductpaginator(result.data.paginator);
        }
        else {
          setServiceList([]);
          setproductpaginator({});
        }
      } else {
        setServiceList([]);
        setproductpaginator({});
      }
    } else {
    }
  };
  return (
    <div className="Home">
      <ReactTitle title="MySpiritualConnent - Home" />
      {/* <section>
        <Jumbotron />
      </section> */}

      <Homebanner />
      {/* <section className="pt-5">
        <About />
      </section> */}
      <Sessionbar />
      <section>
        <SpiritualConnect />
      </section>
      <section>
        <ServiceProvider />
      </section>


      <section id="serviceshome">
        <div className="container" style={{ maxWidth: "1200px" }}>
          <div className="row">
            <div className="col-12">
              <div className="service_head">

                <h3 className="mt-2 mb-0">
                  Our Services
                  <span />
                </h3>
              </div>
            </div>
          </div>
          <div className="row">

            <Servicecard data={serviceList} />
          </div>
        </div>
      </section>

      {/* <section>
        <Quotation />
      </section> */}
      <section>
        <Articles />
      </section>
      {/* {userData.seller_request || login ? (
        <section>
        <Subscribe />
      </section>
      ) : null} */}

      {/* <Footer /> */}
    </div>
  );
};

export default Index;
