import React, { useState } from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
const CustomerService = () => {
  const questions = [0, 1, 2, 3, 4, 5];

  const [state, setstate] = useState({
    ans0: true,
    ans1: false,
    ans2: false,
    ans3: false,
    ans4: false,
    ans5: false,
  });

  const FaqBox = (id) => {
    let name = `ans${id}`;
    //console.log(name, state[name]);
    setstate({ ...state, [name]: !state[name] });
  };
  return (
    <div>
      <section>
        <Jumbotron heading="Customer Services" img={serviceJumboBg} />
      </section>
      <div className="Customer-service feedback">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center my-4">
              <h2>Customer Service</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8  my-4 m-auto">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="col">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="exampleFormControlTextarea1">
                  Any Question
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={5}
                  placeholder="Write Here..."
                />
              </div>
              <div className="text-center my-4">
                <button className="btn submit_btn">Submit</button>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 m-auto ">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Call Back to this Number</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        000000000
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Toll-Free Number</h5>
                      <h6 className="card-subtitle mb-2 text-muted">0000000</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-md-6 mx-auto">
              <h2 className="text-center">FAQ</h2>
              <div className="container-fluid">
                <div className="row">
                  {questions.map((item, index) => {
                    let name = `ans${index}`;
                    return (
                      <div className="col-md-12" key={index}>
                        <div className=" my-3 ans_text">
                          <p
                            className={
                              state[name] ? "question active mb-3" : "question"
                            }
                            onClick={(e) => FaqBox(index)}
                          >
                            Lorem ipsum dolor sit amet
                            {state[name] ? (
                              <i className="fas fa-minus mr-3  "></i>
                            ) : (
                              <i className="fas fa-plus mr-3  "></i>
                            )}
                          </p>

                          <div
                            className={state[name] ? "d-block faqbg" : "d-none"}
                          >
                            <h6>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. A, volutpat nibh cursus Lorem ipsum dolor
                              sit amet, consectetur adipiscing elit. A, volutpat
                              nibh cursus Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Rem repellat quod dolor.
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerService;
