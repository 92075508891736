import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

import Jumbotron from "../../Component/Jumbotron";
import Footer2 from "../../Default/Footer2/index";
import Navbar2 from "../../Default/Navbar2/index";
import Adds from "./Component/Adds";

import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import service1 from "../../Default/Assets/Images/Service/service1.png";
import service2 from "../../Default/Assets/Images/Service/service2.png";
import service3 from "../../Default/Assets/Images/Service/service3.png";
import { Link } from "react-router-dom";

const Index = (props) => {
  // //console.log("cart Data",props.match.params.id);

  useEffect(() => {
    getCardData();
    //console.log(props.match.params.id);

    window.scrollTo(0, 0);
  }, []);

  // const [cartData, setCartdata] = useState(props.location.items);
  // //console.log("newcart Data",cartData);
  const [cartData, setcartData] = useState([]);

  const successCall = (val) => {
    if (val) {
      getCardData();
    }
  }

  const getCardData = async () => {
    let userData = reactLocalStorage.getObject("userData");
    //console.log(userData._id);
    let result = await HttpClient.requestData(
      "get-service-cart/" + userData._id,
      "GET"
    );
    //console.log("cart Result", result);
    if (result && result.status) {
      let data = result.data;
      //console.log("cart data", data);
      setcartData(data);
      //console.log("cart data", cartData);
    }
  };
  const cartItems = [
    {
      imags: service1,
      serviceName: "SERVICE NAME 001",
      providerName: "Provider Name 001",
    },
    {
      imags: service2,
      serviceName: "SERVICE NAME 002",
      providerName: "Provider Name 002",
    },
    {
      imags: service3,
      serviceName: "SERVICE NAME 003",
      providerName: "Provider Name 003",
    },
  ];

  const total = cartData.reduce(
    (prevValue, currentValue) => prevValue + currentValue.price,
    0);
  //console.log(total);
  return (

    <div className="Cart">
      <ReactTitle title="MySpiritualConnent - Cart" />
      <section>
        <Jumbotron heading="CART" img={serviceJumboBg} />
      </section>
      <section className="my-5 container">
        <div className="row">
          {cartData.length > 0 ?
            <div className="col-lg-8">
              {cartData.map((item, index) => {
                return (
                  <div key={index}>
                    <Adds
                      cartImage={item.image}
                      cartServicename={item.service_name}
                      cartPrice={item.price}
                      sellerDetails={item.seller_data}
                      cartItemId={item._id}
                      cart={item}
                      successCall={successCall}
                    // imgs={item.image}
                    // serviceName={item.name}
                    // providerName={item.seller_data[0]}
                    />
                  </div>
                );
              })}
            </div> : <div className="col-lg-8">
              <h3 style={{ textAlign: "center" }}>No Service Added in the Cart</h3>
            </div>
          }

          <div className="col-lg-4">
            <div className="Bill mb-5">
              <h2>Your Cart</h2>
              <div className="d-flex justify-content-between mb-4">
                <h6>
                  Price of {cartData.length}{" "}
                  {cartData.length == 1 ? "Item" : "Items"}
                </h6>
                {/* <h5>60</h5> */}
                <h5>
                  {total.toFixed(2)}
                </h5>
              </div>
              {/* <div className="d-flex justify-content-between mb-4">
                <h6>Discount</h6>
                <h5>60</h5>
              </div> */}
              <div className="d-flex justify-content-between mb-4">
                <h6 className="font-weight-bold">Sub Total</h6>
                <h5 className="font-weight-bold">{total.toFixed(2)}</h5>
              </div>


              <div className="text-center">
                <Link to="/checkout" className="btn proceed_btn" style={{width: "100%"}}>
                  Proceed To Checkout
                </Link>
              </div>

              {/* <div className="d-flex justify-content-between mb-5 pb-4">
                <h6>Taxes</h6>
                <h5>0</h5>
              </div> */}
              {/* <div className="d-flex justify-content-between">
                <h5 className="font-weight-bold">Total Amount</h5>
                <h5 className="font-weight-bold">60</h5>
              </div> */}
            </div>
            {/* <div className="PromoCode mb-4">
              <input className="form-control mb-4" type="text" />
              <div className="text-center">
                <button className="btn PrimaryBtn font-weight-bold">
                  Apply Coupon Code
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <section className="pb-5 pt-4 mb-5">
        <div className="text-center">
          <Link to="/checkout" className="btn PrimaryBtn">
            PROCEED TO BUY
          </Link>
        </div>
      </section> */}
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
