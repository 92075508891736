import React, { useEffect, useState } from "react";
import { ReactTitle } from "react-meta-tags";

import Jumbotron from "../../Component/Jumbotron";
import Footer2 from "../../Default/Footer2/index";
import Navbar2 from "../../Default/Navbar2/index";
import Userlist from "./Component/Userlist";
import MessageDisplay from "./Component/MessageDisplay";
import MessageWrite from "./Component/MessageWrite";
import { reactLocalStorage } from "reactjs-localstorage";

import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Firebase1, { fsdb } from "./firebase";
import Chat from "../Seller/Chat/Chat";
//import MessageHead from "./Component/MessageHead";

const Index = () => {
  const [roomid, setRoomId] = useState("");
  const [senderId, setSenderId] = useState('');
  const [recieverId, setRecieverId] = useState('');
  const [userID, setuserID] = useState("");
  const [name, setname] = useState("");
  const [loading, setloading] = useState(true);
  const [count, setcount] = useState(0);
  // const [session, setsession] = useState(false);
  const [chatlist, setChatList] = useState([]);
  // const [msgData,setMsgData]=useState([]);

  // const [chatData, setChatData] = useState({
  //   userId: '',
  //   name: ''
  // })

  const checkUser = () => {
    let user = reactLocalStorage.getObject("userData");
    console.log("user", user);
    if (user != null && Object.keys(user).length > 0) {
      // setChatData((prev) => {
      //   return ({
      //     ...prev,
      //     userId: user._id,
      //     name: user.firstName + " " + user.lastName,
      //   }
      //   )
      // });
      setuserID(user._id);
      setname(user.firstName);
    }
  }

  const getChats = () => {
    let myId = reactLocalStorage.getObject("userData")._id;
    console.log("myId", myId);
    var docRef = fsdb
      .collection("chat_users")
      .where("receiver_id", "==", myId)
      .onSnapshot((querySnapshot) => {
        // console.log('querySnapshot',querySnapshot)
        let allChaters = [];
        querySnapshot.forEach((doc) => {
          allChaters.push(doc.data());
        });
        setChatList(allChaters);
        setRoomId()
        // console.log('allChaters', allChaters)
      })
  }
  console.log('chatlist', chatlist);

  // const getMessage = () => {
  //   fsdb
  //   .collection("chat")
  //   .doc(roomid)
  //   .collection("messages")
  //   .orderBy("time")
  //   .onSnapshot((querySnapshot) => {
  //     // console.log('querySnapshot',querySnapshot)
  //     let arr=[];
  //     querySnapshot.forEach((item)=>{
  //       arr.push(item.data());
  //     })
  //     // console.log('arr',arr)
  //     setMsgData(arr)
  //   })
  // }
  // console.log('masData',msgData)

  useEffect(() => {
    checkUser();
    getChats();
  }, [])

  // useEffect(()=>{
  //   if(roomid){
  //     getMessage();
  //   }
  // },[roomid])
  // console.log('roomid',roomid)

  // const Callbackchat = async (room_id, fullname, user_id, count) => {
  //   // //console.log("roomid",roomid);
  //   //  //console.log("name",name);
  //   //  //console.log("userId",userID);

  //   setRoomId(room_id);
  //   setcount(count);
  //   setname(fullname);
  //   setuserID(user_id);
  //   setloading(true);
  //   //console.log("roomid", roomid);
  //   //console.log("fulname", name);
  //   //console.log("userId", userID);
  //   //console.log("count", count);
  // };

  // const Callbackwrite = async (room_id, fullname, user_id, count, session) => {
  //   setRoomId(room_id);
  //   setcount(count);
  //   setname(fullname);
  //   setuserID(user_id);
  //   setsession(session)
  //   setloading(true);
  //   //console.log("roomid", roomid);
  //   //console.log("fulname", name);
  //   //console.log("userId", userID);
  //   //console.log("count", count);
  // };
  // const Callbackwritemsg = async (val) => {
  //   //console.log("msg", val);

  // }
  return (
    <div className="chat">
      <ReactTitle title="MySpiritualConnent - Chat" />
      <section>
        <Jumbotron heading="CHAT" img={serviceJumboBg} />
      </section>
      <section className="py-md-5 pt-5">
        <div className="container">
          <h2>Chat</h2>
          <div className="row">
            <div className="col-md-4">
              <Userlist
                // callchat={Callbackchat}
                // callwrite={Callbackwrite}
                // count={count}
                setSenderId={setSenderId}
                setRecieverId={setRecieverId}
                chatlist={chatlist}
                setRoomId={setRoomId}
              />
            </div>
            <div className="col-md-8">
              {loading ? (
                <>
                  {/* <MessageHead /> */}
                  <MessageDisplay
                    roomid={roomid}
                    // userid={userID}
                    // name={name}
                    // count={count}
                    chatlist={chatlist}
                  />
                </>
              ) : (
                <h6 style={{ justifyContent: "center" }}>
                  { }tap on a chat from list to view the conversation
                </h6>
              )}

              <div className="pb-md-5">
                {roomid && senderId ? (
                  <MessageWrite
                    // session={session}
                    // name={name}
                    userid={userID}
                    userID={userID}
                    recieverId={recieverId}
                    roomid={roomid}
                  // userData={reactLocalStorage.getObject("userData")}
                  // count={count}
                  // callback={Callbackchat}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer2/> */}
    </div>
  );
};

export default Index;
