import React from "react";
import { Link } from "react-router-dom";

const ServicePagination = (props) => {
  const paginator = props.productpaginator;

  //console.log("paginator", paginator);

  const array = Array.from(Array(props.productpaginator.pageCount).keys());

  //console.log(array);

  const pages = (item) => {
    // //console.log("i", item);

    props.nextpage(item);
  };

  const previous = () => {
    const prev = paginator.prev;
    props.nextpage(prev);
  };

  const next = () => {
    const next = paginator.next;
    props.nextpage(next);
  };
  return (
    <div className="Pagination_sec">
      <nav aria-label="Page navigation example  ">
        <ul className="pagination flex-wrap my-5">
          {paginator.hasPrev ? (
            <li style={{width:20}} className="page-item mx-3 my-md-0 my-3 ">
              <a
                className="page-link"
                href="#"
                aria-label="Previous"
                onClick={previous}
              >
                <span aria-hidden="true">
                  <i className="fas fa-chevron-left"></i>
                </span>
              </a>
            </li>
          ) : null}

          {array.map((item, index) => {
            return (
              <li
                className="page-item mx-3  my-md-0 my-3"
                key={index}
                onClick={() => pages(item + 1)}
              >
                <a className="page-link" href="#">
                  {item + 1}
                </a>
              </li>
            );
          })}

          {paginator.hasNext ? (
            <li className="page-item mx-3  my-md-0 my-3">
              <a
                className="page-link "
                href="#"
                aria-label="Next"
                onClick={next}
              >
                <span aria-hidden="true">
                  <i className="fas fa-chevron-right"></i>
                </span>
              </a>
            </li>
          ) : null}
        </ul>
      </nav>
    </div>
  );
};
export default ServicePagination;
