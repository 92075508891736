import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const MorePopular = (props) => {
  //console.log("popular article", props.articleData);
  const popularArticle = props.articleData;
  const loop = [
    {
      date: "April 25, 2021",
      type: "true",
    },
    {
      date: "April 25, 2021",
      type: "true",
    },
    {
      date: "April 25, 2021",
      type: "true",
    },
    {
      date: "April 25, 2021",
      type: "false",
    },
  ];
  return (
    <div className="popular_box my-4 p-3  ">
      {popularArticle.length ? (
        popularArticle.map((item, index) => {
          return (
            <div
              className={
                item.type === "true"
                  ? "popular_text border-bottom"
                  : "popular_text"
              }
            >
              <h6>{moment(item.dated).format("LL")}</h6>
              <Link to={"/article-single" + item._id}>
                <h5>{item.title}</h5>
              </Link>
              <Link to={"/article-single" + item._id}>
                {" "}
                <p>Read More</p>
              </Link>
            </div>
          );
        })
      ) : (
        <div>
          <h1> No Article Found</h1>
        </div>
      )}
    </div>
  );
};
export default MorePopular;
