import React from "react";
import {ReactTitle} from 'react-meta-tags';
import Jumbotron from "../../../Component/Jumbotron";
import Footer2 from "../../../Default/Footer2/index";
import Navbar2 from "../../../Default/Navbar2/index";
import ServiceCard from "../../../Component/ServiceCard";
import Provider from "../../../Component/Provider";

import serviceJumboBg from "../../../Default/Assets/Images/Service/serviceJumboBg.png";
import service1 from "../../../Default/Assets/Images/Service/service1.png";

const Index = () => {
  const services = [0, 1, 2, 3];
  const provider = [0, 1, 2, 3];
  return (
    <div className="Favourite">
      <ReactTitle title="MySpiritualConnent - My Favourite"/>
      <section>
        <Jumbotron heading=" MY FAVOURITES" img={serviceJumboBg} />
      </section>
      <section className="py-5">
        <div className="container">
          <h2 className=" text-center">Favourite Services</h2>
          <div className="row mb-4">
            {services.map((item, index) => {
              return (
                <div className="col-md-3 mb-md-0 mb-4" key={index}>     
                  <ServiceCard img={service1} />
                </div>
              );
            })}
          </div>
          <div className="text-center mb-4 pt-2">
            <button className="btn PrimaryBtn">View More</button>
          </div>
        </div>
      </section>
      <section className="pb-5">
        <h2 className="mb-1 text-center">Favourite Shop</h2>
        <div className="container">
          <div className="row pt-3 mb-3">
            {provider.map((item, index) => {
              return (
                <div className="col-md-3" key={index}>
                  <Provider />
                  
                </div>
              );
            })}
          </div>
          <div className="text-center mb-4">
            <button className="btn PrimaryBtn">View More</button>
          </div>
        </div>
      </section>
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
