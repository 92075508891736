import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Firebase1, { fsdb } from "../../Chat/firebase";

import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "../../../utils/HttpClient";
import Modal from "./modal";
import BellIcon from "react-bell-icon";
import { RotatingLines } from "react-loader-spinner";
//import ModalPendingBooking from "./ModalPendingBooking";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Chat List", link: "#" },
        { title: "Manage Chat List", link: "#" },
      ],

      data: [],
      alert: false,
      message: "",
      type: "",
      chatlist: [],
      isLoading: false,
      isOpenLike: false,
      roomid: "",
      name: "",
      fullname: "",
      userid: "",
      sessionclose: false,
      allChaters: [],
      senderDetail: null,
      receiverDetail: null
    };
    // this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.getChats();
  };

  callbackLike = async () => {
    this.state.isOpenLike = false;
    this.setState({});

    //console.log(this.state.isOpenLike);
  };

  checkUser = async () => {
    let user = reactLocalStorage.getObject("userData");
    console.log("user", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user._id,
        name: user.firstName + " " + user.lastName,
      });
      this.fetchData(user._id);
    }
  };
  getChats = () => {
    this.setState({ isLoading: true })
    let myId = reactLocalStorage.getObject("userData")._id;
    console.log("myId", myId);
    var docRef = fsdb
      .collection("chat_users")
      .where("receiver_id", "==", myId)
      .onSnapshot((querySnapshot) => {
        // console.log("mama0",doc.docs)
        this.setState({ isLoading: false })
        let allChaters = [];
        querySnapshot.forEach((doc) => {
          allChaters.push(doc.data());
          // console.log();
        });
        console.log('allChaters', allChaters)
        const newData = allChaters.map((item) => {

          console.log("lkjhkjjlk", item)
          return {
            // ...item,
            name: item.sender_name,
            notification: item.notification ? <BellIcon width='40' active={true} animate={true} /> : "",
            reply: (
              <Button
                className="info"
                outline
                onClick={() => {
                  this.setState({ isOpenLike: true });
                  this.setState({
                    roomid: item.room_id,
                    userid: item.receiver_id,
                    senderDetail: item.sender_details,
                    receiverDetail: item.receiverDetail
                    // fullname: ele.val().name,
                    // sessionclose: ele.val().sessionclose,
                  });
                  //console.log(" isOpenLike", this.state.isOpenLike);
                }}
              >
                Reply
              </Button>
            ),
          };
        });
        this.setState({ allChaters: newData });
      });
    // docRef.onSnapshot((doc)=>{
    //   console.log("currentdata",doc.docs[0].data())
    // })

    // code write by surajit in this section we not get real time update
    // docRef
    //   .get()
    //   .then((querySnapshot) => {
    //     let arr = [];
    //     querySnapshot.forEach((doc) => {
    //       // doc.data() is never undefined for query doc snapshots
    //       console.log(doc.id, " => ", doc.data());
    //       arr.push({
    //         ...doc.data(),

    //         remoteId: doc.id,
    //         name: doc.data().name,
    //         lastMsg: doc.data().image?"...image": doc.data().msg[doc.data().msg.length - 1].msg,
    //         reply: (
    //           <Button
    //             className="info"
    //             outline
    //             onClick={() => {
    //               this.setState({ isOpenLike: true });
    //               this.setState({
    //                 roomid: doc.id,
    //                 // userid: ele.val().userId,
    //                 // fullname: ele.val().name,
    //                 // sessionclose: ele.val().sessionclose,
    //               });
    //               //console.log(" isOpenLike", this.state.isOpenLike);
    //             }}
    //           >
    //             Reply
    //           </Button>
    //         ),
    //       });
    //     });
    //     this.setState({ allChaters: arr });
    //   })
    //   .catch((error) => {
    //     console.log("Error getting documents: ", error);
    //   });
  };
  fetchData = async (id) => {
    // console.log("id", id);
    // console.log('fff',Firebase1.database())

    Firebase1.database()
      .ref()
      .child("chatlist")
      .child(this.state.userId + "S")
      .on("value", (snapshot) => {
        // console.log(snapshot,'snapshot')
        if (snapshot.exists()) {
          // console.log(snapshot.val(), "snap");

          let sl = 1;
          let arr = [];
          snapshot.forEach((ele) => {
            //console.log("val", ele.val());
            let dom = {
              sl: sl,
              name: ele.val().name,
              sessionclose: ele.val().sessionclose,
              lastMsg:
                ele.val().lastMsg != "" ? (
                  ele.val().lastMsg
                ) : (
                  <img
                    data-dz-thumbnail
                    src={HttpClient.IMG_URL + ele.val().forimage}
                    className="avatar-md rounded bg-light"
                    alt="preview"
                  />
                ),
              bell: (
                <BellIcon
                  active={ele.val().lastMsgBy == "User" ? true : false}
                  animate={ele.val().lastMsgBy == "User" ? true : false}
                  color={ele.val().lastMsgBy == "User" ? "#FF3333" : "#0c89e9"}
                  width={20}
                  height={20}
                />
              ),
              reply: (
                <Button
                  className="info"
                  outline
                  onClick={() => {
                    // this.state.isOpenLike = true;

                    this.setState({
                      roomid: ele.val().roomid,
                      userid: ele.val().userId,
                      fullname: ele.val().name,
                      sessionclose: ele.val().sessionclose,
                    });
                    //console.log(" isOpenLike", this.state.isOpenLike);
                  }}
                >
                  {ele.val().sessionclose
                    ? "Chat Session Ended View Messeages"
                    : "Reply"}
                </Button>
              ),
            };
            sl++;
            arr.push(dom);
            //console.log(arr);
          });
          this.state.chatlist = arr;
          this.setState({});
        } else {
          //console.log("No data available");
        }
      });
  };

  render() {
    console.log("chatlist", this.state.chatlist);
    const data = {
      columns: [
        // {
        //   label: "Sl.",
        //   field: "sl",
        //   sort: "asc",
        //   width: 150,
        // },

        {
          label: "Name",
          field: "name",
          sort: "asc",
          widtmsgh: 270,
        },
        //  {
        //   label: "Email",
        //   field: "email",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Notification Bell",
          field: "notification",
          sort: "asc",
          width: 270,
        },

        // {
        //   label: "Last Message",
        //   field: "lastMsg",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Action",
          field: "reply",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.allChaters,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>{" "}
          {this.state.isOpenLike ? (
            <Modal
              status={true}
              onCloseCallBack={this.callbackLike}
              roomid={this.state.roomid}
              image=""
              name={this.state.name}
              fullname={this.state.fullname}
              selleruserid={this.state.userId}
              userid={this.state.userid}
              sessionclose={this.state.sessionclose}
              senderDetail={this.state.senderDetail}
              sellerId={reactLocalStorage.getObject("userData")._id}
            />
          ) : null}
        </div>

        {this.state.isLoading ? <div className="Loader-Show" style={{ position: "absolute", bottom: 323, left: 380, }}><RotatingLines
          visible={true}
          height="96"
          width="120"
          color="red"
          strokeColor="violet"
          strokeWidth="5"
          animationDuration="1"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        /> </div> : null}
      </React.Fragment>
    );
  }
}

export default Chat;
