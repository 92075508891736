import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";

import { reactLocalStorage } from "reactjs-localstorage";
import Jumbotron from "../../Component/Jumbotron";
import ServiceCard1 from "../../Component/serviceCard1";
import Details from "./Components/Details";
// import renderHTML from 'react-render-html';
import ReactStars from "react-rating-stars-component";
import moment from "moment";

import HttpClient from "../../utils/HttpClient";

import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import dumy from "../../assets/blank-profile.png";
import service1 from "../../Default/Assets/Images/Service/service1.png";
import service2 from "../../Default/Assets/Images/Service/service2.png";
import service3 from "../../Default/Assets/Images/Service/service3.png";
import service4 from "../../Default/Assets/Images/Service/service4.png";

const Index = (props) => {
  const [popularServices, setPopularServices] = useState([]);
  const [sellerdetails, setsellerdetails] = useState([]);
  const [categoryname, setcategoryname] = useState("");

  const [oneServiceData, setoneServiceData] = useState({});
  const [sellerId, setsellerId] = useState(false);
  const [images, setimages] = useState([]);
  const [images1, setimages1] = useState([]);
  const [highlights, sethighlights] = useState([]);
  const [reviedData, setreviedData] = useState([]);
  const [video, setvideo] = useState("");
  const user = reactLocalStorage.getObject("userData");

  useEffect(() => {
    // let ab = reactLocalStorage.getObject("userData")
    // //console.log("ab", ab);
    fetchServiceListDetails(props.match.params.id);
    setDetailTab(oneServiceData.details);
    window.scrollTo(0, 0);
  }, []);

  const call = async (id) => {
    //console.log("id", id);
    window.location.href = "/service-details/" + id;
    // fetchServiceListDetails(id);
  };
  const fetchServiceListDetails = async (id) => {
    if (user && user != null && Object.keys(user).length > 0) {
      //console.log("service Id-----", id);
      let result = await HttpClient.requestData(
        "shop/shopservice-details/" + id + "?currency=" + user.currency,
        "GET"
      );

      if (result && result.status) {
        if (
          result.data[0]?.seller_details[0]?._id ==
          reactLocalStorage.getObject("userData")._id
        ) {
          setsellerId(true);
        }

        let data = result.data[0];
        let newArr = result.data[0].image;
        setimages(result.data[0].image);
        //console.log("already images", newArr);
        if (result.data[0].video != "") {
          newArr.push({
            preview: result.data[0].video,
            ismain: false,
            type: "video",
          });
        } else {
        }
        //console.log("new images ---", newArr);
        // if (data.hasOwnProperty("highlights")) {
        //   sethighlights(result.data[0].highlights)
        // } else {
        //     sethighlights([])
        //   }
        // let highlight = (data.hasOwnProperty("highlights")) ? result.data[0].highlights : [];
        sethighlights(
          data.hasOwnProperty("highlights") ? result.data[0].highlights : []
        );
        setsellerdetails(
          result.data[0].seller_details.length > 0
            ? result.data[0].seller_details[0]
            : ""
        );
        setoneServiceData(result.data[0]);
        setreviedData(result.data[0].review_data);

        setvideo(result.data[0].video);

        // //console.log('img arr', result.data[0].video);
        let arr = [];
        // //console.log('img arr', result.data[0].image);
        result.data[0].image.length > 0 &&
          result.data[0].image.forEach((element) => {
            if (element.ismain) {
              if (arr.length > 0) {
                arr[0] = { preview: element.preview, ismain: true };

                // //console.log("Local Arr with true 1", arr);
              } else {
                arr.push({ preview: element.preview, ismain: element.ismain });
                // //console.log("Local Arr with true 2", arr);
              }
            } else {
              if (arr.length > 0) {
                // //console.log("Already a true value inside loop");
              } else {
                arr.push({
                  preview: result.data[0].image[0].preview,
                  ismain: false,
                });
                // //console.log("Local Arr 3", arr);
              }
            }
          });
        setimages1(arr);
        fetchServiceCategories(result.data[0]);
      } else {
      }
    } else {
      //console.log(id);
      let result = await HttpClient.requestData(
        "shop/shopservice-details/" + id + "?currency=" + "USD",
        "GET"
      );
      //console.log("fetchServiceListDetails details-----", result.data[0]);

      if (result && result.status) {
        let data = result.data[0];
        // if (data.hasOwnProperty("highlights")) {
        //   sethighlights(result.data[0].highlights)
        // } else {
        //     sethighlights([])
        //   }
        // let highlight = (data.hasOwnProperty("highlights")) ? result.data[0].highlights : [];
        sethighlights(
          data.hasOwnProperty("highlights") ? result.data[0].highlights : []
        );
        setsellerdetails(
          result.data[0].seller_details.length > 0
            ? result.data[0].seller_details[0]
            : ""
        );
        setoneServiceData(result.data[0]);
        setreviedData(result.data[0].review_data);
        setimages(result.data[0].image);
        setvideo(result.data[0].video);

        // //console.log('img arr', result.data[0].video);
        let arr = [];
        // //console.log('img arr', result.data[0].image);
        result.data[0].image.length > 0 &&
          result.data[0].image.forEach((element) => {
            if (element.ismain) {
              if (arr.length > 0) {
                arr[0] = { preview: element.preview, ismain: true };

                // //console.log("Local Arr with true 1", arr);
              } else {
                arr.push({ preview: element.preview, ismain: element.ismain });
                // //console.log("Local Arr with true 2", arr);
              }
            } else {
              if (arr.length > 0) {
                // //console.log("Already a true value inside loop");
              } else {
                arr.push({
                  preview: result.data[0].image[0].preview,
                  ismain: false,
                });
                // //console.log("Local Arr 3", arr);
              }
            }
          });
        setimages1(arr);
        fetchServiceCategories(result.data[0]);
      } else {
      }
    }
  };

  const fetchServiceCategories = async (currentServiceData) => {
    let result = await HttpClient.requestData("service-category", "GET");

    let cur_data = [];
    if (result && result.status) {
      cur_data = result.data.filter(
        (item) => item._id == currentServiceData.category_id
      );

      setcategoryname(cur_data[0].name);
      //fetchServicesByCategoryId(cur_data[0]._id, currentServiceData);
      popularProduct();
    } else {
    }
  };

  const popularProduct = async () => {
    //console.log("hii");

    let result = await HttpClient.requestData(
      "shop/top-services?currency=" + user.currency,
      "GET"
    );
    //console.log("tyu", result);
    if (result && result.status) {
      //console.log("resultdata", result.data);
      setPopularServices(result.data);
    }
  };

  const services = [
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
    { imags: service4 },
  ];

  const [detailTab, setDetailTab] = useState(oneServiceData.details);
  useEffect(() => {
    setDetailTab(oneServiceData.details);
  }, [oneServiceData]);

  return (
    <div className="ServiceDetails">
      <ReactTitle title="MySpiritualConnent - Service Details" />
      <section>
        <Jumbotron
          heading="ServiceDetails"
          img={serviceJumboBg}
          serviceCategory={categoryname}
        />
      </section>
      <section className=" container">
        <Details
          sellerId={sellerId}
          video={video}
          images1={images1}
          images={images}
          oneData={oneServiceData ? oneServiceData : ""}
          sellerdetails={sellerdetails}
          hightligts={highlights}
        />
        <div className="DetailsTabCon">
          <div className="borderBottom">
            <span
              className={
                detailTab === oneServiceData.details
                  ? "DetailsTab active"
                  : "DetailsTab"
              }
              onClick={() => setDetailTab(oneServiceData.details)}
            >
              DESCRIPTION
            </span>
            {/* <span
              className={
                detailTab === "SPECIFICATION"
                  ? "DetailsTab active"
                  : "DetailsTab"
              }
              onClick={() => setDetailTab("SPECIFICATION")}
            >
              SPECIFICATION
            </span> */}
            <span
              className={
                detailTab === "REVIEWS" ? "DetailsTab active" : "DetailsTab"
              }
              onClick={() => setDetailTab("REVIEWS")}
            >
              TESTIMONIALS
            </span>
          </div>
          <div className="py-4">
            {detailTab == oneServiceData.details ? (
              <p dangerouslySetInnerHTML={{ __html: oneServiceData.details }}>
                {/* {oneServiceData.details} */}
                {/* {oneServiceData.details ? renderHTML(oneServiceData.details) : ""} */}
              </p>
            ) : detailTab == "REVIEWS" ? (
              reviedData.length ? (
                reviedData.map((item) => {
                  return (
                    <>
                      <div className="testiMonialUserprofile">
                        {item.user_data[0].profile != "" ? (
                          <img
                            src={HttpClient.IMG_URL + item.user_data[0].profile}
                            alt=""
                          />
                        ) : (
                          <img src={dumy} alt="" />
                        )}

                        <h3 className="profileName">
                          {item.user_data[0]
                            ? item.user_data[0].firstName +
                              " " +
                              item.user_data[0].lastName
                            : ""}
                        </h3>
                      </div>
                      <h5>{moment(item.rev_date).format("ll")}</h5>
                      <ReactStars
                        count={5}
                        value={item.rating}
                        edit={false}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ff7e00"
                      />
                      <p>{item.comment}</p>
                    </>
                  );
                })
              ) : (
                <p>No review found</p>
              )
            ) : (
              <p dangerouslySetInnerHTML={{ __html: oneServiceData.details }}>
                {/* {oneServiceData.details} */}
                {/* {oneServiceData.details ? renderHTML(oneServiceData.details) : ""} */}
              </p>
            )}
          </div>
        </div>
      </section>
      {/* {popularServices.length > 0 ? (
        <section className="container pb-5 mb-5">
          <h2 className="mb-4">OTHER SERVICE OFFERED BY SELLER</h2>
          <div className="row">
            {popularServices.map((item, index) => {
              return (
                <div className="col-md-3 mb-4" key={index}>
                  <ServiceCard1
                    callback={call}
                    fullitem={item}
                    serviceData={item.service_data}
                  />
                </div>
              );
            })}
          </div>
        </section>
      ) : (
        ""
      )} */}

      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
