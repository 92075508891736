import React, { useState, useEffect } from "react";
import HttpClient from "./../../utils/HttpClient";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
const ItemCondition = () => {
  const [result, setresult] = useState("");
  useEffect(() => {
    scrollTop();
    fetchData();

  }, []);

  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  const fetchData = async () => {

    let result = await HttpClient.requestData(
      "terms-and-condition", "GET"
    );
    //console.log("about", result);
    if (result && result.status) {
      setresult(result.data[0].description)
    }
  }
  return (
    <div>
      <section>
        <Jumbotron heading="Terms & Conditions" img={serviceJumboBg} />
      </section>
      <section>
        <div className="container-fluid my-5">
          <div className="row px-3">
            <div className="col-md-10 mx-auto" dangerouslySetInnerHTML={{__html: result}}>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>{" "}
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p> */}
              {/* {result} */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ItemCondition;
