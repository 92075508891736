import React from 'react'
import Sidebar from '../../Default/Sidebar/index'
import Subscription from './Subscription'

function index() {
  return (
    <main className='main-section'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-xl-3 col-lg-3 col-12'>
                    <Sidebar/>
                </div>
                <div className='col-xl-9 col-lg-9 col-12'>
                    {/* <Subscription/> */}
                </div>
            </div>
        </div>
    </main>
  )
}

export default index