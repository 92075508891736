import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Subscription from "../../Pages/Seller/Subscription";
import HttpClient from "../../utils/HttpClient";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";


import { useUserFetch } from "../../Providers/User";
import DynamicSidebarButton from "../../Component/DynamicSidebarButton";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function Sidebar() {

  const locationData = useLocation()
  console.log(locationData, "ooppw")
  const [isOpen, setisOpen] = useState(false)

  useEffect(() => {
    if (locationData.pathname == "/seller/pending-booking"
      || locationData.pathname == "/seller/accept-booking" ||
      locationData.pathname == "/seller/cancel-booking") {
      setisOpen(true)
    }
  }, [locationData.pathname])


  const { subscriptionTaken, setSubscriptionTaken } = useUserFetch();
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    event.currentTarget.classList.toggle("active");
  };

  // //console.log("contect",userData);

  useEffect(() => {
    // fetch()
    fetchSublist();
  }, []);
  //  const fetch =  async()=>{
  //     let user = reactLocalStorage.getObject("userData")
  // if(user._id){
  //   let result = await HttpClient.requestData(`singleUserProfile/${user._id}`,"GET")
  //   //console.log("userData1",result);
  //   if(result.status){
  //     setUserCredential(result.result)
  //   }

  // }

  //   }

  const fetchSublist = async () => {
    const userdata = reactLocalStorage.getObject("userData");
    //  //console.log('userDataaaa',userdata);
    // //console.log("idd", userdata._id);

    let data = {
      currency: userdata.currency,
    };
    let result = await HttpClient.requestData(
      "listSubs/" + userdata._id,
      "GET",
      data
    );
    //console.log("sdebarsubscriptionList", result);
    if (result && result.status) {
      let data = result.data;
      //console.log("dataSidebar", data);
      let subscribe = false;

      data.forEach((sub) => {
        if (sub.speakers.length > 0) {
          subscribe = true;
          setSubscriptionTaken(true);
          //  this.setState({isSubscribe:true})
          // //console.log('isSubscribe',this.state.isSubscribe);
        } else {
          // window.location.pathname !=="/subscription-plan"
          // history.push("/seller/subscription-plan")
          // this.props.history.push = "/subscription-plan"
          // window.location.href="/subscription-plan"
        }
      });
      if (!subscribe && window.location.pathname !== "/subscription-plan") {
        history.push("/seller/subscription-plan");

        //  window.location.href="/subscription-plan"
      }
    } else {
    }
  };
  return (
    <div className="seller_LeftBar py-3">
      <div className="text-center">
        <img
          src={logo}
          alt="img"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: " 190px",
            height: "45px",
            // borderRadius: "50%",
          }}
        />
      </div>
      <div className="border-bottom text-center p-2">
        <h5>{`${reactLocalStorage.getObject("userData").firstName} ${reactLocalStorage.getObject("userData").lastName
          }`}</h5>
        <p>{reactLocalStorage.getObject("userData").email}</p>
      </div>
      {subscriptionTaken ? (
        <div
          className="d-flex flex-column menu_section"
          style={{ padding: "20px 20px 200px" }}
        >
          <Link to="/seller/dashboard" className="btn left_menu text-left">
            <i className="fas fa-print mr-2" /> Dashboard
          </Link>
          <Link
            to="/seller/shopservice-category"
            className="btn left_menu text-left"
          >
            <i className="fas fa-print mr-2" /> Shop Service
          </Link>
          <Link
            to="/seller/subscription-plan"
            className="btn left_menu text-left"
          >
            <i className="fas fa-print mr-2" />
            Subscription Plan
          </Link>{" "}
          <Link to="/seller/slot-manage" className="btn left_menu text-left">
            <i className="fas fa-print mr-2" />
            Create Slot
          </Link>
          <Link
            to="/seller/off-week-days-manage"
            className="btn left_menu text-left"
          >
            <i className="fas fa-print mr-2" />
            Off Week days
          </Link>{" "}
          <Link to="/seller/order-history" className="btn left_menu text-left">
            <i className="fas fa-print mr-2" />
            Order History
          </Link>{" "}
          <div className="dropdown "
          // onClick={(e) => {
          //   e.preventDefault()
          //   e.stopPropagation()
          //   setisOpen(!isOpen)
          // }}
          >

            <a
              className="btn left_menu text-left"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fab fa-first-order-alt mr-2" /> Seller Booking
            </a>
            <div

              className={"pl-4 dropdown-menu" + isOpen ? " active" : ""}
              aria-labelledby="dropdownMenuButton"
            >
              {/* <div className="btn left_menu text-left active">
                <Link to="/seller/pending-booking">
                  <i className="fas fa-chevron-right mr-2" /> Manage Pending
                  Booking
                </Link>
              </div> */}

              <DynamicSidebarButton to="/seller/pending-booking">Manage Pending Booking</DynamicSidebarButton>
              <DynamicSidebarButton to="/seller/accept-booking">Manage Accepted
                Booking</DynamicSidebarButton>
              <DynamicSidebarButton to="/seller/cancel-booking">Manage Cancel
                Booking</DynamicSidebarButton>



              {/* <div className="btn left_menu text-left">
                <Link to="/seller/accept-booking">
                  <i className="fas fa-chevron-right mr-2" /> Manage Accepted
                  Booking
                </Link>
              </div>{" "} */}
              {/* <div className="btn left_menu text-left">
                <Link to="/seller/cancel-booking">
                  <i className="fas fa-chevron-right mr-2" /> Manage Cancel
                  Booking
                </Link>
              </div> */}
            </div>
          </div>
          <Link to="/seller/wallet" className="btn left_menu text-left">
            <i className="fas fa-print mr-2" />
            My Wallet
          </Link>{" "}
          <Link
            to="/seller/withdraw-balance"
            className="btn left_menu text-left"
          >
            <i className="fas fa-print mr-2" />
            Withdraw Balance
          </Link>{" "}
          <Link to="/seller/user-review" className="btn left_menu text-left">
            <i className="fas fa-print mr-2" />
            Manage User Review & Rating
          </Link>
          <Link to="/seller/create-meeting" className="btn left_menu text-left">
            <i className="fas fa-print mr-2" />
            Create Meeting
          </Link>
          <Link to="/seller/chat" className="btn left_menu text-left">
            <i className="fas fa-print mr-2" />
            Chat
          </Link>

        </div>
      ) : (
        <div className="d-flex flex-column" style={{ padding: 20 }}>
          <Link
            to="/seller/subscription-plan"
            className="btn left_menu text-left"
          >
            <i className="fas fa-print mr-2" />
            Subscription Plan
          </Link>{" "}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
