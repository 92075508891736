import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "./../../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const Slot = (props) => {
  const userData = reactLocalStorage.getObject("userData");
  //console.log("SlotData", props.SlotData);
  //console.log("ServicesId", props.shopServiceId);
  //console.log("ServicesData", props.serviceData);
  //console.log("Seller Detail",props.sellerfulldetails);
  let slotTimesArray = props.SlotData;
  let servicesObject = props.serviceData;
  let servicesId = props.shopServiceId;
  const [slotTimes, setSlotTimes] = useState([]);
  const [servicesData, setServicesData] = useState({});
  const [bookedServicesData, setBookedServicesData] = useState({});
  const [bookedSlot, setBookedSlot] = useState({});

  const [selectedslot, setselectedslot] = useState("");
  const [isBooked, setisBooked] = useState(false);
  const [alreadyBooked, setalreadyBooked] = useState(false);

  useEffect(() => {
    //console.log(props.serviceData);
    //console.log("Seller Detail",props.sellerfulldetails);
    setSlotTimes(slotTimesArray);
    setServicesData(servicesObject);
    // ServicesData(servicesData,servicesId);
    ////console.log("ServicesData....",servicesData);
    getSlot();
  }, []);
  //console.log("SlotTime", slotTimes);

  const getSlot = async () => {
    props.SlotData.forEach((element) => {
      //console.log("hii");
      setselectedslot("");
      setBookedSlot({});
      // if (element.booking_info.length > 0) {
      //   //setisBooked(true);
      //   // setselectedslot(element._id);
      // }
      // //console.log("hii", isBooked);
    });
  };
  const slotBooking = async (item, index) => {
    //console.log("id----",item._id);
    let userData = reactLocalStorage.getObject("userData");

    setselectedslot(item._id);

    //setSlotTimes(slotTimes);
    let convertedTime = moment(item.timing.from, 'hh:mm A').format('HH:mm');
    //console.log("convertedTime",convertedTime);
    //console.log("item service-----", item);
    setBookedSlot({
      user_id: userData._id,
      shop_service_id: item.shop_service_id,
      slot_id: item._id,
      seller_id: props.sellerfulldetails ? props.sellerfulldetails._id : "",
      shop_service_name: props.serviceData ? props.serviceData.name : "",
      price: props.serviceData ? props.serviceData.price : "",
      image: props.serviceData ? props.serviceData.image : "",
      day_name_of_booking: item.weekday_name,
      from: item.timing.from,
      to: item.timing.to,
      duration: item.slot_duration,
      date_of_booking: props.date,
      currency: userData.currency,
    });
    // //console.log("bookedslot", bookedSlot);
  };

  const slotBooked = async () => {
    //console.log("asd", selectedslot);
    if (selectedslot != "") {
      //console.log("bookedslot", bookedSlot);
      let result = await HttpClient.requestData(
        "shop-service/book-slot",
        "POST",
        bookedSlot
      );
      //console.log("result", result);
      if (result && result.status) {
        //console.log("slot Booked", result.data);
        // props.CallBackSlot(false);
        toast.success("Slot selected successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        //  ServicesData();
        setTimeout(function () {
          window.location.href = "/cart";
        }, 1000);
      } else {
        //console.log("slotTimes", slotTimes);
        setBookedSlot({});
        setselectedslot("");
        toast.error(

          "Sorry!You have already a booked slot,try again with another service",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } else {
      // props.CallBackSlot(false)

      toast.warning("Select the Slot first", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="Slot">
      <div className="modal-dialog">
        <div className="modal-content pb-4 pt-4 px-4">
          <div className="">
            <span></span>
            <button
              type="button"
              className="close Single_close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.CallBackSlot(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <h5 className="text-center mb-4">Available Slots</h5>
            <div className="modal-body p-0">
              {slotTimes.map((item, index) => {
                return (
                  <button
                    type="button"
                    //disabled={item._id==selectedslot?true:false }
                    title={
                      item._id == selectedslot || item.booking_info.length > 0
                        ? "Already Booked"
                        : "Slot Available for Booking"
                    }
                    className={`btn ${item._id == selectedslot || item.booking_info.length > 0
                      ? "btn-danger"
                      : "btn-success"
                      } btn-sm slot_btn`}
                    key={index}
                    onClick={() => {
                      //Start
                      // let time = props.date + " " + item.timing.from;
                      // let time1 = moment(time).format("YYYY-MM-DD HH:mm");
                      // let time_1 = moment(time1);
                      // let time2 = moment().format("YYYY-MM-DD HH:mm");
                      // let time3 = moment(time2);
                      // if (moment(time3).isAfter(time_1)) {
                      //   // alert("passed");
                      //   toast.warning("You Cant Book A Slot Already passed", {
                      //     position: "bottom-right",
                      //     autoClose: 2000,
                      //     hideProgressBar: false,
                      //     closeOnClick: true,
                      //     pauseOnHover: true,
                      //     draggable: true,
                      //     progress: undefined,
                      //   });
                      // } else {
                      //end

                      if (item.booking_info.length == 0) {
                        // //console.log("hii 2");
                        slotBooking(item, index);
                      } else {
                        // //console.log("ABCD");
                        toast.warning("This Slot is Already Booked", {
                          position: "bottom-right",
                          autoClose: 3000,
                        });
                      }
                      //start

                      // }
                      //End
                    }}

                  >
                    {item.timing.from}-{item.timing.to}
                  </button>
                );
              })}
            </div>
          </div>

          <div className=" pt-3 text-center">
            {isBooked ? (
              <Link
                className="btn PrimaryBtn"
              // to="/checkout"
              // onClick={slotBooked}
              >
                You have a booked slot already
              </Link>
            ) : (
              <Link className="btn PrimaryBtn" onClick={slotBooked}>
                Book Slot
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slot;
