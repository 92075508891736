import React, { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import BreadCrump from "../../Component/BreadCrump";
import DashboardBox from "../../Component/DashboardBox";
import HttpClient from "../../utils/HttpClient";
import { RotatingLines } from "react-loader-spinner";

function Dashboard() {
  const [dashData, setDashData] = useState([]);
  const [isLoading, setisLoading] = useState(false)
  console.log("hhjkjkk", isLoading)
  const boxes = [
    {
      title: "All services",
      value: 0,
    },
    {
      title: "Total Views",
      value: 0,
    },
    {
      title: "Total Active Services",
      value: 0,
    },
    {
      title: "Total Inactive Services",
      value: 0,
    },
    {
      title: "Total Revenue",
      value: 0,
    },
    {
      title: "Total Order",
      value: 0,
    },
    {
      title: "Total Earning",
      value: 0,
    },
    {
      title: "Earning Settled",
      value: 0,
    },
    {
      title: "Pending",
      value: 0,
    },
    {
      title: "Claimable Amount",
      value: 0,
    },
    {
      title: "Refund Amount",
      value: 0,
    },
    {
      title: "Total Tips",
      value: 0,
    },
    {
      title: "Total Order Accept",
      value: 0,
    },
    {
      title: "Total Order Rejected",
      value: 0,
    },
    {
      title: "Total Order Cancelled",
      value: 0,
    },
    {
      title: "Total Order Pending",
      value: 0,
    },
    {
      title: "Processing Fees",
      value: "45%",
    },
  ];
  useEffect(() => {

    checkUser();
  }, []);
  const checkUser = () => {

    let user = reactLocalStorage.getObject("userData");
    getDashboardData(user._id);
  };
  const getDashboardData = async (id) => {
    setisLoading(true)
    let result = await HttpClient.requestData(
      "seller_account/booking-stat/" + id,
      "GET"
    );

    //console.log("Booking Accept", result);
    if (result && result.status) {
      setDashData(objToArr(result));
      setisLoading(false)
    } else {
      setisLoading(false)
      setDashData(objToArr(result));
    }
  };

  const objToArr = (obj) => {
    let arr = Object.entries(obj);
    //console.log(arr);
    //console.log(arr[0][0]);
    let semifinal = arr.map((item, ind) => {
      return {
        name: item[0],
        value: item[1],
      };
    });
    let final = semifinal.filter(
      (item) => item.name != "status" && item.name != "message"
    );
    return final;
  };
  const FormatText = (txt) => {
    let spl = txt.split("_");
    let outPut = "";
    spl.forEach((element) => {
      outPut +=
        element.charAt(0).toUpperCase() +
        element.slice(1, element.length) +
        " ";
    });
    return outPut
  };

  return (
    <div className="dashboard_over seller">
      <BreadCrump main="Dashboard" />
      <div className="row my-4">
        <div className="col-md-12">
          <div className="_designoverview d-flex align-items-center mb-3">
            {/* <h5 className="mb-0">Stats overview for</h5> */}
            {/* <select className="form-controls ml-3" style={{ maxWidth: 120, padding: "5px 10px", borderRadius: "4px", }}>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="last_month">Last Month</option>
              <option value="custom">Custom</option>
            </select> */}
          </div>
          <div className="row">
            {/* <a href="/seller-service"> */}

            {dashData?.length > 0 &&
              dashData.map(({ name, value }, ind) => {
                return <DashboardBox key={ind} title={FormatText(name)} value={value} />;
              })}
          </div>
        </div>
      </div>
      {/* <div className="row mt-5">
        <div className="col-md-12 text-left">
          <div className="d-flex align-items-center">
            <h5>Total Income History</h5>
          </div>
        </div>
        <div className="col-md-12 mx-auto mt-3">
          <table className="table-responsive table">
            <thead>
              <tr>
                <th>Yearly Total</th>
                <th>Monthly Total</th>
                <th>Weekly Total</th>
                <th>Yesterday Total</th>
                <th>Today Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>₹ 0</td>
                <td>₹ 0</td>
                <td>₹ 0</td>
                <td>₹ 0</td>
                <td>₹ 0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="_rcnt_act py-4">
        <h5 className="mb-4">Recent Activity</h5>
        <div>
          <div>
            <div className="d-flex align-items-center mb-3">
              <div className="ml-3">
                <p className="text-dark mb-0">
                  <a href="/"> Service Name: Birth Chart Reading </a>
                </p>
                <p className="mb-0">
                  <span className="text-dark">ghdtutydrtr</span>
                </p>
                <div
                  className="react-stars-wrapper-08811501012060843"
                  style={{ display: "flex" }}
                >
                  <div
                    tabIndex={0}
                    aria-label="add rating by typing an integer from 0 to 5 or pressing arrow keys"
                    className="undefined react-stars"
                    style={{ overflow: "hidden", position: "relative" }}
                  >
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n    .react-stars-08811501012060843:before {\n      position: absolute;\n      overflow: hidden;\n      display: block;\n      z-index: 1;\n      top: 0; left: 0;\n      width: 50%;\n      content: attr(data-forhalf);\n      color: #ffd700;\n  }",
                      }}
                    />
                    <span
                      className=""
                      data-index={0}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <span
                      className=""
                      data-index={1}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <span
                      className=""
                      data-index={2}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <span
                      className=""
                      data-index={3}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <span
                      className=""
                      data-index={4}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <p
                      role="status"
                      style={{ position: "absolute", left: "-200rem" }}
                    >
                      5
                    </p>
                  </div>
                </div>
                <span className="text-muted">Jan 27, 2023</span>
                <p />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="d-flex align-items-center mb-3">
              <div className="ml-3">
                <p className="text-dark mb-0">
                  <a href="/"> Service Name: Gemstone </a>
                </p>
                <p className="mb-0">
                  <span className="text-dark">Fine</span>
                </p>
                <div
                  className="react-stars-wrapper-0619338860993784"
                  style={{ display: "flex" }}
                >
                  <div
                    tabIndex={0}
                    aria-label="add rating by typing an integer from 0 to 5 or pressing arrow keys"
                    className="undefined react-stars"
                    style={{ overflow: "hidden", position: "relative" }}
                  >
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n    .react-stars-0619338860993784:before {\n      position: absolute;\n      overflow: hidden;\n      display: block;\n      z-index: 1;\n      top: 0; left: 0;\n      width: 50%;\n      content: attr(data-forhalf);\n      color: #ffd700;\n  }",
                      }}
                    />
                    <span
                      className=""
                      data-index={0}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <span
                      className=""
                      data-index={1}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <span
                      className=""
                      data-index={2}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <span
                      className=""
                      data-index={3}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <span
                      className=""
                      data-index={4}
                      data-forhalf="★"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        display: "block",
                        float: "left",
                        color: "rgb(255, 215, 0)",
                        fontSize: 24,
                      }}
                    >
                      ★
                    </span>
                    <p
                      role="status"
                      style={{ position: "absolute", left: "-200rem" }}
                    >
                      5
                    </p>
                  </div>
                </div>
                <span className="text-muted">Jan 27, 2023</span>
                <p />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {isLoading ? <div className="Loader-Show" style={{ position: "absolute", bottom: -93, left: 380, zIndex: 99 }}><RotatingLines
        visible={true}
        height="96"
        width="120"
        color="red"
        strokeColor="violet"
        strokeWidth="5"
        animationDuration="1"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      /> </div> : null}

    </div>
  );
}

export default Dashboard;
