import React, { useEffect } from "react";
import { Redirect, Route, Switch,useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Sidebar from "../Default/Sidebar/Sidebar";
import SellerRoutes from "../SellerRoutes";
import { useUserFetch } from "../Providers/User";
import HttpClient from "../utils/HttpClient";

function SellerLayout() {
  const navigate = useHistory()
  const {userCredential,setUserCredential} = useUserFetch()

//  const stat=reactLocalStorage.getObject("userData").seller_approval
  // window.scrollTo(0, 0);
  useEffect(()=>{
    fetchUser()

  },[])

  const fetchUser = async()=>{
    let user = reactLocalStorage.getObject("userData")
    if(user._id){
      let result = await HttpClient.requestData("singleUserProfile/"+user._id,"GET")
      if(result.status){
        if(result.result.seller_approval){
          setUserCredential(result.result)

        }
        else{
          navigate.push("/")
        }
      }
    }
  }
  return (
    <main className="main-section">
 <div className="container-fluid">
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-12">
          <Sidebar />
        </div>
        <div
          className="col-xl-9 col-lg-9 col-12"
          style={{ background: "aliceblue", height:"auto" }}
        >
          {/* <Subscription/> */}
          <Switch>
            {SellerRoutes.map((route, index) => {
              return route.component ? (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => <route.component {...props} />}
                />
              ) : null;
            })}
            {/* <Redirect to="/home" from="/" /> */}
          </Switch>
        </div>
      </div>
    </div>
    
   
     
    </main>
  );
}

export default SellerLayout;
