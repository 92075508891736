import React, { useState } from 'react'
import { MDBDataTableV5 } from "mdbreact";

function Contactus() {
  const [dataa, setdata] = useState([]);
  const data = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },
      {
        label: "Name",
        field: "Name",
        width: 270,
      },
      {
        label: "Email",
        field: "Emaiil",
        width: 270,
      },
      {
        label: "Query",
        field: "Query",
        width: 270,
      },
      {
        label: "Action",
        field: "Action",
        width: 270,
      },
      {
        label: "Reply",
        field: "Reply",
        width: 270,
      },
    ],
    rows: dataa,
  };


  return (
    <div className='Add_service  '>
      <div className='row mt-3'>
        <div className="col-md-12 text-center">
          <h1>Contact Us</h1>
        </div>
      </div>

      <div className="col-md-8 mx-auto">
        <div className="form-group my-4">
          <input
            type="text"
            className="form-control _borderRed"
            id="exampleInput"
            aria-describedby="emailHelp"
            placeholder="Your Name"
            defaultValue=""
          />
        </div>
        <div className="form-group my-4">
          <input
            type="email"
            className="form-control _borderRed"
            id="email"
            placeholder="Enter your email"
            defaultValue=""
          />
        </div>
        <div className="form-group my-4">
          <select className="form-control _borderRed" id="exampleFormControlSelect1">
            <option value="">Select Catagory</option>
            <option value="">Spell Casting</option>
            <option value="">Vastu</option>
            <option value="">Birth Chart Reading</option>
            <option value="">Blind Reading</option>
            <option value="">Electric Engineer</option>
          </select>
        </div>
        <div className="form-group my-4">
          <select className="form-control _borderRed" id="exampleFormControlSelect1">
            <option>Select Sub Catagory</option>
          </select>
        </div>
        <div className="form-group my-4">
          <textarea
            className="form-control _borderRed"
            id="exampleFormControlTextarea1"
            rows={3}
            placeholder="Write here.."
            defaultValue={""}
          />
        </div>
        <div className="d-flex justify-content-between my-5">
          <button className="btn publish_btn">Submit</button>
        </div>
      </div>

      <div className='col-md-12 text-center mt-5'>
        <div className='col-md-12 text-center'>
          <h1>Contact Us Detail</h1>
        </div>

        <div className='col-md-8 mx-auto'>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={data}
          />
        </div>
      </div>

    </div>
  )
}

export default Contactus