import { configureStore, } from '@reduxjs/toolkit';
import reducer from "../reducer";
import IceServerReducer from '../reducer/IceServerData';


const Store = configureStore({
  reducer,
  IceServerReducer
})


export default Store;