import React from 'react'

function Myservices() {
  return (
    <div>
      <div className="row">
        <div className="col-md-12 text-center mb-4">
          <h1>My Services</h1>
          <a className="add_new_serv" href="/seller/services">
            Add new service
            <i className="fas fa-chevron-right ml-2" />
          </a>
        </div>
      </div>

      
    </div>
  )
}

export default Myservices