import React, { useEffect, useState } from "react";
import Firebase, { fsdb } from "../firebase";
import moment from "moment";
import HttpClient from "../../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import SessionClose from "./sessionclose";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";

const MessageWrite = ({ props, userID, recieverId, roomid }) => {

  const [message, setmessage] = useState("");
  const [firstFile, setfirstFile] = useState("");
  const [filetype, setfiletype] = useState("");
  const [imageUrl, setImageUrl] = useState('');
  const [selectedFiles, setselectedFiles] = useState([]);
  const [msgimg, setMsgImg] = useState(false)
  // const [count, setcount] = useState(props.count);
  const [modal, setmodal] = useState(false);
  const [confirm, setconfirm] = useState(false);
  const [sessionclose, setsessionclose] = useState(false);

  // console.log('iddd',senderId,recieverId)

  // useEffect(() => {
  //   //console.log("prev page write", props.count);
  //   //console.log("prev page write", props.name);
  //   //console.log("prev page write", props.roomid);
  //   //console.log("prev page write", props.userid);
  //   //console.log("prev page write", props.userData);
  //   //console.log("gg", props.session)
  //   //console.log("firstfile", firstFile);
  //   setsessionclose(props.session);
  // }, [props.count]);

  const addMessage = () => {
    if (!message && !msgimg) {
      // alert('error')
      Swal.fire('Please enter message');
    }
    else {

      let ob = {
        msg: message,
        time: moment().format(),
        sender_id: userID,
        receiver_id: recieverId,
        image: msgimg
      };


      // console.log('dataaa',ob)
      fsdb
        .collection("chat")
        .doc(roomid)
        .collection("messages")
        .add(ob)
        .then(() => {
          // console.log('done')
          setmessage('');
          setMsgImg(false)
          setImageUrl("")
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  }
  // console.log('time', moment().format());

  const uploadFile = async (e) => {
    console.log("hii", e.target.files[0]);
    let data = new FormData();
    data.append("image", e.target.files[0]);
    let result = await HttpClient.fireBaseImage(
      "image-uploadurl",
      "POST",
      data
    );
    // console.log('image', result.data)
    //console.log(result);
    if (result && result.status) {
      // Send(result.data);
      setImageUrl(HttpClient.IMG_URL + result.data);
      // setmessage(HttpClient.IMG_URL + result.data);
      setmessage(HttpClient.IMG_URL + result.data)
      setMsgImg(true)

    }
  };
  // console.log('HttpClient.IMG_URL',HttpClient.IMG_URL)


  // const Send = async (url) => {
  //   //alert("hiii");
  //   let date = Date.now();
  //   let date1 = moment(date).format("YYYY-MM-DD");
  //   let date2 = moment(date).format("HH:mm:ssZ");
  //   let msgTime = date1 + "T" + date2;
  //   let fname = props.userData ? props.userData.firstName : "";
  //   let lname = props.userData ? props.userData.lastName : "";


  //   let msgid = Firebase.database()
  //     .ref()
  //     .child("chat")
  //     .child(props.roomid)
  //     .child("messages")
  //     .push().key;
  //   //console.log(msgid);
  //   //now we setting the msg body into the perticular msg id
  //   Firebase.database()
  //     .ref()
  //     .child("chat")
  //     .child(props.roomid)
  //     .child("messages")
  //     .child(msgid)
  //     .set(
  //       {
  //         sender_id: props.userData ? props.userData._id : "",
  //         message: message,
  //         msgid: msgid,
  //         send_time: msgTime,
  //         roomid: props.roomid,
  //         name: fname + " " + lname,
  //         msgimg: filetype == "image/jpeg" ||
  //           filetype == "image/png"
  //           ? url
  //           : "",

  //       },
  //       (error) => {
  //         if (error) {
  //           alert("something went wrong");
  //         } else {
  //           //this.scrollToBottom();
  //           setmessage("");
  //           setfirstFile("");
  //           setselectedFiles([]);
  //           //alert("message has been sent successfully");
  //         }
  //       }
  //     );



  //   //////updating

  //   if (message != "") {

  //     Firebase.database().ref().child("chatlist").child(props.userData._id + "U").child(props.userid)
  //       .update({
  //         //displayName: props.name,
  //         lastMsg: message,
  //         lastMsgTime: msgTime,
  //         forimage: "",
  //         lastMsgBy: "User",
  //         // name: props.name,
  //         // roomid: props.roomid,
  //         // userId: props.userid,

  //       },
  //         (error) => {
  //           if (error) {
  //             alert("something went wrong");
  //           } else {
  //             //this.scrollToBottom();
  //             //console.log("mesage", message);
  //             //alert("updated");

  //           }
  //         }
  //       );
  //   }
  //   else {
  //     Firebase.database().ref().child("chatlist").child(props.userData._id + "U").child(props.userid)
  //       .update({
  //         //displayName: props.name,
  //         lastMsg: message,
  //         lastMsgTime: msgTime,
  //         forimage: url,
  //         lastMsgBy: "User",
  //         // name: props.name,
  //         // roomid: props.roomid,
  //         // userId: props.userid,

  //       },
  //         (error) => {
  //           if (error) {
  //             alert("something went wrong");
  //           } else {
  //             //this.scrollToBottom();
  //             //console.log("mesage", message);
  //             //alert("updated");

  //           }
  //         }
  //       );
  //   }

  //   //Updating the chat list data of seller side--
  //   if (message != "") {
  //     let sellerid = props.userid ? props.userid + "S" : "";
  //     Firebase.database().ref().child("chatlist").child(sellerid).child(props.userData._id).
  //       update({
  //         lastMsg: message,
  //         lastMsgTime: msgTime,
  //         forimage: "",
  //         lastMsgBy: "User",
  //       });
  //   }
  //   else {
  //     let sellerid = props.userid ? props.userid + "S" : "";
  //     Firebase.database().ref().child("chatlist").child(sellerid).child(props.userData._id).update({
  //       lastMsg: message, lastMsgTime: msgTime, forimage: filetype == "image/jpeg" ||
  //         filetype == "image/png"
  //         ? url
  //         : "",
  //       lastMsgBy: "User",
  //     });

  //   }

  // }

  // const handlefilePick = async (event) => {
  //   //console.log("event.target.files[0]", event.target.files[0])
  //   let data = [];
  //   let file = {
  //     preview: URL.createObjectURL(event.target.files[0]),
  //   };

  //   setfiletype(event.target.files[0].type);

  //   setfirstFile(event.target.files[0]);
  //   setselectedFiles([
  //     {
  //       preview: URL.createObjectURL(event.target.files[0]),
  //       type: event.target.files[0].type,
  //     },
  //   ]);

  //   //console.log("selected files", selectedFiles);
  //   setTimeout(() => {
  //     //console.log("selected files", firstFile);
  //   }, 3000);
  // };

  // console.log('imageUrl',imageUrl)


  const CallBack = async () => {
    setmodal(false);
    setsessionclose(true);
  }
  const callmodal = async () => {
    setmodal(true);
    toast.success("Your Session ended successfully", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    Firebase.database().ref().child("chatlist").child(props.userData._id + "U").child(props.userid)
      .update({
        sessionclose: true

      },
        (error) => {
          if (error) {
            alert("something went wrong");
          } else {
            //this.scrollToBottom();
            //console.log("mesage");
            //alert("updated");

          }
        }
      );

    let sellerid = props.userid ? props.userid + "S" : "";
    Firebase.database().ref().child("chatlist").child(sellerid).child(props.userData._id).
      update({
        sessionclose: true
      },
        (error) => {
          if (error) {
            alert("something went wrong");
          } else {
            //console.log("mesage");
          }
        }
      );
    setconfirm(false)

  }

  // console.log('msgimg', msgimg, imageUrl)

  return (
    <div>
      {/* {
        msgimg && (
          <>
            <img src={imageUrl} height='100px' width='100px' />
            <button onClick={() => {
              setMsgImg(false);
              setImageUrl("")
            }}
            >
              x
            </button>
          </>
        )
      } */}
      {
        sessionclose ?
          <div className="MessageWrite"
          >
            <p>
              You Have ended your session
            </p>{" "}
            <div className="d-flex align-items-center">
              <div className="form-group mb-0">

                <i className="fa fa-comments"
                  onClick={() => {
                    setmodal(true);
                    //console.log("true", modal)
                  }}

                ></i>


              </div>
            </div>
          </div>
          :
          <>

            <div className="MessageWrite">

              <div className="relative">
                {selectedFiles.length > 0 &&
                  selectedFiles.map((item, index) => {

                    return (
                      <div>
                        <img alt="img" className="Imgsended img-fluid"
                          // src="http://128.199.25.86:5010/uploads/chat/the-traveler-_-a-simple-flat-designed-wallpaper-1366x768-wallpaper.jpg"
                          style={{ height: "65px" }}
                          src={item.preview}
                        />


                        <button className="bg-transparent _close_btn"
                          onClick={() => {
                            setfirstFile("");
                            setselectedFiles([]);
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    );

                  })}
              </div>

              {
                msgimg
                  ?
                  <div style={{marginRight:'auto'}}>
                    <img src={imageUrl} height='100px' width='100px' />
                    <button 
                    style={{tabSize:'10px'}}
                    onClick={() => {
                      setMsgImg(false);
                      setImageUrl("")
                      setmessage("")
                    }}
                    >
                      x
                    </button>
                  </div>
                  :
                  <input type="text"
                    onChange={(val) => {
                      //console.log(val.target.value)
                      setmessage(val.target.value);
                    }}
                    value={message}
                    className="form-control"
                  />
              }



              <div className="d-flex align-items-center">
                <div className="form-group mb-0">

                  <i className="fa fa-lock"
                    onClick={() => {
                      // setmodal(true);
                      setconfirm(true)


                    }}
                  ></i>


                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0">
                  <label htmlFor="DocumentFile">
                    <i className="fas fa-paperclip"></i>
                  </label>
                  {/* {
                    imageUrl && (
                      <>
                        <img src={imageUrl} height='100px' width='100px' />
                        <button onClick={() => setImageUrl("")}>x</button>
                      </>
                    )
                  } */}
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control-file"
                    id="DocumentFile"
                    // onChange={handlefilePick}
                    onChange={uploadFile}
                  />
                </div>
              </div>
              <div className="send">
                <button className="btn sendBtn"
                  onClick={() => {
                    addMessage()
                  }}
                // onClick={() => {
                //   if (message != "" && firstFile == "") {
                //     // Send("")
                //     addMessage();
                //   }
                //   else if (message != "" && firstFile != "") {
                //     uploadFile()
                //   }
                //   else if (message == "" && firstFile != "") {
                //     uploadFile()
                //   }
                //   else {
                //     toast.warning("Please enter a message", {
                //       position: "bottom-right",
                //       autoClose: 2000,
                //       hideProgressBar: false,
                //       closeOnClick: true,
                //       pauseOnHover: true,
                //       draggable: true,
                //       progress: undefined,
                //     });

                //   }
                // }
                // }
                >
                  <i className="fas fa-paper-plane"></i>
                </button>

              </div>


            </div>  </>}
      {confirm ? (
        <SweetAlert
          title="Are you want to close the chat session?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={callmodal}
          onCancel={() =>
            setconfirm(false)
          }
        >
          You won't be able to chat after session close!
        </SweetAlert>
      ) : null}
      {modal ?
        <div
          className={
            modal ? "modal single_modal show fade" : "modal show fade"
          }
          // tabIndex="-1"
          style={{ display: modal ? "block" : "none" }}
        >
          <SessionClose
            CallBacksession={CallBack}
            userid={props.userData._id}
            sellerid={props.userid}

          />
        </div>
        : null}
    </div>
  );
};

export default MessageWrite;
