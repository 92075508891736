import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
const Site = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section>
        <Jumbotron heading="Site Map" img={serviceJumboBg} />
      </section>
      <div className="container Site_map">
        <div className="row my-5 px-md-0 px-5">
          <div className="col-md-3 ">
            <div className="Site_map_head">Home</div>
            <ul className="">
              <li>
                <Link to="/home">Home</Link>
              </li>
              {/* <li>
                <Link to="/">Link 2</Link>
              </li>
              <li>
                <Link to="/">Link 3</Link>
              </li>
              <li>
                <Link to="/">Link 4</Link>
              </li>
              <li>
                <Link to="/">Link 5</Link>
              </li> */}
            </ul>
          </div>{" "}
          <div className="col-md-3 ">
            <div className="Site_map_head">
              About Us
            </div>
            <ul className="">
              <li>
                <Link to="/about-us">
                  About Us
                </Link>
              </li>
              {/* <li>
                <Link to="/">Link 2</Link>
              </li>
              <li>
                <Link to="/">Link 3</Link>
              </li>
              <li>
                <Link to="/">Link 4</Link>
              </li>
              <li>
                <Link to="/">Link 5</Link>
              </li> */}
            </ul>
          </div>{" "}
          <div className="col-md-3 ">
            <div className="Site_map_head">
              Blog
            </div>
            <ul className="">
              <li>
                <Link to="/blog">
                  Blog
                </Link>
              </li>
              {/* <li>
                <Link to="/">Link 2</Link>
              </li>
              <li>
                <Link to="/">Link 3</Link>
              </li>
              <li>
                <Link to="/">Link 4</Link>
              </li>
              <li>
                <Link to="/">Link 5</Link>
              </li> */}
            </ul>

          </div>{" "}
          <div className="col-md-3 ">
            <div className="Site_map_head">
              Contact Us
            </div>
            <ul className="">
              <li>
                <Link to="/contact">
                  Contact Us
                </Link>
              </li>
              {/* <li>
                <Link to="/">Link 2</Link>
              </li>
              <li>
                <Link to="/">Link 3</Link>
              </li>
              <li>
                <Link to="/">Link 4</Link>
              </li>
              <li>
                <Link to="/">Link 5</Link>
              </li>
              <li>
                <Link to="/">Link 6</Link>
              </li>
              <li>
                <Link to="/">Link 7</Link>
              </li>
              <li>
                <Link to="/">Link 8</Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="row my-5 px-md-0 px-5">
          <div className="col-md-3 ">
            <div className="Site_map_head">
              Privacy Policy
            </div>
            <ul className="">
              <li>
                <Link to="/privacy">
                  Privacy Policy
                </Link>
              </li>
              {/* <li>
                <Link to="/">Link 2</Link>
              </li>
              <li>
                <Link to="/">Link 3</Link>
              </li>
              <li>
                <Link to="/">Link 4</Link>
              </li>
              <li>
                <Link to="/">Link 5</Link>
              </li>
              <li>
                <Link to="/">Link 6</Link>
              </li>
              <li>
                <Link to="/">Link 7</Link>
              </li>
              <li>
                <Link to="/">Link 8</Link>
              </li> */}
            </ul>
          </div>{" "}
          <div className="col-md-3 ">
            <div className="Site_map_head">
              Terms & Conditions
            </div>
            <ul className="">
              <li>
                <Link to="/item-condition">
                  Terms & Conditions
                </Link>
              </li>
              {/* <li>
                <Link to="/">Link 2</Link>
              </li>
              <li>
                <Link to="/">Link 3</Link>
              </li>
              <li>
                <Link to="/">Link 4</Link>
              </li>
              <li>
                <Link to="/">Link 5</Link>
              </li> */}
            </ul>
          </div>{" "}
          <div className="col-md-3 ">
            <div className="Site_map_head">
              Services
            </div>
            <ul className="">
              <li>
                <Link to="/services">
                  Services
                </Link>
              </li>
              {/* <li>
                <Link to="/">Link 2</Link>
              </li>
              <li>
                <Link to="/">Link 3</Link>
              </li>
              <li>
                <Link to="/">Link 4</Link>
              </li>
              <li>
                <Link to="/">Link 5</Link>
              </li>
              <li>
                <Link to="/">Link 6</Link>
              </li>
              <li>
                <Link to="/">Link 7</Link>
              </li>
              <li>
                <Link to="/">Link 8</Link>
              </li> */}
            </ul>
          </div>{" "}
          <div className="col-md-3">
            <div className="Site_map_head">
              Top Providers
            </div>
            <ul className="">
              <li>
                <Link to="/service-provider">
                  Top Providers
                </Link>
              </li>            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Site;
