import { JitsiMeeting } from '@jitsi/react-sdk';
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Card, CardBody, Container } from 'reactstrap';
import BreadCrump from '../../../Component/BreadCrump';
import ShareModal from './ShareModal';


const Meeting = () => {
    const handleJitsiIFrameRef1 = (iframeRef) => {
        iframeRef.style.border = "10px solid #3d3d3d";
        iframeRef.style.background = "#3d3d3d";
        iframeRef.style.height = "600px";
        iframeRef.style.marginBottom = "20px";
    };
    const location = useLocation();
    // console.log('location',location.state.roomId)
    const [modalOpen, setModalOpen] = useState(false);

    const onClose = () => {
        setModalOpen(false)
    }

    // let link = 'http://localhost:3000/payment-meeting/'
    let link = 'https://myspiritualconnect.com/payment-meeting/'
    const handleCopy = () => {
        navigator.clipboard.writeText(link + location.state.roomId);

        toast.success('Copied Successfully', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const handleShare = () => {
        setModalOpen(true)
    }


    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* <BreadCrump main={"Create Meeting"} /> */}
                        <Card>
                            <CardBody>
                                <JitsiMeeting
                                    // roomName={meetingInfo.roomName}
                                    roomName={location.state.roomId}
                                    domain={"jitsi.myspiritualconnect.com"}
                                    userInfo={{
                                        // displayName: user?.firstName,
                                        // email: user?.email,
                                    }}
                                    // spinner={renderSpinner}
                                    configOverwrite={{
                                        subject: "Spritual Meeting",
                                        hideConferenceSubject: false,
                                    }}
                                    // onApiReady={(externalApi) => handleApiReady(externalApi)}
                                    onReadyToClose={() => {
                                        // setShow(false);
                                        // setMeeting(false);
                                        // setMeetingInfo(init);
                                    }}
                                    getIFrameRef={handleJitsiIFrameRef1}
                                />

                                <div>
                                    <button className='btn btn-secondary mr-1' onClick={handleCopy}>Copy</button>
                                    <button className='btn btn-secondary' onClick={handleShare}>Share</button>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>

            <ShareModal
                modalOpen={modalOpen}
                onClose={onClose}
                link={link + location.state.roomId}
            />
        </div>
    )
}

export default Meeting;