import React, { useEffect, useState } from "react";
import { ReactTitle } from "react-meta-tags";

import Jumbotron from "../../Component/Jumbotron";

import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import axios from "axios";
import PayPal from "./paypal";

const Index = () => {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [country, setcountry] = useState([]);
  const [selectcoutry, setselectcoutry] = useState("");
  const [state, setstate] = useState([]);
  const [selectstate, setselectstate] = useState("");
  const [zip, setzip] = useState("");
  const [cash, setcash] = useState("");
  const [promocode, setpromocode] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [isFirstNameEmpty, setIsFirstNameEmpty] = useState(false);
  const [isLastNameEmpty, setIsLastNameEmpty] = useState(false);
  const [isAddressEmpty, setisAddressEmpty] = useState(false);
  const [cartData, setcartData] = useState([]);
  const [serviceids, setserviceids] = useState([]);
  const [discount, setdiscount] = useState(0);
  const user = reactLocalStorage.getObject("userData");
  const [count, setcount] = useState(0);
  const [valid, setvalid] = useState(false);
  const [invalid, setinvalid] = useState(false);
  const [dateInvalid, setdateInvalid] = useState(false);
  const [couponApplied, setcouponApplied] = useState(false);
  const [couponstring, setcouponstring] = useState("");
  const [promocodeData, setpromocodeData] = useState({});
  const [payment, setpayment] = useState("");
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  const [phoneValid, setphoneValid] = useState(false);
  const userData = reactLocalStorage.getObject("userData");

  const getCardData = async () => {
    let result = await HttpClient.requestData(
      "get-service-cart/" + userData._id,
      "GET"
    );
    //console.log("cart Result", result);
    if (result && result.status) {
      let data = result.data;
      //console.log("cart data", data);
      setpromocodeData(
        data[0].coupon_data[0] != undefined ? data[0].coupon_data[0] : null
      );
      //console.log("promocodeData", promocodeData);
      result.data.forEach((element) => {
        serviceids.push(element.service_id);
        setserviceids(serviceids);
      });
      //console.log(serviceids);
      setcartData(data);

      //console.log("cart data", cartData);
    }
  };

  useEffect(() => {
    getCardData();
    getCountry();
    window.scrollTo(0, 0);
  }, []);

  const getCountry = () => {
    let response = axios
      .get("https://countriesnow.space/api/v0.1/countries/capital")
      .then((response) => {
        //console.log("response", response);
        if (response.data.data.length > 0) {
          setcountry(response.data.data);

          //console.log("country", country);
        }
      });
  };

  const getState = (name) => {
    let arr = [];
    let data = {
      country: name,
    };
    //console.log("data", data);
    let response = axios
      .get("https://countriesnow.space/api/v0.1/countries/states", data)
      .then((response) => {
        //console.log("response", response);
        if (response.data.data.length > 0) {
          let cur_data = response.data.data.filter((item) => item.name == name);
          //console.log("curdata", cur_data);
          setstate(cur_data[0].states);
        }
      });
  };

  const handlepayment = (e) => {
    //console.log(e.target.value);
    setpayment(e.target.value);
  };

  const applyPromocode = async () => {
    if (promocode != "") {
      setcouponApplied(false);
      let data = {
        user_id: user._id,
        coup_name: promocode,
      };
      //console.log("coupon", data);

      let result = await HttpClient.requestData("apply-coupon", "POST", data);
      //console.log("checkCoupon", result);
      if (result && result.status) {
        // setcoupondata(result.data);
        // setminPrice(result.data.minprice);
        let expdate = result.data.expdate;
        let expdate1 = moment(expdate).format("YYYY-MM-DD");
        let exp_date = expdate1;
        //console.log("exp", exp_date);
        let expdate2 = moment(exp_date);
        let curdate1 = moment().format("YYYY-MM-DD");
        //console.log("exp1", curdate1);
        let curdate2 = moment(curdate1);
        let date = moment(result.data.expdate).isAfter(curdate1, "day");
        //console.log("date", date);

        if (result.data.minprice <= total) {
          if (moment(result.data.expdate).isSameOrAfter(curdate1, "day")) {
            //console.log("hello");
            if (result.data.discount_type == "Flat discount") {
              // setdiscounttype(result.data.discount_type);
              if (result.data.discount_value >= total) {
                setcouponstring("invalid");
                setdiscount(0);
                setTimeout(() => {
                  setpromocode("");
                  setcouponstring("");
                }, 4000);
              } else {
                setcouponstring("success");
                setdiscount(result.data.discount_value);
              }
            } else {
              // setdiscounttype(result.data.discount_type);

              let amount_dis = (result.data.discount_value * total) / 100;
              if (amount_dis >= total) {
                setcouponstring("invalid");
                setdiscount(0);
                setTimeout(() => {
                  setpromocode("");
                  setcouponstring("");
                }, 4000);
              } else {
                setcouponstring("success");
                setdiscount(amount_dis);
              }
            }
          } else {
            setcouponstring("Date Invalid");
            setdiscount(0);
            setTimeout(() => {
              setpromocode("");
              setcouponstring("");
            }, 4000);
          }
        } else {
          setcouponstring("invalid");
          setdiscount(0);
          setTimeout(() => {
            setpromocode("");
            setcouponstring("");
          }, 4000);
          // toast.warning("Cart Amount Not Matching with the Coupon Min Price", {
          //   position: "bottom-right",
          //   autoClose: 3000,
          // });
        }
      } else {
        setcouponstring("cantapply");
        setdiscount(0);

        setTimeout(() => {
          setpromocode("");
          setcouponstring("");
        }, 4000);
      }
    } else {
      setcouponstring("empty");
      setTimeout(() => {
        setpromocode("");
        setcouponstring("");
      }, 4000);
      // setcouponApplied(true);
    }
  };

  const placeOrder = async () => {
    let userData = reactLocalStorage.getObject("userData");
    if (
      firstname != "" &&
      lastname != "" &&
      address1 != "" &&
      selectcoutry != "" &&
      selectstate != "" &&
      zip != "" &&
      phone != "" &&
      isEmailFromatValid == false &&
      phoneValid == false
    ) {
      let data = {
        user_id: userData._id,
        //service_ids :serviceids,
        cart_items: cartData,
        total: total - discount,
        firstname: firstname,
        lastname: lastname,
        email: email,
        address1: address1,
        address2: address2,
        country: selectcoutry,
        state: selectstate,
        zip: parseInt(zip),
        subtotal: total,
        payment_type: "ICICI Bank Debit card",
        card_name: "TEST TEST",
        card_no: 8956124553452000,
        exp_date: "06/24",
        cvv: 788,
        coupon: promocodeData,
        phone: phone,
      };
      //console.log(data);
      let result = await HttpClient.requestData(
        "shop-service/checkout",
        "POST",
        data
      );
      if (result && result.status) {
        //console.log("checkout", result.data);
        toast.success(
          "Slot Booking Successfully, Awaiting Seller Conformation",
          {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(function () {
          window.location.href = "/my-orders";
        }, 5000);
      } else {
        toast.warning(result.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("Please fill all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const total = cartData.reduce(
    (prevValue, currentValue) => prevValue + currentValue.price,
    0
  );
  //console.log(total);

  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    //console.log(valid);
    setisEmailFromatValid(valid ? false : true);
  }

  return (
    <div className="checkout">
      <ReactTitle title="MySpiritualConnent - Checkout" />
      <section>
        <Jumbotron heading="CHECKOUT" img={serviceJumboBg} />
      </section>
      <section className="container py-5 ">
        <div className="row px-3">
          <ToastContainer />
          <div className="col-md-4 order-md-2 mb-4">
            <div className="cart_box_right">
              <h4 className="d-flex justify-content-between align-items-center mb-3 p-2">
                <span className="text_muted">Your cart</span>
                <span className="badge badge_color badge-pill">
                  {cartData.length}
                </span>
              </h4>
              <ul className="list-group mb-3">
                {cartData.map((item, index) => {
                  return (
                    <div>
                      <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 className="my-0">{item.service_name}</h6>
                          {/* <small className="text_muted">Brief description</small> */}
                        </div>
                        <span className="text_muted">
                          {/* $ */}
                          {userData.currency == "INR"
                            ? "₹"
                            : userData.currency == "CAD"
                              ? "$"
                              : userData.currency == "USD"
                                ? "$"
                                : userData.currency == "GBP"
                                  ? "£"
                                  : userData.currency == "EUR"
                                    ? "€"
                                    : "$"}
                          {item.price.toFixed(2)}
                        </span>
                      </li>
                    </div>
                  );
                })}
                {/* <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 className="my-0">Product name</h6>
                  <small className="text_muted">Brief description</small>
                </div>
                <span className="text_muted">$12</span>
              </li>
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 className="my-0">Second product</h6>
                  <small className="text_muted">Brief description</small>
                </div>
                <span className="text_muted">$8</span>
              </li>
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 className="my-0">Third item</h6>
                  <small className="text_muted">Brief description</small>
                </div>
                <span className="text_muted">$5</span>
              </li> */}
                <li className="list-group-item d-flex justify-content-between bg-light">
                  <div className="text-success">
                    <h6 className="my-0">Promo code</h6>
                    {/* <small>EXAMPLECODE</small> */}
                  </div>
                  {/* <span className="text-success">{cartData.length}</span> */}
                  <span className="text-success">{discount ? discount : 0}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total</span>
                  {/* <strong>{total}</strong> */}
                  <strong>{userData.currency == "INR"
                    ? "₹"
                    : userData.currency == "CAD"
                      ? "$"
                      : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "GBP"
                          ? "£"
                          : userData.currency == "EUR"
                            ? "€"
                            : "$"}{(total - discount).toFixed(2)}</strong>
                </li>
              </ul>

              <div className="input-group p-2">
                <div className="input-group-append w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Promo code"
                    style={{ marginRight: "5px" }}
                    onChange={(val) => {
                      setpromocode(val.target.value);
                    }}
                    value={promocode}
                  />

                  <button
                    type="submit"
                    className="btn PrimaryBtn px-3 py-1"
                    style={{ borderRadius: "0 5px 5px 0" }}
                    onClick={applyPromocode}
                  >
                    Redeem
                  </button>
                </div>
                {couponstring == "success" ? (
                  <div className="py-3">
                    <span className="text-success ">
                      <i className="far fa-check-circle"></i> Promocode Applied
                      Successfully
                    </span>
                  </div>
                ) : null}
                {couponstring == "invalid" ? (
                  <div className="py-3">
                    <span className="text-danger">
                      <i className="far fa-times-circle"></i> Promocode Invalid
                    </span>
                  </div>
                ) : null}
                {couponstring == "empty" ? (
                  <div className="py-3">
                    <span className="text-danger">
                      <i className="far fa-times-circle"></i> Please Enter
                      Promocode
                    </span>
                  </div>
                ) : null}
                {couponstring == "Date Invalid" ? (
                  <div className="py-3">
                    <span className="text-danger">
                      <i className="far fa-times-circle"></i>Promocode expired
                    </span>
                  </div>
                ) : null}
              </div>

            </div>
          </div>
          <div className="col-md-8 order-md-1">
            <h4 className="mb-3">Billing address</h4>
            {/* <form className="needs-validation" noValidate> */}
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="firstName">First name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter First Name"
                  onChange={(val) => {
                    setfirstname(val.target.value);
                    if (val.target.value) {
                      setIsFirstNameEmpty(false);
                    } else {
                      setIsFirstNameEmpty(true);
                    }
                  }}
                />
                {isFirstNameEmpty ? (
                  <small style={{ color: "red" }}>
                    {" "}
                    Please enter first Name.{" "}
                  </small>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="lastName">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter Last Name"
                  onChange={(val) => {
                    setlastname(val.target.value);
                    if (val.target.value) {
                      setIsLastNameEmpty(false);
                    } else {
                      setIsLastNameEmpty(true);
                    }
                  }}
                />
                {isLastNameEmpty ? (
                  <small style={{ color: "red" }}>
                    {" "}
                    Please enter last Name.{" "}
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* <div className="mb-3">
                <label htmlFor="username">Username</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">@</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Username"
                    required
                  />
                  <div className="invalid-feedback" style={{ width: "100%" }}>
                    Your username is required.
                  </div>
                </div>
              </div> */}
            <div className="mb-3">
              <label htmlFor="email">
                Email
                {/* <span className="text_muted">(Optional)</span> */}
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="you@example.com"
                onChange={(val) => {
                  setemail(val.target.value);
                  if (val.target.value) {
                    setIsEmail(false);
                    validateEmail(val.target.value);
                  } else {
                    setIsEmail(true);
                    validateEmail(val.target.value);
                  }
                }}
              />
              {isEmail ? (
                <small style={{ color: "red" }}> Please enter email. </small>
              ) : isEmailFromatValid ? (
                <small style={{ color: "red" }}>
                  {" "}
                  Please enter a valid email address for shipping updates.{" "}
                </small>
              ) : null}
              {/* <div className="invalid-feedback">
                Please enter a valid email address for shipping updates.
              </div> */}
            </div>
            <div className="mb-3">
              <label htmlFor="email">Phone</label>
              <input
                type="number"
                className="form-control"
                id="number"
                placeholder="Enter Phone Number"
                onChange={(val) => {
                  if (val.target.value.length > 15) {
                    setphoneValid(true);
                  } else {
                    setphoneValid(false);
                    setphone(val.target.value);
                  }
                }}
              />
              {phoneValid ? (
                <small style={{ color: "red" }}>
                  {" "}
                  Please enter phone number less than 15 digit.{" "}
                </small>
              ) : (
                ""
              )}
              {/* {isEmail ? (
                <small style={{ color: "red" }}> Please enter email. </small>
              ) :  isEmailFromatValid ? (
                <small style={{ color: "red" }}> Please enter a valid email address for shipping updates. </small>
              ) : null} */}
              {/* <div className="invalid-feedback">
                Please enter a valid email address for shipping updates.
              </div> */}
            </div>
            <div className="mb-3">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="1234 Main St"
                onChange={(val) => {
                  setaddress1(val.target.value);
                  if (val.target.value) {
                    setisAddressEmpty(false);
                  } else {
                    setisAddressEmpty(true);
                  }
                }}
              />
              {isAddressEmpty ? (
                <small style={{ color: "red" }}> enter Address. </small>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="address2">
                Address 2 <span className="text_muted">(Optional)</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="address2"
                placeholder="Apartment or suite"
                onChange={(val) => {
                  setaddress2(val.target.value);
                  // this.setState({ address2: val.target.value });
                }}
              />
            </div>
            <div className="row">
              <div className="col-md-5 mb-3">
                <label htmlFor="country">Country</label>
                <select
                  className="form-control contact_form_text"
                  id="exampleFormControlSelect1"
                  onChange={(val) => {
                    //console.log("hjhj", val.target.value);
                    setselectcoutry(val.target.value);

                    getState(val.target.value);
                  }}
                >
                  <option value>Choose...</option>
                  {country.map((item, index) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
                <div className="invalid-feedback">
                  Please select a valid country.
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="state">State</label>
                <select
                  className="form-control contact_form_text"
                  id="exampleFormControlSelect1"
                  onChange={(val) => {
                    //console.log("val", val.target.value);
                    setselectstate(val.target.value);
                  }}
                  value={selectstate}
                >
                  <option value>Choose...</option>
                  {state.map((item, index) => {
                    return (
                      <option value={item.name} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <div className="invalid-feedback">
                  Please provide a valid state.
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="zip">Post Code</label>
                <input
                  type="number"
                  className="form-control"
                  id="zip"
                  placeholder="Enter Post Code"
                  onChange={(val) => {
                    setzip(val.target.value);
                    // if (val.target.value) {
                    //   setisAddressEmpty(false);
                    // } else {
                    //   setisAddressEmpty(true);
                    // }
                  }}
                />
                <div className="invalid-feedback">Post Code required.</div>
              </div>
            </div>
            {/* <hr className="mb-4" />
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="same-address"
                />
                <label className="custom-control-label" htmlFor="same-address">
                  Shipping address is the same as my billing address
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="save-info"
                />
                <label className="custom-control-label" htmlFor="save-info">
                  Save this information for next time
                </label>
              </div>
              <hr className="mb-4" /> */}
            <h4 className="mb-3">Payment</h4>
            <div className=" my-3 d-md-flex">
              <div className="custom-control custom-radio mr-md-4">
                <input
                  id="cash"
                  name="paymentMethod"
                  type="radio"
                  className="custom-control-input"
                  value="cash"
                  defaultChecked
                  onChange={(val) => {
                    //console.log(val.target.value);
                    setcash(val.target.value);
                  }}
                />
                {/* <input
                  id="strip"
                  name="paymentMethod"
                  type="radio"
                  className="custom-control-input"
                  value="stripe"
                  checked={payment == "stripe" ? true : false}
                  onChange={handlepayment}
                /> */}
                {/* <label className="custom-control-label" htmlFor="cash">
                    Cash on Delivery
                  </label>
                </div>
                <div className="custom-control custom-radio mr-md-4">
                  <input
                    id="credit"
                    name="paymentMethod"
                    type="radio"
                    className="custom-control-input"
                  
                    required
                  /> */}
                <label className="custom-control-label" htmlFor="credit">
                  Credit Card
                </label>
              </div>
              <div className="custom-control custom-radio mr-md-4">
                <input
                  id="debit"
                  name="paymentMethod"
                  type="radio"
                  className="custom-control-input"
                  required
                />
                <label className="custom-control-label" htmlFor="debit">
                  Debit card
                </label>
              </div>
              <div className="custom-control custom-radio mr-md-4">
                <input
                  id="paypal"
                  name="paymentMethod"
                  type="radio"
                  className="custom-control-input"
                // value="paypal"
                // checked={payment == "paypal" ? true : false}
                // onChange={handlepayment}
                />
                <label className="custom-control-label" htmlFor="paypal">
                  Paypal
                </label>
              </div>
            </div>

            <hr className="mb-4" />
            <button
              className="btn PrimaryBtn rounded btn-lg btn-block"
              type="button"
              onClick={placeOrder}
            >
              Place Order
            </button>
            {/* {payment == "paypal" && (
                <PayPal
                  total={total - discount}
                  // senddetails={senddetails}
                />
              )} */}
            {/* </form> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
