import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "../route";
import Navbar from "../Default/Navbar/index";
import Footer from "../Default/Footer/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Firebase from "../Pages/Chat/firebase";
import { reactLocalStorage } from "reactjs-localstorage";
import AudioModal from "../Pages/Chat/Component/AudioModal";
import VideoModal from "../Pages/Chat/Component/VideoModal";
import Meeting from "../Pages/Seller/CreateMeet/Meeting";
import myMusic from '../assets/classical-demo.mp3'
export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callData: {},
      call: false,
      videoCall: false,
    };
  }

  componentDidMount = () => {
    // console.log("ljiofuk", this.state.videoCall)
    let user = reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      //console.log('{user._id', user._id)
      Firebase.database()
        .ref(`/call/${user._id}`)
        .on("child_added", (snapshot) => {
          if (snapshot.exists()) {
            let fbData = snapshot.val();
            //console.log("fbData", fbData);
            if (fbData.type == "Incomming" && !fbData.endStatus) {
              //console.log("if condition fbdata", fbData);
              this.setState({
                callData: fbData,
              });
              if (fbData.videoCall == true) {
                this.setState({ videoCall: true });
              } else {
                this.setState({ call: true });
              }
            }
          }
        });

      var starCountRef = Firebase.database().ref('validUsers/' + user._id);
      starCountRef.on('value', (snapshot) => {
        const data = snapshot.val();
        //console.log("llll", data);
        if (data == null) {

        } else if (data.valid == false) {

          reactLocalStorage.clear();
          reactLocalStorage.setObject("loginstatus", false);

          toast.error("your account banned ", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(function () {
            window.location.href = "/home";
          }, 1500);
        }
      });

    }
  };

  callBackcall = () => {
    this.setState({
      call: false,
    });
  };

  callBackcall1 = () => {
    this.setState({
      videoCall: false,
    });
  };


  render() {
    return (
      <div>
        <ToastContainer />


        <Navbar />
        <Switch>
          {
            routes.map((route, index) => {
              if (route.path != "/seller") {
                return route.component ? (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => <> <route.component {...props} /><Footer /></>}
                  />
                ) : null;

              }
              else {
                return route.component ? (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => <route.component {...props} />}
                  />
                ) : null;
              }

            })}
          <Redirect to="/home" from="/" />
        </Switch>

        {this.state.call ? (
          <div
            className="modal fade single_modal show"
            id="exampleModal1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <AudioModal
              status={true}
              remoteData={this.state.callData}
              type="Incomming"
              callBackcall1={() => this.callBackcall()}
              image=""
            />
          </div>
        ) : null}

        {this.state.videoCall ? (
          <div
            className="modal fade single_modal show"
            id="exampleModal1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <VideoModal
              status={true}
              remoteData={this.state.callData}
              type="Incomming"
              callBackcall1={() => this.callBackcall1()}
              image=""
            />
          </div>
        ) : null}

        <video
          id="video-ringtone"
          src={myMusic}
          style={{
            display: 'contents'
          }}
        />
        {/* <Footer /> */}
      </div>
    );
  }
}
