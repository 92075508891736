import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class BodyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    return (
      <div>
        <span color="danger" onClick={this.toggle}>
          {this.props.buttonLabel}
        </span>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalBody>{this.props.body}</ModalBody>
          {/* <ModalFooter> */}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          {/* </ModalFooter> */}
        </Modal>
      </div>
    );
  }
}

export default BodyModal;
