import React, { memo } from "react";

const DashboardBox = ({ title, value }) => {
    
  return (
    <div className="col-md-3 my-2 col-sm-6 col-xs-6">
      <div className="balance_card">
        <div className="card-body text-center">
          <h6>{title}</h6>
          {value && <span>{value}</span>}
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardBox);
