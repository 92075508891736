import React, { useState, useEffect } from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";
const Feedback = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [feedback, setfeedback] = useState("");
  const [blankemail, setblankemail] = useState(false);
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const Submit = async () => {
    let userData = reactLocalStorage.getObject("userData");
    if (name != "" && email != "" && phone != "" && feedback != "") {
      if (isEmailFromatValid) {
        setisEmailFromatValid(true);
      } else {
        let data = {
          user_id: userData._id,
          name: name,
          email: email,

          phone: phone,

          feedback_detail: feedback,
        };

        //console.log("data", data);
        let result = await HttpClient.requestData("feedback", "POST", data);

        //console.log("POST", result);

        if (result && result.status) {
          toast.success("Feedback Added Successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,
          });

          setname("");
          setemail("");
          setphone("");
          setfeedback("");
        } else {
          toast.warning(result.message, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      toast.warning("please select all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    //console.log(valid);
    setisEmailFromatValid(valid ? false : true);
  }
  return (
    <div>
      {/* <section>
        <Jumbotron heading="Give Your Feedback" img={serviceJumboBg} />
      </section> */}
      <div className="Customer-service feedback">
        <div className="container-fluid mt-5">
        <div className="col-md-12 text-center my-4"><h2 className="innertitle">Feedback</h2></div>
          <div className="row px-3">
            <div className="col-md-8  my-5 m-auto">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Person Name"
                    onChange={(val) => {
                      setname(val.target.value);
                    }}
                    value={name}
                  />
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Contact Number"
                    onChange={(val) => {
                      setphone(val.target.value);
                    }}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    value={phone}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={(val) => {
                      setemail(val.target.value);
                      if (val.target.value == "") {
                        setblankemail(true);
                        validateEmail(val.target.value);
                      } else {
                        setblankemail(false);
                        validateEmail(val.target.value);
                      }
                    }}
                    value={email}
                  />
                  {blankemail ? (
                    <small style={{ color: "red" }}>
                      {" "}
                      Please enter email.{" "}
                    </small>
                  ) : isEmailFromatValid ? (
                    <small style={{ color: "red" }}>
                      {" "}
                      Please enter a valid email.{" "}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">
                      Write Feedback
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      placeholder="Write here..."
                      onChange={(val) => {
                        setfeedback(val.target.value);
                      }}
                      value={feedback}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center my-4">
                  <button className="btn submit_btn" onClick={Submit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
