import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "../../utils/HttpClient";
import Jumbotron from "../../Component/Jumbotron";
import { reactLocalStorage } from "reactjs-localstorage";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import { useParams } from "react-router-dom";
// import FavShop from "../../../Default/Assets/Images/Service/FavouriteShop.png";
import Dashboard from "../../Default/Assets/Images/blank-profile-picture-973460_640.png";

const Index = () => {
  const [favTab, setFavTab] = useState("About");
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [image, setimage] = useState("");
  const params = useParams();
  let id = params.id;
  //console.log(id);
  useEffect(() => {
    scrollTop();
    fetchData();
  }, []);
  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const fetchData = async () => {
    let result = await HttpClient.requestData("associate", "GET");
    // //console.log("associate", result);
    if (result && result.status) {
      //   let data = result.data;
      let filterAssociate =
        result.data.length > 0 && result.data.filter((item) => item._id === id);
      //   //console.log(filterAssociate);
      if (filterAssociate.length) {
        setname(filterAssociate[0].name);
        setdescription(filterAssociate[0].description);
        setimage(filterAssociate[0].image);
      }
    }
  };

  return (
    <div className="Service_Provider">
      <ReactTitle title="MySpiritualConnent - Service Provider" />
      <section>
        <Jumbotron heading="Our Associate" img={serviceJumboBg} />
      </section>
      <section className="container py-5 px-4">
        <div className="FavouriteShop my-3">
          <div className="container-fluid">
            <div className="row borderbottom pb-4">
              <div className="col-md-12 px-md-0 ">
                <div className="container-fluid">
                  <div className="row">
                    <div className=" col-md-4 ">
                      <div className="FavImgBox mb-md-0 mb-3 ">
                        {image != "" ? (
                          <img
                            src={HttpClient.IMG_URL + image}
                            className="img-fluid"
                            alt="img"
                          />
                        ) : (
                          <img
                            src={Dashboard}
                            className="img-fluid"
                            alt="img"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-8 ">
                      <h3 className="mb-2">{name}</h3>
                      <p className="ParaLead1">{description}</p>
                      {/* <p className="ParaLead " style={{ color: "blue" }}>
                    {props.data.email}
                  </p> */}
                      {/* <p className="ParaLead ">
                    {props.data.mobile}
                  </p> */}
                      {/* <div className="d-flex align-items-center mb-2">
                    <i class="fas fa-map-marker-alt location mr-2"></i>
                    <span style={{ fontSize: "20px" }}>Delhi, India</span>
                  </div> */}
                      {/* <div className="d-flex align-items-center mb-2">
                    <i class="fas fa-star star mr-2"></i>
                    <span
                      className="font-weight-bold"
                      style={{ fontSize: "18px" }}
                    >
                      4.5/5
                    </span>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-5 px-md-0">
            <div className="text-md-right">
              <button className="btn PrimaryBtn">Favourite Shop</button>
            </div>
          </div> */}
            </div>
          </div>
          {/* <div className="mb-4">
        <span
          className={favTab === "About" ? "FavTab active" : "FavTab"}
          onClick={() => setFavTab("About")}
        >
          About Us
        </span>
        <span
          className={favTab === "Contact" ? "FavTab active" : "FavTab"}
          onClick={() => setFavTab("Contact")}
        >
          Contact Us
        </span>
        <span
          className={favTab === "Choose" ? "FavTab active" : "FavTab"}
          onClick={() => setFavTab("Choose")}
        >
          Why Choose Us
        </span>
      </div> */}
          {/* <div className="px-3 pt-3">
        <h5>About Physhic Amandastudio</h5>
        <p style={{ fontSize: "18px" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad, illo
          numquam minima eum facere, porro possimus autem rem itaque quam
          voluptatem eveniet incidunt quas repudiandae est. Optio debitis
          repellendus dolore. Lorem ipsum dolor sit amet consectetur adipisicing
          elit. Fugit ab, iusto voluptatem nostrum nobis sapiente iure eveniet
          velit eum unde assumenda, dolorum saepe. Inventore excepturi
          consectetur harum voluptatem autem corrupti itaque quam fuga est.
        </p>
      </div> */}
        </div>
      </section>

      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
