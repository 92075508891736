import React, { Component } from "react";
import img from "../../../assets/pro.jpeg";
import Peer from "peerjs";
//import img1 from "../../assets/images/mp4.jpeg";
import HttpClient from "../../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
//import ReactJsAlert from "reactjs-alert";
import "./chatlist.css";
import Firebase, { fsdb } from "../../Chat/firebase";
import { Row, Card, CardBody, Col, Input, Button, Spinner } from "reactstrap";
import SimpleBar from "simplebar-react";

import moment from "moment";
import AudioModal from "../../Chat/Component/AudioModal";
import VideoModal from "../../Chat/Component/VideoModal";
import SessionClose from "./sessionclose";
import SweetAlert from "react-bootstrap-sweetalert";

const { v4: uuidv4 } = require("uuid");
const userdata = reactLocalStorage.getObject("userData");

//Creating an Object to the PeerServer for the connection builup
var peer = new Peer();
var mypeerId = "";
var myOwnStream = null; //taking my own media object
var remoteUserStream = null; // setting the remote user media to an object

//Getting my own PeerId
/* peer.on('open', () => {
  mypeerId = peer.id;
  //console.log("My Own Peer Id", mypeerId);
})
 */
//when Someone calling to Me
peer.on("connection", (conn) => {
  conn.on("data", function (data) {
    // Will print 'hi!'
    //console.log(data);
  });
});

//If any error is occuring
peer.on("error", (err) => {
  //alert("An error has occured", err);
});

export default class verifymobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "",
      status: false,
      title: "",
      msglist: [],
      message: "",
      modalStatus: this.props.status ? this.props.status : false,
      selectedFiles: [],
      filetype: "",
      firstFile: "",
      videoloading: false,
      adminCount: 0,
      userCount: 0,
      roomId: "",
      callmodal: false,
      videoModal: false,
      modalsession: false,
      confirm: false,
      sessionclose: false,
      orderData: [],
      allMsg: [],
      imagePrev: "",
      roomFound: false,
    };

    this.messagesEndRef = React.createRef();
  }
  componentDidMount() {
    //console.log("id---", this.props.serviceData._id);
    //console.log("klk", this.props.sellerdetails);
    // if (reactLocalStorage.hasOwnProperty("userData") && Object.keys("userData").length) {
    //   this.getOderHistory();
    // }
    //this.FetchMessageList("");
    this.getOderHistory();

    this.get_and_create_messages_list();

    //  Comment by shivam due to change chat functionality  // this.checkRoomId();

    this.getMsgsNew();
    // this.getFirestoreRoomId();
  }



  get_and_create_messages_list = () => {
    const sender_id = reactLocalStorage.getObject("userData");
    const receiver_id = this.props.sellerdetails;
    // console.log(receiverDetail);
    var chatUser = {
      sender_id: sender_id._id,
      sender_name: sender_id.firstName,
      receiver_name: receiver_id.firstName,
      room_id: uuidv4(),
      receiver_id: receiver_id._id,
    };

    const chat_usersRef = fsdb.collection("chat_users");
    chat_usersRef
      .where("sender_id", "==", chatUser.sender_id)
      .where("receiver_id", "==", chatUser.receiver_id)
      .get()
      .then((querySnap) => {
        if (querySnap.empty) {
          chat_usersRef
            .where("receiver_id", "==", chatUser.sender_id)
            .where("sender_id", "==", chatUser.receiver_id)
            .get()
            .then((secondQuerySnap) => {
              if (secondQuerySnap.empty) {
                this.setState({ roomFound: false });
                // this.FetchMessageList(chatUser.room_id);

                // nothing here we have create room when user send message
                // chat_usersRef
                //   .add(chatUser)
                //   .then((docRef) => {
                //     console.log("Document written with ID: ", docRef.id);
                //     this.setState({ roomId: chatUser.room_id });
                //   })
                //   .catch((err) => {
                //     console.log("err", err);
                //   });
              } else {
                this.setState({
                  roomId: secondQuerySnap.docs[0].data().room_id,
                  roomFound: true
                });

                this.FetchMessageList(secondQuerySnap.docs[0].data().room_id);
              }
            });
        } else {
          this.setState({ roomId: querySnap.docs[0].data().room_id, roomFound: true });
          this.FetchMessageList(querySnap.docs[0].data().room_id);
          // querySnap.forEach((doc) => {
          //     console.log(doc.id, ' => ', doc.data());
          // });
          // this.FetchMessageList(
          //   querySnap.forEach((doc) => {
          //     return doc.id;
          //   })
          // );
        }
      });

    // console.log("chatuser", chatUser);

    // chat_usersRef.where("")
  };

  getOderHistory = async () => {
    let userData = reactLocalStorage.getObject("userData");
    //console.log("usd", userData);
    let result = await HttpClient.requestData(
      "myaccount/service-order-history/" + userData._id,
      "GET"
    );
    //console.log("result of oder", result);
    if (result && result.status) {
      let orderDatalist = result.data.filter(
        (item) => item.cart_data[0].service_id == this.props.serviceId
      );
      //console.log("orderData", orderDatalist);
      this.setState({ orderData: orderDatalist });
    }
  };

  callBackcall1 = () => {
    this.setState({
      videoModal: false,
    });
  };

  scrollToBottom = async () => {
    const target = this.messagesEndRef?.current;

    target?.scrollIntoView({
      //top: target.scrollHeight,
      behavior: "smooth",
    });
  };

  //for checking the roomid if the chat is already initiated
  checkRoomId = async () => {
    Firebase.database()
      .ref()
      .child("chatlist")
      .child(this.props.sellerdetails ? this.props.sellerdetails._id + "S" : "")
      .child(this.props.userData ? this.props.userData._id : "")
      .get()
      .then((snapshot) => {
        //If the room id is already available

        if (snapshot.exists()) {
          //console.log("check room id", snapshot.val().roomid);
          //Fetch the msglist between the two user & provider
          this.state.roomId = snapshot.val().roomid;
          this.state.sessionclose = snapshot.val().sessionclose;
          this.setState({});
          //console.log("roomid", this.state.roomId);
          this.FetchMessageList(snapshot.val().roomid);
        } else {
          //console.log("No data available");
          this.state.msglist = [];

          this.setState({});
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  createRoomid = async () => {
    fsdb
      .collection("chatroom")
      .doc(this.props.sellerdetails._id)
      .set({
        receiverId: this.props.sellerdetails._id,
        sender_id: this.props.userData._id,
      })
      .then(() => {
        console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };
  getMsgsNew = async () => {
    return false; //made by shivam
    fsdb
      .collection(this.props.sellerdetails._id)
      .doc(this.props.userData._id)
      .onSnapshot((doc) => {
        if (doc.data()) {
          console.log("Current data: ", doc.data());
          this.setState({ allMsg: doc.data().msg });

        }
      });
  };
  getFirestoreRoomId = async () => {
    let chatref = fsdb.collection("chatroomId");

    let room = chatref
      .where("sellerId", "==", this.props.sellerdetails._id)
      .where("userId", "==", this.props.userData._id);

    room
      .get()
      .then((querySnapshot) => {
        console.log("querySnapshot", querySnapshot.empty);
        if (querySnapshot.empty) {
        }
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    return;
  };

  SendMsgNew = async () => {
    // alert("mkmk")
    // let data = this.state.allMsg;
    let user_id = reactLocalStorage.getObject("userData")._id;

    let ob = {
      msg: this.state.message,
      time: moment().format(),
      image: this.state.imagePrev,
      // sender_id: this.props.userData._id,
      sender_id: user_id,
      receiver_id: this.props.sellerdetails?._id,
    };
    if (this.state.imagePrev !== "") {
      ob.image = true;
    } else {
      ob.image = false;
    }
    console.log("message", ob);

    if (!this.state.roomFound) {
      const sender_id = reactLocalStorage.getObject("userData");
      const receiver_id = this.props.sellerdetails;
      // console.log(receiverDetail);
      let chatUser = {
        sender_id: sender_id._id,
        sender_name: sender_id.firstName,
        senderDetail: sender_id,
        receiverDetail: receiver_id,
        receiver_name: receiver_id.firstName,
        room_id: uuidv4(),
        receiver_id: receiver_id._id,
      };
      fsdb
        .collection("chat_users")
        .add(chatUser)
        .then(() => {
          console.log("complete");
          fsdb
            .collection("chat")
            .doc(chatUser.room_id)
            .collection("messages")
            .add(ob)
            .then((doc) => {
              this.setState({ roomFound: true, message: "", imagePrev: "" })
              this.setState({ roomId: chatUser.room_id })
              this.FetchMessageList(chatUser.room_id)
              this.createNotificationInotherSide(chatUser.room_id, true)


            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      fsdb
        .collection("chat")
        .doc(this.state.roomId)
        .collection("messages")
        .add(ob)
        .then((doc) => {
          this.createNotificationInotherSide(this.state.roomId, true)

          this.setState({ message: "", imagePrev: "" })

        })
        .catch((err) => {
          console.log(err);
        });
    }

    // this code write by surajit due to some reason me shivam Comment this code i write own code
    // data.push(ob);
    // fsdb
    //   .collection(this.props.sellerdetails._id)
    //   .doc(this.props.userData._id)
    //   .set({
    //     msg: data,
    //     name:
    //     reactLocalStorage.getObject("userData").firstName +" "+
    //     reactLocalStorage.getObject("userData").lastName,
    //   })
    //   .then(() => {
    //     this.setState({ message: "", imagePrev: "" });
    //   })
    //   .catch((error) => {
    //     console.error("Error writing document: ", error);
    //   });
  };

  FetchMessageList = async (roomid) => {
    // alert('njj')
    // console.log("hii", roomid);

    fsdb
      .collection("chat")
      .doc(roomid ?? this.state.roomId)
      .collection("messages")
      .orderBy("time")
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.empty) {
          var message = [];
          querySnapshot.forEach((doc) => {
            message.push(doc.data());
          });

          this.setState({ allMsg: message });
          this.scrollToBottom()
        }

        console.log("shivmessage", message);
      });

    // comment by shivam due to change chat functionlity
    // return false
    // Firebase.database()
    //   .ref()
    //   .child("chat")
    //   .child(roomid)
    //   .child("messages")
    //   .on("value", (snapshot) => {
    //     let arr = [];
    //     if (snapshot.exists()) {
    //       // //console.log("IndividualResponseClick", snapshot.val());

    //       // snapshot.forEach((ele) => {
    //       //   let dom = {
    //       //     sender_id: ele.val().sender_id,
    //       //     message: ele.val().message,
    //       //     msgid: ele.val().msgid,
    //       //     send_time: moment(ele.val().send_time).fromNow(),
    //       //     roomid: ele.val().roomid,
    //       //     name: ele.val().name,
    //       //     msgimg: ele.val().msgimg ? ele.val().msgimg : "",
    //       //   };
    //       //   //console.log("dom from", dom);
    //       //   arr.push(dom);
    //       // });
    //       // //console.log("arr", arr);
    //       this.setState({
    //         msglist: Object.values(snapshot.val()),
    //       });
    //       this.scrollToBottom();
    //     }
    //   });
  };

  onCloseMethod = () => {
    this.props.onCloseCallBack("close");
  };

  SendMsgSecondtime = async (url) => {
    let date = Date.now();
    let date1 = moment(date).format("YYYY-MM-DD");
    let date2 = moment(date).format("HH:mm:ssZ");
    let msgTime = date1 + "T" + date2;
    let fname = this.props.userData ? this.props.userData.firstName : "";
    let lname = this.props.userData ? this.props.userData.lastName : "";
    //now we setting the msg body into the perticular room id

    //console.log("roomid", this.state.roomId);
    //console.log("msgtime", msgTime);

    let msgId = Firebase.database()
      .ref()
      .child("chat")
      .child(this.state.roomId)
      .child("messages")
      .push().key;
    Firebase.database()
      .ref()
      .child("chat")
      .child(this.state.roomId)
      .child("messages")
      .child(msgId)
      .set(
        {
          sender_id: this.props.userData ? this.props.userData._id : "",
          message: this.state.message,
          msgid: msgId,
          send_time: msgTime,
          roomid: this.state.roomId,
          name: fname + " " + lname,
          msgimg:
            this.state.filetype == "image/jpeg" ||
              this.state.filetype == "image/png"
              ? url
              : "",
        },
        (error) => {
          if (error) {
            // alert("something went wrong");
          } else {
          }
        }
      );

    //Updating the chat list data of seller side--
    if (this.state.message != "") {
      let sellerid = this.props.sellerdetails
        ? this.props.sellerdetails._id + "S"
        : "";
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(sellerid)
        .child(this.props.userData ? this.props.userData._id : "")
        .update({
          lastMsg: this.state.message,
          lastMsgTime: msgTime,
          forimage: "",
          sessionclose: false,
        });
    } else {
      let sellerid = this.props.sellerdetails
        ? this.props.sellerdetails._id + "S"
        : "";
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(sellerid)
        .child(this.props.userData ? this.props.userData._id : "")
        .update({
          lastMsg: this.state.message,
          lastMsgTime: msgTime,
          forimage:
            this.state.filetype == "image/jpeg" ||
              this.state.filetype == "image/png"
              ? url
              : "",
        });
    }
    //Updating the user side list--
    if (this.state.message != "") {
      let userid = this.props.userData ? this.props.userData._id + "U" : "";
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(userid)
        .child(this.props.sellerdetails ? this.props.sellerdetails._id : "")
        .update({
          lastMsg: this.state.message,
          lastMsgTime: msgTime,
          forimage: "",
        });
    } else {
      let userid = this.props.userData ? this.props.userData._id + "U" : "";
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(userid)
        .child(this.props.sellerdetails ? this.props.sellerdetails._id : "")
        .update({
          lastMsg: this.state.message,
          lastMsgTime: msgTime,
          forimage:
            this.state.filetype == "image/jpeg" ||
              this.state.filetype == "image/png"
              ? url
              : "",
        });
    }

    this.setState({
      message: "",
      firstFile: "",
      selectedFiles: [],
    });
    this.checkRoomId();
  };

  SendMsg = async (url) => {
    let date = Date.now();
    let date1 = moment(date).format("YYYY-MM-DD");
    let date2 = moment(date).format("HH:mm:ssZ");
    let msgTime = date1 + "T" + date2;
    let fname = this.props.userData ? this.props.userData.firstName : "";
    let lname = this.props.userData ? this.props.userData.lastName : "";
    //now we setting the msg body into the perticular room id
    let roomid = uuidv4();
    //console.log("roomid", roomid);
    //console.log("msgtime", msgTime);

    Firebase.database()
      .ref()
      .child("chat")
      .child(roomid)

      .set(
        {
          messages: "",
        },

        (error) => {
          if (error) {
            //alert("something went wrong");
          } else {
            //this.scrollToBottom();
            // alert("created");
          }
        }
      );

    //creating message id
    let msgid = Firebase.database()
      .ref()
      .child("chat")
      .child(roomid)
      .child("messages")
      .push().key;
    //console.log("msgid", msgid);
    /////////in messages
    Firebase.database()
      .ref()
      .child("chat")
      .child(roomid)
      .child("messages")
      .child(msgid)
      .set(
        {
          sender_id: this.props.userData ? this.props.userData._id : "",
          message: this.state.message,
          msgid: msgid,
          send_time: msgTime,
          roomid: roomid,
          name: fname + " " + lname,
          msgimg:
            this.state.filetype == "image/jpeg" ||
              this.state.filetype == "image/png"
              ? url
              : "",
        },

        (error) => {
          if (error) {
            // alert("something went wrong");
          } else {
            //alert("messagelist created");

            this.props.onCloseCallBack("redirect");
          }
        }
      );
    this.setState({
      message: "",
      firstFile: "",
      selectedFiles: [],
    });

    //For Seller chat list view
    if (this.state.message != "") {
      let sellerid = this.props.sellerdetails
        ? this.props.sellerdetails._id + "S"
        : "";
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(sellerid)
        .child(this.props.userData ? this.props.userData._id : "")
        .set(
          {
            displayName:
              this.props.userData.firstName +
              " " +
              this.props.userData.lastName,
            lastMsg: this.state.message,
            lastMsgTime: msgTime,
            name:
              this.props.userData.firstName +
              " " +
              this.props.userData.lastName,
            roomid: roomid,
            userId: this.props.userData ? this.props.userData._id : "",
            forimage: "",
            sessionclose: false,
            lastMsgBy: "User",
          },
          (error) => {
            if (error) {
              // alert("something went wrong");
            } else {
              //this.scrollToBottom();
              ////console.log("mesage", message);
              ////console.log("updated");
            }
          }
        );
    } else {
      let sellerid = this.props.sellerdetails
        ? this.props.sellerdetails._id + "S"
        : "";
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(sellerid)
        .child(this.props.userData ? this.props.userData._id : "")
        .set(
          {
            displayName:
              this.props.userData.firstName +
              " " +
              this.props.userData.lastName,
            lastMsg: this.state.message,
            lastMsgTime: msgTime,
            name:
              this.props.userData.firstName +
              " " +
              this.props.userData.lastName,
            roomid: roomid,
            userId: this.props.userData ? this.props.userData._id : "",
            forimage:
              this.state.filetype == "image/jpeg" ||
                this.state.filetype == "image/png"
                ? url
                : "",
            sessionclose: false,
            lastMsgBy: "User",
          },
          (error) => {
            if (error) {
              // alert("something went wrong");
            } else {
              //this.scrollToBottom();
              ////console.log("mesage", message);
              ////console.log("updated");
            }
          }
        );
    }

    //for owner side /user side  chatlist set.........................

    if (this.state.message != "") {
      let userid = this.props.userData ? this.props.userData._id + "U" : "";
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(userid)
        .child(this.props.sellerdetails ? this.props.sellerdetails._id : "")
        .set(
          {
            displayName:
              this.props.sellerdetails.firstName +
              " " +
              this.props.sellerdetails.lastName,
            lastMsg: this.state.message,
            lastMsgTime: msgTime,
            name:
              this.props.sellerdetails.firstName +
              " " +
              this.props.sellerdetails.lastName,
            roomid: roomid,
            userId: this.props.sellerdetails
              ? this.props.sellerdetails._id
              : "",
            forimage: "",
            sessionclose: false,
            lastMsgBy: "User",
          },
          (error) => {
            if (error) {
              alert("something went wrong");
            } else {
              //this.scrollToBottom();
              ////console.log("mesage", message);
              //console.log("updated");
              this.checkRoomId();
            }
          }
        );
    } else {
      let userid = this.props.userData ? this.props.userData._id + "U" : "";

      Firebase.database()
        .ref()
        .child("chatlist")
        .child(userid)
        .child(this.props.sellerdetails ? this.props.sellerdetails._id : "")
        .set(
          {
            displayName:
              this.props.sellerdetails.firstName +
              " " +
              this.props.sellerdetails.lastName,
            lastMsg: this.state.message,
            lastMsgTime: msgTime,
            name:
              this.props.sellerdetails.firstName +
              " " +
              this.props.sellerdetails.lastName,
            roomid: roomid,
            userId: this.props.sellerdetails
              ? this.props.sellerdetails._id
              : "",
            forimage:
              this.state.filetype == "image/jpeg" ||
                this.state.filetype == "image/png"
                ? url
                : "",
            sessionclose: false,
            lastMsgBy: "User",
          },
          (error) => {
            if (error) {
              alert("something went wrong");
            } else {
              //this.scrollToBottom();
              ////console.log("mesage", message);
              //console.log("updated");
              this.checkRoomId();
            }
          }
        );
    }

    //this.checkRoomId();
  };

  uploadFile = async () => {
    this.setState({
      videoloading: true,
    });
    let data = new FormData();
    data.append("image", this.state.firstFile);
    let result = await HttpClient.fireBaseImage(
      "image-uploadurl",
      "POST",
      data
    );
    //console.log(result);
    if (result && result.status) {
      this.setState({
        videoloading: false,
      });
      if (this.state.roomId != "") {
        this.SendMsgSecondtime(result.data);
      } else {
        this.SendMsg(result.data);
      }
    }
  };

  handlefilePick = async (event) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      filetype: event.target.files[0].type,
      //firstFile: URL.createObjectURL(event.target.files[0]),
      firstFile: event.target.files[0],
      selectedFiles: [
        {
          preview: URL.createObjectURL(event.target.files[0]),
          type: event.target.files[0].type,
        },
      ],
    });
    //console.log("selected files", this.state.selectedFiles);
    setTimeout(() => {
      //console.log("selected files", this.state.firstFile);
    }, 3000);
  };

  imgup = async (e) => {
    console.log("files", e.target.files);
    let data = new FormData();
    data.append("image", e.target.files[0]);
    let result = await HttpClient.fireBaseImage(
      "image-uploadurl",
      "POST",
      data
    );
    console.log(result);
    if (result && result.status) {
      this.setState({ imagePrev: HttpClient.IMG_URL + result.data });
      this.setState({ message: HttpClient.IMG_URL + result.data });
    }
  };
  callBackcall = () => {
    this.setState({ callmodal: false });
    //console.log("gud");
  };

  CallBack = async () => {
    this.state.modalsession = false;
    this.state.sessionclose = true;
    this.setState({});
  };

  callmodal = async () => {
    this.state.modalsession = true;
    this.setState({});
    toast.success("Your Session ended successfully", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    this.state.confirm = false;
    this.setState({});
    let userid = this.props.userData ? this.props.userData._id + "U" : "";
    Firebase.database()
      .ref()
      .child("chatlist")
      .child(userid)
      .child(this.props.sellerdetails ? this.props.sellerdetails._id : "")
      .update(
        {
          sessionclose: true,
        },
        (error) => {
          if (error) {
            alert("something went wrong");
          } else {
            //this.scrollToBottom();
            //console.log("mesage");
            //alert("updated");
          }
        }
      );
    let sellerid = this.props.sellerdetails
      ? this.props.sellerdetails._id + "S"
      : "";
    Firebase.database()
      .ref()
      .child("chatlist")
      .child(sellerid)
      .child(this.props.userData._id)
      .update(
        {
          sessionclose: true,
        },
        (error) => {
          if (error) {
            alert("something went wrong");
          } else {
            //console.log("mesage");
          }
        }
      );
  };

  createNotificationInotherSide = async (room_id, notification) => {
    fsdb.collection("chat_users").where("room_id", "==", room_id).get()
      .then((doc) => {

        let Docid = doc.docs[0].id
        fsdb.collection("chat_users").doc(Docid).update({
          notification: notification
        })
      })

    // return false

    // fsdb.collection("chat_users").doc(docId).update({
    //   notification:notification
    // }).then(()=>{

    // }).catch((err)=>{

    // })
  }

  render() {
    const { selectedOption } = this.state;
    console.log("first", this.state.allMsg);
    return (
      <div
        className={this.state.modalStatus ? "modal show fade" : "modal fade"}
        id="add-new-task-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="add-new-task-modalLabel"
        aria-hidden="true"
        style={{ display: this.state.modalStatus ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
          <div className="modal-content">
            <Card className="mb-0">
              {/* chat head area */}
              <CardBody className="border-bottom">
                <div className="user-chat-border">
                  <Row>
                    <Col md={9} xs={9}>
                      <div className="d-flex">
                        <div className="my-auto mr-3">
                          {this.props.image != "" ? (
                            <img
                              src={this.props.image}
                              className="img-fluid rounded-circle"
                              style={{ height: "30px", width: "30px" }}
                            />
                          ) : (
                            <img
                              src={img}
                              className="img-fluid rounded-circle"
                              style={{ height: "30px", width: "30px" }}
                            />
                          )}
                        </div>
                        <h5 className="font-size-15 my-auto mr-auto">
                          {this.props.sellerdetails.firstName +
                            " " +
                            this.props.sellerdetails.lastName}
                        </h5>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="d-flex  mr-4 chat-head">
                        {this.state.orderData.length || this.props.sellerOrderModal ? (
                          <>
                            <div
                              className="call-icon align-self-center mr-4"
                              onClick={() => {
                                if (this.state.sessionclose == true) {
                                } else {
                                  this.setState({
                                    callmodal: true,
                                  });
                                }
                              }}
                            >
                              <i className="fas fa-phone-alt"></i>
                            </div>
                            <div
                              className="call-icon align-self-center mr-4"
                              onClick={() => {
                                // this.props.openCalling('video', this.state.roomId)
                                if (this.state.sessionclose == true) {
                                  toast.error("session Out")
                                } else {
                                  this.setState({
                                    videoModal: true,
                                  });
                                }
                              }}
                            >
                              <i className="fas fa-video"></i>

                            </div>
                          </>
                        ) : (
                          <>
                            <div className="call-icon align-self-center mr-4"></div>
                            <div className="call-icon align-self-center mr-4"></div>
                          </>
                        )}

                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={() => this.onCloseMethod()}
                        >
                          ×
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
              {/* chat list body */}
              <CardBody>
                <div className="chat-widget">
                  <div className="chat-conversation">
                    <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="list-unstyled mb-0 px-3">
                        {this.state.allMsg?.length > 0 &&
                          this.state.allMsg.map((item, index) => {

                            if (item.videoStatus) {
                              return <li className="log_time">
                                <span>{
                                  item.sender_id !== this.props.userData._id ?
                                    `${this.props.sellerdetails.firstName} missed video call at ${moment(item.time).fromNow()}` :
                                    `You missed video call at ${moment(item.time).fromNow()}`
                                }</span>
                              </li>
                            }
                            if (item.audioStatus) {
                              return <li className="log_time">
                                <span>{
                                  item.sender_id !== this.props.userData._id ?
                                    `${this.props.sellerdetails.firstName} call at ${moment(item.time).fromNow()}` :
                                    `You call at ${moment(item.time).fromNow()}`
                                }</span>
                              </li>
                            }
                            return (
                              <li
                                className={
                                  item.sender_id == this.props.userData._id
                                    ? "receiver"
                                    : "sender"
                                }
                                key={index}
                              >
                                <div className="conversation-list">
                                  <div className="ctext-wrap">
                                    <div className="ctext-wrap-content py-1 px-2">
                                      {item.image ? (
                                        <>
                                          {" "}
                                          <img
                                            style={{ height: 75, width: 75 }}
                                            src={item.msg}
                                          />
                                          <br />
                                          <span className="mb-0 small text-muted">
                                            {moment(item.time).fromNow()}
                                          </span>
                                        </>
                                      ) : (
                                        <>{item.msg.slice(0, 4) == "http" ? <a href={item.msg}>{item.msg}</a> :
                                          <p className="mb-0">{item.msg}</p>}

                                          <span className="mb-0 small text-muted">
                                            {moment(item.time).fromNow()}
                                          </span>
                                        </>
                                      )}

                                      {/* if image exist */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        {/*                         
                        {this.state.msglist.length > 0 &&
                          this.state.msglist.map((item, index) => {
                            // //console.log("item", item);
                            // //console.log("item1", this.props.userData._id);
                            return (
                              <>
                                {item.type == "call" ? (
                                  <li className="log_time">
                                    {moment(item.callTime).format("hh:mm A")}
                                    <br />
                                    <span>
                                      {item.callStatus == "Recieved"
                                        ? `Call ${item.callDuration}`
                                        : item.sender_id ==
                                          this.props.userData._id
                                        ? "Not Connected"
                                        : "Missed Call"}
                                    </span>
                                    <br />
                                    <span>
                                      {moment(item.callTime).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </span>
                                  </li>
                                ) : (
                                  <li
                                    className={
                                      item.sender_id == this.props.userData._id
                                        ? "receiver"
                                        : "sender"
                                    }
                                    key={index}
                                  >
                                    {item.message != "" && item.msgimg == "" ? (
                                      <div className="conversation-list">
                                        <div className="ctext-wrap">
                                          <div className="ctext-wrap-content py-1 px-2">
                                            <p className="mb-0">
                                              {item.message}
                                            </p>
                                            <span className="mb-0 small text-muted">
                                              {moment(item.send_time).fromNow()}
                                            </span>
                                        
                                          </div>
                                        </div>
                                      </div>
                                    ) : item.message != "" &&
                                      item.msgimg != "" ? (
                                      <div className="conversation-list">
                                        <div className="ctext-wrap">
                                          <div className="ctext-wrap-content py-1 px-2">
                                            <p className="mb-0">
                                              {item.message}
                                            </p>
                                            <a
                                              href={
                                                HttpClient.IMG_URL + item.msgimg
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                data-dz-thumbnail
                                                src={
                                                  HttpClient.IMG_URL +
                                                  item.msgimg
                                                }
                                                height="80px"
                                                width="80px"
                                                // className="img-fluid p-2 Imgsended"
                                                alt="preview"
                                              />
                                            </a>
                                            <p className="mb-0 small text-muted">
                                              {moment(item.send_time).fromNow()}
                                            </p>
                                           
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="conversation-list">
                                        <div className="ctext-wrap">
                                          <div className="ctext-wrap-content py-1 px-2">
                                            <a
                                              href={
                                                HttpClient.IMG_URL + item.msgimg
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                data-dz-thumbnail
                                                src={
                                                  HttpClient.IMG_URL +
                                                  item.msgimg
                                                }
                                                height="80px"
                                                width="80px"
                                                // className="img-fluid p-2 Imgsended"
                                                alt="preview"
                                              />
                                            </a>
                                            <p className="mb-0 small text-muted">
                                              {moment(item.send_time).fromNow()}
                                            </p>

                                           
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </li>
                                )}
                              </>
                            );
                          })} */}
                        <li ref={this.messagesEndRef}></li>
                      </ul>
                    </SimpleBar>
                  </div>
                </div>
              </CardBody>
              {/* chat write area */}
              <div className="p-3 chat-input-section border-top">
                {this.state.sessionclose ? (
                  <div className="MessageWrite">
                    <p>
                      You Have ended your session You can't use audio & video
                      call features
                    </p>{" "}
                    <div className="d-flex align-items-center">
                      <div class="form-group mb-0">
                        <i
                          class="fa fa-comments"
                          onClick={() => {
                            this.state.modalsession = true;
                            this.setState({});
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Row>
                    {/* upload image here */}
                    <Col md={12}>
                      <div>
                        {this.state.selectedFiles.length > 0 &&
                          this.state.selectedFiles.map((item, index) => {
                            return (
                              <div className="upld_img">
                                {item.type == "image/jpeg" ? (
                                  <img
                                    data-dz-thumbnail
                                    src={item.preview}
                                    height="60px"
                                    width="60px"
                                    // className="img-fluid p-2 Imgsended"
                                    alt="preview"
                                  />
                                ) : (
                                  <img
                                    data-dz-thumbnail
                                    src={img}
                                    className="avatar-sm rounded bg-light"
                                    alt="preview"
                                  />
                                )}

                                <button
                                  //className="close border-0 bg-transparent p-0 img_remove"
                                  onClick={() => {
                                    this.setState({
                                      firstFile: "",
                                      selectedFiles: [],
                                    });
                                  }}
                                >
                                  <i class="fas fa-times"></i>
                                </button>
                              </div>
                            );
                          })}
                        {/* {this.state.videoloading?
                        <img
                        src="https://tenor.com/view/loading-waiting-please-wait-gif-14579561"
                        />:null} */}

                        {/* {this.state.videoloading ? (
                          <img
                            src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/loading-waiting-please-wait-gif-14579561"
                            style={{ height: 50, width: 50 }}
                          />
                        ) : null} */}
                      </div>
                    </Col>
                    {/* choose image */}
                    <Col md={1}>
                      <label htmlFor="fileUpload" className="my_fileUpload">
                        <i className="far fa-file-image"></i>
                        <input
                          accept="image/*"
                          type="file"
                          id="fileUpload"
                          className="fileUpload"
                          // onChange={this.handlefilePick}
                          onChange={this.imgup}
                        />
                      </label>
                    </Col>

                    {/* write message */}
                    <Col md={9}>
                      <div>
                        {this.state.imagePrev !== "" ? (
                          <div>
                            <img
                              style={{ height: 100, width: 100 }}
                              src={this.state.imagePrev}
                            />
                            <button
                              onClick={() => {
                                this.setState({ imagePrev: "", message: "" });
                              }}
                              className="btn btn-danger"
                            >
                              X
                            </button>
                          </div>
                        ) : (
                          <Input
                            type="text"
                            className="form-control rounded chat-input pl-3"
                            placeholder="Enter Message..."
                            onChange={(val) =>
                              this.setState({
                                message: val.target.value,
                              })
                            }
                            value={this.state.message}
                          />
                        )}
                      </div>
                    </Col>
                    {this.state.msglist?.length > 0 &&
                      userdata.type === "Seller" ? (
                      <i
                        class="fa fa-lock"
                        onClick={() => {
                          this.state.confirm = true;

                          this.setState({});
                        }}
                      ></i>
                    ) : null}
                    {this.state.message != "" && this.state.firstFile != "" ? (
                      <Col md={2} xs={{ size: "auto" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={this.uploadFile}
                          className="chat-send waves-effect waves-light font-size-12 w-100"
                        >
                          <span className="d-none d-sm-inline-block mr-1">
                            Send
                          </span>{" "}
                          <i className="mdi mdi-send"></i>
                        </Button>
                      </Col>
                    ) : this.state.message == "" &&
                      this.state.firstFile != "" ? (
                      <Col md={2} xs={{ size: "auto" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={this.uploadFile}
                          className="chat-send waves-effect waves-light font-size-12 w-100"
                        >
                          <span className="d-none d-sm-inline-block mr-1">
                            Send
                          </span>{" "}
                          <i className="mdi mdi-send"></i>
                        </Button>
                      </Col>
                    ) : this.state.message != "" &&
                      this.state.firstFile == "" ? (
                      <Col md={2} xs={{ size: "auto" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            this.SendMsgNew();
                          }}
                          // onClick={() => {
                          //   if (this.state.roomId != "") {
                          //     this.SendMsgSecondtime("");
                          //   } else {
                          //     this.SendMsg("");
                          //   }
                          // }}
                          className="chat-send waves-effect waves-light font-size-12 w-100"
                        >
                          <span className="d-none d-sm-inline-block mr-1">
                            Send
                          </span>{" "}
                          <i className="mdi mdi-send"></i>
                        </Button>
                      </Col>
                    ) : (
                      //    this.state.videoloading?
                      //    <Col md={2} xs={{ size: "auto" }}>
                      //     <Spinner
                      //     color="primary"

                      //     className="chat-send waves-effect waves-light font-size-12 w-100"
                      //    >

                      //   </Spinner>
                      //  </Col>:
                      <Col md={2} xs={{ size: "auto" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            toast.warning("Please enter a message", {
                              position: "bottom-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }}
                          className="chat-send waves-effect waves-light font-size-12 w-100"
                        >
                          <span className="d-none d-sm-inline-block mr-1">
                            Send
                          </span>{" "}
                          <i className="mdi mdi-send"></i>
                        </Button>
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            </Card>
          </div>
          {this.state.callmodal ? (
            <div
              className={
                this.state.callmodal
                  ? "modal fade single_modal show"
                  : "modal fade"
              }
              id="exampleModal1"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: this.state.callmodal ? "block" : "none" }}
            >
              <AudioModal
                status={true}
                userData={reactLocalStorage.getObject("userData")}
                chatRoomId={this.state.roomId}
                remoteData={this.props.sellerdetails}
                callBackcall1={() => this.callBackcall()}
                image=""
              />
            </div>
          ) : null}

          {this.state.videoModal ? (
            <div
              className="modal fade single_modal show"
              id="exampleModal1"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <VideoModal
                chatRoomId={this.state.roomId}
                remoteData={this.props.sellerdetails}
                callBackcall1={(val) => this.callBackcall1(val)}
              />
            </div>
          ) : null}
        </div>
        {this.state.modalsession ? (
          <div
            className={
              this.state.modalsession
                ? "modal single_modal show fade"
                : "modal show fade"
            }
            // tabIndex="-1"
            style={{ display: this.state.modalsession ? "block" : "none" }}
          >
            <SessionClose
              CallBacksession={this.CallBack}
              sellerid={
                this.props.sellerdetails ? this.props.sellerdetails._id : ""
              }
              userid={this.props.userData ? this.props.userData._id : ""}
            />
          </div>
        ) : null}
        {this.state.confirm ? (
          <SweetAlert
            title="Are you want to close the chat session?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={this.callmodal}
            onCancel={() => {
              this.state.confirm = false;
              this.setState({});
            }}
          >
            You won't be able to chat after session close!
          </SweetAlert>
        ) : null}
      </div>
    );
  }
}
