import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { ToastContainer, toast } from "react-toastify";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

const Rating = (props) => {
  const [rating, setrating] = useState(0);
  const userData = reactLocalStorage.getObject("userData");
  const [comment, setcomment] = useState("");
  const [serviceimg, setserviceimg] = useState();
  const [name, setname] = useState(userData.firstName + " " + userData.lastName);
  const [email, setemail] = useState(userData.email);
  const [phone, setphone] = useState("");
  const [address, setaddress] = useState("");
  const [govtIdName, setgovtIdName] = useState("");
  const [govtId, setgovtId] = useState("");
  const [preview, setpreview] = useState([]);
  const [multiImages, setmultiImages] = useState([]);
  const [arr, setarr] = useState([]);
  const ratingChanged = (newRating) => {
    //console.log(newRating);
    setrating(newRating);
  };

  //   const Submit = async () => {
  //     //console.log("hii");

  //     if (
  //       rating != ""

  //     ) {

  //       let data = {
  //         user_id: userid,
  //         service_id: serviceid,
  //         comment: comment,
  //         rating: rating,
  //         order_id: orderid,
  //         seller_id: sellerid

  //       }
  //       //console.log(data);
  //       let result = await HttpClient.requestData(
  //         "seller-service/reviews",
  //         "POST",
  //         data
  //       );
  //       if (result && result.status) {
  //         //console.log("checkout", result.data);
  //         toast.success("Thanks for rating", {
  //           position: "bottom-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         CallBackRate(false)
  //       } else {
  //         toast.warning(result.message, {
  //           position: "bottom-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //     } else {

  //       toast.warning("please rate the service", {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }

  //   };
  const onImage = async (event) => {
    setserviceimg(event.target.files[0]);
    setarr([
      {
        preview: URL.createObjectURL(event.target.files[0]),
      },
    ]);
  };
  const deleteFile = (e) => {
    const s = arr.filter((item, index) => index !== e);
    setarr(s);
    // //console.log(s);
  };
  const apply = async () => {
    if (
      name != "" &&
      email != "" &&
      phone != ""&&
      address != "" &&
      govtIdName != "" &&
      govtId != "" &&
      arr.length != 0
    ) {
      let data = new FormData();
      data.append("name", name);
      data.append("email", email);
      data.append("phone", phone);
      data.append("address", address);
      data.append("govt_id_name", govtIdName);
      data.append("govt_id", govtId);
      data.append("image", serviceimg);
      data.append("seller_id", userData._id);
      let result = await HttpClient.fileUplode(
        "apply-for-seller",
        "POST",
        data
      );
      //console.log("Apply data", result);
      if (result && result.status) {
        setname("");
        setemail("");
        setphone("");
        setaddress("");
        setgovtIdName("");
        setserviceimg("");
        setgovtId("");
        toast.success(result.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reactLocalStorage.setObject("seller_approval_id", result.data._id);
        props.callBackService(false);
        props.success(true);
      } else {
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warn("Please Enter All Fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="modal-dialog my-3 Rating">
      <div className="modal-dialog">
        <ToastContainer />
        <div className="modal-content pt-5 px-5 pb-4">
          <div className="">
            <span></span>
            <button
              type="button"
              className="close Single_close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.callBackService(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="mb-4">
            <h6 className=" mb-2" style={{ fontSize: "18px" }}>
              Name
            </h6>
            <input
              type="text"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              placeholder="Enter Name"
              onChange={(val) => {
                setname(val.target.value);
              }}
              value={name}
            />
          </div>
          <div className="mb-4">
            <h6 className=" mb-2" style={{ fontSize: "18px" }}>
              Email
            </h6>
            <input
              type="email"
              class="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Enter Email"
              rows="5"
              onChange={(val) => {
                setemail(val.target.value);
              }}
              value={email}
            />
          </div>
          <div className="mb-4">
            <h6 className=" mb-2" style={{ fontSize: "18px" }}>
              Phone
            </h6>
            <input
              type="text"
              class="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Enter Phone"
              rows="5"
              onChange={(val) => {
                setphone(val.target.value);
              }}
              value={phone}
            />
          </div>
          <div className="mb-4">
            <h6 className=" mb-2" style={{ fontSize: "18px" }}>
              Address
            </h6>
            <input
              type="text"
              class="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Enter Address"
              rows="5"
              onChange={(val) => {
                setaddress(val.target.value);
              }}
              value={address}
            />
          </div>
          <div className="mb-4">
            <h6 className=" mb-2" style={{ fontSize: "18px" }}>
              Goverement Id Name
            </h6>
            <input
              type="text"
              class="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Enter Goverement Id Name"
              rows="5"
              onChange={(val) => {
                setgovtIdName(val.target.value);
              }}
              value={govtIdName}
            />
          </div>
          <div className="mb-4">
            <h6 className=" mb-2" style={{ fontSize: "18px" }}>
              Goverement Id Number
            </h6>
            <input
              type="text"
              class="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Enter Goverement Id Number"
              rows="5"
              onChange={(val) => {
                setgovtId(val.target.value);
              }}
              value={govtId}
            />
          </div>
          <div className="mb-4">
            <h6 className=" mb-2" style={{ fontSize: "18px" }}>
              Image
            </h6>
            <input
              type="file"
              multiple
              className="input_file"
              onChange={onImage}
            />
            {/* Choose File */}
            {arr.map((item, index) => (
              <div className="product-thumb">
                <img
                  className="avatar ml-3 img-fluid img-thumbnail "
                  key={index}
                  style={{ width: "54px", height: "54px" }}
                  src={item.preview}
                  alt="..."
                />
                <button
                  type="button"
                  className="btn cross-btn"
                  onClick={() => deleteFile(index)}
                >
                  <i className="far fa-times-circle"></i>
                </button>
              </div>
            ))}
          </div>
          <div className=" pt-3 text-center mb-3">
            <button type="submit" onClick={apply} className="btn  PrimaryBtn">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rating;
