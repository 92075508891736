import React from 'react'
import bannerImage from "../../../assets/bannerImage.jpg"
import bannerImage2 from "../../../assets/bannerImage2.jpg"
import bannerimage3 from "../../../assets/bannerimage3.jpg"
function Homebanner() {
    return (
        <div id="demo" className="carousel slide" data-ride="carousel">
            {/* Indicators */}
            <ul className="carousel-indicators">
                <li data-target="#demo" data-slide-to={0} className="active" />
                <li data-target="#demo" data-slide-to={1} />
                <li data-target="#demo" data-slide-to={2} />
            </ul>
            {/* The slideshow */}
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={bannerImage} alt="Los Angeles" width={1100} height={500} />
                    <div className="carousel-caption">
                        <h3>Let Manifestation Of Magic Embrace Your Life!</h3>
                        <p>A guide on how to manifest something into existence</p>
                        <button className="shop_btn my-md-3 mb-md-0" role="button">
                            Shop Now
                            <i className="fas fa-chevron-right ml-2" />
                        </button>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={bannerImage2} alt="Chicago" width={1100} height={500} />
                    <div className="carousel-caption">
                        <h3>A Psychic Reading Brings Clarity To Your Life.</h3>
                        <p>They'll look into your medium and clear your worries.</p>
                        <button className="shop_btn my-md-3 mb-md-0" role="button">
                            Shop Now
                            <i className="fas fa-chevron-right ml-2" />
                        </button>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={bannerimage3} alt="New York" width={1100} height={500} />
                    <div className="carousel-caption">
                        <h3>Time To Unfold The Secret Of Your Love Life With Psychic Love  Reading Sessions.</h3>
                        <p>A Real Psychic Will Draw Your Soulmate and Provide In-Depth Reading Of Character Traits.</p>
                        <button className="shop_btn my-md-3 mb-md-0" role="button">
                            Shop Now
                            <i className="fas fa-chevron-right ml-2" />
                        </button>
                    </div>
                </div>
            </div>
            {/* Left and right controls */}
            <a className="carousel-control-prev" href="#demo" data-slide="prev">
                <span className="carousel-control-prev-icon" />
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
                <span className="carousel-control-next-icon" />
            </a>
        </div>

    )
}

export default Homebanner