import React, { useState, useEffect } from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
import ApplyModal from "./ApplyModal";
import HttpClient from "../../utils/HttpClient";
const Career = () => {
  const loop = [1, 2, 3, 4];
  const [result, setresult] = useState([]);
  const [applymodal, setApplymodal] = useState("");
  const [jobData, setjobData] = useState({});
  const callBackmodal = (val) => {
    setApplymodal(val);
    //console.log("hi");
  };

  useEffect(() => {
    scrollTop();
    fetchData();
  }, []);

  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchData = async () => {
    let result = await HttpClient.requestData("careers", "GET");
    //console.log("resut", result);
    if (result && result.status) {
      setresult(result.data);
    } else {
    }
  };
  return (
    <div>
      <section>
        <Jumbotron heading="Career With Us" img={serviceJumboBg} />
      </section>
      <section>
        <div className="container">
          <div className="row px-3">
            {result.map((item, index) => {
              return (
                <div className="col-md-3 my-5">
                  <div className="card career_box">
                    {/* <img
                      src="https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1174&q=80"
                      className="card-img-top"
                      alt="img"
                    /> */}
                    <div className="card-body text-center">
                      <h5 className="card-title">{item.title}</h5>
                      <p
                        className="card-text mb-2 _desc"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      >
                        {/* {item.description} */}
                      </p>
                      <p className="card-text mb-2">
                        <b>Vacancy:</b> {item.vacancy}
                      </p>
                      <p className="card-text mb-2">
                        <b>Exp:</b> {item.experience}{" "}
                        {item.experience.includes("year") ? null : "year"}
                      </p>
                      <button
                        type="button"
                        className="btn PrimaryBtn"
                        onClick={() => {
                          setApplymodal("open");
                          setjobData(item);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div
        className={
          applymodal === "open" ? "modal fade  show single_modal" : "modal fade"
        }
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: applymodal === "open" ? "block" : "none" }}
      >
        <ApplyModal jobData={jobData} callBackmodal={callBackmodal} />
      </div>
    </div>
  );
};

export default Career;
