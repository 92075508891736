import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";

import Jumbotron from "../../Component/Jumbotron";
import ServiceCard from "../../Component/ServiceCard";
import Footer2 from "../../Default/Footer2/index";
import Navbar from "../../Default/Navbar2/index";
// import SideDrop from "./Component/SideDrop";

import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import service1 from "../../Default/Assets/Images/Service/service1.png";
import service2 from "../../Default/Assets/Images/Service/service2.png";
import service3 from "../../Default/Assets/Images/Service/service3.png";
import ServicePagination from "../../Pages/Shop/ServicePagination";



const Index = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //console.log("service Id", props.id);
  const services = [
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
  ];
  const [categoryname, setcategoryname] = useState("");
  const [serviceList, setServiceList] = useState([]);
  console.log(serviceList, categoryname, "jjj")

  const [service_id, setService_id] = useState(props.match.params.id);
  const [user_id, setuser_id] = useState("");
  const [login, setlogin] = useState(false);
  const [productpaginator, setproductpaginator] = useState({});
  const user = reactLocalStorage.getObject("userData");

  useEffect(() => {
    // fetchShopId();

    //console.log(props.match.params.id);
    getUserData();
    let pro = fetchService();
    //console.log("promise", pro);

    window.scrollTo(0, 0);
  }, []);

  const getUserData = async () => {
    let getAccount = reactLocalStorage.getObject("userData");
    if (
      getAccount &&
      getAccount != null &&
      Object.keys(getAccount).length > 0
    ) {
      setlogin(true);
      setuser_id(getAccount._id);
      //console.log(getAccount);
      fetchServiceList(props.match.params.id, getAccount._id, null);
    } else {
      //console.log("not logged in");
      fetchServiceList1(props.match.params.id, "61618d5ddbd041128049d4bb", null);
    }
  };

  const fetchService = async () => {
    //console.log("fetchservice");
    let result = await HttpClient.requestData("service-category", "GET");
    //console.log("servicecategory", result);
    let cur_data = [];
    if (result && result.status) {
      cur_data = result.data.filter(
        (item) => item._id == props.match.params.id
      );

      //console.log(cur_data[0].name);
      setcategoryname(cur_data[0].name);
    } else {
    }
  };

  const fetchServiceList = async (id = null, userid = null, next = null) => {
    //console.log("idd", id);
    // setLoading(true);
    let sendData = next ? next : "1";
    let result = await HttpClient.requestData(
      "service-category/shop-services/" + userid + "/" + id + "/" + sendData + "?currency=" + user.currency,
      "GET"
    );
    //console.log("serviceList", result);
    if (result && result.status) {
      //console.log(result.data);
      if (result.data.hasOwnProperty("itemsList")) {
        if (result.data.itemsList.length > 0 && result.data.itemsList != undefined) {
          let cur_data = result.data.itemsList.filter((item) => item.user_id != userid);
          //console.log("data", cur_data);
          if (cur_data.length > 0 && cur_data != undefined) {
            //console.log('cur_data', cur_data)
            setServiceList(cur_data);
            setproductpaginator(result.data.paginator);
          }
        }
      } else {
        setServiceList([]);
        setproductpaginator({});
      }
    } else {
      return false;
    }

  };

  const fetchServiceList1 = async (id = null, userid = null, next = null) => {
    //console.log("idd", id);
    // setLoading(true);
    let sendData = next ? next : "1";
    let result = await HttpClient.requestData1(
      "service-category/shop-services/" + userid + "/" + id + "/" + sendData + "?currency=" + "USD",
      "GET"
    );
    //console.log("serviceList", result);
    if (result && result.status) {
      //console.log(result.data);
      if (result.data.hasOwnProperty("itemsList")) {
        if (result.data.itemsList.length > 0) {
          let cur_data = result.data.itemsList.filter((item) => item.user_id != userid);
          //console.log("data", cur_data);
          if (cur_data.length > 0 && cur_data != undefined) {
            setServiceList(cur_data);
            setproductpaginator(result.data.paginator);
          }
        }
      } else {
        setServiceList([]);
        setproductpaginator({});
      }
    } else {
      return false;
    }

  };
  const nextpage = (val) => {
    fetchServiceList(props.match.params.id, user._id, val);
    fetchServiceList1(props.match.params.id, "61618d5ddbd041128049d4bb", val)
  };

  return (
    <div className="ServiceList">
      <ReactTitle title="MySpiritualConnent - Service List" />
      <section>
        <Jumbotron heading="Service Listing" img={serviceJumboBg} />

      </section>
      <section className="container py-1" style={{ maxWidth: "1200px" }}>
        <h3 className="text-center mb-4 category_Name">
          Category Name: <span>{categoryname}</span>
        </h3>
        <div className="row justify-content-end mb-3">
          {/* <div className="col-md-4 ">
            <div className="d-flex align-items-center pr-3">
              <p className="mb-0" style={{ minWidth: "5rem" }}>
                Sort by
              </p>
              <select class="form-control">
                <option>Newly Added</option>
                <option>old Adds</option>
              </select>
            </div>
          </div> */}
        </div>
        <div className="row ">
          {/* <div className="col-md-3 mb-5 mb-md-0">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Date"
                aria-describedby="button-addon2"
              />
              <div class="input-group-append">
                <button class="btn BtnSearch" type="button" id="button-addon2">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
            <SideDrop />
          </div> */}
          <div className="col-md-12 ">
            <div className="container-fluid pb-4">
              {serviceList !== null && serviceList.length > 0 ? (
                <div className="row px-4">
                  {
                    //console.log('serviceList', serviceList)
                  }
                  <ServiceCard
                    data={serviceList}
                    title={""}
                    image={""}
                    userData={reactLocalStorage.getObject("userData")}
                  // sellerdetails={
                  //   item?.seller_details.length > 0
                  //     ? item.seller_details[0]
                  //     : ""
                  // }
                  />
                  {/* {serviceList.map((item, index) => {
                    // let arr = [];
                    // //console.log("img arr", item.image);
                    // item.image.length > 0 &&
                    //   item.image.forEach((element) => {
                    //     if (element.ismain) {
                    //       if (arr.length > 0) {
                    //         arr[0] = { preview: element.preview, ismain: true };

                    //         //console.log("Local Arr with true 1", arr);
                    //       } else {
                    //         arr.push({
                    //           preview: element.preview,
                    //           ismain: element.ismain,
                    //         });
                    //         //console.log("Local Arr with true 2", arr);
                    //       }
                    //     } else {
                    //       if (arr.length > 0) {
                    //         //console.log("Already a true value inside loop");
                    //       } else {
                    //         arr.push({
                    //           preview: item.image[0].preview,
                    //           ismain: false,
                    //         });
                    //         //console.log("Local Arr 3", arr);
                    //       }
                    //     }
                    //   });
                    return (
                      <div className="col-lg-3 col-md-6 mb-4" key={index}>
                        <ServiceCard
                          data={item}
                          title={categoryname}
                          image={item.image}
                          userData={reactLocalStorage.getObject("userData")}
                          sellerdetails={
                            item.seller_details.length > 0
                              ? item.seller_details[0]
                              : ""
                          }
                        />
                      </div>
                    );
                  })} */}
                </div>
              ) : (
                <div className="">
                  <div
                    className="text-center mb-4 category_Name pt-5 h2"
                    style={{ textAlign: "center" }}
                  >
                    No Service Found gfjh
                  </div>
                </div>
              )}
            </div>
            {/* <div className="py-4 text-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <i class="fas fa-angle-left"></i>
                      </span>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link " href="#">
                      01
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      02
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      03
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      04
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <i class="fas fa-angle-right"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}
            <section className="container">
              <div className="row">
                <div className="col-md-12 ">
                  <ServicePagination
                    productpaginator={productpaginator}
                    nextpage={nextpage}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
