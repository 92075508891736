import React, { useState } from "react";

const Faqs = (props) => {
  // //console.log("props faq", props.data);
  const faqAll = props.data;
  const [state, setstate] = useState({
    ans0: true,
    ans1: false,
    ans2: false,
    ans3: false,
  });

  const FaqBox = (id) => {
    let name = `ans${id}`;
    // //console.log(name, state[name]);
    setstate({ ...state, [name]: !state[name] });
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          {faqAll.length ? (
            faqAll.map((item, index) => {
              let name = `ans${index}`;
              return (
                <div className="col-md-12" key={index}>
                  <div className=" my-3 ans_text">
                    <p onClick={(e) => FaqBox(index)}>
                      {item.question}
                      {state[name] ? (
                        <i className="fas fa-minus mr-3  "></i>
                      ) : (
                        <i className="fas fa-plus mr-3  "></i>
                      )}
                    </p>

                    <div className={state[name] ? "d-block faqbg" : "d-none"}>
                      <h6>
                        {item.answer}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="">
              <div
                className="text-center mb-4 category_Name pt-5 h2"
                // style={{ textAlign: "center" }}
              >
                No FAQ
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
