import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Card, CardBody, Col, Input, Button, Spinner } from "reactstrap";
import HttpClient from '../../../utils/HttpClient';


const MeetingFormModal = ({ modalOpen = false, onClose, user_Id, room_id, setLinkModalShow,getPaymentDetails }) => {
    const [meetingData, setMeetingData] = useState({ date: '', time: '', price: '' });
    console.log('meetingData', meetingData)
    // console.log('userId',userId)

    const AddSchedule = async () => {
        if (
            meetingData.date &&
            meetingData.price &&
            meetingData.time
        ) {
            let sendData = {
                userId: user_Id,
                roomId: room_id,
                price: meetingData.price,
                meetType: 'Schedule',
                date: meetingData.date,
                time: meetingData.time
            }
            console.log('sendData', sendData)
            let result = await HttpClient.requestData(
                "createMeetingPayment",
                "POST",
                sendData
            );
            console.log('addResult', result)
            if (result && result.status) {
                setMeetingData({ date: '', time: '', price: '' })
                onClose();
                getPaymentDetails()
                toast.success('Meeting Added Successfully', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            // setLinkModalShow(true);

        }
        else {
            toast.warning('Please Fill Up All Details', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    return (
        <div>
            <div
                className={modalOpen ? "modal show fade" : "modal fade"}
                id="add-new-task-modal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="add-new-task-modalLabel"
                aria-hidden="true"
                style={{ display: modalOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
                    <div className="modal-content" style={{ width: "51%" }}>
                        <Card className="mb-0">
                            {/* chat head area */}
                            <CardBody className="border-bottom">
                                <div className="user-chat-border">
                                    <Row>
                                        <Col md={10} xs={10}>
                                            <h5 style={{ textAlign: "center" }}>
                                                Create Schedule Meeting
                                            </h5>
                                        </Col>
                                        <Col md={2}>
                                            <div className="d-flex  mr-4 chat-head">
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-hidden="true"
                                                    onClick={() => onClose()}
                                                >
                                                    ×
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                            {/* chat list body */}

                            {/* chat write area */}
                            <div className="p-3 chat-input-section border-top">
                                {/* <div className='link-box' style={{ display: "flex", justifyContent: "space-around" }}>

                                    {link}
                                    <i
                                        style={{ fontSize: "24px", cursor: "pointer" }}
                                        className="fa "
                                    // onClick={copyToClipBoard} 
                                    >
                                        &#xf0c5;
                                    </i>
                                </div> */}
                                <div class="form-group">
                                    {/* <label for="exampleInputEmail1">Date</label> */}
                                    <input
                                        type="date"
                                        // minDate={moment().toDate()}\
                                        min={moment().format('YYYY-MM-DD')}
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Date"
                                        value={meetingData.date}
                                        onChange={(e) => {
                                            setMeetingData((prev) => {
                                                return (
                                                    {
                                                        ...prev,
                                                        date: e.target.value
                                                    }
                                                )
                                            })
                                        }}
                                    />
                                </div>

                                <div class="form-group">
                                    {/* <label for="exampleInputEmail1">Time</label> */}
                                    <input
                                        type="time"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        // placeholder="Enter Time"
                                        value={meetingData.time}
                                        onChange={(e) => {
                                            setMeetingData((prev) => {
                                                return (
                                                    {
                                                        ...prev,
                                                        time: e.target.value
                                                    }
                                                )
                                            })
                                        }}
                                    />
                                </div>


                                <div class="form-group">
                                    {/* <label for="exampleInputEmail1">Price</label> */}
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Price"
                                        value={meetingData.price}
                                        onChange={(e) => {
                                            setMeetingData((prev) => {
                                                return (
                                                    {
                                                        ...prev,
                                                        price: e.target.value
                                                    }
                                                )
                                            })
                                        }}
                                    />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button
                                        style={{ backgroundColor: "#a024b7", color: '#fff' }}
                                        className='btn'
                                        onClick={AddSchedule}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </div>



                </div>


            </div>

        </div>
    )
}

export default MeetingFormModal