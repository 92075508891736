import moment from 'moment';
import React from 'react'
import { useHistory } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
import { reactLocalStorage } from 'reactjs-localstorage';
import HttpClient from '../../../utils/HttpClient';


const MeetingPopUp = ({ setShow, roomName, meetingPrice }) => {
    const userData = reactLocalStorage.getObject("userData");
    console.log('userData', userData)
    const history = useHistory();

    // console.log('roomNamepopup',roomName)
    console.log('userData', userData)

    const onToken = (token) => {
        stripeapicall(token.id);
        console.log('token', token)
    }

    const stripeapicall = async (token) => {
        const card = {
            amount: meetingPrice,
            currency: userData.currency,
            source: token,
            description: "MySpritual Service Payment",
        };
        let result = await fetch("https://api.stripe.com/v1/charges", {
            headers: {
                // Use the correct MIME type for your server
                Accept: "application/json",
                // Use the correct Content Type to send data to Stripe
                "Content-Type": "application/x-www-form-urlencoded",
                // Use the Stripe publishable key as Bearer
                Authorization: `Bearer sk_test_51JavJLSB5KAxkRypR3jypiPJk4I9nQ7L9uGMqDwQSPXCEhwFhYRMGVLpp5UvQTXFYJ6GQ4MqXfAW1cVFxbqEvpR900Bc3ynSKr`,
            },
            // Use a proper HTTP method
            method: "post",
            // Format the credit card data to a string of key-value pairs
            // divided by &
            body: Object.keys(card)
                .map((key) => key + "=" + card[key])
                .join("&"),
        }).then((response) => response.json());

        console.log('stripeResult', result);

        if (result) {
            // console.log('success')
            createPayment();
        }
    }

    console.log('date', moment().format('hh:mm'))

    const createPayment = async () => {
        // console.log('createPayment')
        let sendData = {
            roomId: roomName,
            userId: userData._id
        }
        console.log('sendData', sendData)
        let result = await HttpClient.requestData(
            "meetingPayments",
            "POST",
            sendData
        );
        console.log('payResult',result)
        if(result && result.status){
            history.push('/meeting',{roomId:roomName})
        }
    }


    return (
        <>
            <div className="Rating">
                <div className="modal-dialog">
                    <div className="modal-content pt-5 px-5 pb-4">
                        <div className="">
                            <span></span>
                            <button
                                type="button"
                                className="close Single_close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setShow(false)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="mb-3" style={{ textAlign: "center" }}>
                            <h3><b>Stripe Payment</b></h3>
                        </div>
                        <div className="mb-4" style={{ display: "grid" }}>
                            <StripeCheckout
                                token={onToken}
                                stripeKey="pk_test_51JavJLSB5KAxkRypwpyFZVGF7Mxwel9iBFVqCbIaqDQERfj9A6NoeJEaBBZnnGRgSCasukWcLtWICm7SI0CtSDAl00bKUxkGVB"
                                currency={userData.currency}
                                amount={meetingPrice * 100}
                                description="Pay & Buy"
                                shippingAddress={true}
                                name="payment for meeting"
                            />
                            {/* <h6 className=" mb-2" style={{ fontSize: "18px" }}></h6> */}
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default MeetingPopUp;