import React, { useState, useEffect } from "react";
// import shop1 from "../../Assets/Images/service_list 1.png";

// import ReviewList from "../../Views/ServiceDetails/ReviewList";
// import EditSellerProfile from "./EditSellerProfile";
import HttpClient from "../../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import BreadCrump from "../../../Component/BreadCrump";
import { RotatingLines } from "react-loader-spinner";

const MyWallet = () => {
  const [edit, setEdit] = useState(false);
  const [user, setuser] = useState({});
  const [isLoading, setisLoading] = useState(false);

  const [totalEarning, settotalEarning] = useState(0);

  const [totalSettle, settotalSettle] = useState(0);
  const [pending, setpending] = useState(0);
  const [wallet, setwallet] = useState(0);
  const [refund, setrefund] = useState(0);
  const userdata = reactLocalStorage.getObject("userData");
  useEffect(() => {
    fetchcount();
  }, []);

  const fetchcount = async () => {
    setisLoading(true)
    let data = {
      currency: userdata.currency,
    };
    //console.log(data);
    let result = await HttpClient.requestData(
      "wallet/" + userdata._id,
      "POST",
      data
    );
    //console.log("Count", result);
    //console.log("userdata._id==", userdata._id);
    // if (result && result.status) {
    settotalEarning(result.total_earnings);
    settotalSettle(result.earning_settled);
    settotalSettle(result.earning_settled);
    setpending(result.pending_settlement);
    setwallet(result.claimable_earnings);
    setrefund(result.service_refund_amt);
    setisLoading(false)
    // setuser(result.data);

    //console.log("setuser", result.total_earnings);
    // } else {
    // }
  };

  // const Item = [
  //     {
  //         img: shop1,
  //         name: "Lorem Ipsum",
  //         para: "Description",
  //         status: "₹500",
  //     },
  //     {
  //         img: shop1,
  //         name: "Lorem Ipsum",
  //         para: "Description",
  //         status: "₹500",
  //     },
  //     {
  //         img: shop1,
  //         name: "Lorem Ipsum",
  //         para: "Description",
  //         status: "₹500",
  //     },
  //     {
  //         img: shop1,
  //         name: "Lorem Ipsum",
  //         para: "Description",
  //         status: "₹500",
  //     },
  // ];
  const loop = [1, 2, 3];
  return (
    <div className="page-content">
      <BreadCrump main="My Wallet" />
      <div className="Seller_Profile">
        {edit ? (
          <div>
            <div className="row my-2">
              <div className="col-md-12">
                <div onClick={() => setEdit(false)}>
                  <a href="#" className="text-dark">
                    <i className="fas fa-chevron-left mr-2"></i>
                    Back
                  </a>
                </div>
              </div>
            </div>
            {/* <EditSellerProfile /> */}
          </div>
        ) : (
          <div>
            <div className="row my-3">
              <div className="col-md-12 sell_profile_head p-3 mx-auto">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    {/* <img src={Avatar} alt="img" /> */}
                    <div className="px-3 py-2 ">
                      {/* <h5>{user.firstName + " " + user.lastName}</h5>

                                            <p>{user.email}</p> */}
                      <h5> MY Wallet</h5>
                    </div>
                  </div>
                  {/* <div className="" onClick={() => setEdit(true)}>
                                        <button className="btn my-3" type="button">
                                            <i className="fas fa-pencil-alt text-white"></i>
                                        </button>
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="row">
                  <div className="col-md-3 my-2">
                    <div className="balance_card">
                      <div className="card-body text-center">
                        <h6>Total Earning</h6>
                        <span>
                          {userdata.currency == "INR"
                            ? "₹"
                            : userdata.currency == "CAD"
                              ? "$"
                              : userdata.currency == "USD"
                                ? "$"
                                : userdata.currency == "	GBP"
                                  ? "£"
                                  : "€"}{" "}
                          {totalEarning.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 my-2">
                    <div className="balance_card">
                      <div className="card-body text-center">
                        <h6>Earning Settled </h6>
                        <span>
                          {" "}
                          {userdata.currency == "INR"
                            ? "₹"
                            : userdata.currency == "CAD"
                              ? "$"
                              : userdata.currency == "USD"
                                ? "$"
                                : userdata.currency == "	GBP"
                                  ? "£"
                                  : "€"}{" "}
                          {totalSettle.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 my-2">
                    <div className="balance_card">
                      <div className="card-body text-center">
                        <h6>Pending </h6>
                        <span>
                          {" "}
                          {userdata.currency == "INR"
                            ? "₹"
                            : userdata.currency == "CAD"
                              ? "$"
                              : userdata.currency == "USD"
                                ? "$"
                                : userdata.currency == "	GBP"
                                  ? "£"
                                  : "€"}{" "}
                          {pending.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 my-2">
                    <div className="balance_card">
                      <div className="card-body text-center">
                        <h6>Claim Amount</h6>
                        <span>
                          {" "}
                          {userdata.currency == "INR"
                            ? "₹"
                            : userdata.currency == "CAD"
                              ? "$"
                              : userdata.currency == "USD"
                                ? "$"
                                : userdata.currency == "	GBP"
                                  ? "£"
                                  : "€"}{" "}
                          {wallet.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 my-2">
                    <div className="balance_card">
                      <div className="card-body text-center">
                        <h6>Refund Amount</h6>
                        <span>
                          {" "}
                          {userdata.currency == "INR"
                            ? "₹"
                            : userdata.currency == "CAD"
                              ? "$"
                              : userdata.currency == "USD"
                                ? "$"
                                : userdata.currency == "	GBP"
                                  ? "£"
                                  : "€"}{" "}
                          {refund}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row my-4">
            <div className="col-md-8 offset-md-2">
              <Activity data={Item} />
            </div>
          </div> */}
            {/* <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="d-flex justify-content-between">
                <h4>User Review</h4>
                <div className="">
                  <Link to="/..">View More</Link>
                </div>
              </div>
              <ReviewList data={loop} />
            </div>
          </div> */}
          </div>
        )}
      </div>

      {isLoading ? <div className="Loader-Show" style={{ position: "absolute", bottom: 323, left: 380, }}><RotatingLines
        visible={true}
        height="96"
        width="120"
        color="red"
        strokeColor="violet"
        strokeWidth="5"
        animationDuration="1"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      /> </div> : null}
    </div>
  );
};
export default MyWallet;
