// import React from 'react'

// function DynamicSidebarButton() {
//   return (
//     <div>Dynamic sidebarButton</div>
//   )
// }

// export default DynamicSidebarButton

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function DynamicSidebarButton({ to, children }) {
  const location = useLocation();

  // Check if the current location matches the target route
  const isActive = location.pathname === to;

  // Define the className based on isActive
  const className = `btn left_menu text-left ${isActive ? 'active' : ''}`;

  return (
    <div className={className}>
      <Link to={to}>
        <i className="fas fa-chevron-right mr-2" /> {children}
      </Link>
    </div>
  );
}

export default DynamicSidebarButton

// // Usage example:
// <DynamicSidebarButton to="/seller/pending-booking">Manage Pending Booking</DynamicSidebarButton>
