import React, { useState, useEffect } from "react";
import HttpClient from "./../../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import { Row, Card, CardBody, Col, Input, Button } from "reactstrap";

const OrderDelete = (props) => {
  //console.log("order Details", props.servdetails);
  const [modalStatus, setmodalStatus] = useState(
    props.status ? props.status : false
  );

  useEffect(() => {}, []);

  const Cancelorder = async () => {
    let result = await HttpClient.requestData(
      "myaccount/cancel-order/" + props.servdetails._id,
      "PUT"
    );
    //console.log("order cancelled", result);
    if (result && result.status) {
      toast.success("Order Cancelled Successfully", {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.onCloseCallBack2(props.index);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <div
      className={modalStatus ? "modal show fade _modal_open" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: modalStatus ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
        <div className="modal-content">
          <Card className="mb-0">
            <CardBody className="border-bottom chat_head py-2">
              <div className="user-chat-border">
                <Row>
                  <Col md={9} xs={9}>
                    <div className="d-flex">
                      <h5 className="font-size-15 my-auto mr-auto">
                        Cancel Order
                      </h5>
                    </div>
                  </Col>
                  <Col md={3}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={() => props.onCloseCallBack2(false)}
                    >
                      ×
                    </button>
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardBody>
              <div className="d-flex justify-content-around mt-4">
                <Button
                  color="danger"
                  className="btn dltmodal_btn"
                  onClick={Cancelorder}
                >
                  Confirm
                </Button>
                <Button
                  color="danger"
                  className="btn dltmodal_btn"
                  onClick={() => props.onCloseCallBack2(false)}
                >
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default OrderDelete;
