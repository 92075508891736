import React, { useEffect, useState }  from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import moment from "moment";
import HttpClient from "./../../../utils/HttpClient";
import blankImg from "../../../assets/blank.jpg";

function Blog(props) {
  // //console.log(props.blog_id);

  useEffect(() => {
    window.scrollTo(0, 0);
   
  }, []);
 
  return (
    <div className={props.class ? props.class : ""}>
      <div className="card mb-0">
        <div className="Cardtop">
          <img
            src={
              props.imgs
                ? HttpClient.IMG_URL + props.imgs
                : blankImg
            }
            className="card-img-top"
            alt="..."
          />
          <span className="Date">
            {moment(props.date).format("DD MMMM ,YYYY")}
          </span>
        </div>
        <div className="card-body" style={{paddingTop: 0}}>
          <p>
            {/* By - Person Name{" "} */}
            {props.blogtype === "single" ? (
              <button 
              onClick={() => {props.handleChangeTab(props.blog_id)}}
              style={{border: "none", background: "none"}}
              >
              See Comments
            </button>
            ) : null}
            
          </p>
          <h5 className="card-title mb-3">{props.title}</h5>
          {/* {props.type === "true" ? ( */}
          <div>
            <p className="card-text" dangerouslySetInnerHTML={{__html: props.content}}>
              {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
                suscipit deleniti perferendis eveniet, voluptate consectetur
                repellendus eos ea soluta quae tempore possimus ut ullam esse
                neque autem hic porro minima officia delectus a est. Soluta
                quibusdam quam exercitationem amet vero? */}
              {/* {props.content} */}
            </p>
            {props.blogtype === "multi" ? (
              <div style={{width: "100%", textAlign: "center", margin: "20px 0 0"}}>
              <Link
                //to="/blog-singlePage"

                to={{
                  pathname: "/blog-singlePage" + props.title,
                }}
                className="ReadMore"
              >
                View 
                {/* <Icon.ArrowRight /> */}
              </Link>
              </div>
              
            ) : null}
          </div>
          {/* ) : ( */}
          {/* <div className="card-text">
              <p className="mb-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
                suscipit deleniti perferendis eveniet, voluptate consectetur
                repellendus eos ea soluta quae tempore possimus ut ullam esse
                neque autem hic porro minima officia delectus a est. Soluta
                quibusdam quam exercitationem amet vero. Lorem ipsum dolor sit
                amet consectetur adipisicing elit. Aliquid non dolore rem!
                Labore, repellendus veritatis inventore perferendis a
                necessitatibus ullam. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Tempore ea officia deleniti doloremque ipsum.
                Odio aspernatur harum, nesciunt saepe, amet veritatis tempore
                eos iste consectetur cum perspiciatis, quidem nulla illum.
              </p>
              <p className="mb-4">
                Odio aspernatur harum, nesciunt saepe, amet veritatis tempore
                eos iste consectetur cum perspiciatis, quidem nulla illum. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Placeat
                impedit, quas ut nulla nobis vero nemo, ab veniam eveniet, et
                voluptatem veritatis quidem libero adipisci? Possimus iste
                veniam porro dolorum.
              </p>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default Blog;
