import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";
import { useParams } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import Jumbotron from "../../Component/Jumbotron";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Dashboard from "../../Default/Assets/Images/blank-profile-picture-973460_640.png";

export default function ProviderDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProviderDetail();
  }, []);

  let { id } = useParams();
  const [providerDetail, setproviderDetail] = useState([]);
  const [serviceItem, setserviceItem] = useState("");

  const fetchProviderDetail = async (userid) => {
    let result = await HttpClient.requestData("top-sellers", "GET");
    //console.log("top-sellers details", result);
    if (result && result.status) {
      //console.log(result.data);
      let filterProvider = result.data.filter((item) => item._id === id);
      //console.log(filterProvider);
      setproviderDetail(filterProvider);
    } else {
    }
  };

  const handleChangeService = (event) => {
    setserviceItem(event.target.value)
    let id = event.target.value;
    window.location.href = "/service-details/" + id;
  }

  return (
    <div>
      <section>
        <div>
          <div className="Provider_banner">
            {/* <img
              src="https://astrophy.com/static/media/provider_banner.4a4c77a9.png"
              alt="img"
              style={{ height: "400px", width: "100%" }}
            /> */}
            <ReactTitle title="MySpiritualConnent - Service Provider Details" />
            <section>
              <Jumbotron heading="Top Providers Details" img={serviceJumboBg} />
            </section>
          </div>
          {providerDetail.length
            ? providerDetail.map((item, index) => {
              return (
                <div
                  className="container provider_banner_card"
                  style={{ maxWidth: "1200px" }}
                  key={index}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-md-flex">
                            <div className="col-md-4 col-12">
                              <div className="provider_card_img">
                                {item.profile != "" &&
                                  item.hasOwnProperty("profile") ? (
                                  <img
                                    src={
                                      HttpClient.IMG_URL +
                                      item.profile
                                    }
                                    className="img-fluid"
                                    alt="img"
                                  />
                                ) : (
                                  <img
                                    src={Dashboard}
                                    className="img-fluid"
                                    alt="img"
                                  />
                                )}
                                {/* <img
                                  src="https://admin.astrophy.com/uploads/shop_services/shop_Screenshot_20220411-092104_Amazon.jpg"
                                  alt="img"
                                /> */}
                              </div>
                            </div>
                            <div className="col-md-8 col-12">
                              <div className="provider_card_head px-3">
                                <div className="d-flex flex-wrap mb-2 ">
                                  {/* <p className="my-2">
                                    <i className="fas fa-star icon  mr-2" />
                                    /5
                                  </p> */}
                                </div>
                                <h4>{item.firstName + " " + item.lastName}</h4>
                                {/* <span>Monty Jadhav</span> */}
                                <p>
                                  {/* <i className="fas fa-map-marker-alt location mr-2" /> */}
                                  {item.email}
                                </p>
                                <p>{item.mobile}</p>
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    id="exampleFormControlSelect1"
                                    value={serviceItem}
                                    onChange={handleChangeService}
                                  >
                                    <option>Provided Services</option>
                                    {item.service_data.length ? (
                                      item.service_data.map((item2, index2) => {
                                        return (
                                          <option key={index2} value={item2._id}>
                                            {item2.name}
                                          </option>
                                        );
                                      })
                                    ) : (
                                      <option>No Service Found  </option>
                                    )}
                                  </select>
                                </div>
                                <div className="provider_card_text">
                                  <h5>About</h5>
                                  <p>{item.about}</p>
                                </div>
                              </div>

                              <div className="provider_card_right ml-auto">
                                {/* <p className="text-right">
                                  Last updated on April 11th 2022
                                </p> */}
                                {/* <div className="d-flex flex-wrap details_sub_text">
                                  <div className="d-flex border-left px-2">
                                    <img
                                      src="/static/media/user.43ebe323.jpg"
                                      alt="img"
                                      style={{
                                        width: "100%",
                                        height: "27px",
                                        maxWidth: "27px",
                                      }}
                                    />
                                    <div className=" mx-2">
                                      <h5>Monty Jadhav</h5>
                                      <p>Ownerof Monty </p>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>

                          </div>
                          {/* <div className="provider_card_text">
                              <h5>About</h5>
                              <p>{item.about}</p>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            : null}
        </div>
      </section>
    </div>
  );
}
