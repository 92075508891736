import React from "react";
import {ReactTitle} from 'react-meta-tags';

import logo from "../../Default/Assets/Images/logo.png";
import ErrorImg from "../../Default/Assets/Images/Error/ErrorImg.png";

function Index() {
  return (
    <div>
      <ReactTitle title="MySpiritualConnent - Error Page"/>
      <section className="ErrorJumbo">
        <div className="px-5 pt-4">
          <img src={logo} className="img-fluid ml-5" alt="img" />
        </div>
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-8">
              <img src={ErrorImg} className="img-fluid" alt="img" />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <h1>404 ERROR</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="text-center centerText">
        <h2 className="mb-3">OOPS..!</h2>
        <h4 className="mb-5">WE CANNOT FIND THE PAGE YOU ARE LOOKING FOR</h4>
        <div className="pb-5">
          <button className="btn PrimaryBtn">GO TO HOME PAGE</button>
        </div>
      </section>
      <section className="ErrorFooter ">
        <div className="container ">
          <div className="row ">
            <div className="col-md-6 d-flex align-items-end">
              <div className="d-flex justify-content-center ml-5">
                <div className="socialBox">
                  <i class="fab fa-facebook-f"></i>
                </div>
                <div className="socialBox">
                  <i class="fab fa-twitter"></i>
                </div>
                <div className="socialBox">
                  <i class="fab fa-youtube"></i>
                </div>
                <div className="socialBox">
                  <i class="fab fa-linkedin-in"></i>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-right">
                <img src={logo} className="img-fluid" alt="img" style={{width:"50%"}} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Index;
