import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import blankImg from "../assets/blank.jpg";
import HttpClient from "../utils/HttpClient";

const ServiceCard = (props) => {
  //console.log("popularServices", props.serviceData);

  return (
    <div className="ServiceCard">
      <Link to="/service-details">
        <div className="card" style={{ maxWidth: "20rem" }}>
          <img
            src={
              props.data.image
                ? HttpClient.IMG_URL + props.data.image
                : blankImg
            }
            className="card-img-top"
            alt="img"
            style={{ height: "210px" }}
          />
          <div className="card-body">
            {/* <h5 className="card-title">{props.img.name}</h5>
          <h6 className="card-title">{props.data.details}</h6> */}

            <p className="font-weight-bold mb-2">
              <i class="fas fa-star star mr-2"></i>
              4.5/5
            </p>
            <p className="font-weight-bold mb-2">
              Category Name: {props.data ? props.data.category_name : ""}
            </p>
            <p className="font-weight-bold mb-2 _serv_title">
              Shop Service Name : {props.data ? props.data.name : ""}
            </p>
            <p className="mb-0 long_desc">
              Details:{props.data ? props.data.details : ""}
            </p>
            {/* <p className="Schedule">
              <span>From :</span> 05:00pm <span>To:</span> 08:00pm
            </p> */}
            <h4>${props.data ? props.data.price : ""}</h4>
            {props.userData && props.userData._id ? (
              <Link
                to={"/service-details/" + props.data._id}
                className="btn  PrimaryBtn"
              >
                Schedule
              </Link>
            ) : (
              <Link
                className="btn  PrimaryBtn"
                onClick={() => {
                  toast.warning("Login First to Schedule", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }}
              >
                Schedule
              </Link>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ServiceCard;
