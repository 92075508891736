import React, { useState, useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "./../../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";

const DeleteAccount = async () => {
  let getAccount = reactLocalStorage.getObject("userData");
  //console.log("grt", getAccount);
  let data = {};
  let result = await HttpClient.requestData(
    "myaccount/delete-profile/" + getAccount._id,
    "DELETE",
    data
  );

  if (result && result.status) {

    toast.success("Account Deleted Successfully", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setTimeout(() => {
      logout();
    }, 3000);    
  }
  else {
  }
}

const logout = async () => {
  reactLocalStorage.clear();


  setTimeout(function () {
    window.location.href = "/home";
  }, 1500);
};
const ProfDelete = (props) => {
  return (
    <div className="ProfDelete">
      <ToastContainer />
      <div className="modal-dialog">
        <div className="modal-content p-5">
          <div className="">
            <span></span>
            <button
              type="button"
              className="close Single_close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.CallBackProfileDel(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <h5 className="mb-5 pb-3  text-center font-weight-bold">
            Are You Sure to Delete Your Account ?
          </h5>
          <div className=" pt-3 text-center mb-3">
            <button className="btn w-50 PrimaryBtn"
              onClick={() => {
                DeleteAccount()
              }}
            >Yes, I Confirm</button>
          </div>
          <div className=" text-center">
            <button className="btn w-50 PrimaryBtn-outline" onClick={() => props.CallBackProfileDel(false)}>No</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfDelete;
