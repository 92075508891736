import React, {useState} from 'react'
import { MDBDataTableV5 } from "mdbreact";
function Withdrawbalance() {

  const [dataa, setdata] = useState([]);
  const data = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },
      {
        label: "Order Id",
        field: "order_id",
        width: 270,
      },
      {
        label: "Service Name",
        field: "service_name",
        width: 270,
      },
      {
        label: "Image",
        field: "image",
        width: 270,
      },
      // {
      //   label: "Transaction Id",
      //   field: "txnid",
      //   width: 270,
      // },
    ],
    rows: dataa,
  };


  return (
    <div className='withdraw'>
      <div className='row mt-4'>
        <div className="col-md-12 text-center">
          <h1>Claimable Balance List</h1>
        </div>

        <div className='col-md-10 mr-auto'>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={data}
          />
        </div>
      </div>

      <div className='row mt-5'>
        <div className='col-md-12 text-center'>
          <h1>Withdraw History</h1>
        </div>

        <div className='col-md-10 mr-auto'>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={data}
          />
        </div>
      </div>

    </div>
  )
}

export default Withdrawbalance