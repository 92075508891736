import React from 'react'
import { Row, Card, CardBody, Col, Input, Button, Spinner } from "reactstrap";


function MeetingModal({ link, modalOpen = false, onClose }) {

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(link)
    alert("Copied to clipboard")
  }
  return (
    <div
      className={modalOpen ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: modalOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
        <div className="modal-content" style={{ width: "51%" }}>
          <Card className="mb-0">
            {/* chat head area */}
            <CardBody className="border-bottom">
              <div className="user-chat-border">
                <Row>
                  <Col md={9} xs={9}>
                    <h5 style={{ textAlign: "center" }}>Here's the link to your meeting</h5>
                  </Col>
                  <Col md={3}>
                    <div className="d-flex  mr-4 chat-head">


                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={() => onClose()}
                      >
                        ×
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
            {/* chat list body */}

            {/* chat write area */}
            <div className="p-3 chat-input-section border-top">
              Copy this link and send it to people you want to meet with. Be sure to save it so you can use it later, too.
              <div className='link-box' style={{ display: "flex", justifyContent: "space-around" }}>

                {link}
                <i style={{ fontSize: "24px", cursor: "pointer" }} className="fa " onClick={copyToClipBoard} >&#xf0c5;</i>
              </div>
            </div>
          </Card>
        </div>



      </div>


    </div>)
}

export default MeetingModal