import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailsPro_img from "../../../Default/Assets/Images/Service/detailspro_pic.png";
import Slider from "react-slick";
import { reactLocalStorage } from "reactjs-localstorage";

import moment from "moment";
import HttpClient from "./../../../utils/HttpClient";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactStars from "react-rating-stars-component";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { ToastContainer, toast } from "react-toastify";
import Slot from "./Slot";
import Modal from "./modal";
import { Link } from "react-router-dom";
import Firebase from "../../Chat/firebase";
import AudioModal from "../../Chat/Component/AudioModal";
import VideoModal from "../../Chat/Component/VideoModal";
import Loader from "../../../Component/Loader";
// import playbtn from "../../../../src/assets/btn.png"
import $ from "jquery";

import LoadingBar from "react-top-loading-bar";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Details = (props) => {
  //console.log("yyy", props.oneData);
  // //console.log("imAGES", props.images);
  //console.log("video----------", props.video);
  // //console.log("highlight-----",props.hightligts);
  const { id } = useParams();
  const userData = reactLocalStorage.getObject("userData");
  const [counter, setCounter] = useState(1);
  const [bookModal, setBookModal] = useState(false);
  const [slotData, setslotData] = useState([]);
  const [modal, setModal] = useState(false);
  const [callmodal, setCallmodal] = useState("");
  const [chatRoomId, setChatRoomId] = useState(null);
  const [selectimage, setselectimage] = useState("");
  const [imageType, setimageType] = useState("");
  const [date, setdate] = useState("");
  const [calendarStart, setcalendarStart] = useState("");
  const [calendarEnd, setcalendarEnd] = useState("");
  const [eventData, seteventData] = useState([]);
  const [nextMonth, setnextMonth] = useState(0);
  const [refress, setrefress] = useState(false);
  const [checkMonth, setCheckMonth] = useState("");
  const [progress, setProgress] = useState(0);

  const highlightData = props.hightligts;
  console.log('modal', modal)
  const [useridalreadycreated, setuseridalreadycreated] = useState(false);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // //console.log("hii", props.sellerdetails);
    // //console.log("onedata", props.oneData);
    hideToday();
    if (Object.keys(userData).length) {
      currentDateofMonth();
    }

    // handleMonthChange();
  }, []);

  const openCalling = (val, roomId) => {
    setChatRoomId(roomId);
    setCallmodal(val);
  };

  const currentDateofMonth = async () => {
    // 2022-05-01
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    //console.log("current month", m);
    setnextMonth(m);
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    let modifyFirstDate = moment(tomorrow).format("YYYY-MM-DD");
    let modifyLastDate = moment(lastDay).format("YYYY-MM-DD");
    let data = {
      shop_service_id: id,
      user_id: reactLocalStorage.getObject("userData")._id,
      datefrom: modifyFirstDate,
      dateto: modifyLastDate,
    };
    //console.log("date-=====", data);
    setrefress(true);
    let result = await HttpClient.requestData(
      "shop-service/viewSlotsAllDay",
      "POST",
      data
    );
    //console.log("day check-------", result.data);
    if (result && result.status) {
      seteventData(result.data);
      setrefress(false);
    }
  };

  reactLocalStorage.setObject("click", 1);

  const clicked = async (e) => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let mm = 0;

    // //console.log("calender click-----",e.target.className);
    if (
      e.target.className == "fc-icon fc-icon-chevron-right" ||
      e.target.className == "fc-next-button fc-button fc-button-primary"
    ) {
      // //console.log("clicked cal");

      //console.log(m);
      //   for (var i = m; i <= m+2; i++) {
      //     m= i
      // }
      let clicked = reactLocalStorage.getObject("click");
      m = Number(m) + Number(clicked);
      let mm = m;
      //console.log("current month", m);

      // var firstDay = new Date(y, m, 1);
      let modifyFirstDate = y + "-" + (Number(m) + Number(1)) + "-" + "01";
      let lastdat = new Date(y, m + 1, 0);
      let modifyLastDate = moment(lastdat).format("YYYY-MM-DD");
      //console.log("modifyFirstDate", modifyFirstDate);
      //console.log("modifyLastDate", modifyLastDate);
      let data = {
        shop_service_id: id,
        user_id: reactLocalStorage.getObject("userData")._id,
        datefrom: modifyFirstDate,
        dateto: modifyLastDate,
      };
      //console.log("date-=====", data);
      setrefress(true);
      let result = await HttpClient.requestData(
        "shop-service/viewSlotsAllDay",
        "POST",
        data
      );
      //console.log("day check-------", result.data);
      if (result && result.status) {
        seteventData(result.data);
        setrefress(false);
      }
      clicked++;
      reactLocalStorage.setObject("click", clicked);
      reactLocalStorage.setObject("month", mm);
      //console.log("afterclick", reactLocalStorage.getObject("click"));
      // //console.log("nest month",m);
    } else if (
      e.target.className == "fc-icon fc-icon-chevron-left" ||
      e.target.className == "fc-prev-button fc-button fc-button-primary"
    ) {
      // reactLocalStorage.setObject("click", clicked--);

      let clicked = reactLocalStorage.getObject("click");
      var date = new Date(),
        month = date.getMonth();
      let mon = reactLocalStorage.getObject("month");
      //console.log("clicked value", clicked);
      //console.log("mm", mon);
      m = Number(mon) - Number(1);
      reactLocalStorage.setObject("month", m);
      //console.log("current month", m);
      //console.log("current month---", month);
      const today = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      let modifyCurrentDate = moment(tomorrow).format("YYYY-MM-DD");
      var firstDay = new Date(y, m, 1);
      let modifyFirstDate = y + "-" + (Number(m) + Number(1)) + "-" + "01";
      let lastdat = new Date(y, m + 1, 0);
      let modifyLastDate = moment(lastdat).format("YYYY-MM-DD");
      //console.log("modifyFirstDate", modifyFirstDate);
      //console.log("modifyLastDate", modifyLastDate);
      if (month == m) {
        let data = {
          shop_service_id: id,
          user_id: reactLocalStorage.getObject("userData")._id,
          datefrom: modifyCurrentDate,
          dateto: modifyLastDate,
        };
        //console.log("date-=====", data);
        setrefress(true);
        let result = await HttpClient.requestData(
          "shop-service/viewSlotsAllDay",
          "POST",
          data
        );
        //console.log("day check-------", result.data);
        if (result && result.status) {
          seteventData(result.data);
          setrefress(false);
        }
        clicked--;
        reactLocalStorage.setObject("click", clicked);
      } else {
        let data = {
          shop_service_id: id,
          user_id: reactLocalStorage.getObject("userData")._id,
          datefrom: modifyFirstDate,
          dateto: modifyLastDate,
        };
        //console.log("date-=====", data);
        setrefress(true);
        let result = await HttpClient.requestData(
          "shop-service/viewSlotsAllDay",
          "POST",
          data
        );
        //console.log("day check-------", result.data);
        if (result && result.status) {
          seteventData(result.data);
          setrefress(false);
        }
        clicked--;
        reactLocalStorage.setObject("click", clicked);
      }
    }
  };

  if (refress === true) {
    document.body.style.opacity = "0.3";
  } else {
    document.body.style.opacity = "1";
  }
  const handleDateClick = async (arg) => {
    //console.log("date", arg.dateStr);
    // setdate(arg.dateStr);
    // let cur_date = moment();
    // // //console.log("arg", arg)
    // let date = arg.dateStr;

    // let date2 = moment(cur_date).format("YYYY-MM-DD");
    // // //console.log("date", date1);
    // //console.log("date2", date2);
    // if (moment(date).isBefore(date2, "day")) {
    setdate(arg.dateStr);
    let cur_date = moment();
    // //console.log("arg", arg)
    let date = arg.dateStr;

    let date2 = moment(cur_date).format("YYYY-MM-DD");
    //console.log("date", date2);
    //console.log("date2", date2);
    if (
      moment(date).isBefore(date2, "day") ||
      moment(date).isSame(date2, "day")
    ) {
      toast.warning("you cannot access previous date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let weekDayName = moment(date).format("dddd");

      let data = {
        weekday_name: weekDayName,
        shop_service_id: props.oneData._id,
        date: date,
        user_id: reactLocalStorage.getObject("userData")._id,
      };

      //console.log("date----------", data);

      let result = await HttpClient.requestData(
        "shop-service/day-timing",
        "POST",
        data
      );

      if (result && result.status) {
        // //console.log("result", result.data);
        setslotData(result.data);
        // //console.log("ggg", slotData);
        if (result.data.length > 0) {
          setBookModal(true);
        } else {
          setBookModal(false);
          toast.warning("No slot available on this day", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };
  const CallBackSlot = (val) => {
    setBookModal(val);
  };
  const Callbackmsg = async (val) => {
    if (val == "close") {
      setModal(false);
    }
  };
  const callBackcall = (val) => {
    setCallmodal(val);
  };

  const currentData = (currentDate) => {
    //console.log("currentDate", currentDate);
    var startDate = new Date(currentDate.valueOf());
    startDate.setDate(startDate.getDate() + 1);
    return { start: startDate };
  };
  const loop = [1, 2, 3, 4, 5];
  //   const loop1 = [1, 2, 3, 4, 5];
  useEffect(() => {
    CheckingMonth();
  }, [checkMonth]);

  const hideToday = () => {
    $(".fc-today-button").addClass("hideButton");
  };

  const CheckingMonth = () => {
    let todayMonth = new Date().getMonth();
    //console.log("useEffcet", todayMonth);
    if (todayMonth == checkMonth) {
      // alert("true")
      $(".fc-prev-button").addClass("hideButton");
    } else {
      $(".fc-prev-button").removeClass("hideButton");
    }
  };
  return (
    <div className="row Details">
      <div className="col-md-1 pt-3 pb-3">
        {/* <Slider {...settings}> */}
        {props.images.length > 0 &&
          props.images.map((item2, index2) => {
            //console.log("item----", item2);
            return (
              <div
                className=" mb-5 mt-3 "
                style={{
                  position: "relative",
                  border: "1px solid #dee2e6",
                  padding: "7px",
                  width: "90px",
                  height: "90px",
                }}
                key={index2}
                onClick={() => {
                  //console.log("prev", item2.type);
                  setselectimage(item2.preview);
                  setimageType(item2.type);
                }}
              >
                {item2.type == "image" ? (
                  <img
                    className=""
                    src={HttpClient.IMG_URL + item2.preview}
                    alt="img1"
                    style={{
                      width: "100%",
                      height: "100%",

                      // height: "110px",
                    }}
                  />
                ) : item2.type == "video" ? (
                  <>
                    {/* <div className="ban_play"></div> */}
                    <video
                      // controls
                      // preload
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                      className="ban_Img"
                      onClick={() => {
                        //console.log("prev", item2.type);
                        setselectimage(item2.preview);
                        setimageType(item2.type);
                      }}
                    >
                      <source
                        //src={"https://api.myspiritualconnect.com/uploads/shop_services/video_sample-mp4-file-small.mp4"}
                        src={HttpClient.IMG_URL + item2.preview}
                        type="video/mp4"
                      />
                    </video>
                    <div
                      className="vid_play"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#a024b7",
                        width: "25px",
                        height: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <i
                        className="fa fa-play"
                        aria-hidden="true"
                        style={{ color: "#fff", fontSize: "12px" }}
                      ></i>
                    </div>
                  </>
                ) : // <img src={playbtn} className="img-fluid"/>
                  null}
              </div>
            );
          })}
        {/* </Slider> */}
      </div>
      <div className="col-md-5 pt-3 pb-3">
        {props.images1.length > 0 &&
          props.images1.map((item1, index1) => {
            // //console.log("itemimage", item1);
            return (
              <div key={index1}>
                {selectimage != "" && imageType == "image" ? (
                  <img
                    src={HttpClient.IMG_URL + selectimage}
                    alt="img"
                    className="detail_pic img-fluid w-100"
                  />
                ) : selectimage != "" && imageType == "video" ? (
                  <video
                    controls
                    style={{ width: "100%", maxWidth: "800px" }}
                    className="ban_Img my-4 test1"
                  >
                    <source
                      //src={"https://api.myspiritualconnect.com/uploads/shop_services/video_sample-mp4-file-small.mp4"}
                      src={HttpClient.IMG_URL + selectimage}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    src={HttpClient.IMG_URL + item1.preview}
                    alt="img"
                    className="detail_pic img-fluid w-100"
                  />
                )}
              </div>
            );
          })}

        {/* {props.video != "" ? (
          <video
            controls
            style={{ width: "100%", maxWidth: "800px" }}
            className="ban_Img my-4"
          >
            <source
              //src={"https://api.myspiritualconnect.com/uploads/shop_services/video_sample-mp4-file-small.mp4"}
              src={HttpClient.IMG_URL + props.video}
              type="video/mp4"
            />
          </video>
        ) : null} */}
      </div>
      <div className="col-md-6 px-4 pt-3 pb-3 details_text">
        <div className="heading">
          <div>
            <h4 className="my-3">{props.oneData.name}</h4>
            <h5 className="my-3">
              {/* $ */}
              {userData.currency == "INR"
                ? "₹"
                : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                    ? "$"
                    : userData.currency == "GBP"
                      ? "£"
                      : userData.currency == "EUR"
                        ? "€"
                        : "$"}
              {props.oneData.price}
            </h5>
          </div>
          <p>
            {!props.sellerId && (
              <button
                className="btn  PrimaryBtn"
                style={{
                  whiteSpace: "nowrap",
                  maxWidth: "200px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  // alert("h")
                  setModal(true);
                }}
              >
                <i className=" fas fa-envelope mr-1"></i> Chat with Seller
              </button>
            )}
          </p>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 rating_sec px-0">
              <h6 className="d-flex align-items-center mb-3">
                {/* <ReactStars
                  count={5}
                  value={3}
                  edit={false}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ff7e00"
                /> */}
                {/*  <span className="ml-3"> 5331 Ratings &amp; 122 Reviews</span> */}
                {/* {loop1.map((item, index) => {
                  return <i className="fas fa-star my-1 mx-2 " key={index}></i>;
                })} */}
              </h6>
            </div>
          </div>
        </div>

        <div className="Highlights">
          <div>
            {highlightData.length ? <h5 className="mb-3">Highlights</h5> : null}
            {highlightData.length
              ? highlightData.map((item) => {
                return (
                  <>
                    <p className="mb-1">
                      <i className="fas fa-check-circle mr-2"></i>
                      {item.name}
                    </p>
                  </>
                );
              })
              : null}
            {/* <p className="mb-1">
              <i className="fas fa-check-circle mr-2"></i>Handmade
            </p>
            <p>
              <i className="fas fa-check-circle mr-2"></i>
              Materials: Crystal
            </p> */}
          </div>
        </div>
        {refress === true ? (
          <Loader />
        ) : (
          <p></p>
          // <div className="calender_Booking" onClick={clicked}>
          // <FullCalendar
          // plugins={[dayGridPlugin]}
          // initialView="dayGridMonth"
          // // calendarStart=""
          // calendarEnd= ""
          // visibleRange={currentData}
          // weekends={true}
          // plugins={[dayGridPlugin, interactionPlugin]}
          // dateClick={handleDateClick}
          // datesSet= {handleMonthChange}
          // date={date}
          // events={eventData}
          // />
          // </div>
        )}

        <div className="calender_Booking" onClick={clicked}>
          <FullCalendar
            // plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            // calendarStart=""
            // calendarEnd= ""
            visibleRange={currentData}
            showNonCurrentDates={false}
            weekends={true}
            plugins={[dayGridPlugin, interactionPlugin]}
            dateClick={handleDateClick}
            // datesSet= {handleMonthChange}
            date={date}
            events={eventData}
            datesSet={(dateInfo) => {
              setCheckMonth(dateInfo.start.getMonth());
            }}
          />
        </div>
      </div>
      {bookModal ? (
        <div
          className={bookModal ? "modal single_modal fade show" : "modal fade"}
          tabindex="-1"
          style={{ display: bookModal ? "block" : "none" }}
        >
          <Slot
            CallBackSlot={CallBackSlot}
            SlotData={slotData}
            shopServiceId={props.oneData._id}
            serviceData={props.oneData}
            sellerfulldetails={props.sellerdetails}
            date={date}
          />
        </div>
      ) : null}
      {modal ? (
        <Modal
          openCalling={openCalling}
          status={true}
          userData={reactLocalStorage.getObject("userData")}
          sellerdetails={
            props.oneData && props.oneData.seller_details.length > 0
              ? props.oneData.seller_details[0]
              : ""
          }
          serviceId={props.oneData?._id}
          chatRoomId={chatRoomId}
          onCloseCallBack={Callbackmsg}
          image=""
        />
      ) : null}
      {/* {callmodal === "audio" ? (
        <div
          className={
            callmodal === "audio"
              ? "modal fade single_modal show"
              : "modal fade"
          }
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: callmodal === "audio" ? "block" : "none" }}
        >
          <AudioModal
            status={true}
            userData={reactLocalStorage.getObject("userData")}
            sellerdetails={
              props.oneData && props.oneData.seller_details.length > 0
                ? props.oneData.seller_details[0]
                : ""
            }
            callBackcall1={callBackcall}
            image=""
          />
        </div>
      ) : null} */}
      {callmodal === "video" ? (
        <div
          className={
            callmodal === "video"
              ? "modal fade single_modal show"
              : "modal fade"
          }
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: callmodal === "video" ? "block" : "none" }}
        >
          <VideoModal
            status={true}
            userData={reactLocalStorage.getObject("userData")}
            remoteData={props.oneData.seller_details[0]}
            callBackcall1={(val) => callBackcall(val)}
            image=""
          />
        </div>
      ) : null}
    </div>
  );
};
export default Details;
