import React from "react";
import SercviceRequestBox from "../../Component/SercviceRequestBox";

function Servicerequest() {
  const boxes = [
    {
      img: "https://admin.astrophy.com/uploads/shop_services/download (2).jpg",
      number: 812023946723,
      text: "Birth Chart Reading ",
      greenBtn: "Accept",
      purpleBtn: "Reject",
    },
    {
      img: "https://admin.astrophy.com/uploads/shop_services/download (2).jpg",
      number: 812023946723,
      text: "Birth Chart Reading ",
      greenBtn: "Accept",
      purpleBtn: "Reject",
    },
    {
      img: "https://admin.astrophy.com/uploads/shop_services/gemstone02.jpg",
      number: 2120231147149,
      text: "Gemstone",
      greenBtn: "Accept",
      purpleBtn: "Reject",
    },
    {
      img: "https://admin.astrophy.com/uploads/shop_services/download (2).jpg",
      number: 2120231056507,
      text: "Birth Chart Reading",
      greenBtn: "Accept",
      purpleBtn: "Reject",
    },
    {
      img: "https://admin.astrophy.com/uploads/shop_services/chart.jpg",
      number: 2120231052554,
      text: "Talk to Astrologer",
      greenBtn: "Accept",
      purpleBtn: "Chat with Buyer",
    },
  ];
  return (
    <div className="dashboard_over seller">
      <div>
        <div className="row">
          <div className="Toastify" />
          {boxes.map(({ img, number, text, greenBtn, purpleBtn }, ind) => {
            return (
              <SercviceRequestBox
                img={img}
                text={text}
                greenBtn={greenBtn}
                purpleBtn={purpleBtn}
                number={number}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Servicerequest;
