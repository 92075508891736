import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Peer from "peerjs";
import * as uuid from "uuid";
// import {
//   ref,
//   get,
//   set,
//   child,
//   limitToLast,
//   onChildAdded,
//   query,
//   orderByKey,
//   endAt,
//   onValue,
//   update,
// } from "firebase/database";
// import db from "../../../Firebase/fbconfig";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import Firebase1 from "../firebase";
import myMusic from "../../../assets/classical-demo.mp3";
import { fsdb } from "../firebase";
import { useUserFetch } from "../../../Providers/User";
// import { ICESERVER } from "../../../Server";

// const configuration = {
//   iceServers: [
//     { url: "stun:stun.l.google.com:19302" },
//     { url: "stun:stun1.l.google.com:19302" },
//     { url: "stun:stun2.l.google.com:19302" },
//     { url: "stun:stun3.l.google.com:19302" },
//     { url: "stun:stun4.l.google.com:19302" },
//     {
//       url: "turn:numb.viagenie.ca",
//       credential: "Bokachoda@2020",
//       username: "soumya.webhibe@gmail.com",
//     },
//   ],
// };


// const configuration = {
//   iceServers: [],
// };

var peerServer = null;
var myStream = null;
var remoteUserId = "";
var remoteUserStream = null;
var peerCall = null;
var callNodeId = null;
var hour = 0;
var min = 0;
var sec = 0;
var timerRef = null;

const AudioModal = (props) => {
  // alert("k")
  // const { iceServerData } = useUserFetch()
  // console.log(iceServerData, "kluiu7jkn")

  const { iceServerData } = useUserFetch()

  const configuration = {
    iceServers: iceServerData,
  };



  const { remoteData, roomId, type, chatRoomId } = props;
  const [iconoff, setIconoff] = useState("");
  const [recieved, setRecieved] = useState(false);
  const [remoteUserToken, setRemoteUserToken] = useState("");
  const [callState, setCallState] = useState("Connecting");
  const [audioMute, setAudioMute] = useState(false);
  const [remoteUserMute, setRemoteUserMute] = useState(false);
  const [refress, setRefress] = useState(false);

  const [audio] = useState(new Audio(myMusic));
  const myUserData = reactLocalStorage.getObject("userData");
  useEffect(() => {
    // //console.log("remote", props.remoteData);
    getUserMedia();
  }, []);

  useEffect(() => {
    if (callState == "Recieved") {
      // //console.log("call start")

      startTimer();
    }
  }, [callState]);

  const startTimer = () => {
    let re = false;
    timerRef = setInterval(() => {
      if (sec < 59) {
        sec = sec + 1;
      } else {
        min = min + 1;
        sec = 0;
      }

      if (min == 60) {
        min = 0;
        hour = hour + 1;
      }
      // //console.log("balance", balance)
      setRefress(!re);
      re = !re;
    }, 1000);
  };

  const getUserMedia = () => {
    navigator.getUserMedia(
      { audio: true },
      (stream) => {
        // //console.log("stream", stream)
        initCall(stream);
      },
      (err) => {
        //console.log("err", err);
      }
    );
  };

  const sendNotificationEnd = async (token) => {
    const FIREBASE_API_KEY =
      "AAAA2DbAgKw:APA91bE_UJyTH7Tat101doNZihbFeCbIbHyLj2-ZaTzyjQDKCov5umGbcaJT-w_7x8v8u87W-lVTVrNCxWQlIK_2Vgu-_U4pRT_iyGfltl-cFmIOsNjRNSzhkbCecp1U70KZXHI9RMX7";
    // data.notiType = 'Call';
    const message = {
      registration_ids: [token],
      priority: "high",
      data: { notiType: "Call", callDataType: "end" },
    };

    let headers = new Headers({
      "content-type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    });
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    });
    response = await response.json();
    //console.log("response55555", response, message);
  };

  const sendNotification = async (data, token) => {
    const FIREBASE_API_KEY =
      "AAAA2DbAgKw:APA91bE_UJyTH7Tat101doNZihbFeCbIbHyLj2-ZaTzyjQDKCov5umGbcaJT-w_7x8v8u87W-lVTVrNCxWQlIK_2Vgu-_U4pRT_iyGfltl-cFmIOsNjRNSzhkbCecp1U70KZXHI9RMX7";
    // data.notiType = 'Call';
    const message = {
      registration_ids: [token],
      priority: "high",
      data: { ...data, notiType: "Call", callDataType: "start" },
      notification: {
        title: myUserData.firstname + " " + myUserData.lastname,
        body: "Incoming Call",
        vibrate: 1,
        sound: 1,
        show_in_foreground: true,
        priority: "high",
        content_available: true,
      },
    };

    let headers = new Headers({
      "content-type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    });
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    });
    response = await response.json();
    //console.log("response55555", response, message);
  };

  const FBGetValue = (nodeId) => {
    Firebase1.database()
      .ref(`/call/${myUserData._id}/${nodeId}`)
      .on("value", (snapshot) => {
        let data = snapshot.val();
        setCallState(data.status);

        if (data.status == "Recieved") {
          // InCallManager.stopRingtone();
          // InCallManager.stopRingback();
        }

        if (data.muted) {
          setRemoteUserMute(true);
        } else {
          setRemoteUserMute(false);
        }

        if (data.endStatus) {

          Firebase1.database()
            .ref(`/user/${remoteData._id}`)
            .once("value", (snapshot) => {
              //console.log("snapshot.val().fcmToken", snapshot);
              let tk = snapshot.val()?.fcmToken;
              if (tk) {

                sendNotificationEnd(tk);
              }
            });
          endCall(data.status);
        }
      });
  };

  const initCall = (stream) => {
    myStream = stream;
    peerServer = new Peer(undefined, {
      host: "peer.astrophy.com",
      path: "/",
      secure: true,
      config: configuration,
    });

    peerServer.on("open", (userId) => {
      //console.log("userid", userId);
      if (type == "Incomming") {
        audio.play();
        callNodeId = remoteData.nodeId;
        FBGetValue(remoteData.nodeId);
        updateMyFb({ status: "Ringing" });
        updateClientFb({ status: "Ringing" });
      } else {
        let remUserFcmtoken = "";
        Firebase1.database()
          .ref(`/user/${remoteData._id}`)
          .once("value", (snapshot) => {
            //console.log("snapshot.val().fcmToken", snapshot);
            let tk = snapshot.val().fcmToken;
            setRemoteUserToken(tk);
            remUserFcmtoken = tk;
          });
        // //console.log("remoteData.userId", remoteData, myUserData)
        let callId = uuid.v4();
        callNodeId = callId;
        let myData = {
          userId: remoteData._id,
          name: `${remoteData.firstName} ${remoteData.lastName}`,
          nodeId: callId,
          time: moment().format(),
          type: "Outgoing",
          status: "Connecting",
          endStatus: false,
        };

        Firebase1.database()
          .ref(`/call/${myUserData._id}/${callId}`)
          .set(myData)
          .then(() => {
            FBGetValue(callId);
          });

        let clientData = {
          userId: myUserData._id,
          _id: myUserData._id,
          name: `${myUserData.firstName} ${myUserData.lastName}`,
          firstName: myUserData.firstName,
          lastName: myUserData.lastName,
          peerId: userId,
          time: moment().format(),
          type: "Incomming",
          status: "Connecting",
          nodeId: callId,
          endStatus: false,
        };

        Firebase1.database()
          .ref(`/call/${remoteData._id}/${callId}`)
          .set(clientData)
          .then(() => {
            Firebase1.database()
              .ref(`/user/${remoteData._id}`)
              .once("value", (snapshot) => {
                //console.log("snapshot.val().fcmToken", snapshot);
                let tk = snapshot.val().fcmToken;
                sendNotification(clientData, tk);
              });


          });
      }
    });

    peerServer.on("call", (call) => {
      const conn = peerServer.connect(call.peer);
      call.answer(stream);

      call.on("stream", (userStream) => {
        //console.log("userStream peer", userStream);
        peerCall = call;
        remoteUserStream = userStream;
        const videoGreed = document.getElementById("video-view-rtc");
        videoGreed.srcObject = userStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
        });
      });

      call.on("close", () => {
        //console.log("streamstream1");
      });
    });
  };

  const connectUser = (userId) => {
    // //console.log("called")
    let data = {};
    const conn = peerServer.connect(userId);
    const call = peerServer.call(userId, myStream);

    call.on("stream", (remoteVideoStream) => {
      if (remoteVideoStream) {
        peerCall = call;
        //console.log("user stream", remoteVideoStream);
        remoteUserStream = remoteVideoStream;
        const videoGreed = document.getElementById("video-view-rtc");
        videoGreed.srcObject = remoteVideoStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
        });
      }
    });
    call.on("close", () => {
      //console.log("streamstream");
    });
  };

  const reciveCall = () => {
    if (peerServer != null) {
      connectUser(remoteData.peerId);
      audio.pause();
      updateMyFb({ status: "Recieved" });
      updateClientFb({ status: "Recieved" });
      setRecieved(true);
    }
  };

  const endCall = (callStat) => {
    if (chatRoomId) {
      callStatusUpdateInchatList()

    }
    remoteUserStream = null;
    if (peerCall != null) {
      peerCall.close();
    }
    clearInterval(timerRef);

    if (type != "Incomming") {
      let callMsgRef = Firebase1.database()
        .ref(`/chat/${chatRoomId}/messages`)
        .push();

      let msdId = callMsgRef.key;
      //console.log("callState", callStat);

      callMsgRef.set({
        sender_id: myUserData._id,
        type: "call",
        msgid: msdId,
        send_time: moment().format(),
        roomid: chatRoomId,
        name: myUserData.firstName + " " + myUserData.lastName,
        callDuration: `${hour.toString().padStart(2, "0")} : ${min
          .toString()
          .padStart(2, "0")} : ${sec.toString().padStart(2, "0")}`,
        callTime: moment().format(),
        callStatus: callStat == "Recieved" ? "Recieved" : "Outgoing",
      });
    }

    hour = 0;
    min = 0;
    sec = 0;

    myStream.getTracks().forEach(function (track) {
      track.stop();
    });
    audio.pause();
    props.callBackcall1(false);
  };

  const updateMyFb = (data) => {
    console.log("endcall", chatRoomId)


    // fsdb.collection("chat").doc(chatRoomId).collection("messages").add({
    //   audioCall:true,
    //   msg:"",
    //   sender_id:myUserData._id,
    //   receiver_id:remoteData._id,
    //   time:moment().format(),
    //   image:false
    // })
    // .then((doc)=>{

    // })
    // .catch((err)=>{
    //   console.log(err)
    // })


    // return false
    //console.log("myUserData", myUserData);
    // //console.log("updateMy", '/user/' + myUserData._id + '/call/' + callNodeId)
    Firebase1.database()
      .ref(`/call/${myUserData._id}/${callNodeId}`)
      .update(data)
      .then(() => {
        //console.log("done");
      });
  };

  const updateClientFb = (data) => {

    // //console.log("updateClient", '/user/' + remoteUserData.userId + '/call/' + callNodeId)
    Firebase1.database()
      .ref(`/call/${remoteData._id}/${callNodeId}`)
      .update(data)
      .then(() => { });
  };


  const callStatusUpdateInchatList = async () => {
    let data = {
      sender_id: myUserData._id,
      receiver_id: remoteData._id,
      time: moment().format(),
      msg: "",
      audioStatus: true,
      room_id: chatRoomId
    }
    console.log("my firebase", data);
    fsdb.collection("chat").doc(chatRoomId).collection("messages").add(data)
      .then((doc) => {
        // console.log()

      }).catch((err) => {
        console.log(err);
      })

  }
  return (
    <div>
      <div className="modal-dialog  my-3 ">
        <div className="modal-content call_modal">
          <div className="call-body my-3 px-5 pt-4">
            <div className="text-center">
              <h5>{`${remoteData.firstName} ${remoteData.lastName}`}</h5>
              {callState != "Recieved" ? (
                <p>{callState}...</p>
              ) : (
                <p>{`${hour.toString().padStart(2, "0")} : ${min
                  .toString()
                  .padStart(2, "0")} : ${sec.toString().padStart(2, "0")}`}</p>
              )}

              <img
                src="https://png.pngitem.com/pimgs/s/130-1300344_user-symbol-png-transparent-png.png"
                alt="img"
                className="audio-img"
                style={{
                  objectFit: "contain",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          </div>

          <video
            id="video-view-rtc"
            style={{
              display: "none",
            }}
            muted={remoteUserMute}
          />

          <div className=" down-part mt-5">
            <div className="d-flex justify-content-around">
              {!recieved && type == "Incomming" ? (
                <button
                  type="button"
                  className="btn receive-btn"
                  onClick={reciveCall}
                >
                  <i className="fas fa-phone" />
                </button>
              ) : null}

              {callState == "Recieved" ? (
                !audioMute ? (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(true);
                      updateClientFb({ muted: true });
                    }}
                  >
                    <i className="fas fa-microphone-slash"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(false);
                      updateClientFb({ muted: false });
                    }}
                    style={{
                      borderRadius: 30,
                      background: "red",
                    }}
                  >
                    <i
                      className="fas fa-microphone-slash"
                      aria-hidden="true"
                    ></i>
                  </button>
                )
              ) : null}

              <button
                type="button"
                className="btn decline-btn"
                onClick={() => {
                  updateMyFb({ endStatus: true });
                  updateClientFb({ endStatus: true });
                }}
              >
                <i className="fas fa-phone"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioModal;
