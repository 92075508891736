import React, { useState, useEffect } from "react";

import NewMoon from "../../../Default/Assets/Images/NewMoon.png";
import Uranus from "../../../Default/Assets/Images/Uranus.png";
import Jupitar from "../../../Default/Assets/Images/Jupitar.png";
import ArticleArrow from "../../../Default/Assets/Images/ArticleArrow.png";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../../utils/HttpClient";
// import renderHTML from "react-render-html";
import moment from "moment";

const Articles = () => {
  const [articleData, setarticleData] = useState([]);
  useEffect(() => {
    getArticles();
  }, []);

  const getArticles = async () => {
    let result = await HttpClient.requestData("articles", "GET");
    //console.log("articleData Result", result);
    if (result && result.status) {
      setarticleData(result.data);
    }
  };
  const article = [
    { img: NewMoon, title: "New Moon in Cancer" },
    { img: Uranus, title: "Uranus in House" },
    { img: Jupitar, title: "Jupitar in Scorpio 2018" },
  ];
  return (
    <div className="Articles px-4">
      <div className="text-center">
        <h1 className="secTionHeading mb-5 designBtm2" style={{color: "#fff"}}>
          MySpiritualConnect Articles
        </h1>
      </div>
      <div className="container">
        <div className="row mb-2">
          {articleData.length ? (
            articleData.slice(0, 3).map((item, index) => {
              return (
                <div className="col-md-3 mb-2 text-center customBox" key={index} >
                  <div>
                    <img
                      src={HttpClient.IMG_URL + item.image}
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div className="">
                    <p className="date">
                      {moment(item.dated).format("LL")},{" "}
                      {item.author}
                    </p>
                    <h4>{item.title}</h4>
                    <p
                      className="mb-4 _details"
                      dangerouslySetInnerHTML={{ __html: item.details }}
                    >
                      {/* {renderHTML(item.details)} */}
                      {/* ul full count run batted in outside season, cracker jack
                      hall of fame club check swing. Rotation shift series sport
                      ball around the */}
                    </p>
                    {/* <img src={ArticleArrow} className="img-fluid" alt="img" style={{ width: "106", height: "20px" }} /> */}
                    {/* <div className="text-center">
                      <Link
                        to={"/article-single" + item._id}
                        type="button"
                        className="btn PrimaryBtn"
                      >
                        <b>READ MORE</b>
                      </Link>
                    </div> */}
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <h1> No Article Found</h1>
            </div>
          )}
        </div>
        <div className="text-center mt-5">
          <Link to="/more-article" className="btn PrimaryBtn" style={{textTransform: "capitalize"}}>
            <span>Read More</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Articles;
