import React, { useState } from "react";
import {ReactTitle} from 'react-meta-tags';

import Jumbotron from "../../Component/Jumbotron";
import Footer2 from "../../Default/Footer2/index";
import Navbar2 from "../../Default/Navbar2/index";
import Faqs from "../../Component/Faqs";
import SideDrop from "../ServiceList/Component/SideDrop";

import FaqJumboBg from "../../Default/Assets/Images/FaqJumboBg.png";

const Index = () => {
  const questions = [0, 1, 2, 3, 4, 5];

  const [state, setstate] = useState({
    ans0: true,
    ans1: false,
    ans2: false,
    ans3: false,
    ans4: false,
    ans5: false,
  });

  const FaqBox = (id) => {
    let name = `ans${id}`;
    //console.log(name, state[name]);
    setstate({ ...state, [name]: !state[name] });
  };

  return (
    <div className="Faq ">
      <ReactTitle title="MySpiritualConnent - Faq"/>
      <section>
        <Jumbotron heading="FAQ" img={FaqJumboBg} />
      </section>
      <section className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <SideDrop />
            </div>
            <div className="col-md-8 ">
              <h2>FAQ</h2>
              <div className="container-fluid">
                <div className="row">
                  {questions.map((item, index) => {
                    let name = `ans${index}`;
                    return (
                      <div className="col-md-12" key={index}>
                        <div className=" my-3 ans_text">
                          <p className={state[name]?"question active mb-3":"question"} onClick={(e) => FaqBox(index)}>
                            Lorem ipsum dolor sit amet
                            {state[name] ? (
                              <i className="fas fa-minus mr-3  "></i>
                            ) : (
                              <i className="fas fa-plus mr-3  "></i>
                            )}
                          </p>

                          <div
                            className={state[name] ? "d-block faqbg" : "d-none"}
                          >
                            <h6>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. A, volutpat nibh cursus Lorem ipsum dolor
                              sit amet, consectetur adipiscing elit. A, volutpat
                              nibh cursus Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Rem repellat quod dolor.
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
