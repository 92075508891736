import React from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
const ReportProb = () => {
  return (
    <div>
      <section>
        <Jumbotron heading="Report Problem" img={serviceJumboBg} />
      </section>
      <div className="feedback">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center my-4">
              <h2>Report a Problem</h2>
            </div>
            <div className="col-md-6 m-auto">
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">
                  Write Your Problem Here
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={5}
                  placeholder="Write Here..."
                />
              </div>
              <div className="text-center my-4">
                <button className="btn submit_btn">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportProb;
