import React, { useState, useEffect } from "react";

import PsychicIcon from "../../../Default/Assets/Images/logo/PsychicIcon.png";
import ReikiIcon from "../../../Default/Assets/Images/logo/ReikiIcon.png";
import EnergyHealing from "../../../Default/Assets/Images/logo/EnergyHealing.png";
import SpiritualCoach from "../../../Default/Assets/Images/logo/SpiritualCoach.png";
import MeditationIcon from "../../../Default/Assets/Images/logo/MeditationIcon.png";
import OracleReaderIcon from "../../../Default/Assets/Images/logo/OracleReaderIcon.png";
import TarrorCardIcon from "../../../Default/Assets/Images/logo/TarrorCardIcon.png";
import MediumIcon from "../../../Default/Assets/Images/logo/MediumIcon.png";
import NumerologyIcon from "../../../Default/Assets/Images/logo/NumerologyIcon.png";
import FaceReadingIcon from "../../../Default/Assets/Images/logo/FaceReadingIcon.png";
import GemologyIcon from "../../../Default/Assets/Images/logo/GemologyIcon.png";
import PalmistryIcon from "../../../Default/Assets/Images/logo/PalmistryIcon.png";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";


import HttpClient from "../../../utils/HttpClient";

const SpiritualConnect = () => {
  const forecasts = [
    { img: PsychicIcon, title: "Psychic" },
    { img: ReikiIcon, title: "Reiki" },
    { img: EnergyHealing, title: "Distance Energy Healing" },
    { img: SpiritualCoach, title: "Spiritual Coach" },
    { img: MeditationIcon, title: "Meditation" },
    { img: OracleReaderIcon, title: "Oracle Reader" },
    { img: TarrorCardIcon, title: "Tarror Card Reader" },
    { img: MediumIcon, title: "Medium" },
    { img: NumerologyIcon, title: "Numerology" },
    { img: FaceReadingIcon, title: "Face Reading" },
    { img: GemologyIcon, title: "Gemology" },
    { img: PalmistryIcon, title: "Palmistry" },
  ];
  const [service, setService] = useState([]);
  const [login, setlogin] = useState(false);

  useEffect(() => {
    // fetchShopId();
    getUserData();
    fetchService();
    window.scrollTo(0, 0);
  }, []);

  const getUserData = async () => {
    let getAccount = reactLocalStorage.getObject("userData");
    //console.log("getAccount", getAccount);
    if (
      getAccount &&
      getAccount != null &&
      Object.keys(getAccount).length > 0
    ) {
      setlogin(true);

    }
    else {

    }
  }

  const fetchService = async () => {

    let result = await HttpClient.requestData("service-category", "GET");
    //console.log("servicecategory", result);
    if (result && result.status) {
      let data = result.data;
      //console.log("data", data);

      setService(data);

    } else {
    }
  };

  return (
    <div className="SpiritualConnect px-4">
      <div className="text-center">
        <h1 className="secTionHeading DesignBtm">MySpiritualConnect Modalities</h1>
        <p className="mb-5" style={{maxWidth: "700px", margin: "0 auto"}}>
          Read free daily, weekly and monthly horoscopes which are always
          available through the website. You will find a compilation of
          all current astrological forecasts.
        </p>
        <div className="container">
          <div className="row ">
            {service.slice(0,12).map((item, index) => {
              return (
                <div className="col-lg-2 col-md-4 col-sm-6 mb-4 text-center" key={index}>

                  <Link to={"/service-list/" + item._id}>
                    <img
                      src={HttpClient.IMG_URL + item.image}
                      className="img-fluid mb-3"
                      alt="img"
                    // style={{ width: "100%", height: "100%" }}
                    // style={{minHeight:"140px"}}
                    />
                  </Link>:


                  <Link to={"/service-list/" + item._id} >
                    <h4 className="mb-0">{item.name}</h4>
                    {/* <p className="mt-1">{item.description}</p> */}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className="text-center">
          {/* {login ?
            <Link to="/category-listing" className="btn PrimaryBtn">
              {" "}
              <b> View All</b>
            </Link> : null} */}
            <Link to="/category-listing" className="btn PrimaryBtn">
              {" "}
              <span> View All</span>
            </Link>
        </div>
      </div>
    </div>
  );
};

export default SpiritualConnect;
