import React, { Component } from "react";
import { CSVLink } from "react-csv";
import Details from "./Details";
import ChatWithBuyer from "./ChatWithBuyer"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Modalchattt from "../../ServiceDetails/Components/modal"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";

import HttpClient from "../../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";

import ChatWithPersonel from "./chatwithpersonel";
import "./chatlist.css";

import { result } from "lodash";
import BreadCrump from "../../../Component/BreadCrump";
import Firebase1 from "../../Chat/firebase";
import { fsdb } from "../../Chat/firebase";
import { RotatingLines } from "react-loader-spinner";
const { v4: uuidv4 } = require("uuid");

const header = [
  { label: "Sl.", key: "sl" },
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Order Id", key: "orderid" },
  { label: "Book Date", key: "bookdate" },
  { label: "Category Name", key: "cat_name" },
  { label: "Service Name", key: "service" },
  { label: "Total Price", key: "total_price" },
  { label: "Product Currency", key: "currency" },
  { label: "Comission for Admin", key: "admin_com" },
];

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      breadcrumbItems: [
        { title: "Order History", link: "#" },
        { title: "Show Order History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],
      subServiceAll: [],
      eachservicedetails: {},
      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",
      sub_srvc_id: "",
      market_price: "",
      selling_price: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],
      hashtag: "",
      personalisation: "",
      details: "",
      price: "",
      allService: [],
      shopService: [],
      allService_id: "",
      allShopService_id: "",
      to_time: "",
      from_time: "",
      available_duration: 0,
      slot_duration_id: "",
      orderHistory: [],
      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      selectcategory: "",
      selectshopserviceid: "",
      edit_id: "",
      weekDay: [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
      ],
      slot_duration: [
        { label: "10", value: "10" },
        { label: "15", value: "15" },
        { label: "30", value: "30" },
      ],
      language: [{ label: "en", value: "en" }],
      lang: "",
      slot_time: "",
      selectedWeek: "",
      selectWeekName: "",
      oderId: [],
      ismodalopen: false,
      ismodalopen1: false,
      orderid: "",
      firebaseData: [],
      remoteId: "",
      roomId: "",
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    // this.checkUser();
    // this.fetchService();
    //this.fetchData1();
    this.fetchData1();
    // this.findRoomId();
  };


  findRoomId = async (otherUser) => {
    const sender_id = reactLocalStorage.getObject("userData");
    // const receiver_id = this.state.userDetails;
    // console.log(receiverDetail);
    var chatUser = {
      sender_id: sender_id._id,
      sender_name: sender_id.firstName,
      receiver_name: otherUser.firstName,
      room_id: uuidv4(),
      receiver_id: otherUser._id,
    };
    // console.log("room", chatUser);
    // return false
    const chat_usersRef = fsdb.collection("chat_users");
    chat_usersRef
      .where("sender_id", "==", chatUser.sender_id)
      .where("receiver_id", "==", chatUser.receiver_id)
      .get()
      .then((querySnap) => {
        if (querySnap.empty) {
          chat_usersRef
            .where("receiver_id", "==", chatUser.sender_id)
            .where("sender_id", "==", chatUser.receiver_id)
            .get()
            .then((secondQuerySnap) => {
              if (secondQuerySnap.empty) {
                this.setState({ roomFound: false });
                // this.FetchMessageList(chatUser.room_id);

                // nothing here we have create room when user send message
                // chat_usersRef
                //   .add(chatUser)
                //   .then((docRef) => {
                //     console.log("Document written with ID: ", docRef.id);
                //     this.setState({ roomId: chatUser.room_id });
                //   })
                //   .catch((err) => {
                //     console.log("err", err);
                //   });
              } else {
                this.setState({
                  roomId: secondQuerySnap.docs[0].data().room_id,
                  roomFound: true
                });

                this.FetchMessageList(secondQuerySnap.docs[0].data().room_id);
              }
            });
        } else {
          this.setState({ roomId: querySnap.docs[0].data().room_id, roomFound: true });
          this.FetchMessageList(querySnap.docs[0].data().room_id);
          // querySnap.forEach((doc) => {
          //     console.log(doc.id, ' => ', doc.data());
          // });
          // this.FetchMessageList(
          //   querySnap.forEach((doc) => {
          //     return doc.id;
          //   })
          // );
        }
      });
  }


  onCloseCallBack = async (val) => {
    this.setState({ ismodalopen: val });
  };

  fetchData1 = async () => {
    this.setState({ isLoading: true })
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller_account/service-order-history/" + userData._id,
      "GET"
    );
    console.log("getOrderHistory", result.data);

    if (result && result.status) {
      let arr = [];
      this.setState({ isLoading: false })
      result.data.forEach((element, index) => {

        if (element.order_id) {
          Firebase1.database()
            .ref()
            .child("PersonelSellerchat")
            .child(element?.order_id)

            .on("value", (snapshot) => {
              if (snapshot.exists()) {
                //console.log("snapshot", snapshot.val());

                let dom = {
                  id: snapshot.val().order_id,
                };

                arr.push(dom);
                //console.log("arr", arr);

                this.setState({
                  firebaseData: arr,
                });
              }
              //console.log("Array 2", this.state.firebaseData);
              this.fetchData(arr);
            });
        }
        this.setState({ isLoading: false })
      });
    }
  };

  fetchData = async (arrfirebase) => {
    //console.log("hii");
    //console.log(this.state.selectshopserviceid);
    this.setState({ isLoading: true })
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller_account/service-order-history/" + userData._id,
      "GET"
    );
    console.log("getOrderHistory11", result);
    if (result && result.status) {
      let data = [];
      let sl = 1;
      this.setState({ result: result.data });
      //console.log('result.data', result.data)
      result.data.forEach((element, index) => {
        // //console.log("element", element);
        let j = arrfirebase.findIndex((ele) => ele.id == element.order_id);
        // //console.log("valueof j ", j);
        let i = element.image.findIndex((element) => element.ismain == true);
        let image =
          element.service_data.length > 0 ? element.service_data[0].image : "";
        let fname =
          element.buyer_data.length > 0 ? element.buyer_data[0].firstname : "";
        let lname =
          element.buyer_data.length > 0 ? element.buyer_data[0].lastname : "";
        let comission =
          element.admin_commission.length > 0
            ? element.admin_commission[0].percentage
            : 0;
        let rows = {
          sl: sl,
          // name: fname + " " + lname,
          // email:
          //   element.buyer_data.length > 0 ? element.buyer_data[0].email : "",
          // orderid: element.order_id ? element.order_id : "",
          name: element.checkout_data.length > 0 ? element.checkout_data[0].firstname + " " + element.checkout_data[0].lastname : "",

          email:
            element.checkout_data.length > 0 ? element.checkout_data[0].email : "",
          admin_com: (element.price * comission) / 100,
          total_price:
            (userData.currency == "INR"
              ? "₹"
              : userData.currency == "CAD"
                ? "$"
                : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "	GBP"
                    ? "£"
                    : "€") + element.price,
          currency: element.currency ? element.currency : "",
          price: element.price - (element.price * comission) / 100,
          // price:element?.checkout_data[0]?.total??"0",
          bookdate: moment(element.date_of_booking).format("DD-MM-YYYY"),
          discount_amount: element?.discount_amount ?? "0",

          cat_name:
            element.service_data.length > 0
              ? element.service_data[0].category_name
              : "",
          service:
            element.service_data.length > 0 ? element.service_data[0].name : "",
          complete_order: element.seller_confirmed ? (
            <button
              title="Complete Order"
              // className="btn btn-primary"
              className={
                element.completestatus == "complete"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
              onClick={() => {
                this.completeBooking(element, index);
              }}
            >
              {element.completestatus == "complete"
                ? "Completed"
                : "Complete Order"}
              {/* Complete Order */}
            </button>
          ) : null,
          orderid: element.order_id,
          order_status: (
            <div>
              {element.seller_confirmed == true ? (
                <button
                  title="Order Accepted"
                  // className="btn btn-primary"
                  className="btn btn-success"
                >
                  Order Accepted
                </button>
              ) : element.seller_reject == true ? (
                <button
                  title="Order Cancelled"
                  // className="btn btn-primary"
                  className="btn btn-danger"
                >
                  Order Cancelled
                </button>
              ) : element.order_status == "cancelled" ? (
                <button
                  title="Cancelled by Buyer"
                  // className="btn btn-primary"
                  className="btn btn-danger"
                >
                  Cancelled by Buyer
                </button>
              ) : (
                <button
                  title="Order Pending"
                  // className="btn btn-primary"
                  className="btn btn-warning"
                >
                  Order Pending
                </button>
              )}
              &nbsp;
              {element.refund_request == "Yes" ? (
                <button
                  title="Refund Claim"
                  // className="btn btn-primary"
                  className="btn btn-primary"
                >
                  Refund Claim
                </button>
              ) : null}
            </div>
          ),
          img: (
            <div>
              {i > -1 ? (
                <img
                  src={HttpClient.IMG_URL + element.image[i].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              ) : (
                <img
                  src={HttpClient.IMG_URL + element.image[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              )}
            </div>
          ),
          details: (
            <button
              title="Details"
              className="btn btn-primary"
              onClick={() => {
                this.setState({
                  oderId: element,
                  ismodalopen: true,
                });
              }}
            >
              View Details
            </button>
          ),
          reply: (
            <div>
              {j > -1 ? (
                <Button
                  color="info"
                  type="button"
                  className="btn-sm"
                  onClick={() => {
                    this.state.ismodalopen1 = true;
                    this.state.orderid = element.order_id;
                    // this.setState({});
                    this.setState({ eachservicedetails: element })
                  }}
                >
                  Chat with Admin
                </Button>
              ) : null}
            </div>
          ),

          chatwithbuyer: (
            <Button
              color="secondary"
              type="button"
              className="btn-sm"
              disabled={element.completestatus === "complete" ? true : false}
              onClick={() => {

                this.state.isOpenmodalbuyer = true;
                this.state.orderid = element.order_id;
                this.state.name = fname + " " + lname;
                this.state.servid =
                  element.service_data.length > 0
                    ? element.service_data[0]._id
                    : "";
                //  this.setState({});
                // this.findRoomId(element.user_data[0])
                this.setState({ isOpenmodalbuyer: true, eachservicedetails: element, remoteId: element.user_id, userDetails: element.user_data[0] })
              }}
            >
              Chat with Buyer
            </Button>
          ),

          withdraw: (
            <button
              title="Details"
              className={
                element.earning_data.length > 0 &&
                  element.earning_data[0].receipt_status == "Requested"
                  ? "btn btn-warning"
                  : element.earning_data.length > 0 &&
                    element.earning_data[0].receipt_status == "Approved"
                    ? "btn btn-danger"
                    : "btn btn-success"
              }
              onClick={() => {
                this.withdraw(
                  element,
                  element.price - (element.price * comission) / 100,
                  (element.price * comission) / 100
                );
              }}
            >
              {element.earning_data.length > 0 &&
                element.earning_data[0].receipt_status == "Requested"
                ? "Requested for WithDraw"
                : element.earning_data.length > 0 &&
                  element.earning_data[0].receipt_status == "Approved"
                  ? "Approved for Withdrawl"
                  : "Withdraw"}
            </button>
          ),
        };
        sl++;
        data.push(rows);
      });
      //console.log('datadata', data)
      this.setState({
        data: data.reverse(),
      });

      this.setState({ isLoading: false })
    } else {
      this.setState({ isLoading: false })
      this.setState({
        data: [],
      });
    }
    //
  };

  completeBooking = async (item, index) => {
    console.log("completeBooking", item, index);

    let result = await HttpClient.requestData(
      "seller-service/complete-booking/" + item._id,
      "PUT"
    );

    console.log("completeBookinghistry", result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Order Completed Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 2000);
      this.fetchData1();
      // window.location.href = "/accept-booking";
    } else {
      this.setState({
        alert: true,
        message: result.message,
        type: "danger",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  withdraw = async (item, seller_com, admin_com) => {
    //console.log("item", item);

    let sendData = {
      seller_id: item.seller_id,
      serv_id: item.service_id,
      order_id: item.order_id,
      total_earning: item.price,
      seller_earning: seller_com,
      commission: admin_com,
      images: item.image,
    };
    let result = await HttpClient.requestData(
      "seller_account/withdraw-earnings",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.setState({
        alert: true,
        message: result.message,
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);

      this.fetchData();
    }
  };
  callbackLike = async () => {
    this.setState({ isOpenmodalbuyer: false })
    // this.state.isOpenmodalbuyer = false;


    //console.log(this.state.isOpenLike);
  };
  Callbackmsg = async (val) => {
    this.state.ismodalopen1 = false;
    this.setState({});
  };

  Callbackmsg1 = async (val) => {
    //console.log(val);

    this.setState({ isOpenmodalbuyer: false });
  };

  render() {
    // console.log('this.state.isOpenmodalbuyer', this.state.isOpenmodalbuyer)
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        // {
        //   label: "Booked By",
        //   field: "booked_by",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Order Id",
          field: "orderid",
          sort: "asc",
          width: 270,
        },

        {
          label: "Book Date",
          field: "bookdate",
          sort: "asc",
          width: 270,
        },
        {
          label: "Category Name",
          field: "cat_name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Service Name",
          field: "service",
          sort: "asc",
          width: 100,
        },
        {
          label: "Total Price",
          field: "total_price",
          sort: "asc",
          width: 100,
        },
        {
          label: "Discount",
          field: "discount_amount",
          sort: "asc",
          width: 100,
        },
        // {
        //   label: "Price",
        //   field: "price",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Product Currency",
          field: "currency",
          sort: "asc",
          width: 100,
        },
        {
          label: "Comission for Admin",
          field: "admin_com",
          sort: "asc",
          width: 100,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 100,
        },

        // {
        //   label: "Booking Status",
        //   field: "assign",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Order Status",
          field: "order_status",
          sort: "asc",
          width: 280,
        },

        {
          label: "View Details",
          field: "details",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Chat with Personel",
        //   field: "reply",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Chat with Buyer",
          field: "chatwithbuyer",
          sort: "asc",
          width: 270,
        },
        {
          label: "Order Complete",
          field: "complete_order",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Action",
        //   field: "withdraw",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (

      <React.Fragment>
        <BreadCrump main="Order History" sub="Show Order History" />
        <div className="page-content">
          {/* {//console.log("orderHistory===",)} */}
          <Container fluid>

            {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null}

            <Row>
              <Col xl={12}>
                {this.state.data.length > 0 ? (
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"OrderHistoryList.csv"}
                    className="btn btn-success"
                  >
                    Export Report in CSV
                  </CSVLink>
                ) : null}
                <div className="card p-3">
                  {this.state.ismodalopen ? (
                    <Details
                      status={true}
                      onCloseCallBack={this.onCloseCallBack}
                      data={this.state.oderId}
                    />
                  ) : null}
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    onPageChange={() => { window.scrollTo(0, 0) }}
                  />
                </div>
                {this.state.ismodalopen1 ? (
                  <ChatWithPersonel
                    status={true}
                    // orderdetails={sentorderdetails}
                    orderid={this.state.orderid}
                    onCloseCallBack={this.Callbackmsg}
                    image=""
                  />
                ) : null}

                {this.state.isOpenmodalbuyer ? (

                  <Modalchattt
                    status={true}
                    onCloseCallBack={this.callbackLike}

                    // roomid={this.state.remoteId}
                    // senderDetail={this.state.userDetails}
                    // sellerId={reactLocalStorage.getObject("userData")._id}
                    userData={reactLocalStorage.getObject("userData")}
                    sellerdetails={this.state.userDetails}
                    // serviceId={props.oneData?._id}
                    sellerOrderModal={true}
                    // chatRoomId={chatRoomId}
                    image=""
                  />
                  // <ChatWithBuyer
                  //   status={true}
                  //   orderdetails={this.state.eachservicedetails}
                  //   name={this.state.name}
                  //   orderid={this.state.orderid}
                  //   onCloseCallBack1={this.Callbackmsg1}
                  //   image=""
                  //   servid={this.state.servid}


                  // openCalling={openCalling}
                  // status={true}
                  // userData={reactLocalStorage.getObject("userData")}
                  // sellerdetails={
                  //   props.oneData && props.oneData.seller_details.length > 0
                  //     ? props.oneData.seller_details[0]
                  //     : ""
                  // }
                  // serviceId={props.oneData?._id}
                  // chatRoomId={chatRoomId}
                  // onCloseCallBack={Callbackmsg}
                  // image=""

                  // status={true}
                  // onCloseCallBack={this.callbackLike}
                  // roomid={this.state.roomid}
                  // image=""
                  // name={this.state.name}
                  // fullname={this.state.fullname}
                  // selleruserid={this.state.userId}
                  // userid={this.state.userid}
                  // sessionclose={this.state.sessionclose}
                  // senderDetail={this.state.senderDetail}
                  // sellerId={reactLocalStorage.getObject("userData")._id}
                  // />
                ) : null}
              </Col>
            </Row>
          </Container>
        </div>

        {this.state.isLoading ? <div className="Loader-Show" style={{ position: "absolute", bottom: 323, left: 380, }}><RotatingLines
          visible={true}
          height="96"
          width="120"
          color="red"
          strokeColor="violet"
          strokeWidth="5"
          animationDuration="1"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        /> </div> : null}
      </React.Fragment>
    );
  }
}

export default OrderHistory;
