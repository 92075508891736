import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Video = (props) => {
  useEffect(() => {
    document.getElementById("play").addEventListener("click", (e) => {
      document.getElementById("play").play();
    });
    return () => {
      // stopvideo();
    };
  }, []);

  const [iconoff, setIconoff] = useState("");
  return (
    <div>
      <div className="  ">
        <div className=" video-modal call_modal" style={{ height: "100vh" }}>
          <div className="call-body   ">
            <div className="">
              <div className="_large_video">
                <video
                  src="https://www.w3schools.com/html/mov_bbb.mp4"
                  width="100%"
                  height="100%"
                  id="play"
                  //   autoPlay
                />
                <div className="_video_intro d-flex pl-lg-5 mx-5">
                  <button
                    className="_meet_act_btn px-3"
                    style={{ width: "auto" }}
                  >
                    User Name
                  </button>
                  <button
                    className="_meet_act_btn px-3"
                    style={{ width: "auto" }}
                  >
                    00:00
                  </button>
                </div>
                <div>
                  <img
                    src="https://png.pngitem.com/pimgs/s/130-1300344_user-symbol-png-transparent-png.png"
                    alt="img"
                    className="video-img"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" down-part " style={{ marginTop: "10em" }}>
            <div className="d-flex justify-content-around">
              {iconoff === "speaker" ? (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("")}
                >
                  <i className="fas fa-volume-mute"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("speaker")}
                >
                  <i className="fas fa-volume-down"></i>
                </button>
              )}
              {iconoff === "micro" ? (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("")}
                >
                  <i className="fas fa-microphone-slash"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("micro")}
                >
                  <i className="fas fa-microphone"></i>
                </button>
              )}
              {iconoff === "pause" ? (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("")}
                >
                  <i className="fas fa-video-slash"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("pause")}
                >
                  <i className="fas fa-video"></i>
                </button>
              )}
              <button type="button" className="btn receive-btn">
                <i className="fas fa-phone"></i>
              </button>
              <Link
                to="/chat"
                type="button"
                className="btn decline-btn "
                style={{ lineHeight: "2.6" }}
              >
                <i className="fas fa-phone"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
