import React, { useEffect, useState } from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
const Associate = () => {
  const loop = [1, 2, 3, 4];

  const [result, setresult] = useState([]);
  useEffect(() => {
    scrollTop();
    fetchData();
  }, []);

  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const fetchData = async () => {
    let result = await HttpClient.requestData("associate", "GET");
    //console.log("about", result);
    if (result && result.status) {
      setresult(result.data);
    }
  };
  return (
    <div>
      <section>
        <Jumbotron heading="The Team" img={serviceJumboBg} />
      </section>
      <div className="associate ">
        <div className="container">
          <div className="row px-3">
            <div className="col-md-12 text-center my-3">
              <h2>Our Team</h2>
            </div>
            {result.map((item, index) => {
              return (
                <div className="col-md-3" key={index}>
                  <div className="card text-center providerCon p-3 my-3">
                    <div className="card-body casting_text p-0">
                      <Link to={"/single-associate/" + item._id}>
                        <div style={{ 
                          width: "100%", 
                          height: "200px", 
                          backgroundImage: `url(${HttpClient.IMG_URL + item.image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover"
                          }}></div>
                        {/* <img
                          className=""
                          src={
                            HttpClient.IMG_URL + item.image
                          }
                          alt="img"
                          style={{ width: "100%" }}
                        /> */}
                      </Link>

                      <h4 className="my-3">{item.name}</h4>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Associate;
