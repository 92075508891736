import React from 'react'
import loader from "../Default/Assets/Images/loader.gif"

export default function 
() {
    return (
        <div className="main_loader" >
            <img src={loader} alt="loader" className="loader_gif" />
        </div>
    )
}
