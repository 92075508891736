import React, { useState, useEffect } from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";


const Grievances = () => {

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [url, seturl] = useState("");
  const [problem, setproblem] = useState("");
  const [report, setreport] = useState("");
  const [image, setimage] = useState("");
  const [selectprob, setselectprob] = useState("");
  const [blankemail, setblankemail] = useState(false);
  const [clearimage, setclearimage] = useState("");
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  const [problemarr, setproblemarr] = useState([
    {
      id: 1,
      report: "problem1"
    },
    {
      id: 2,
      report: "problem2",
    }
  ]);

  useEffect(() => {
    scrollTop();


  }, []);


  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  const Submit = async () => {
    let userData = reactLocalStorage.getObject("userData");
    if (name != "" && email != "" && phone != "" && url != "" && report != "" && image != "") {

      if (isEmailFromatValid) {
        setisEmailFromatValid(true)
      }
      else {

        let data = new FormData();
        data.append("user_id", userData._id);
        data.append("name", name);
        data.append("email", email);
        data.append("phone", phone);
        data.append("url", url);
        data.append("report_against", selectprob);
        data.append("report_detail", report);

        data.append("attachment", image);
        //console.log("data", data);
        let result = await HttpClient.fileUplode("complaint", "POST", data);

        //console.log("POST", result);

        if (result && result.status) {
          let randomString = Math.random().toString(36);
          setclearimage(randomString);
          toast.success("Complaint Added Successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setname("");
          setemail("");
          setphone("");
          seturl("");
          setselectprob("");
          setreport("");
        } else {
          toast.warning(result.message, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      }
    } else {
      toast.warning("please fillup all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  };

  const changePic = async (event) => {
    //console.log("files", event.target.files[0]);
    setimage(event.target.files[0]);
  }
  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    //console.log(valid);
    setisEmailFromatValid(valid ? false : true);
  }

  return (
    <div>
      <section>
        <Jumbotron heading="Grievances" img={serviceJumboBg} />
      </section>
      <div className="Customer-service feedback">
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center my-4">
              <h2>Grievance</h2>
            </div>
          </div>
          <div className="row px-3">
            <div className="col-md-8  my-4 m-auto">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Person Name"
                    onChange={(val) => {
                      setname(val.target.value);
                    }}
                    value={name}
                  />
                </div>
                <div className="col">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={(val) => {
                      setemail(val.target.value)
                      if (val.target.value == "") {
                        setblankemail(true);
                        validateEmail(val.target.value);
                      } else {
                        setblankemail(false);
                        validateEmail(val.target.value);
                      }
                    }}
                    value={email}
                  />
                  {blankemail ?
                    <small style={{ color: "red" }}>
                      {" "}
                      Please enter email.{" "}
                    </small> :
                    isEmailFromatValid ? (
                      <small style={{ color: "red" }}>
                        {" "}
                        Please enter a valid email.{" "}
                      </small>
                    ) : null}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Contact Number"
                    onChange={(val) => {
                      //console.log("phone", phone);
                      setphone(val.target.value)
                    }}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    value={phone}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="URL"
                    onChange={(val) => {
                      seturl(val.target.value)
                    }}
                    value={url}
                  />
                </div>
              </div>
              <div className="form-group my-3">
                <label htmlFor="exampleFormControlSelect1">
                  Report Against
                </label>
                {/* <select className="form-control"
                  onChange={(val) => {
                    //console.log("hjh", val.target.value)
                    setselectprob(val.target.value)
                  }
                  }
                  id="exampleFormControlSelect1"
                >
                  <option >Select Problem</option>
                  {problemarr.map((item, index) => {

                    return (
                      <option value={item.report}>{item.report}</option>
                    );
                  })
                  }


                </select> */}
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={3}
                  onChange={(val) => {
                    setselectprob(val.target.value)
                  }}
                  value={selectprob}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">
                  Write Your Report
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={3}
                  onChange={(val) => {
                    setreport(val.target.value)
                  }}
                  value={report}
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="file"
                  className="form-control-file btn"
                  id="exampleFormControlFile1"
                  onChange={changePic}
                  key={clearimage || ""}
                />
              </div>
              <div className="text-center my-4">
                <button className="btn submit_btn"
                  onClick={Submit}
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Grievances;
