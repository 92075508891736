import React from 'react'

function Addservices() {
  return (
    <div className="Add_service  ">
      <div className="row mt-3">
        <div className="col-md-12 text-center">
          <h1>Add Services</h1>
        </div>
        <div className="col-md-8 mx-auto">
          <div className="form-group my-4">
            <label htmlFor="name">
              Service Name{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id="name"
              aria-describedby="nameHelp"
              placeholder="Service Name"
              defaultValue=""
            />
          </div>
          <div className="form-group my-4">
            <label htmlFor="currency">
              Currency{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id="name"
              aria-describedby="nameHelp"
              placeholder="Currency"
              readOnly=""
              defaultValue="INR"
            />
          </div>
          <div className="form-group my-4">
            <label htmlFor="price">
              Service Price{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              inputMode="numeric"
              className="form-control _borderRed"
              id="price"
              aria-describedby="emailHelp"
              placeholder="Service Price"
              defaultValue=""
            />
          </div>

          <div className="form-group my-4" />
          <div className="row">
            <div className="col-md-6  ">
              <div className="form-group">
                <select
                  className="form-control _borderRed"
                  id="exampleFormControlSelect1"
                >
                  <option value="">Select Catagory</option>
                  <option value="6204f6f56dcb6904acb6a894">Spell Casting</option>
                  <option value="62aa1f66fdd9199c9439e35b">Vastu</option>
                  <option value="63c7f05cec39ee4a940f9bbd">
                    Birth Chart Reading
                  </option>
                  <option value="63c7f0e8ec39ee4a940f9bf0">Blind Reading</option>
                  <option value="63f26770d5b792d7ddba452b">
                    Electric Engineer
                  </option>
                </select>
                <span className="required" style={{ color: "red" }}>
                  *
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                  className="form-control _borderRed"
                  id="exampleFormControlSelect1"
                >
                  <option>Select Sub Catagory</option>
                </select>
                <span className="required" style={{ color: "red" }}>
                  *
                </span>
              </div>
            </div>
          </div>
          <div className="form-group my-4">
            <button type="button" className="btn preview_btn">
              Highlights
            </button>
            <br />
            <br />
            <div>
              <div className="d-flex justify-content-between add-product-field my-3">
                <input
                  type="text"
                  name="highlight"
                  className="form-control _borderRed"
                  placeholder="Add Highlights"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <h5>
                Service Image{" "}
                <span className="required" style={{ color: "red" }}>
                  *
                </span>
              </h5>
            </div>
            <div className="choose_file my-4">
              <input type="file" multiple="" className="input_file" />
              Choose File
            </div>
          </div>
          <div className="d-flex justify-content-between my-5">
            <button type="button" className="btn publish_btn">
              Publish
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addservices