import React from "react";
import { Link } from "react-router-dom";
import HttpClient from "./../utils/HttpClient";

import JohnImg from "../Default/Assets/Images/ProviderSlider/John Doe.png";
import rating from "../Default/Assets/Images/ProviderSlider/rating.png";
import Dashboard from "../Default/Assets/Images/blank-profile-picture-973460_640.png";


const Provider = (props) => {
  //console.log("service provider", props.data);
  const serviceProvider = props.data;
  return (
    <div className="bg_lineCover">
      {/* <div className="py-3 px-2 "> */}
      <div className="">
        <div className="text-center providerCon">
          <Link to={"/provider-details/" + serviceProvider._id}>
            {serviceProvider.profile != "" && serviceProvider.hasOwnProperty("profile") ? (
              <img
                src={HttpClient.IMG_URL + serviceProvider.profile}
                className="img-fluid mx-auto mb-3"
                alt="img"
              />
            ) : (
              <img src={JohnImg} className="img-fluid mx-auto mb-3" alt="img" />
            )}
          </Link>
          {/* <img src={JohnImg} className="img-fluid mx-auto mb-3" alt="img" /> */}
          <h4 className="mb-0">
            {serviceProvider.firstName + " " + serviceProvider.lastName}
          </h4>
          <h5 className="mb-2">{serviceProvider.about} </h5>
          {/* <img src={rating} className="img-fluid mx-auto mb-2" alt="img" /> */}
          {/* <div>
          <Link to="/service-provider">Read More</Link>
        </div> */}
        </div>
      </div>
    </div>

  );
};

export default Provider;
