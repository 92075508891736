import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HttpClient from "../../../utils/HttpClient";

function About() {
  React.useEffect(() => {
    // window.scrollTo(0, 0);
    fetchAboutBanner();
  }, []);
  const [aboutBanner, setaboutBanner] = useState([]);

  const fetchAboutBanner = async () => {
    let result = await HttpClient.requestData("bannerAbout", "GET");
    if (result && result.status) {
      // //console.log("about resultdata------", result.data[0]);
      setaboutBanner(result.data[0]);
    }
  };
  return (
    <div className="About">
      <div className="container">
        <div className="row">
          <div className="col-md-9 mx-auto text-center">
            <h1 className="secTionHeading pt-5  designBtm2">
              {/* About MySpiritualConnect */}
              {aboutBanner.heading1}
            </h1>
            <h5 dangerouslySetInnerHTML={{__html: aboutBanner.content1}}>
              {/* {aboutBanner.content1} */}
              {/* My SpiritualConnect the belief the positions of the stars and
              movements of the planets of the Solar System, and also asteroids
              and other stars, but not as much, have an influence on the events,
              lives, and behavior of people. */}
            </h5>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-md-6 ml-auto  text-white mb-5">
            <h3 className="mb-3">
              {/* MySpiritualConnect Revels  */}
              {aboutBanner.heading2}
              </h3>
            <p dangerouslySetInnerHTML={{__html: aboutBanner.content2}}>
              {/* {aboutBanner.content2} */}
              {/* My SpiritualConnect the belief the positions of the stars and
              movements of the planets of the Solar System, and also asteroids
              and other stars, but not as much, have an influence on the events,
              lives, and behavior of people. */}
            </p>
          </div>
        </div>
        <div className="text-center pt-4 pb-5">
          <Link to="/about-us" className="btn PrimaryBtn">
            <span> Read More</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default About;
