import React, { useState, useEffect } from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
import HttpClient from "./../../utils/HttpClient";
const SafetyGuide = () => {
  const [isloading, setisloading] = useState(false);
  const [safety, setsafety] = useState("");

  useEffect(() => {
    scrollTop();
    fetchData();
  }, [])

  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const fetchData = async () => {
    let result = await HttpClient.requestData("safety-guide", "GET");
    //console.log("resut", result);
    if (result && result.status) {
      setisloading(true);
      setsafety(result.data[0].description)


    }
    else {

    }
  }
  return (
    <div>
      <section>
        <Jumbotron heading="Safety Guide" img={serviceJumboBg} />
      </section>
      <div className="feedback">
        <div className="container-fluid">
          <div className="row px-3">
            {/* 
                <div className="col-md-12 text-center my-4">
                  <h2>Safety Guide</h2>
                </div>
            */}
            {isloading ?
              <div className="col-md-8 m-auto mt-3">
                <p className="text-dark" dangerouslySetInnerHTML={{__html: safety}}>
                  {/* {safety} */}
                </p>
              </div> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetyGuide;
