import React, { useState, useEffect } from "react";
import Firebase, { fsdb } from "../firebase";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import BellIcon from "react-bell-icon";
import HttpClient from "../../../utils/HttpClient";
import John from "../../../Default/Assets/Images/ProviderSlider/John Doe.png";

const Userlist = ({ chatlist, setRoomId, setRecieverId, setSenderId }) => {
  const [messageTab, setMessageTab] = useState("Inbox");
  const [active, setActive] = useState('');
  // const [chatlist, setChatlist] = useState([]);
  // const [count, setcount] = useState(props.count);
  // const [chatData, setChatData] = useState({
  //   userId: '',
  //   name: ''
  // })

  // useEffect(() => {
  //console.log("count", props.count);
  // fetchList();
  // checkUser();
  // getChats();
  // }, []);


  // const checkUser = () => {
  //   let user = reactLocalStorage.getObject("userData");
  //   console.log("user", user);
  //   if (user != null && Object.keys(user).length > 0) {
  //     setChatData((prev) => {
  //       return ({
  //         ...prev,
  //         userId: user._id,
  //         name: user.firstName + " " + user.lastName,
  //       }
  //       )
  //     });
  //   }
  // }

  // const getChats = () => {
  //   let myId = reactLocalStorage.getObject("userData")._id;
  //   // console.log("myId", myId);
  //   var docRef = fsdb
  //     .collection("chat_users")
  //     .where("receiver_id", "==", myId)
  //     .onSnapshot((querySnapshot) => {
  //       // console.log('querySnapshot',querySnapshot)
  //       let allChaters = [];
  //       querySnapshot.forEach((doc) => {
  //         allChaters.push(doc.data());
  //       });
  //       setChatlist(allChaters)
  //       console.log('allChaters', allChaters)
  //     })
  // }



  // const fetchList = async () => {
  //   let userData = reactLocalStorage.getObject("userData");
  //console.log("usd", userData);

  //     const dbRef = Firebase.database().ref();
  // dbRef.child("chatlist").child("611b8bf0b194f2414fcd9812").get().then((snapshot) => {
  //   if (snapshot.exists()) {
  //     //console.log(snapshot.val());
  //   } else {
  //     //console.log("No data available");
  //   }
  // }).catch((error) => {
  //   console.error(error);
  // });

  //   Firebase.database().ref().child("chatlist").child(userData._id + "U").on("value", (snapshot) => {
  //     if (snapshot.exists()) {
  //       //console.log("snapshot.val()", snapshot.val());
  //       let sl = 1;
  //       let arr = [];
  //       snapshot.forEach((ele) => {
  //         //console.log("val", ele.val());
  //         let dom = {
  //           sl: sl,
  //           name: ele.val().name,
  //           lastMsg: ele.val().lastMsg,
  //           lastMsgTime: moment(ele.val().lastMsgTime).fromNow(),
  //           roomid: ele.val().roomid,
  //           userId: ele.val().userId,
  //           forimage: ele.val().forimage,
  //           sessionclose: ele.val().sessionclose,
  //           lastMsgBy: ele.val().lastMsgBy

  //         }
  //         arr.push(dom);
  //         //console.log("arr", arr);

  //       }

  //       );
  //       setChatlist(arr);

  //     } else {
  //       //console.log("No data available");
  //     }
  //   })

  // }


  // const Users = [0, 1, 2, 3, 4];
  // const callback = async (item) => {
  //   //console.log("item,item", item);
  //   setcount(prevstate => prevstate + 1);
  //   let count1 = count;
  //   //console.log("count", count1);
  //   props.callchat(item.roomid, item.name, item.userId, count1, item.sessionclose);
  //   props.callwrite(item.roomid, item.name, item.userId, count1, item.sessionclose)
  // }

  return (
    <div className="Userlist">
      <div className="MessageTypeBox">
        <span
          className={
            messageTab === "Inbox"
              ? "w-100 messageType active"
              : "w-50 messageType"
          }
        // onClick={() => setMessageTab("Inbox")}
        >
          Inbox
        </span>
        {/* <span
          className={
            messageTab === "Draft"
              ? "w-50 messageType active"
              : "w-50 messageType"
          }
          onClick={() => setMessageTab("Draft")}
        >
          Draft
        </span> */}
      </div>
      {/* <div className="SearchBar ">
        <input type="text" className="form-control" placeholder="Search" />
        <i class="fas fa-search"></i>
      </div> */}
      {chatlist.length > 0 ? chatlist.map((item, index) => {
        return (
          <div className="d-flex border-bottom px-2 py-3" key={index}>
            {/* <div className="imgCon">

              <img
                src={John}
                className="img-fluid rounded-circle  "
                alt="img"
              />
            </div> */}
            <div className="mx-3 my-auto"
              // onClick={() => callback(item)}
              onClick={() => {
                setRoomId(item.room_id)
                setSenderId(item.sender_id)
                setRecieverId(item.receiver_id)
              }
              }
            >
              <h6
                style={{
                  cursor: 'pointer',
                  color: `${active === index ? '#b214b2' : ''}`
                }}
                onClick={() => {
                  setActive(index)
                }}
              >
                {item.sender_name}
              </h6>

              {item.lastMsg != "" ?
                <p className="mb-0">
                  {item.lastMsg}{" "}
                  {item.lastMsgTime}
                </p>
                :
                <p>
                  <img
                    src={HttpClient.IMG_URL + item.forimage}
                    height="50px"
                    width="50px"
                    alt="image"
                  />{" "}
                  {item.lastMsgTime}
                </p>
              }

              {/* <BellIcon
                width="40"
                active={item.lastMsgBy == "Seller" ? true : false}
                animate={item.lastMsgBy == "Seller" ? true : false}
                color={
                  item.lastMsgBy == "Seller" ? "#FF3333" : "#0c89e9"
                }

                height="20"
              /> */}

            </div>
          </div>
        );
      }) : <div className="d-flex border-bottom px-2 py-3" style={{ justifyContent: "center" }}>No Chats yet </div>}
    </div>
  );
};

export default Userlist;
