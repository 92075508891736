import React, { useState } from "react";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";

const ApplyModal = (props) => {
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [experiance, setexperiance] = useState("");
  const [description, setdescription] = useState("");
  const [image, setimage] = useState("");
  const [clearimage, setclearimage] = useState("");
  const experiances = [
    { label: "0-1 Year", value: "0-1 Year" },
    { label: "1-3 Years", value: "1-3 Years" },
    { label: "3-5 Years", value: "3-5 Years" },
    { label: "5-7 Years", value: "5-7 Years" },
    { label: "7+ Years", value: "7+ Years" },
  ];

  const changePic = async (event) => {
    //console.log("files", event.target.files[0]);
    //console.log("type", event.target.files[0].type);
    if (
      event.target.files[0].type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      event.target.files[0].type == "application/pdf"
    ) {
      setimage(event.target.files[0]);
    } else {
      toast.warning("Invalid File Format", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleChangeExperience = (event) => {
    //console.log(event.target.value);
    let experiencedata = event.target.value;
    setexperiance(experiencedata);
  };

  const Submit = async () => {
    let userData = reactLocalStorage.getObject("userData");
    if (fname !== "" && lname != "" && email != "") {
      if (image != "") {
        let data = new FormData();
        data.append("job_id", props.jobData._id);
        data.append("firstName", fname);
        data.append("lastName", lname);
        data.append("email", email);
        data.append("about_yourself", description);
        data.append("experience", experiance);
        data.append("contact", phone);
        data.append("cv", image);
        //console.log("data", data);
        let result = await HttpClient.fileUplode("apply-to-job", "POST", data);

        //console.log("POST", result);

        if (result && result.status == true) {
          let randomString = Math.random().toString(36);
          setclearimage(randomString);
          toast.success("Application Submitted Successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setfname("");
          setlname("");
          setemail("");
          setphone("");
          setimage("");
          setdescription("");
          setexperiance("");
        } else {
          toast.warning(result.message, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.warning("Please Select File.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("please select all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div>
              {" "}
              <h5
                className="modal-title text-center mb-4"
                id="exampleModalLabel"
              >
                Apply for Job
                <button
                  type="button"
                  className="close mx-3"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => props.callBackmodal(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </h5>
            </div>
            <form>
              <div className="form-group">
              <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First name"
                  onChange={(val) => {
                    setfname(val.target.value);
                  }}
                  value={fname}
                />
              </div>
              <div className="form-group">
              <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last name"
                  onChange={(val) => {
                    setlname(val.target.value);
                  }}
                  value={lname}
                />
              </div>
              <div className="form-group">
              <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  onChange={(val) => {
                    setemail(val.target.value);
                  }}
                  value={email}
                />
              </div>

              <div className="form-group">
              <label>Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Phone Number"
                  maxLength={10}
                  onChange={(val) => {
                    setphone(val.target.value);
                  }}
                  value={phone}
                />
              </div>
              <div className="form-group">
                {/* <input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Experience"
                  maxLength={10}
                  onChange={(val) => {
                    setexperiance(val.target.value);
                  }}
                  value={experiance}
                /> */}
                <label>Select Experience</label>
                <select
                  className="form-control"
                  onChange={handleChangeExperience}
                  value={experiance}
                >
                  <option value="">Select Experience</option>
                  {experiances.map((item, index) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                </select>
              </div>
              <div className="form-group">
              <label>About</label>
                <textarea
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="About"
                  onChange={(val) => {
                    setdescription(val.target.value);
                  }}
                  value={description}
                ></textarea>
              </div>
              <div className="d-flex">
              <div className="choose_file d-flex">
                <input
                  type="file"
                  className="input_file"
                  // accept=".doc, application/pdf"
                  id="exampleFormControlFile1"
                  onChange={changePic}
                  key={clearimage || ""}
                />
                Choose CV
              </div>&nbsp;
              {image != "" ? (
                <div className="form-group">
                <input
                  type="text"
                  placeholder="File Name"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={image.name}
                />
              </div>
              ) : null}
              
              </div>
            </form>
          </div>
          <div className="text-center mb-4">
            <button type="button" className="btn PrimaryBtn" onClick={Submit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyModal;
