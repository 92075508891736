import Firebase1 from 'firebase/app';
import firestore from 'firebase/firestore'
import database from 'firebase/database';
const settings = { timestampsInSnapshots: true };

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyCaLPxEsafQP5bZGfuCmBJigZXPkcMI14w",
  authDomain: "myspiritual-2f910.firebaseapp.com",
  databaseURL: "https://myspiritual-2f910-default-rtdb.firebaseio.com",
  projectId: "myspiritual-2f910",
  storageBucket: "myspiritual-2f910.appspot.com",
  messagingSenderId: "928631521452",
  appId: "1:928631521452:web:c77b620fbfc46450311f09",
  measurementId: "G-ZEYPZ55LLL"
};
Firebase1.initializeApp(firebaseConfig);

Firebase1.database()

Firebase1.firestore().settings(settings);
export const fsdb = Firebase1.firestore();
export default Firebase1;

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const auth = getAuth(app);


// export const database = Firebase1.database();