import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HttpClient from "../../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Provider from "../../../Component/Provider";
import { reactLocalStorage } from "reactjs-localstorage";

import { Link } from "react-router-dom";

const ServiceProvider = () => {
  const [provider, setprovider] = useState([]);
  const userDataa = reactLocalStorage.getObject("userData");

  useEffect(() => {
    fetchProvider();
  }, []);

  const fetchProvider = async (userid) => {
    let result = await HttpClient.requestData("top-sellers", "GET");
    //console.log("serviceList", result);
    if (result && result.status) {
      //console.log(result.data);
      // let cur_data = result.data.filter(
      //   (item) => item.user_id != userid
      // );
      // //console.log("data", cur_data);

      setprovider(result.data);
    } else {
    }
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const alertt = () => {
    toast.error("You have to login first", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const [providers, setProvider] = useState([1, 2, 3, 4, 5, 6]);
  return (
    <div className="ServiceProvider">
      <div className="text-center">
        <h1 className="secTionHeading DesignBtm ">Top Service Providers</h1>
        <p>Choose From Our Top Rated service provider</p>
      </div>
      <div className="container mb-5">
        <Slider {...settings}>
          {/* {providers.map((item, index) => {
            return (
              <div className="" key={index}>
                <Provider />
              </div>
            );
          })} */}
          {provider.length > 0 &&
            provider.map((item, index) => {
              return <Provider data={item} />;
            })}
        </Slider>
      </div>
      <div className="text-center">
        {Object.keys(userDataa).length ? (
          <Link
          to="/service-provider"
          className="btn PrimaryBtn btn-lg"
          style={{ fontSize: "15px", textTransform: "capitalize" }}
        >
          <span>View All</span>
        </Link>
        ) : (
          <Link
          onClick={alertt}
          className="btn PrimaryBtn btn-lg"
          style={{ fontSize: "18px" }}
        >
          <span>VIEW ALL</span>
        </Link>
        )}
        
      </div>
    </div>
  );
};

export default ServiceProvider;
