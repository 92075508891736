import React, { useState, useEffect } from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
import Slider from "react-slick";
import HttpClient from "../../utils/HttpClient";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";
import customer1 from "../../Default/Assets/Images/customer1.png";
import customer2 from "../../Default/Assets/Images/customer2.png";
const Testimonial = () => {
  const [testimonialData, settestimonialData] = useState([]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    getTestimonial();
  }, []);
  const getTestimonial = async () => {
    let result = await HttpClient.requestData("cms/testimonials", "GET");
    //console.log("cms/testimonials Result", result);
    if (result && result.status) {
      settestimonialData(result.data);
    }
  };
  var settings = {
    dots: true,

    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const users = [
    { img: customer1 },
    { img: customer2 },
    { img: customer1 },
    { img: customer2 },
    { img: customer1 },
    { img: customer2 },
  ];
  return (
    <div>
      <section>
        <Jumbotron heading=" Testimonials" img={serviceJumboBg} />
      </section>
      <div className="Quotation px-4" style={{ padding: "85px 0 200px 0" }}>
        {/* <div className="text-center">
          <h1 className="secTionHeading mb-5 designBtm2">
            What Our Customers Say
          </h1>
        </div> */}
        <div className="container ">
          <div className="row">
            {testimonialData.length
              ? testimonialData.map((item, index) => {
                  return (
                    <div className=" col-md-6 my-3 px-2" key={index}>
                      <div className="Quotes">
                        <i class="fas fa-quote-left mr-3"></i>
                        <p>{item.description}</p>
                      </div>
                      <div className="imgCon">
                        <img
                          src={
                            HttpClient.IMG_URL + item.image
                          }
                          className="img-fluid rounded-circle"
                          alt="img"
                        />
                      </div>
                      <div
                        className="text-center"
                        style={{ marginTop: "-20px" }}
                      >
                        <h3>{item.heading}</h3>
                      </div>
                    </div>
                  );
                })
              : null}
            {/* {users.map((item, index) => {
              return (
                <div className=" col-md-4 my-3 px-2" key={index}>
                  <div className="Quotes">
                    <i class="fas fa-quote-left mr-3"></i>
                    <p>
                      Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                      consequat ipsum, nec sagittis sem nibh id elit.
                    </p>
                  </div>
                  <div className="imgCon">
                    <img
                      src={item.img}
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </div>
                  <div className="text-center" style={{ marginTop: "-20px" }}>
                    <h3>Abcdefg Hijklmn</h3>
                    <h5>There are many variation</h5>
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
