import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "./../../utils/HttpClient";
import Jumbotron from "../../Component/Jumbotron";
import Footer2 from "../../Default/Footer2/index";
import Navbar2 from "../../Default/Navbar2/index";
import Faqs from "../../Component/Faqs";
import CountUp from "react-countup";
import AboutIntroImg from "../../Default/Assets/Images/AboutUs/AboutIntroImg.png";
import AboutFaqImg from "../../Default/Assets/Images/AboutUs/AboutFaqImg.png";
import BlogJumboBg from "../../Default/Assets/Images/Blog/BlogJumboBg.png";
import aboutbgleft from "../../assets/aboutbgleft.png"
const Index = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [result, setresult] = useState("");
  const [image, setimage] = useState("");
  const [faqData, setfaqData] = useState([]);
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    scrollTop();
    fetchFaq();
    fetchData();
  }, []);

  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchData = async () => {
    let result = await HttpClient.requestData("about-us", "GET");
    //console.log("about", result);
    if (result && result.status) {
      setresult(result.data[0].description);
      setimage(result.data[0].image)
    }
  };
  const fetchFaq = async () => {
    let result = await HttpClient.requestData("faq", "GET");
    //console.log("faq", result);
    if (result && result.status) {
      setfaqData(result.data);
    }
  };
  const counter = [
    { number: "123", title: "Trusted By MIllion Clients", type: true },
    { number: "16", title: "Years of experience" },
    { number: "13", title: "Types of Horoscopes", type: true },
    { number: "16", title: "Qualified Astrologers" },
    { number: "36", title: "Success Horoscope", type: true },
  ];
  const AboutFaq = [0, 1, 2, 3];
  //   const [ans2, setAns2] = useState(false);
  //   const [ans3, setAns3] = useState(false);
  //   const [ans4, setAns4] = useState(false);
  return (
    <div className="AboutUs ">
      <ReactTitle title="MySpiritualConnent - About Us" />
      {/* <section>
        <Jumbotron heading="ABOUT US" img={BlogJumboBg} />
      </section> */}
      <section id="title-about">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p className="title-wrapper">About Us</p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid py-4">
        <div className="row px-4">
          <div className="col-md-3 about_ban_head">
          <h4 >Who are we ?</h4>
            <div className="Pink_layout d-lg-block d-none">
              <img src={aboutbgleft} alt="" className="img-fluid" />
            </div>
            <div className="about_ban_img">
              <img className="img-fluid" src={HttpClient.IMG_URL + image} alt="img" />
            </div>

          </div>
          <div className="col-md-9 d-flex align-items-center">
            <div className="">
              <h4 className="mb-4">
                MYSPIRITUALCONNECT REVEALS THE WILL OF GOD
              </h4>
              <p className="txtstyle" dangerouslySetInnerHTML={{ __html: result }}>
                {/* Fuga consequatur deserunt veritatis asperiores delectus ab, iste
                expedita ad voluptatibus. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Odit voluptate asperiores ipsum cum explicabo
                minima labore repellat, numquam officiis rerum recusandae
                blanditiis enim. Fugiat ipsam illum perspiciatis non est atque
                id cumque! Quidem placeat explicabo temporibus accusantium
                sapiente deleniti enim. Excepturi sit et optio quasi, vero
                deleniti quidem eaque nam. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Fugit quae, ipsum autem et a
                perspiciatis, atque aliquam laboriosam nesciunt nostrum
                accusantium distinctio. Modi et excepturi cupiditate laboriosam
                veritatis voluptates quam aliquid eius facilis ut officia dolore
                reprehenderit eligendi, consequuntur possimus nihil laudantium
                dolor facere labore voluptas illo quod similique. */}
                {/* {result} */}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="d-lg-flex">
          {counter.map((item, index) => {
            return (
              <div
                className="countBox"
                key={index}
                style={{ backgroundColor: item.type ? "#75429c" : "#623485" }}
              >
                <div className="counterCircle mb-4"> */}
      {/* <h3 className="mb-0 font-weight-bold">{item.number}</h3> */}
      {/* <CountUp
                    start={0}
                    end={item.number}
                    duration={10}
                    // suffix={item.sufix ? " +" : ""}
                  ></CountUp> */}
      {/* </div>
                <p className="mb-0">{item.title}</p>
              </div>
            );
          })}
        </div>
      </section> */}


      {/* <section className="AboutVideo">
        <div className="VideoContent px-4">
        </div>
        {playVideo ? (
          <div className="video_part">
            <button className="close_btn" onClick={() => setPlayVideo(false)}>
              <i class="fas fa-times"></i>
            </button>
            <video autoPlay height="475px">
              <source src="https://www.w3schools.com/tags/movie.mp4" />
            </video>
          </div>
        ) : null}
      </section> */}
      <section className="Aboutfaq py-5 px-2">
        <div className="container-fluid pt-1">
          <div className="row">
            <div className="col-md-12 text-center">
              <h5 className="mb-4 pl-3">OUR MYSPIRITUALCONNECT</h5>
              <img src={AboutFaqImg} className="img-fluid" alt="img" />
            </div>
            <div className="col-md-12">
              <h5 className="mb-2 pb-1 pl-3 text-center">FAQ's</h5>
              <Faqs data={faqData} />
            </div>
          </div>
        </div>
      </section>
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
