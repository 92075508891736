import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "../../utils/HttpClient";

import Jumbotron from "../../Component/Jumbotron";
import ServiceCard from "../../Component/serviceCard3";
import Footer2 from "../../Default/Footer2/index";
import Navbar from "../../Default/Navbar/index";
import FavouriteShop from "./Component/FavouriteShop";
import { reactLocalStorage } from "reactjs-localstorage";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import service1 from "../../Default/Assets/Images/Service/service1.png";
import service2 from "../../Default/Assets/Images/Service/service2.png";
import service3 from "../../Default/Assets/Images/Service/service3.png";
import service4 from "../../Default/Assets/Images/Service/service4.png";
import circleShape from "../../Default/Assets/Images/Service/circleShape.png";

const Index = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const services = [
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
    { imags: service4 },
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
    { imags: service4 },
  ];
  const reviews = [0, 1, 2];
  const [provider, setprovider] = useState([]);
  const [login, setlogin] = useState(false);
  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = async () => {
    let getAccount = reactLocalStorage.getObject("userData");
    if (
      getAccount &&
      getAccount != null &&
      Object.keys(getAccount).length > 0
    ) {
      setlogin(true);

      fetchProvider(getAccount._id);
    } else {
    }
  };

  const fetchProvider = async (userid) => {
    let result = await HttpClient.requestData("top-sellers", "GET");
    //console.log("top-sellers", result);
    if (result && result.status) {
      //console.log(result.data);
      // let cur_data = result.data.filter(
      //   (item) => item.user_id != userid
      // );
      // //console.log("data", cur_data);

      setprovider(result.data);
    } else {
    }
  };
  return (
    <div className="Service_Provider">
      <ReactTitle title="MySpiritualConnent - Service Provider" />
      <section>
        <Jumbotron heading="Top Service Providers" img={serviceJumboBg} />
      </section>
      <section className="container py-1 px-4">
        
        {provider.length > 0 &&
          provider.map((item, index) => {
            return <FavouriteShop data={item} />;
          })}
      </section>
      {/* <section className=" ShopService">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-4 mb-3 mb-md-0">
              <div style={{ position: "relative" }}>
                <input
                  class="form-control mr-sm-2 search"
                  type="search"
                  placeholder="Search Services"
                  aria-label="Search"
                />
                <i class="fas fa-search searchIcon"></i>
              </div>
            </div>
            <div className="col-md-4 ml-md-auto text-md-right text-center mb-md-0 mb-4">
              <button className="btn PrimaryBtn" style={{ fontSize: "18px" }}>
                Book an Appointment
              </button>
            </div>
          </div>
          <h2 className="px-3 text-center text-md-left">Shop Services</h2>
          <div className="container-fluid">
            <div className="row">
              {services.map((item, index) => {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6 my-4">
                    <ServiceCard img={item.imags} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="py-4 text-center">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">
                      <i class="fas fa-angle-left"></i>
                    </span>
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link " href="#">
                    01
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    04
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">
                      <i class="fas fa-angle-right"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section> */}
      {/* <section className="review">
        <h2 className="text-center mb-5">Review for the shop</h2>
        <div className="container">
          {reviews.map((item, index) => {
            return (
              <div className="row mb-4" key={index}>
                <div className="col-md-2 text-md-right">
                  <img
                    src={circleShape}
                    className="rounded-circle img-fluid mb-3 mb-md-0"
                    alt="img"
                  />
                </div>
                <div className="col-md-10">
                  <h3>T boutique</h3>
                  <div className="mb-2">
                    <i class="fas fa-star star mr-2"></i>
                    <i class="fas fa-star star mr-2"></i>
                    <i class="fas fa-star star mr-2"></i>
                    <i class="fas fa-star star mr-2"></i>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Veniam fugiat ex dolor repellat quibusdam error facere illum
                    dolores. Debitis, consectetur.
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </section> */}
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
