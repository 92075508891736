import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HttpClient from "./../../utils/HttpClient";
import logo from "../Assets/Images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";
import play_store from "../../assets/play_store.png"
import app_store from "../../assets/app_store.png"
import aboutbgleft from "../../assets/aboutbgleft.png"
function Index() {
  const [fb, setfb] = useState("");
  const [link, setlink] = useState("");
  const [twt, settwt] = useState("");
  const [you, setyou] = useState("");
  const [result, setresult] = useState({});
  const [login, setlogin] = useState(false);

  var loc = window.location.pathname;
  useEffect(() => {
    getUserData();
    socialMedia();
  }, []);

  const getUserData = async () => {
    let getAccount = reactLocalStorage.getObject("userData");
    if (
      getAccount &&
      getAccount != null &&
      Object.keys(getAccount).length > 0
    ) {
      setlogin(true);
    } else {
    }
  };

  const socialMedia = async () => {
    let result = await HttpClient.requestData("social-media-info", "GET");
    //console.log("object", result);
    if (result && result.status) {
      setfb(result.data[0].facebook);
      setyou(result.data[0].youtube);
      settwt(result.data[0].twitter);
      setlink(result.data[0].linkedin);
      //console.log("lkl", result.data[0]);
    }
    getContact();
  };

  const getContact = async () => {
    let result = await HttpClient.requestData("contact-us-info", "GET");
    //console.log("object", result);
    if (result && result.status) {
      setresult(result.data[0]);
    }
  };
  return (
    <div
      className={`Footer ${loc === "/home" || loc === "/contact" ? "second" : "second"
        }`}
    >
      <div className="container">
        <ToastContainer />
        <div className="row">

          <div className="col-12">
            <div className="footer_store d-flex justify-content-center mb-3  pt-3">
              <a href="#" alt="">
                <img src={play_store} alt="IOS App" className="img-fluid" />
              </a>
              <a href="#   " alt="">
                <img src={app_store} alt="IOS App" className="img-fluid" />
              </a>
            </div>
            <h3 className="mb-4">My Spiritual Connect</h3>
            <p className="mb-4 text-light" style={{ opacity: "0.75" }}>
              MSC is a platform where users can find out about all kinds of
              spiritual healing practices that can be performed remotely since
              energy is boundless.
            </p>
          </div>
        </div>
        <div className="row pt-5 px-4">
          <div className="col-lg-3 col-md-6 p-4 ">
            {/* <img
              src={logo}
              className="img-fluid mb-4"
              alt="img"
              style={{ width: "234px" }}
            /> */}


            {/* <div className="mb-4">
              <Link className="readMore" to="/">
                Read More
              </Link>
            </div> */}

            <div className="mt-3 mt-md-0">
              <h4>Quick Links</h4>
              <hr className="footer_hr"></hr>
              <ul>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                {/* <li>
                    <Link to="/seller-panel">Seller Panel</Link>
                  </li> */}
                <li>
                  <Link to="/services">Our Service</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  {login ? (
                    <Link to="/feedback">Feedback</Link>
                  ) : (
                    <Link
                      onClick={() => {
                        toast.warning("Please Login First to give Feedback", {
                          position: "bottom-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }}
                    >
                      Feedback
                    </Link>
                  )}
                </li>

                <li>
                  <Link to="/testimonials">Testimonials</Link>
                </li>
              </ul>
            </div>

          </div>
          <div className="col-lg-3 col-md-6  py-4">
            <div className="d-lg-flex  justify-content-between">

              <div className="mt-3 mt-md-0">
                <h4>Legal Links</h4>
                <hr className="footer_hr"></hr>
                <ul>
                  <li>
                    <Link to="/item-condition">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  {/* <li>
                    <Link to="/customer">Customer Services</Link>
                  </li> */}

                  <li>
                    <Link to="/legal-notice">Legal Notice</Link>
                  </li>

                  {/* <li>
                    {login ? (
                      <Link to="/grievances">Grievances</Link>
                    ) : (
                      <Link
                        onClick={() => {
                          toast.warning("Please Login First to give Feedback", {
                            position: "bottom-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        }}
                      >
                        Grievances
                      </Link>
                    )}
                  </li> */}
                  <li>
                    <Link to="/safety-guide">Safety Guide</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-lg-flex  mt-5 justify-content-between">
              <div className="mt-3">
                <h4 style={{ minWidth: "137px" }}>Others Link</h4>
                <hr className="footer_hr"></hr>
                <ul>
                  <li>
                    <Link to="/site-map">Site Map</Link>
                  </li>
                  <li>
                    <Link to="/career-with-us">Career With Us</Link>
                  </li>
                  {/* <li>
                    <Link to="/report-problem">Report a Problem</Link>
                  </li> */}
                  <li>
                    <Link to="/our-associate">The Team</Link>
                  </li>
                  {/* <li>
                    <Link to="/unsubscribe-us">Unsubscribe Us</Link>
                  </li> */}
                </ul>
              </div>
              {/* <div className="mt-3">
                <h4>Download Our Mobile App</h4>
                <ul>
                  <li>
                    <Link to="/..">Android App</Link>
                  </li>
                  <li>
                    <Link to="/..">iOS App</Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-4">
            <h4 className="mb-4">NEED OUR HELP</h4>
            <hr className="footer_hr"></hr>
            <p className="mb-2">
              If you need our help you can contact us from this number{" "}
              {result.phone}
            </p>
            <div className="text-left">
              <Link className="btn PrimaryBtn" to="/contact">
                <span>FREE QUOTE</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-4">
            <Link to="/contact">
              <h4 className="mb-4">CONTACT US TODAY</h4>
            </Link>
            <hr className="footer_hr"></hr>
            <p className="mb-3">Call Us :-{result.phone}</p>
            <p className="mb-3">
              Send an Email :-{" "}
              <a href={"mailto:" + result.email}> {result.email}</a>
            </p>
            <p className="mb-3">Visit Us at :- 123 Fake Street- London 12358</p>

            <div className="d-flex d-flex justify-content-between">
              <p style={{ marginBottom: 0 }}>Follow Us:-</p>
              <a href={fb} className="socialBox" target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href={twt} className="socialBox" target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
              <a href={you} className="socialBox" target="_blank">
                <i class="fab fa-youtube"></i>
              </a>
              <a href={link} className="socialBox" target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
            {/* <div class="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email Address..."
                aria-describedby="emailHelp"
              />
              <button className="btn  send-btn" type="button">
                
                <i className="fas fa-paper-plane"></i>
              </button>
            </div> */}
          </div>
        </div>

        <div className="row border-top pt-3 mt-3">
          <div className="col-md-12 text-center">
            <h6 className="text-white mb-0">
              © 2023{" "}
              <a className="text-light" href="/">
                MySpiritualConnent
              </a>
            </h6>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Index;
