import React, { useEffect, useState } from "react";
import Firebase, { fsdb } from "../firebase";
import moment from "moment";
import img from "../../../assets/pro.jpeg";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../../utils/HttpClient";
import John from "../../../Default/Assets/Images/ProviderSlider/John Doe.png";

import reciever from "../../../Default/Assets/Images/reciever.png";
import MessageWrite from "./MessageWrite";

const MessageDisplay = ({ chatlist, roomid }) => {
  // const [chatlist, setChatlist] = useState([]);
  const [userData, setuserData] = useState(
    reactLocalStorage.getObject("userData")
  );
  const [msgData, setMsgData] = useState([]);

  // useEffect(() => {
  //console.log("prev page data", props.count);
  //console.log("prev page data", props.name);
  //console.log("prev page data", props.roomid);
  //console.log("prev page data", props.userid);
  // fetchList();
  // }, [props.count]);

  // const fetchList = async () => {
  //   Firebase.database()
  //     .ref()
  //     .child("chat")
  //     .child(props.roomid)
  //     .child("messages")
  //     .on("value", (snapshot) => {
  //       if (snapshot.exists()) {
  //         //console.log("snapshot.val()", snapshot.val());
  //         let sl = 1;
  //         let arr = [];
  //         snapshot.forEach((ele) => {
  //           //console.log("val", ele.val());
  //           let dom = {
  //             sl: sl,
  //             name: ele.val().name,
  //             message: ele.val().message,
  //             send_time: moment(ele.val().send_time).fromNow(),
  //             roomid: ele.val().roomid,
  //             sender_id: ele.val().sender_id,
  //             msgimg: ele.val().msgimg,
  //           };
  //           arr.push(dom);
  //           //console.log("arr", arr);
  //         });
  //         setChatlist(arr);
  //       } else {
  //         //console.log("No data available");
  //       }
  //     });
  // };

  const getMessage = () => {
    fsdb
      .collection("chat")
      .doc(roomid)
      .collection("messages")
      .orderBy("time")
      .onSnapshot((querySnapshot) => {
        // console.log('querySnapshot',querySnapshot)
        let arr = [];
        querySnapshot.forEach((item) => {
          arr.push(item.data());
        })
        // console.log('arr',arr)
        setMsgData(arr);
      })
  }
  console.log('masData', msgData)

  const scrollToBottom = () => {
    let element = document.getElementById("messageScroll")
    element.scrollTop=element.scrollHeight;
    // console.log('heiggg',element.scrollHeight)
  }

  useEffect(() => {
    if (roomid) {
      getMessage();
    }
  }, [roomid])

  useEffect(()=>{
    // console.log('kkkkkkkkkk')
    scrollToBottom()
  },[msgData])

  // console.log('roomid', roomid)

  // console.log('maschat', chatlist, roomid)

  return (
    <div id="messageScroll" className="MessageDisplay py-5">
      {/* <button onClick={scrollToBottom}>click</button> */}
      {msgData.length > 0 &&
        msgData.map((item, index) => {
          //console.log("kjk", userData._id);
          return (
            <div
              
              className={
                item.sender_id == userData._id
                  ? "Chats sender my-4 py-3 d-flex align-items-center"
                  : "Chats reciever my-4 py-3 d-flex align-items-center"
              }
              key={index}
            >
              {/* <div className="mr-5">
                {item.sender_id == userData._id ? null : (
                  <img
                    src={John}
                    height="40px"
                    width="40px"
                    className="img-fluid rounded-circle"
                    alt="img"
                  />
                )}
              </div> */}

              {
                item.image
                  ?
                  <>
                    <div className="text">
                      <a href={item.msg} target="_blank">
                        <img
                          alt="img"
                          className="img-fluid p-2 Imgsended"
                          src={item.msg}
                        />
                      </a>
                    </div>
                    <span className="mb-0 small text-muted mx-2">
                      {moment(item.time).fromNow()}
                    </span>
                  </>
                  :
                  <>
                    <div className="text">
                    {item.msg.slice(0, 4) == "http" ? <a href={item.msg}>{item.msg}</a> : 
                        <p className="mb-0">{item.msg}</p>}
                      
                      </div>
                    {/* <div className="text">
                      <p className="pt-1 pb-2 px-2 mb-0">{item.msg}</p>
                    </div> */}
                    <br />
                    <span className="mb-0 small text-muted mx-2">
                      {moment(item.time).fromNow()}
                    </span>
                  </>
              }

              {/* {item.msg != "" && item.msgimg != "" ? (
                <div className="text">
                  <div>
                    <p className="pt-1 pb-2 px-2 mb-0">{item.msg}</p>
                    <a href={HttpClient.IMG_URL + item.msgimg} target="_blank">
                      <img
                        alt="img"
                        className="img-fluid p-2 Imgsended"
                        src={HttpClient.IMG_URL + item.msgimg}
                      />
                    </a>
                  </div>
                </div>
              ) : item.msg != "" && item.msgimg == "" ? (
                <div className="text">
                  <div className="d-flex justify-content-between px-2 py-1 border-bottom">
                    <h5 className="mb-0">{item.name}</h5>
                    <p className="mb-0">{item.time}</p>
                  </div>
                  <div>
                    <p className="pt-1 pb-2 px-2 mb-0">{item.msg}</p>
                  </div>
                </div>
              ) : (
                <div className="text">
                  <div className="d-flex justify-content-between px-2 py-1 border-bottom">
                    <h5 className="mb-0">{item.name}</h5>
                    <p className="mb-0">{item.send_time}</p>
                  </div>
                  <div>
                    <a href={HttpClient.IMG_URL + item.msgimg} target="_blank">
                      <img
                        alt="img"
                        className="img-fluid p-2 Imgsended"
                        src={HttpClient.IMG_URL + item.msgimg}
                      />
                    </a>
                  </div>
                </div>
              )} */}

            </div>
          );
        })

      }

    </div>
  );
};

export default MessageDisplay;
