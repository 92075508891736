import React, { useState, useEffect, useRef } from "react";
import { ReactTitle } from "react-meta-tags";
import ReactStars from "react-rating-stars-component";

import Jumbotron from "../../../Component/Jumbotron";
import Footer2 from "../../../Default/Footer2/index";
import Navbar2 from "../../../Default/Navbar2/index";
import Rating from "../Component/Rating";
import { reactLocalStorage } from "reactjs-localstorage";

import HttpClient from "./../../../utils/HttpClient";

import serviceJumboBg from "../../../Default/Assets/Images/Service/serviceJumboBg.png";
import service1 from "../../../Default/Assets/Images/Service/service1.png";
import RefundModal from "../Component/refund";
import { Row, Card, CardBody, Col, Input, Button } from "reactstrap";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ChatWithSeller from "./chatwithseller";
import OrderDelete from "./OrderDelete";
import ChatBox from "../../../Pages/ServiceDetails/Components/modal"

const Index = () => {
  const [isOpenmodal, setisopenmodal] = useState(false);
  const [orderDlt, setorderDlt] = useState(false);
  const [eachservicedetails, seteachservicedetails] = useState({});
  const [orderid, setorderid] = useState("");
  const [rating, setRating] = useState(false);
  const [userID, setuserID] = useState("");
  const [serviceId, setserviceId] = useState("");
  const [OrderId, setOrderId] = useState("");
  const [BookingId, setBookingId] = useState("");
  const [sellerid, setsellerid] = useState("");
  const [oderHistory, setOderHistory] = useState([]);
  const [sentorderdetails, setsentorderdetails] = useState({});
  const [isOpenmodal1, setisopenmodal1] = useState(false);
  const [index, setindex] = useState(0);
  const [sellerDetail, setSellerDetail] = useState(null)
  const userData = reactLocalStorage.getObject("userData");
  const componentRef = useRef();

  const CallBack = async (val) => {
    //console.log("val", val);
    setRating(val);
    if (val == false) {
      //console.log("in fasle modal");
      getOderHistory();
      window.location.href = "/my-orders";
    } else {
      setRating(false);
    }
  };

  useEffect(() => {
    getOderHistory();
    window.scrollTo(0, 0);
  }, []);

  const getOderHistory = async () => {
    let userData = reactLocalStorage.getObject("userData");
    //console.log("usd", userData);
    let result = await HttpClient.requestData(
      "myaccount/service-order-history/" + userData._id,
      "GET"
    );
    console.log("result of oder", result.data.cart_data);
    if (result && result.status) {
      setOderHistory(result.data);

      // //console.log("oderHistory", oderHistory);
      // //console.log("date", result.data[0].booking_date);
      // getDate(result.data.length > 0 ? result.data[0].booking_date : "");
    }
  };

  // const getDate = (date) => {
  //   return date = new Date().toDateString();
  // }

  const Callbackmsg = async (val) => {
    setisopenmodal(false);
    getOderHistory();
  };

  const toPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const Callbackmsg1 = async (val) => {
    setisopenmodal1(false);
    // props.updatedList(props.orderdetails, 2)
  };
  const Callbackmsg2 = async (val) => {
    setorderDlt(false);
    getOderHistory();
  };

  // console.log('oderHistory', oderHistory[0]?.cart_data)
  return (
    <div className="OrderHistory">
      <ReactTitle title="MySpiritualConnent - My Order" />
      <section>
        <Jumbotron heading=" ORDER HISTORY" img={serviceJumboBg} />
      </section>
      <section className="py-5">
        <div className="container pb-5">
          {/* <h2>Order History</h2>  */}
          {oderHistory.length > 0 ? (
            <div className="OrderBox">
              {oderHistory.map((item, index) => {
                return (
                  <div className="container-fluid" ref={componentRef}>
                    <div className="row BoxTop">
                      <div className="col-md-6 px-2">
                        <div className="d-flex align-items-center order-no">
                          <p className="mb-0 mr-4">
                            <span style={{ color: "#000" }}>Order </span> #
                            {item.cart_data[0].order_id}
                          </p>
                          {/* <span className="mb-0">{getDate(item.booking_date)}</span> */}
                          <span className="mb-0">
                            {moment(item.cart_data[0].booking_date).format(
                              "LL"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 px-2 text-right" id="removeArea">
                        <Button color="success" onClick={toPrint}>
                          Download receipt
                        </Button>
                        {/* <p className="mb-0">Order Total : ${item.order_subtotal} </p> */}
                      </div>
                      <div className="col-md-6 px-2 text-right order-print">
                        <p className="mb-0">
                          Order Total :{" "}
                          {userData.currency == "INR"
                            ? "₹"
                            : userData.currency == "CAD"
                              ? "$"
                              : userData.currency == "USD"
                                ? "$"
                                : userData.currency == "GBP"
                                  ? "£"
                                  : userData.currency == "EUR"
                                    ? "€"
                                    : "$"}
                          {item.order_subtotal.toFixed(2)}{" "}
                        </p>
                      </div>
                    </div>
                    {item.cart_data?.length > 0 &&
                      item.cart_data.map((cartItem, cartIndex) => {
                        let i = cartItem.image?.findIndex(
                          (element) => element.ismain == true
                        );
                        // console.log("cartItem", cartItem.image==undefined);

                        return (
                          <div className="d-sm-flex d-flex justify-content-between py-4 px-3 print-space">
                            <div className="OrderTable" key={cartIndex}>
                              <p>{cartItem.service_name}</p>

                              <ReactStars
                                count={5}
                                value={cartItem.rating ? cartItem.rating : 0}
                                size={24}
                                isHalf={true}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={
                                  <i className="fa fa-star-half-alt"></i>
                                }
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                                edit={false}
                              />
                              {/* <img
                                  src={
                                    HttpClient.IMG_URL +
                                    cartItem.image[0].preview
                                  }
                                  className="img-fluid"
                                  alt="img"
                                  style={{ height: "86px", width: "110px" }}
                                /> */}
                              {i > -1 ? (
                                <img
                                  src={
                                    HttpClient.IMG_URL +
                                    cartItem.image[i].preview
                                  }
                                  className="img-fluid"
                                  alt="img"
                                  style={{ height: "86px", width: "110px" }}
                                />
                              ) : (
                                cartItem.image!=undefined
                                &&
                                <img
                                  src={
                                    HttpClient.IMG_URL +
                                    cartItem?.image[0]?.preview
                                  }
                                  className="img-fluid"
                                  alt="img"
                                  style={{ height: "86px", width: "110px" }}
                                />

                                // null
                              )}
                            </div>
                            {/* <div className="OrderTable">
                        <p>QUANTITY</p>
                        <span>1</span>
                      </div> */}
                            {/* <div className="OrderTable">
                            <p>PRICE</p>
                            <span>${cartItem.price}</span>
                          </div> */}
                            <div className="OrderTable">
                              <p>SLOT TIME</p>
                              <span>
                                {cartItem.slot_data
                                  ? cartItem.slot_data.from
                                  : ""}
                                -
                                {cartItem.slot_data
                                  ? cartItem.slot_data.to
                                  : ""}
                              </span>
                              <br></br>

                              <span>
                                {moment(cartItem.date_of_booking).format(
                                  "ddd [-] MMM DD YYYY"
                                )}
                              </span>
                            </div>
                            <div className="OrderTable">
                              <p>TOTAL</p>
                              <span>
                                {userData.currency == "INR"
                                  ? "₹"
                                  : userData.currency == "CAD"
                                    ? "$"
                                    : userData.currency == "USD"
                                      ? "$"
                                      : userData.currency == "GBP"
                                        ? "£"
                                        : userData.currency == "EUR"
                                          ? "€"
                                          : "$"}
                                {cartItem?.price?.toFixed(2)}
                              </span>
                            </div>
                            <div className="OrderTable">
                              <p className="removeInPrint">FEEDBACK</p>
                              <h4
                                className="Status"
                                style={{ color: "#23c113" }}
                              >
                                {cartItem.status}
                              </h4>
                              <div>
                                <button
                                  type="button"
                                  className="btn PrimaryBtn test"
                                  onClick={() => {
                                    //console.log("modal click");
                                    setOrderId(cartItem.order_id);
                                    setserviceId(cartItem.service_id);
                                    setuserID(cartItem.user_id);
                                    setBookingId(cartItem.user_booking_id);
                                    setsellerid(cartItem.seller_id);
                                    setRating(true);

                                    // //console.log("ratingmodal", rating)
                                    // //console.log("serid", cartItem.service_id)
                                  }}
                                >
                                  Rate &amp; Review
                                </button>
                              </div>
                            </div>
                            {cartItem.completestatus == "complete" ? (
                              <div className="OrderTable">
                                <p>STATUS</p>
                                <h4
                                  className="Status"
                                  style={{ color: "#23c113" }}
                                >
                                  {cartItem.status}
                                </h4>
                                <div>
                                  <Button
                                    type="button"
                                    // className="btn PrimaryBtn"
                                    color={
                                      cartItem.refund_request ==
                                        "Refund initiated"
                                        ? "success"
                                        : cartItem.refund_request == "Rejected"
                                          ? "secondary"
                                          : cartItem.refund_request == "Yes"
                                            ? "warning"
                                            : "info"
                                    }
                                    onClick={() => {
                                      // setTimeout(() => {
                                      setisopenmodal(!isOpenmodal);
                                      setsentorderdetails(cartItem);
                                      // }, 1000);
                                      // setTimeout(() => {
                                      //console.log("hhj", isOpenmodal);
                                      // }, 1000);
                                    }}
                                  >

                                    {cartItem.refund_request ==
                                      "Refund initiated"
                                      ? "Refund Initiated"
                                      : cartItem.refund_request ==
                                        "Rejected"
                                        ? "Refund Rejected"
                                        : cartItem.refund_request == "Yes"
                                          ? "Refund Requested"
                                          : "Refund"}
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div className="OrderTable">
                                {/* <p>STATUS</p> */}
                              </div>
                            )}
                            <div className="chat-seller_custom">
                              <Button
                                onClick={() => {
                                  console.log(cartItem)
                                  setSellerDetail(cartItem.seller_data[0])
                                  setisopenmodal1(true);
                                  seteachservicedetails(cartItem);
                                  setorderid(cartItem.order_id);
                                }}
                              >
                                Chat with seller
                              </Button>
                            </div>
                            &nbsp;&nbsp;
                            {cartItem.seller_confirmed == false ? (
                              cartItem.seller_reject == true ? (
                                <div>
                                  <Button
                                    color="danger"
                                  >
                                    Service Cancelled by Seller
                                  </Button>
                                  {/* <Button
                                  color="danger"
                                  onClick={() => {
                                    setorderDlt(true);
                                    seteachservicedetails(cartItem);
                                    setorderid(cartItem.order_id);
                                    setindex(cartIndex)
                                  }}
                                >
                                  Cancel Service
                                </Button> */}
                                </div>
                              ) : (
                                <div>
                                  {cartItem.order_status &&
                                    cartItem.order_status == "cancelled" ? (
                                    <Button color="success">Cancelled</Button>
                                  ) : (
                                    <Button
                                      color="danger"
                                      onClick={() => {
                                        setorderDlt(true);
                                        seteachservicedetails(cartItem);
                                        setorderid(cartItem.order_id);
                                      }}
                                    >
                                      Cancel Service
                                    </Button>
                                  )}
                                  {/* <Button
                                  color="danger"
                                  onClick={() => {
                                    setorderDlt(true);
                                    seteachservicedetails(cartItem);
                                    setorderid(cartItem.order_id);
                                    setindex(cartIndex)
                                  }}
                                >
                                  Cancel Service
                                </Button> */}
                                </div>
                              )

                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
          ) : (
            <h3 style={{ textAlign: "center" }}>No Order List Items</h3>
          )}
        </div>
        {rating ? (
          <div
            className={
              rating ? "modal single_modal show fade" : "modal show fade"
            }
            tabIndex="-1"
            style={{ display: rating ? "block" : "none" }}
          >
            <Rating
              CallBackRate={CallBack}
              orderid={OrderId}
              userid={userID}
              serviceid={serviceId}
              bookingid={BookingId}
              sellerid={sellerid}
            />
          </div>
        ) : null}
        {isOpenmodal ? (
          <RefundModal
            status={true}
            orderdetails={sentorderdetails}
            onCloseCallBack={Callbackmsg}
            image=""
          />
        ) : null}
        {orderDlt ? (
          <OrderDelete
            status={true}
            servdetails={eachservicedetails}
            onCloseCallBack2={Callbackmsg2}
            index={index}
          />
        ) : null}
        {isOpenmodal1 ? (
          <ChatBox
            // openCalling={openCalling}
            status={true}
            userData={reactLocalStorage.getObject("userData")}
            sellerdetails={sellerDetail}
            serviceId={eachservicedetails?.service_id}
            // chatRoomId={chatRoomId}
            onCloseCallBack={Callbackmsg1}
            image=""
          />
          // <ChatWithSeller
          //   status={true}
          //   orderid={orderid}
          //   servdetails={eachservicedetails}
          //   onCloseCallBack1={Callbackmsg1}
          //   image=""
          // />
        ) : null}
      </section>
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
