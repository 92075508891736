import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Firebase from "./Pages/Chat/firebase";
import { ToastContainer, toast } from "react-toastify";

import Layout from "./Layout/index";
import Video from "./Pages/Chat/Component/Video";
import { useEffect, useState } from "react";
import SellerLayout from "./Layout/SellerLayout";
import { useUserFetch } from "./Providers/User";
import HttpClient from "./utils/HttpClient";
import Meeting from "./Pages/Seller/CreateMeet/Meeting";
import PaymentMeeting from "./Pages/Seller/CreateMeet/PaymentMeeting";


function App() {
  const { userCredential, fetchUser, setUserCredential } = useUserFetch();
  const { iceServerData } = useUserFetch()
  // console.log(iceServerData, "kluiu7jkn")



  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    let user = reactLocalStorage.getObject("userData");
    if (user._id) {
      let result = await HttpClient.requestData(
        `singleUserProfile/${user._id}`,
        "GET"
      );
      //console.log("userData1", result);
      if (result.status) {
        setUserCredential(result.result);
      }
    }
  };


  return (

    <Router>
      <Switch>
        <Route path='/meeting' render={(props) => <Meeting {...props} />} />
        <Route path='/payment-meeting/:roomName' render={(props) => <PaymentMeeting {...props} />} />

        <Route
          path="/video-call"
          name="VideoCall"
          render={(props) => <Video {...props} />}
        />
        <Route
          path="/"
          name="Layouts"
          render={(props) => <Layout {...props} />}
        />

        {/* <Route
          path="/seller"
          name="Layouts"
          render={(props) => <SellerLayout {...props} />}
        /> */}
      </Switch>
    </Router>
  );
}

export default App;
