import { createSlice } from '@reduxjs/toolkit'

const serviceData = createSlice({
  name: 'serviceData',
  initialState: {
    categoryname:"Tarot reading" ,
    selectcategory:"61122e6129890f40806a0002",
   },
  reducers: {
    setCategoryName:(state,action)  => {  
      state.categoryname =action.payload
    },
    setSelectedCategory:(state,action)  => {  
      state.selectcategory =action.payload
    },
    
     }
})

export const {setCategoryName,setSelectedCategory} = serviceData.actions
export default serviceData.reducer
