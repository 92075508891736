import React, { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "./../../utils/HttpClient";
import Jumbotron from "../../Component/Jumbotron";
import Footer2 from "../../Default/Footer2/index";
import Navbar2 from "../../Default/Navbar2/index";

import Blog from "./Component/Blog";

import blogImg1 from "../../Default/Assets/Images/Blog/blogImg1.png";
import blogImg2 from "../../Default/Assets/Images/Blog/blogImg2.png";
import blogImg3 from "../../Default/Assets/Images/Blog/blogImg3.png";
import BlogJumboBg from "../../Default/Assets/Images/Blog/BlogJumboBg.png";

const Index = () => {
  const [blogItems, setblogItems] = useState([]);
  const [blogType, setblogType] = useState([]);
  const [blogvalue, setblogvalue] = useState("");
  const [blog_id, setblog_id] = useState("");

  // const blogItems = [
  //   { imgas: blogImg1 },
  //   { imgas: blogImg2 },
  //   { imgas: blogImg3 },
  // ];
  const categories = [
    { listItem: "Blog Categories" },
    { listItem: "Horoscope News" },
    { listItem: "Astrology News" },
    { listItem: "Events" },
    { listItem: "News" },
    { listItem: "Blog News" },
    { listItem: "Puja Accessories" },
    { listItem: "Religion Spiritual" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    scrollTop();
    fetchData();
    fetchBlogType();
  }, []);

  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchBlogType = async () => {
    let result = await HttpClient.requestData("viewBlogData", "GET");
    //console.log("viewBlogData", result);
    if (result && result.status) {
      setblogType(result.data)
    } else {
      setblogType([]);
    }
  };

  const fetchData = async (it) => {
    let result = await HttpClient.requestData("blog", "GET");
    // //console.log("all blog", result);
    if (result && result.status) {
      // //console.log("blog type", it);
      if (it) {
        let arr = result.data.filter((item) => item.blog_type === it);
        if (arr.length > 0) {
          setblogItems(arr);
        } else {
          setblogItems([]);
        }
      } else {
        setblogItems(result.data);
      }
    } else {
      setblogItems([]);
    }
  };

  // const handleClick = async (event) => {
  //   //console.log("gh", event.target.value);
  // }
  return (
    <div className="blog">
      <ReactTitle title="MySpiritualConnent - Blog" />
      <section>
        <Jumbotron heading="BLOG" img={BlogJumboBg} />
      </section>
      <section className="blogContent px-3">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3 order-lg-2 mb-lg-0 mb-5">
             
              <div className="categories">
                <ul>
                  {blogType.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          setblogvalue(item.blog_type);
                          fetchData(item.blog_type);
                        }}
                        role="button"
                      >
                        {item.blog_type}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div> */}

            {/* <div className="col-lg-9 order-lg-1"> */}
            <div className="col-lg-12 col-12">
              <h4 style={{ textAlign: "center" }}>{blogvalue}</h4>
              <div className="row">
                {blogItems.length ? (
                  blogItems.map((item, index) => {
                    return (

                      <div key={index} className="col-lg-4 col-12 col-md-4 col-xl-4">
                        <Blog
                          blogtype="multi"
                          imgs={item.image}
                          title={item.title}
                          blog_id={item._id}
                          content={item.content}
                          type={item.blog_type}
                          date={item.created_at}
                        // type="true"
                        />
                      </div>


                    );
                  })
                ) : (
                  <div>
                    <h1> No Blog Found</h1>
                  </div>
                )}
              </div>

            </div>
          </div>

        </div>
      </section>

    </div>
  );
};

export default Index;
