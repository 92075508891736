import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Blog from "../Component/Blog";
import { scroller } from "react-scroll";
import Jumbotron from "../../../Component/Jumbotron";
import HttpClient from "./../../../utils/HttpClient";
import Footer2 from "../../../Default/Footer2/index";
import Navbar2 from "../../../Default/Navbar2/index";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import blogImg1 from "../../../Default/Assets/Images/Blog/blogImg1.png";
import blogImg2 from "../../../Default/Assets/Images/Blog/blogImg2.png";
import blogImg3 from "../../../Default/Assets/Images/Blog/blogImg3.png";
import BlogJumboBg from "../../../Default/Assets/Images/Blog/BlogJumboBg.png";
import { Link } from "react-router-dom";

const SinglePage = (props) => {
  const {id} = useParams();
  const [tab, setTab] = useState("");
  const [title, settitle] = useState("");
  const [blog_id, setblog_id] = useState("");
  const [blog_type, setblog_type] = useState("");
  // const [id, setid] = useState(props.match.params.id);
  const [res, setres] = useState([]);
  const [blogItems, setblogItems] = useState([]);
  const [relatedItems, setRelatedItems] = useState([]);
  const [blogComment, setblogComment] = useState([]);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  const userData = reactLocalStorage.getObject("userData");

  const myRef = useRef(null)

  const handleChangeTab = (val,reff) => {
    // //console.log("----------",reff.current.offsetTop);
    // window.scrollTo(0, reff.current.offsetTop)
    // document.getElementById("comment")
    scrollToSection();
    //console.log(val);
    fetchComment(val);
    setTab(val);
  };
  const scrollToSection = () => {
    scroller.scrollTo("commentSection", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  const loop = [1, 2, 3, 4];
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fliterData();
    fetchData();
    fetchComment();
    // //console.log("nm", props.match.params.id);
  }, [id]);

  const fetchData = async (it) => {
    let userData = reactLocalStorage.getObject("userData");
    //console.log("UserDataId", userData._id);
    let result = await HttpClient.requestData("blog", "GET");
    //console.log("blog", result);
    if (result && result.status) {
      //console.log("jk", it);

      let arr = result.data.filter((item) => item.blog_type == it);
      if (arr.length > 0) {
        setRelatedItems(arr);
      } else {
        setRelatedItems(result.data);
      }
      //console.log("jk", arr);
    }
  };

  const fetchComment = async (it) => {
    let userData = reactLocalStorage.getObject("userData/" + it);
    //console.log("UserDataId", userData._id);
    let result = await HttpClient.requestData("blog-comment", "GET");
    //console.log("fetch blog comment----", result);
    if (result && result.status) {
      setblogComment(result.data);
    }
  };

  const fliterData = async () => {
    let result = await HttpClient.requestData("blog", "GET");
    //console.log("getblog data---", result);
    if (result && result.status) {
      setblogItems(result.data);
      //console.log("jk", result.data);
      let res = result.data.filter((f) => f.title == id);
      //console.log("res", res);
      setres(res);
      settitle(res[0].title);
      setblog_id(res[0]._id);
      setblog_type(res[0].blog_type);
    }
  };
  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    //console.log(valid);
    setisEmailFromatValid(valid ? false : true);
  }
  const comment = async () => {
    if (name != "" && email != "" && message != "") {
      let data = {
        blog_id: blog_id,
        blog_type: blog_type,
        user_id: userData._id,
        name: name,
        email: email,
        message: message,
      };
      let result = await HttpClient.requestData("blog-comment", "POST", data);
      //console.log("blog-comment", result);
      if (result && result.status) {
        toast.success(result.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setname("");
        setemail("");
        setmessage("");
        fetchComment();
        scrollToSection();
      } else {
        toast.error("Please Login First", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warn("Please fill all details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="blogSinglePage">
      <ToastContainer />
      <section>
        <Jumbotron type="SinglePage" heading={title} img={BlogJumboBg} />
      </section>
      <section className="blogContent">
        {res.length > 0 &&
          res.map((item, index) => {
            return (
              <div className="container blog" key={index}>
                <Blog
                  handleChangeTab={handleChangeTab}
                  blogtype="single"
                  imgs={item.image}
                  title={item.title}
                  blog_id={blog_id}
                  content={item.content}
                  type={item.blog_type}
                  date={item.created_at}
                  // ref={myRef}
                />
              </div>
            );
          })}
        <div className="commentSection mb-5">
          {tab === blog_id ? (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>Comment Section</h2>
                  {blogComment.length ? (
                    blogComment.map((item, index) => {
                      return (
                        <div className="media py-3 border-top" key={index}>
                          <div className="avatar-xs me-3">
                            <div className="avatar-title rounded-circle bg-light text-primary">
                              <i className="fas fa-user-circle"></i>
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="font-size-14 mb-1 mx-3">
                              {/* Keith McCoy{" "} */}
                              {item.name}
                              <small className="text-muted float-end  mx-3">
                                {moment(item.added_on).format("DD MMMM ,YYYY")}
                              </small>
                            </h5>
                            <p className="text-muted">
                              {/* Donec posuere vulputate arcu. phasellus accumsan
                              cursus velit */}
                              {item.message}
                            </p>
                            <div>
                              {/* <Link to="#" className="text-success">
                                <i className="mdi mdi-reply"></i> Reply
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-muted">
                      No comment Found
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <h2>Leave Your Comment</h2>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div class="form-group">
                  <label for="exampleInputName1">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputName1"
                    onChange={(val) => {
                      setname(val.target.value);
                    }}
                    value={name}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  onChange={(val) => {
                    setemail(val.target.value);
                    if (val.target.value) {
                      validateEmail(val.target.value);
                    } else {
                      validateEmail(val.target.value);
                    }
                  }}
                  value={email}
                />
                {isEmailFromatValid ? (
                  <small style={{ color: "red" }}>
                    {" "}
                    Please enter a valid email.{" "}
                  </small>
                ) : null}
              </div>
              <div className="col-md-12">
                <label for="exampleInputMessage1">Message</label>
                <textarea
                  rows="5"
                  className="form-control"
                  onChange={(val) => {
                    setmessage(val.target.value);
                  }}
                  value={message}
                ></textarea>
              </div>
            </div>
            <div className="text-center py-5">
              <button className="btn PrimaryBtn" onClick={comment}>
                <span>Leave Comment</span>
              </button>
            </div>
          </div>
        </div>
        <div className="BlogSlider px-5 blog container test">
          {relatedItems.length > 0 ? (
            <Slider {...settings}>
              {relatedItems.map((item, index) => {
                return (
                  <div key={index} className={item.type ? "p-3" : "p-3"}>
                    <Blog
                      className="single_blog"
                      blogtype="multi"
                      imgs={item.image}
                      title={item.title}
                      content={item.content}
                      type={item.blog_type}
                      date={item.created_at}
                    />
                  </div>
                );
              })}
            </Slider>
          ) : (
            <h5>No blog found!</h5>
          )}
        </div>
      </section>

      {/* <section>
        <Footer2 />
      </section> */}
    </div>
  );
};

export default SinglePage;
