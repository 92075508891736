import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { ToastContainer, toast } from "react-toastify";
import HttpClient from "../../../utils/HttpClient";

const Rating = ({ props, orderid, userid, serviceid, bookingid, sellerid, CallBacksession }) => {

  const [rating, setrating] = useState(0);
  const [comment, setcomment] = useState("");
  const [tip, settip] = useState(0);
  const ratingChanged = (newRating) => {
    //console.log(newRating);
    setrating(newRating);
  };

  const Submit = async () => {
    //console.log("hii");

    if (
      rating != "" && comment != ""

    ) {

      let data = {
        user_id: userid,

        comment: comment,
        rating: rating,

        seller_id: sellerid,
        tip: tip


      }
      //console.log(data);
      let result = await HttpClient.requestData(
        "add-chat-review",
        "POST",
        data
      );
      //console.log("result", result);
      if (result && result.status) {
        //console.log("checkout", result.data);
        toast.success("Thanks for review and tip", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        CallBacksession(false)
      } else {
        toast.error(result.error, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {

      toast.warning("please rate the seller first", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  };

  return (
    <div className="Rating">
      <div className="modal-dialog">
        <ToastContainer />
        <div className="modal-content pt-5 px-5 pb-4">
          <div className="">
            <span></span>
            <button
              type="button"
              className="close Single_close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => CallBacksession("close")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="mb-3">
            <h6 className=" mb-0" style={{ fontSize: "18px" }}>
              Rate the Seller
            </h6>
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={24}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
          </div>
          <div className="mb-4">
            <h6 className=" mb-2" style={{ fontSize: "18px" }}>
              Review the Seller
            </h6>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              onChange={(val) => {
                setcomment(val.target.value);
              }}
            ></textarea>
          </div>



          <div className="mb-4">
            <h6 className=" mb-2" style={{ fontSize: "18px" }}>
              Add tip to seller
            </h6>
            <input
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              onChange={(val) => {
                settip(val.target.value);
              }}
            ></input>
          </div>

          <div className=" pt-3 text-center mb-3">
            <button type="submit"
              onClick={

                Submit
              }
              className="btn  PrimaryBtn">Submit </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rating;
