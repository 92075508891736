import React from 'react'

function Mybankdetails() {
  return (
    <div className="row create_shop mt-3">
      <div className="Toastify" />
      <div className="col-md-12 mx-auto">
        <div className="col-md-12 text-center">
          <h1>My Bank Details</h1>
        </div>
        <div className="col-md-8 mx-auto">
          <div className="form-group mt-3">
            <label htmlFor={1}>
              Name{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id={1}
              placeholder="Please Enter Name"
              defaultValue=""
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor={2}>
              Account Number{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id={2}
              placeholder="Please Enter Account Number"
              defaultValue=""
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor={3}>
              Bank Name{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id={3}
              placeholder="Please Enter Bank Name"
              defaultValue=""
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor={4}>
              IFSC Code{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id={4}
              placeholder="Please Enter IFSC Code"
              defaultValue=""
            />
          </div>
          <div className="text-center my-3">
            <button type="button" className="btn changes_btn">
              Send Details
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Mybankdetails