import React from 'react'

function Sessionbar() {
  
    return (
        <section className="contact_div_index shadow d-none d-xxl-block d-xl-block d-lg-block d-md-block">
            <div className="container">
                <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                        <div className="contact_div_index_text_div">
                            <p>You can also avail one free session with Astrology from here</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 text-right">
                        <div className="contact_div_index_btn_div">
                            <a className="btn contact_div_index_btn" href="/contact-us">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Sessionbar