import React from "react";
import Jumbobgimage from "../../src/assets/jumbobgimage.jpg"
const Jumbotron = (props) => {
  return (
    <div
      className="Jumbotron"
      // style={{ backgroundImage: `url('${props.img}')` }}
      style={{ backgroundImage: `url('${Jumbobgimage}')` }}
    >
      <div className="container">
        {props.type === "SinglePage" ? (
          <div className="JumboContent">
            <h4>{props.heading}</h4>
          </div>
        ) : (
          <div className="JumboContent" style={{marginTop: "80px"}}>
            <h3 className="text-center">{props.heading}</h3>
            {props.serviceCategory !== null ||
              props.serviceCategory !== "" ||
              props.serviceCategory !== undefined ? (
                <h4 className="text-center">{props.serviceCategory}</h4>
              ) : ""}
          </div>
        )}
      </div>
    </div>
  );
};

export default Jumbotron;
