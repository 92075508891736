// import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import HttpClient from '../../utils/HttpClient'; // Import HttpClient from wherever it's defined

// const initialState = {
//     data: [],
//     isLoading: false // Change "" to false for better consistency
// }


// const fetchIceServerData = createAsyncThunk("iceServer", async () => {
//     try {
//         const res = await HttpClient.requestData1(
//             "https://jsonplaceholder.typicode.com/users",
//             "GET"
//         );
//         return res.data;
//     } catch (error) {
//         console.error("Error fetching ice server data:", error);
//         throw error;
//     }
// });

// const iceServerDataSlice = createSlice({
//     name: 'IceServerData',
//     initialState,
//     reducers: {

//     },

//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchIceServerData.pending, (state, action) => {
//                 state.isLoading = true;
//             })
//             .addCase(fetchIceServerData.fulfilled, (state, action) => {
//                 state.isLoading = false;
//                 state.data = action.payload;
//             })
//             .addCase(fetchIceServerData.rejected, (state, action) => {
//                 state.isLoading = false;
//                 // Handle rejection if needed
//             });
//     },
// });

// export const iceServerActions = {
//     fetchIceServerData // You can export other actions if needed
// };



// export default iceServerDataSlice.reducer;


import { createSlice } from '@reduxjs/toolkit'

const iceServerData = createSlice({
    name: 'serviceData',
    initialState: {
        data: [],
        isLoading: false,
    },
    reducers: {

    }
})


export default iceServerData.reducer


