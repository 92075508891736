import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import renderHTML from "react-render-html";
import blankImg from "../assets/blank.jpg";
import HttpClient from "../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import CalenderModal from "./CalenderModal";

const ServiceCard = (props) => {
  console.log("ServiceListData----", props.data);
  // //console.log("ServiceListData image-----", props.image);
  // //console.log(props.data._id);
  // //console.log(props.title);
  // //console.log("sellerdetails---",props.sellerdetails);
  const userData = reactLocalStorage.getObject("userData");
  const [oderHistory, setOderHistory] = useState([]);
  const [dataId, setdataId] = useState("");
  const [calenderOpen, setcalenderOpen] = useState(false);
  useEffect(() => {
    // getOderHistory();
    window.scrollTo(0, 0);
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  console.log(props.data, "ppwq")
  // const getOderHistory = async () => {
  //   let userData = reactLocalStorage.getObject("userData");
  //   //console.log("usd", userData);
  //   let result = await HttpClient.requestData(
  //     "myaccount/service-order-history/" + userData._id,
  //     "GET"
  //   );
  //   //console.log("result of oder", result);
  //   if (result && result.status) {
  //     setOderHistory(result.data);

  //     //console.log("oderHistory", oderHistory);
  //     // //console.log("date", result.data[0].booking_date);
  //     // getDate(result.data.length > 0 ? result.data[0].booking_date : "");
  //   }
  // };

  const CallBack = (val) => {
    setcalenderOpen(val);
  };
  const handleWarn = () => {
    toast.warning("Login First to View Details", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <>
      {props.data !== null && props.data.length > 0 ? (
        props.data.map((item, index) => {
          if (item.seller_details[0]?._id === userData._id) return;
          return (
            //  <div className="col-lg-4 col-md-4 mb-4">
            <div className="col-md-3 col-lg-3 col-12 mb-4">
              <Link>
                <div
                  className="card ServiceCard"
                  // style={{ minWidth: "20rem" }}
                  key={index}
                >
                  <Slider {...settings}>
                    {item.image.length > 0 &&
                      item.image.map((item1, index1) => {
                        // //console.log("item", item1);

                        return (
                          <>
                            {userData && userData._id ? (
                              <Link to={"/service-details/" + item._id}>
                                <div key={index1}>
                                  <img
                                    src={
                                      item1.preview
                                        ? HttpClient.IMG_URL + item1.preview
                                        : blankImg
                                    }
                                    className="card-img-top"
                                    alt="img"
                                    style={{ height: "210px" }}
                                  />
                                </div>
                              </Link>
                            ) : (
                              <Link onClick={handleWarn}>
                                <div key={index1}>
                                  <img
                                    src={
                                      item1.preview
                                        ? HttpClient.IMG_URL + item1.preview
                                        : blankImg
                                    }
                                    className="card-img-top"
                                    alt="img"
                                    style={{ height: "210px" }}
                                  />
                                </div>
                              </Link>
                            )}
                          </>
                        );
                      })}
                  </Slider>
                  <div className="card-body">
                    <h5 className="card-title">{item.name}</h5>
                    {/* <h6
              className="card-description"
              dangerouslySetInnerHTML={{ __html: props.data.details }}
            >
              {props.data.details}
              {props.data.details ? renderHTML(props.data.details) : ""}
            </h6> */}
                    <div className="d-flex justify-content-between">
                      <p className="">
                        <i class="fas fa-star star mr-2"></i>
                        {item.avgRating ? item.avgRating.toFixed(2) : 0}
                        /5
                      </p>
                      <h4>
                        {userData.currency == "INR"
                          ? "₹"
                          : userData.currency == "CAD"
                            ? "$"
                            : userData.currency == "USD"
                              ? "$"
                              : userData.currency == "GBP"
                                ? "£"
                                : userData.currency == "EUR"
                                  ? "€"
                                  : "$"}
                        {item.price}
                        {/* $20 <span>$80</span> */}
                      </h4>
                    </div>
                    <div className="d-flex justify-content-between">
                      {userData && userData._id ? (
                        <Link
                          to={"/service-details/" + item._id}
                          className="btn  PrimaryBtn"
                        >
                          Schedule
                        </Link>
                      ) : (
                        <Link
                          className="btn  PrimaryBtn"
                          onClick={() => {
                            toast.warning("Login First to Schedule", {
                              position: "bottom-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }}
                        >
                          Schedule
                        </Link>
                      )}

                      {userData && userData._id ? (
                        <button
                          // to={"/service-details/" + props.data._id}
                          className="btn  PrimaryBtn cartBtn"
                          onClick={() => {
                            setcalenderOpen(true);
                            setdataId(item._id);
                          }}
                        >
                          <i
                            className="fas fa-cart-plus text-dark"
                            style={{ marginRight: "3px" }}
                          ></i>
                          Add to Cart
                        </button>
                      ) : (
                        <button
                          className="btn  PrimaryBtn "
                          onClick={() => {
                            toast.warning("Login First to Schedule", {
                              position: "bottom-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }}
                        >
                          Add to Cart
                        </button>
                      )}
                    </div>

                    {/* <Link to={{pathname:"/cart/",items:{data:props.data}}} 
          className="btn  PrimaryBtn">
          Book Now
          </Link> */}
                    {calenderOpen ? (
                      <div
                        className={
                          calenderOpen
                            ? "modal single_modal fade show"
                            : "modal fade"
                        }
                        tabindex="-1"
                        style={{ display: calenderOpen ? "block" : "none" }}
                      >
                        <CalenderModal
                          CallBack={CallBack}
                          dataId={dataId}
                          sellerDetails={item.seller_details[0]}
                          serviceDetails={item}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </Link>
            </div>
          );
        })
      ) : (
        <div className="">
          <div
            className="text-center mb-4 category_Name pt-5 h2"
            style={{ textAlign: "center" }}
          >
            No Service Found  pqr
          </div>
        </div>
      )}
      {/* <Link to="/service-details/" items={{data:props.data}}> */}
    </>
  );
};

export default ServiceCard;
