import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { ToastContainer, toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import Slot from "../Pages/ServiceDetails/Components/Slot";

import moment from "moment";
import HttpClient from "./../utils/HttpClient";
import $ from "jquery";

const CalenderModal = (props) => {
  // //console.log("serviceDetail", props.serviceDetails);
  // //console.log("props.sellerDetails------", props.sellerDetails);
  const serviceDetail = props.serviceDetails;
  const [date, setdate] = useState("");
  const [bookModal, setBookModal] = useState(false);
  const [slotData, setslotData] = useState([]);
  const userData = reactLocalStorage.getObject("userData");
  const [nextMonth, setnextMonth] = useState(0);
  const [eventData, seteventData] = useState([]);
  const [refress, setrefress] = useState(false);
  const [checkMonth, setCheckMonth] = useState("");
  useEffect(() => {
    // //console.log("hii", props.sellerdetails);
    // //console.log("onedata", props.oneData);
    hideToday();
    currentDateofMonth();
    // if (Object.keys(userData).length) {
    //   currentDateofMonth();
    // }

    // handleMonthChange();
  }, []);

  const CallBackSlot = (val) => {
    setBookModal(val);
  };

  const currentDateofMonth = async () => {
    // 2022-05-01
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    //console.log("current month", m);
    setnextMonth(m);
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    let modifyFirstDate = moment(tomorrow).format("YYYY-MM-DD");
    let modifyLastDate = moment(lastDay).format("YYYY-MM-DD");
    let data = {
      shop_service_id: props.dataId,
      user_id: reactLocalStorage.getObject("userData")._id,
      datefrom: modifyFirstDate,
      dateto: modifyLastDate,
    };
    //console.log("date-=====", data);
    setrefress(true);
    let result = await HttpClient.requestData(
      "shop-service/viewSlotsAllDay",
      "POST",
      data
    );
    //console.log("day check-------", result.data);
    if (result && result.status) {
      seteventData(result.data);
      setrefress(false);
    }
  };

  const clicked = async (e) => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let mm = 0;

    // //console.log("calender click-----",e.target.className);
    if (
      e.target.className == "fc-icon fc-icon-chevron-right" ||
      e.target.className == "fc-next-button fc-button fc-button-primary"
    ) {
      // //console.log("clicked cal");

      //console.log(m);
      //   for (var i = m; i <= m+2; i++) {
      //     m= i
      // }
      let clicked = reactLocalStorage.getObject("click");
      m = Number(m) + Number(clicked);
      let mm = m;
      //console.log("current month", m);

      // var firstDay = new Date(y, m, 1);
      let modifyFirstDate = y + "-" + (Number(m) + Number(1)) + "-" + "01";
      let lastdat = new Date(y, m + 1, 0);
      let modifyLastDate = moment(lastdat).format("YYYY-MM-DD");
      //console.log("modifyFirstDate", modifyFirstDate);
      //console.log("modifyLastDate", modifyLastDate);
      let data = {
        shop_service_id: props.dataId,
        user_id: reactLocalStorage.getObject("userData")._id,
        datefrom: modifyFirstDate,
        dateto: modifyLastDate,
      };
      //console.log("date-=====", data);
      setrefress(true);
      let result = await HttpClient.requestData(
        "shop-service/viewSlotsAllDay",
        "POST",
        data
      );
      //console.log("day check-------", result.data);
      if (result && result.status) {
        seteventData(result.data);
        setrefress(false);
      }
      clicked++;
      reactLocalStorage.setObject("click", clicked);
      reactLocalStorage.setObject("month", mm);
      //console.log("afterclick", reactLocalStorage.getObject("click"));
      // //console.log("nest month",m);
    } else if (
      e.target.className == "fc-icon fc-icon-chevron-left" ||
      e.target.className == "fc-prev-button fc-button fc-button-primary"
    ) {
      // reactLocalStorage.setObject("click", clicked--);
      let clicked = reactLocalStorage.getObject("click");
      var date = new Date(),
        month = date.getMonth();
      let mon = reactLocalStorage.getObject("month");
      //console.log("clicked value", clicked);
      //console.log("mm", mon);
      m = Number(mon) - Number(1);
      reactLocalStorage.setObject("month", m);
      //console.log("current month", m);
      //console.log("current month---", month);
      const today = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      let modifyCurrentDate = moment(tomorrow).format("YYYY-MM-DD");
      var firstDay = new Date(y, m, 1);
      let modifyFirstDate = y + "-" + (Number(m) + Number(1)) + "-" + "01";
      let lastdat = new Date(y, m + 1, 0);
      let modifyLastDate = moment(lastdat).format("YYYY-MM-DD");
      //console.log("modifyFirstDate", modifyFirstDate);
      //console.log("modifyLastDate", modifyLastDate);
      if (month == m) {
        let data = {
          shop_service_id: props.dataId,
          user_id: reactLocalStorage.getObject("userData")._id,
          datefrom: modifyCurrentDate,
          dateto: modifyLastDate,
        };
        //console.log("date-=====", data);
        setrefress(true);
        let result = await HttpClient.requestData(
          "shop-service/viewSlotsAllDay",
          "POST",
          data
        );
        //console.log("day check-------", result.data);
        if (result && result.status) {
          seteventData(result.data);
          setrefress(false);
        }
        clicked--;
        reactLocalStorage.setObject("click", clicked);
      } else {
        let data = {
          shop_service_id: props.dataId,
          user_id: reactLocalStorage.getObject("userData")._id,
          datefrom: modifyFirstDate,
          dateto: modifyLastDate,
        };
        //console.log("date-=====", data);
        setrefress(true);
        let result = await HttpClient.requestData(
          "shop-service/viewSlotsAllDay",
          "POST",
          data
        );
        //console.log("day check-------", result.data);
        if (result && result.status) {
          seteventData(result.data);
          setrefress(false);
        }
        clicked--;
        reactLocalStorage.setObject("click", clicked);
      }
    }
  };

  const handleDateClick = async (arg) => {
    setdate(arg.dateStr);
    let cur_date = moment();
    // //console.log("arg", arg)
    let date = arg.dateStr;

    let date2 = moment(cur_date).format("YYYY-MM-DD");
    //console.log("date", date2);
    //console.log("date2", date2);
    if (
      moment(date).isBefore(date2, "day") ||
      moment(date).isSame(date2, "day")
    ) {
      // //console.log("not selected");
      toast.warning("You cannot access this date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let weekDayName = moment(date).format("dddd");

      let data = {
        weekday_name: weekDayName,
        shop_service_id: props.dataId,
        date: date,
        user_id: reactLocalStorage.getObject("userData")._id,
      };

      //console.log("date", data);

      let result = await HttpClient.requestData(
        "shop-service/day-timing",
        "POST",
        data
      );
      //console.log("result", result.data);
      if (result && result.status) {
        // //console.log("result", result.data);
        setslotData(result.data);
        // //console.log("ggg", slotData);
        if (result.data.length > 0) {
          setBookModal(true);
        } else {
          setBookModal(false);
          toast.warning("No slot available on this day", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };
  const currentData = (currentDate) => {
    //console.log("currentDate", currentDate);
    var startDate = new Date(currentDate.valueOf());
    startDate.setDate(startDate.getDate() + 1);
    return { start: startDate };
  };

  useEffect(() => {
    CheckingMonth();
  }, [checkMonth]);

  const hideToday = () => {
    $(".fc-today-button").addClass("hideButton")
  }

  const CheckingMonth = () => {
    let todayMonth = new Date().getMonth();
    //console.log("useEffcet", todayMonth);
    if (todayMonth == checkMonth) {
      // alert("true")
      $(".fc-prev-button").addClass("hideButton");
    } else {
      $(".fc-prev-button").removeClass("hideButton");
    }
  };

  if (refress === true) {
    document.body.style.opacity = "0.3";
  } else {
    document.body.style.opacity = "1";
  }
  return (
    <div className="Slot">
      <div className="modal-dialog">
        <div className="modal-content pb-4 pt-4 px-4">
          <div className="">
            <span></span>
            <button
              type="button"
              className="close Single_close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.CallBack(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="calender_Booking" onClick={clicked}>
            <FullCalendar
              initialView="dayGridMonth"
              weekends={true}
              showNonCurrentDates={false}
              visibleRange={currentData}
              plugins={[dayGridPlugin, interactionPlugin]}
              dateClick={handleDateClick}
              date={date}
              events={eventData}
              datesSet={(dateInfo) => {
                setCheckMonth(dateInfo.start.getMonth());
              }}
            />
          </div>
        </div>
      </div>
      {bookModal ? (
        <div
          className={bookModal ? "modal single_modal fade show" : "modal fade"}
          tabindex="-1"
          style={{ display: bookModal ? "block" : "none" }}
        >
          <Slot
            CallBackSlot={CallBackSlot}
            SlotData={slotData}
            shopServiceId={props.dataId}
            serviceData={props.serviceDetails}
            sellerfulldetails={props.sellerDetails}
            date={date}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CalenderModal;
