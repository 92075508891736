import React, { useState, useEffect } from "react";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import Jumbotron from "../../Component/Jumbotron";
import NewMoon from "../../Default/Assets/Images/NewMoon.png";
import Uranus from "../../Default/Assets/Images/Uranus.png";
import Jupitar from "../../Default/Assets/Images/Jupitar.png";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
// import renderHTML from "react-render-html";
import moment from "moment";
const MoreArticle = () => {
  const [articleData, setarticleData] = useState([]);
  useEffect(() => {
    window.scrollTo(0,0)
    getArticles();
  }, []);

  const getArticles = async () => {
    let result = await HttpClient.requestData("articles", "GET");
    //console.log("articleData Result", result);
    if (result && result.status) {
      setarticleData(result.data);
    }
  };
  const article = [
    { img: NewMoon, title: "New Moon in Cancer" },
    { img: Uranus, title: "Uranus in House" },
    { img: Jupitar, title: "Jupitar in Scorpio 2018" },
  ];
  return (
    <div>
      <section>
        <Jumbotron heading="More Articles" img={serviceJumboBg} />
      </section>
      <div className="Articles" >
        {/* <div className="text-center">
          <h1 className="secTionHeading mb-5 designBtm2">
            MySpritualConnect Articles
          </h1>
        </div> */}
        <div className="container ">
          <div className="row mb-2">
            {articleData.length
              ? articleData.map((item, index) => {
                  return (
                    <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt-5">
                    <div
                      className="text-center customBox pb-4"
                      key={index}
                    >
                      <div className="">
                        <img
                          src={
                            HttpClient.IMG_URL + item.image
                          }
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div className="">
                        <p className="date">
                          {moment(item.dated).format("LL")}
                          {item.author}
                        </p>
                        <h4>{item.title}</h4>
                        <p
                          className="mb-4 _details"
                          dangerouslySetInnerHTML={{ __html: item.details }}
                        >
                          {/* {renderHTML(item.details)} */}
                          {/* ul full count run batted in outside season, cracker jack
                        hall of fame club check swing. Rotation shift series sport
                        ball around the */}
                        </p>
                        {/* <img src={ArticleArrow} className="img-fluid" alt="img" style={{ width: "106", height: "20px" }} /> */}
                        <div className="text-center">
                          <Link
                            to={"/article-single" + item._id}
                            type="button"
                            className="btn PrimaryBtn"
                          >
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreArticle;
