import React, { memo } from "react";

const SercviceRequestBox = ({ img, number, text, greenBtn, purpleBtn }) => {
  return (
    <div className="col-md-6">
      <div className="card sell_order my-3">
        <div className="card-body">
          <div className="d-flex justify-content-around">
            <img src={img} alt="img" />
            <div className="ml-3">
              <h6>{number}</h6>
              <h5>{text}</h5>
            </div>
            <div className="ml-4 w-25">
              <button type="button" className="btn acc_btn">
                {greenBtn}
              </button>
              <button type="button" className="btn reject_btn my-3">
                {purpleBtn}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SercviceRequestBox);
