import React, { useState, useEffect } from "react";
import Jumbotron from "../../Component/Jumbotron";
import Blog_ban from "../../assets/NewMoon.65b69f1b.png";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import NewMoon from "../../Default/Assets/Images/NewMoon.png";
import Uranus from "../../Default/Assets/Images/Uranus.png";
import Jupitar from "../../Default/Assets/Images/Jupitar.png";
import MorePopular from "./MorePopular";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
// import renderHTML from "react-render-html";
import moment from "moment";
const ArticleSingle = () => {
  const params = useParams();
  let id = params.id;
  const [eid, seteid] = useState(id);
  const [singleArtice, setsingleArtice] = useState({});
  const [refress, setrefress] = useState(false)
  const [articleData, setarticleData] = useState([]);
  const [newArticleData, setnewArticleData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleArticles(id);
    getArticles();
    setrefress(true)
  }, [eid, id]);

  const getSingleArticles = async (id) => {
    let result = await HttpClient.requestData("articles/" + id, "GET");
    // //console.log("single articleData Result", result);
    if (result && result.status) {
      setsingleArtice(result.data);
    }
  };
  const getArticles = async () => {
    let result = await HttpClient.requestData("articles", "GET");
    // //console.log("articleData Result", result);
    if (result && result.status) {
      setarticleData(result.data);
      let filterArticle = result.data.filter((item) => item._id != id);
      setnewArticleData(filterArticle);
    }
  };
  const article = [
    { img: NewMoon, title: "New Moon in Cancer" },
    { img: Uranus, title: "Uranus in House" },
    { img: Jupitar, title: "Jupitar in Scorpio 2018" },
  ];
  return (
    <div>
      <section>
        <Jumbotron heading=" Article" img={serviceJumboBg} />
      </section>
      <section>
        <div className="container-fluid  mt-3">
          <div className="row p-4">
            <div className="col-md-12 blog-detail-head">
              <div className="d-flex justify-content-between dlex-wrap   ">
                <h6 className="blog-date">
                  {moment(singleArtice.dated).format("LL")} By: {singleArtice.author}
                </h6>
                <h6 className="blog_view">
                  {/* <i className="far fa-eye mx-2"></i>1024 */}
                </h6>
              </div>
              <div>
                <h3>{singleArtice.title}</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row p-4">
            <div className="col-lg-3 text-center">
              <div className="blog_ban_img">
                <img
                  src={
                    HttpClient.IMG_URL + singleArtice.image
                  }
                  alt="img"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </div>
            <div className="col-md-6 blog_details_text">
              {/* {singleArtice.details ? renderHTML(singleArtice.details) : ""} */}
              <p dangerouslySetInnerHTML={{ __html: singleArtice.details }}></p>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus{" "}
              </p>
              <br></br>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus{" "}
              </p>
              <br></br>

              <h5>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit.
                <span>
                  A, volutpat nibh cursus Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. A, volutpat nibh cursus. Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
                  nibh cursus Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. A, volutpat nibh cursus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. A, volutpat nibh cursus{" "}
                </span>
              </h5>
              <br></br>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus{" "}
              </p>
              <br></br>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus{" "}
              </p> */}
            </div>
            <div className="col-md-3 Latest_blog">
              <h6>More Popular</h6>
              <MorePopular articleData={newArticleData} />
            </div>
            {/* <div className="col-lg-3 my-lg-0 my-4">
                <SubsBox />
              </div> */}
          </div>
        </div>
      </section>

      {/* 
    <section className="mb-5">
        <div className="container-fluid ">
          <div className="row p-4">
            
            
          </div>
        </div>
      </section>
    
    */}
      <section className="Articles pt-4">
        <div className="container-fluid">
          <div className="row px-4">
            <div className="col-md-12">
              <div className="">
                <h3 className="secTionHeading mb-3">Other Articles</h3>
              </div>
            </div>
          </div>
          <div className="row  p-4">
            {newArticleData.length ? (
              newArticleData.slice(0, 3).map((item, index) => {
                return (
                  <div className="col-lg-3 col-md-6 col-12 mb-4">
                    <div
                      className="text-center customBox pb-3"
                      key={index}
                    >
                      <div>
                        <img
                          src={HttpClient.IMG_URL + item.image}
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div className="">
                        <p className="date">
                          {moment(item.dated).format("LL")}
                          {item.author}
                        </p>
                        <h4>{item.title}</h4>
                        <p
                          className="mb-4 _details"
                          dangerouslySetInnerHTML={{ __html: item.details }}
                        >
                          {/* {renderHTML(item.details)} */}
                          {/* ul full count run batted in outside season, cracker
                          jack hall of fame club check swing. Rotation shift
                          series sport ball around the */}
                        </p>
                        {/* <img src={ArticleArrow} className="img-fluid" alt="img" style={{ width: "106", height: "20px" }} /> */}
                        <div className="text-center">
                          <Link
                            to={"/article-single" + item._id}
                            type="button"
                            className="btn PrimaryBtn"
                          >
                            <span>READ MORE</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <h1> No Article Found</h1>
              </div>
            )}
          </div>
          {/* <div className="text-center mt-5">
            <button className="btn PrimaryBtn">
              <b>VIEW MORE</b>
            </button>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default ArticleSingle;
