import React, { useState } from "react";

const SideDrop = () => {
  const [dropState, setDropState] = useState({
    drop: false,
    drop1: false,
    drop2: true,
    drop3: false,
    drop4: false,
    drop5: false,
    drop6: false,
  });
  const drops = [
    { number: "Filter Category 1" },
    { number: "Filter Category 2" },
    { number: "Filter Category 3" },
    { number: "Filter Category 4" },
    { number: "Filter Category 5" },
    { number: "Filter Category 6" },
    { number: "Filter Category 7" },
  ];
  const subCategory = [
    { number: "Sub Category 1" },
    { number: "Sub Category 2" },
    { number: "Sub Category 3" },
    { number: "Sub Category 4" },
    { number: "Sub Category 5" },
    { number: "Sub Category 6" },
    { number: "Sub Category 7" },
  ];

  const dropDown = (id) => {
    let num = `drop${id}`;
    //console.log(num, dropState[num]);
    setDropState({ ...dropState, [num]: !dropState[num] });
  };
  return (
    <div className="SideDrop">
      <h4>Filters</h4>
      <ul>
        {drops.map((item, index) => {
          let num = `drop${index}`;
          return (
            <li key={index}>
              <div className=" ">
                <button
                  className="btn d-flex justify-content-between align-items-center w-100"
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() => dropDown(index)}
                >
                  {item.number}
                  <i
                    className={
                      dropState[num]
                        ? "fas fa-caret-up fa-2x"
                        : "fas fa-caret-down fa-2x"
                    }
                  ></i>
                </button>
              </div>
              {dropState[num] ? (
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {subCategory.map((item, index) => {
                    return (
                      <a className="dropdown-item" href="#" key={index}>
                        {item.number}
                      </a>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SideDrop;
