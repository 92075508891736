import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";
import blankProfile from "../../assets/blank-profile.png";
import Auth from "../../Auth/index";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../utils/HttpClient";
import { Search } from "react-feather";
import SearchPage from "../search/search";
import SellerModal from "./SellerModal";
import { useUserFetch } from "../../Providers/User";


const Index = () => {
  const { userCredential, setUserCredential } = useUserFetch()
  const [navbarState, setNavbarState] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchDrop, setSearchDrop] = useState(false);
  const [loginSts, setLoginSts] = useState(false);
  const [loginBtn, setLoginBtn] = useState(false);
  const [loginDrop, setLoginDrop] = useState(false);
  const [serviceDrop, setserviceDrop] = useState(false);
  const [searchvalue, setsearchvalue] = useState("");
  const [searcharr, setsearcharr] = useState([]);
  const [cartData, setcartData] = useState([]);
  const [approvedStatus, setapprovedStatus] = useState([]);
  const [servicemodal, setServicemodal] = useState(false);

  const [service, setService] = useState([]);

  const [login, setLogin] = useState(
    reactLocalStorage.getObject("loginstatus")
  );
  const userDataa = reactLocalStorage.getObject("userData");
  const [userData, setuserData] = useState("");
  const [issearchlengthZero, setissearchlengthZero] = useState(true);
  const navigate = useHistory();

  const changeNavstyle = () => {
    if (window.scrollY >= 80) {
      setNavbarState(true);
    } else {
      setNavbarState(false);
    }
  };
  const callBackService = (val) => {
    setServicemodal(val);
    fetchStatus();
  };

  window.addEventListener("scroll", changeNavstyle);

  const callBackLogin = (val) => {
    setLoginBtn(val);
  };

  useEffect(() => {
    if (
      localStorage.getItem("loginstatus") !== null ||
      localStorage.getItem("loginstatus") !== ""
    ) {
      setLoginSts(localStorage.getItem("loginstatus"));
      getCardData();
      const intervalid = setInterval(() => {
        setuserData(reactLocalStorage.getObject("userData"));
      }, 2000);
      return () => clearInterval(intervalid);
    }
    //console.log("token", userData);
  }, []);

  useEffect(() => {
    fetchStatus();
  }, []);

  const fetchStatus = async () => {
    let obj = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller-approval-status/" + reactLocalStorage.getObject("userData")._id,
      "GET"
    );
    //console.log("seller-approval-status", result);
    if (result && result.status) {
      setapprovedStatus(result.data);
      obj.type = result.data.type;
      reactLocalStorage.setObject("userData", obj);
    } else {
    }
  };

  const getCardData = async () => {
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "get-service-cart/" + userData._id,
      "GET"
    );
    //console.log("cart Result", result);
    if (result && result.status) {
      setcartData(result.data);
    }
  };

  const logout = async () => {
    reactLocalStorage.clear();
    reactLocalStorage.setObject("loginstatus", false);

    toast.success("Logout successfully", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setTimeout(function () {
      window.location.href = "/home";
    }, 1500);
  };


  const seller = async () => {
    navigate.push("/seller");

    // alert("lk")
    let result = await HttpClient.requestData(
      "subscription/check-subscription/" + userData._id,
      "GET"
    );
    //console.log("getSubscription Check", result);

    if (result.status) {
      // reactLocalStorage.setObject()
    }
    if (result.data.length > 0) {

      // window.location.href =
      // "https://seller.myspiritualconnect.com/login/" + userData.token;
      // window.location.href =
      // "http://13.235.24.150:8012/login/" + userData.token;
      // window.location.href = "http://localhost:3001/login/" + userData.token;
    } else {
      // toast.warning("Please Take Subscription First", {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      // window.location.href =
      // "https://seller.myspiritualconnect.com/login/" + userData.token;
      // window.location.href =
      // "http://localhost:3001/login/" + userData.token;
    }

    //console.log("token", userData.token);
  };

  const Searchservice = async (value, type) => {
    if (value != "" || searchvalue != "") {
      let data = {
        searchname: type == "button" ? searchvalue : value,
      };
      //console.log("data", data);
      let result = await HttpClient.requestData("search-service", "POST", data);
      //console.log("result", result);

      if (result && result.status) {
        setsearcharr(result.data);
        if (result.data.length > 0) {
          setissearchlengthZero(false);
        } else if (result.data.length == 0) {
          setissearchlengthZero(true);
        } else {
        }
        reactLocalStorage.setObject("searcharr", result.data);
        setSearchDrop(true);
        if (type == "button") {
          window.location.href = "/search";
          setsearcharr([]);
          setSearchOpen(false);
          setSearchDrop(false);
        } else {
        }
      } else {
        if (type == "button") {
          window.location.href = "/search";
        } else {
          setsearcharr([]);
        }

        // toast.error("No Search Found", {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      }
    } else {
      toast.warning("Please Enter the Search value", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  var loc = window.location.pathname;

  const alertt = () => {
    toast.error("You have to login first", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    // <div className={navbarState ? "Sticky Navbar" : "Navbar"}>
    <div className="Navbar">
      {/* <ToastContainer /> */}
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg  ">
          <Link to="/home">
            <img
              src={logo}
              className="img-fluid site-logo mr-lg-3"
              alt="img"
              style={{ height: "28px", width: "7rem" }}
            />
          </Link>
          {/* <a className="navbar-brand" href="/home">
            MySpiritualConnect
          </a> */}
          <button
            class="navbar-toggler navbar-dark"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            {searchOpen ? (
              <div className="SearchBar">
                <div className="d-flex ">
                  <input
                    type="text"
                    className="form-control SearchBox"
                    placeholder="Search Your Services...."
                    onChange={(val) => {
                      if (val.target.value != "") {
                        //console.log(val.target.value);
                        setsearchvalue(val.target.value);
                        Searchservice(val.target.value, "input");
                      } else {
                        setsearcharr([]);
                        setSearchDrop(false);
                        setissearchlengthZero(true);
                      }
                      //setSearchDrop(!searchDrop)
                    }}
                  // onKeyPress={() =>
                  //   setSearchDrop(!searchDrop)}
                  />
                  <div className="CrossBtn">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        //console.log("cross");
                        setSearchOpen(false);
                        setSearchDrop(false);
                      }}
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                  <div className="SearchIcon">
                    <button type="button" className="btn">
                      <i
                        class="fas fa-search"
                        onClick={() => {
                          //console.log("valuesearch");
                          Searchservice("", "button");
                        }}
                      ></i>
                    </button>
                  </div>
                </div>
                {/* {searchDrop&& issearchlengthZero==false ? */}
                {searchDrop && issearchlengthZero == false ? (
                  <div className="SearchDrop">
                    <ul className="NavScroller">
                      {/* {searchDrop&& issearchlengthZero==false ? */}
                      {searcharr.map((item, index) => {
                        //console.log("item", item);

                        return (
                          <li key={index}>
                            <Link
                              to={"/service-details/" + item._id}
                              onClick={() => {
                                setSearchOpen(false);
                                setSearchDrop(false);
                              }}
                            >
                              {item.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : searchDrop && issearchlengthZero == true ? (
                  <div className="SearchDrop">
                    <ul>
                      <li className="mb-0">No Search Found</li>
                    </ul>
                  </div>
                ) : null}
              </div>
            ) : (
              <ul class="navbar-nav mr-auto">
                {/* <li class="nav-item active">
                  <Link class="nav-link" to="/home">
                    HOME <span class="sr-only">(current)</span>
                  </Link>
                </li> */}
                {/* {loc === "/home" ? (
                  <></>
                ) : ( */}
                <>
                  {/* <li class="nav-item">
                      <a
                        class="nav-link "
                        href="#"
                        tabindex="-1"
                        aria-disabled="true"
                      >
                        PAGES
                      </a>
                    </li> */}
                  <li class="nav-item">
                    {/* <a
                      class="nav-link "
                      href="#"
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      FORECAST
                    </a> */}
                    <div>
                      <div className="dropdown">
                        {/* <a
                            class="nav-link "
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                            onClick={() => setserviceDrop(!serviceDrop)}
                          >
                            FORECAST
                          </a> */}
                        {/* <button
                        className="nav-link "
                        type="button"
                        id="dropdownMenuButton"
                        onClick={() => setLoginDrop(!loginDrop)}
                      >
                        <img src={Dashboard} className="img-fluid" alt="img" />
                      </button> */}
                        {/* <div
                            className={
                              serviceDrop ? "dropdown-menu d-block" : "d-none"
                            }
                            aria-labelledby="dropdownMenuButton"
                          >
                            {service.map((item, index) => {
                              return (
                                <div className="dropdown-item" key={index}>
                                  <Link className="mb-0">{item.name}</Link>
                                </div>
                              );
                            })} */}
                        {/* <h5 className="pl-3 font-weight-bold">Account Details</h5>
                        <Link className="dropdown-item" to="/edit-profile">
                          Edit Profile
                        </Link>
                        <Link className="dropdown-item" to="/chat">
                          Messages
                        </Link>
                        <Link className="dropdown-item" to="/my-orders">
                          My Orders
                        </Link>
                        <Link className="dropdown-item" to="/my-history">
                          My Favourites
                        </Link> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </li>
                </>
                {/* )} */}
                {/* <li class="nav-item">
                  <Link class="nav-link" to="/blog">
                    BLOG
                  </Link>
                </li> */}
                {/* <li class="nav-item">
                  <Link
                    class="nav-link "
                    to="/my-services"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    My Services
                  </Link>
                </li> */}
                <li class="nav-item">
                  <Link
                    class="nav-link "
                    to="/services"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    Our Services
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link "
                    to="/service-provider"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    Top Providers
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link "
                    to="/blog"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    Blog
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link "
                    to="/about-us"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    About Us
                  </Link>
                </li>
              </ul>
            )}
            {/* <form class="form-inline my-2 my-lg-0"> */}
            {Object.keys(userDataa).length ? (
              <>
                <Link
                  className="d-block order-1 order-lg-0 mx-2"
                  style={{ cursor: "pointer" }}
                  to="/edit-profile"
                >
                  <i class="fas fa-user text-dark"></i>
                </Link>
                &nbsp;&nbsp;
                <Link
                  className="d-block order-1 order-lg-0 mx-2"
                  style={{ cursor: "pointer" }}
                  to="/cart"
                >
                  <i class="fas fa-cart-plus text-dark"></i>
                </Link>
              </>
            ) : (
              <>
                <Link
                  className="d-block order-1 order-lg-0 mx-2"
                  style={{ cursor: "pointer" }}
                  onClick={alertt}
                >
                  <i class="fas fa-user text-dark"></i>
                </Link>
                &nbsp;&nbsp;
                <Link
                  className="d-block order-1 order-lg-0 mx-2"
                  style={{ cursor: "pointer" }}
                  onClick={alertt}
                >
                  <i class="fas fa-shopping-cart text-dark"></i>
                </Link>
              </>
            )}
            &nbsp;&nbsp;
            <div
              className={
                searchOpen ? "d-none" : "d-block order-1 order-lg-0 mx-2"
              }
              style={{ cursor: "pointer" }}
            >
              <i
                class="fas fa-search mr-4 text-dark"
                onClick={() => {
                  //console.log("hii");

                  setSearchOpen(true);
                  setsearchvalue("");
                  setsearcharr([]);
                }}
              ></i>
            </div>
            {/* {login && typeof(login) == "boolean" ? ()} */}
            {login && typeof login == "boolean" ? (
              <div className="DashboardItems">
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        userData.profile && userData.profile != ""
                          ? HttpClient.IMG_URL + userData.profile
                          : blankProfile
                      }
                      className="img-fluid"
                      alt="img"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </button>
                  {/* <div
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton2"
                      // onClick={() => setLoginDrop(!loginDrop)}
                      // onFocus={() => setLoginDrop(!loginDrop)}
                      // onBlur={() => setLoginDrop(!loginDrop)}
                      role="button"
                      tabIndex="0"
                    > 
                      <img src={Dashboard} className="img-fluid" alt="img" />
                    </div> */}
                  <div
                    // className={loginDrop ? "dropdown-menu d-block" : "d-none"}
                    aria-labelledby="dropdownMenuButton2"
                    className="dropdown-menu"
                  >
                    <div
                      className="pl-3 mb-2"
                      style={{ borderBottom: "1px solid #bf4ed3" }}
                    >
                      <h5 className="font-weight-bold">
                        {userData.firstName + " " + userData.lastName}
                      </h5>
                    </div>
                    <h5 className="pl-3 font-weight-bold">Account Details</h5>
                    <Link className="dropdown-item" to="/edit-profile">
                      Edit Profile
                    </Link>
                    <Link className="dropdown-item" to="/chat">
                      Messages
                    </Link>
                    {cartData.length > 0 ? (
                      <Link className="dropdown-item" to="/checkout">
                        Checkout
                      </Link>
                    ) : (
                      <Link
                        className="dropdown-item"
                        onClick={() => {
                          toast.warning("Book a Slot for checkout", {
                            position: "bottom-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        }}
                      >
                        Checkout
                      </Link>
                    )}
                    <Link className="dropdown-item" to="/my-orders">
                      My Orders
                    </Link>
                    {/* <Link className="dropdown-item">My Favourites</Link> */}
                    {/* <button 
                      className="btn PrimaryBtn btn-sm" 
                      onClick={seller} 
                      disabled={userdata.type = "Seller" ? false : true}
                      >
                      Seller
                      </button>{"  "} */}
                    <div className="px-2 d-flex">
                      <div
                        className="btn PrimaryBtn btn-sm"
                        // onClick={seller}
                        onClick={() => {
                          // buySubscription(item)
                          // setServicemodal(true);

                          console.log(approvedStatus)
                          if (approvedStatus.seller_approval == true) {
                            seller();
                          } else if (approvedStatus.seller_request == true) {
                            toast.warn("Allready Applied")
                          } else {
                            setTimeout(() => {
                              setServicemodal(true);
                            }, 100);
                          }
                        }}
                        role="button"
                      // disabled={(userData.type = "Seller" ? false : true)}
                      >
                        {approvedStatus.seller_approval
                          ? "Seller"
                          : approvedStatus.seller_request == true
                            ? "Applied for Seller"
                            : "Become a Seller"}
                        {/* Seller */}
                      </div>
                      &nbsp;
                      {"  "}
                      <button
                        className="btn PrimaryBtn btn-sm"
                        /* to="/home" */
                        onClick={logout}
                      >
                        LogOut
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <button
                class="btn PrimaryBtn btn-sm m-0"
                type="button"

                onClick={() => {
                  // if (reactLocalStorage.getObject("loginstatus")) {
                  //   //console.log("Already Logged In");
                  // } else {
                  setLoginBtn(true);
                  // }
                }}
              >
                <span>Login</span>
              </button>
            )}
            {/* </form> */}
            {/* <div className="d-flex">
              <button className="btn" type="button">
                <i className="far fa-heart text-white"></i>
              </button>
              <button className="btn" type="button">
                <i className="fas fa-shopping-cart text-white"></i>
              </button>
            </div> */}
          </div>
        </nav>


        {loginBtn ? (
          <div
            className={loginBtn ? "modal fade single_modal show" : "modal fade"}
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: loginBtn ? "block" : "none" }}
          >
            <Auth callBackLogin={callBackLogin} />
          </div>
        )
          :
          null
        }


        {
          servicemodal
            ?
            (
              <div
                className={
                  servicemodal ? "modal single_modal show fade" : "modal show fade"
                }
                tabIndex="-1"
                style={{ display: servicemodal ? "block" : "none" }}
              >
                <SellerModal callBackService={callBackService} />
              </div>
            )
            :
            null
        }

      </div>
      {/* {searcharr.length>0?
       <SearchPage
       searcharr={searcharr}
        
        />:null} */}
    </div>
  );
};

export default Index;
