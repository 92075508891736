import React, { useEffect, useState } from "react";
import ProfilePic from "../../../Default/Assets/Images/ProfilePic.png";
import HttpClient from "./../../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";

const EditProfilePic = (props) => {
  const [imageurl, setimageurl] = useState("");
  const imageUpload = async (e) => {

    //console.log("e", e.target.files);

    let data = new FormData();
    data.append("profile", e.target.files[0]);

    let result = await HttpClient.profileImage(
      "myaccount/image-upload/" + reactLocalStorage.getObject("userData")._id,
      "PUT",
      data
    );
    //console.log(result);
    if (result && result.status) {
      setimageurl(result.data);
    }
  };
  const saveImage = async () => {


    toast.success("Image Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    props.CallBackProfilePic(false, imageurl);

  };
  return (
    <div className="EditProfilePic">
      <div className="modal-dialog">
        <div className="modal-content p-5">
          <div className="">
            <span></span>
            <button
              type="button"
              className="close Single_close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.CallBackProfilePic(false, reactLocalStorage.getObject("userData").profile)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="d-flex file-choose" style={{ height: "70px" }}>
            <div className="choose_file " role="button">
              <input
                type="file"
                className="input_file"
                onChange={(e) => {
                  imageUpload(e);
                }}
              />
              Choose File
            </div>
            {imageurl != "" ? (
              <img
                src={HttpClient.IMG_URL + imageurl}
                data-dz-thumbnail
                alt="image"
                height="70px"
                width="70px"
                className="thumbimg "
              />
            ) : (
              <h6 className="align-self-center ml-3 ">No Choosen File</h6>
            )}
          </div>

          {/* <form>
            <div class="form-group mb-4">
              <label for="exampleFormControlFile1">Choose File</label>
              <input
                type="file"
                class="form-control-file"
                id="exampleFormControlFile1"
                onChange={(e) => {
                  imageUpload(e);
                }}
              />

              <img src="" alt="image" style={{ height: 50, width: 50 }} />
            </div>
          </form> */}
          <h5 className="mb-4 pt-3 text-center font-weight-bold">
            Must be a .png, .jpg or .jpeg file less than 5mb size and atlease
            300px by 300px
          </h5>

          <div className=" pt-3 text-center">
            {imageurl != "" ?
              <button className="btn PrimaryBtn" onClick={saveImage}>
                Save Profile Image
              </button> :
              <button className="btn PrimaryBtn" disabled>
                Save Profile Image
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfilePic;
