import React from 'react'

function Myshop() {
    return (
        <div className="seller_shop">
            <div>
                <div className="row my-4 px-4">
                    <div className="col-xl-7 offset-xl-1">
                        <h4>Overview</h4>
                        <div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="shop_name">Banner Image</label>
                                        <div className="img-wrap">
                                            <img
                                                className="avatar ml-3 img-fluid img-thumbnail"
                                                alt="img"
                                                src="https://admin.astrophy.com/uploads/shop_services/banner_download (2).jpeg"
                                                style={{ width: 54, height: 54 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="shop_name">Shop Image</label>
                                        <div className="img-wrap">
                                            <img
                                                className="avatar ml-3 img-fluid img-thumbnail"
                                                alt="img"
                                                src="https://admin.astrophy.com/uploads/shop_services/shop_WhatsApp Image 2023-02-17 at 17.42.24.jpeg"
                                                style={{ width: 54, height: 54 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="shop_name">Shop Name </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="shop_name"
                                            placeholder="Please Enter Shop Name"
                                            readOnly=""
                                            defaultValue="Chandan Astro."
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="shop_name">Shop Title </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="shop_title"
                                            placeholder="Please Enter Shop Title"
                                            readOnly=""
                                            defaultValue="Astrology on the Pick"
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="shop_name">Tags </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="tags"
                                            placeholder="Please Enter Tags"
                                            readOnly=""
                                            defaultValue="Palm Astro Talk"
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="shop_name">Description </label>
                                        <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows={3}
                                            placeholder="Please Enter Description"
                                            readOnly=""
                                            defaultValue={
                                                "The study of the positions and movements of the stars and planets and the way that some people believe they affect people and events."
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="shop_name">About Shop </label>
                                        <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows={3}
                                            placeholder="Please Enter About Shop"
                                            readOnly=""
                                            defaultValue={
                                                "Our new opening hours will be Monday to Sunday 11:00 till 18:00From everyone at The Astrology Shop."
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 text-center">
                        <div>
                            <button type="button" className="btn shop_create_btn">
                                Edit Shop
                            </button>
                        </div>
                        <div>
                            <button className="btn shop_create_btn" style={{ marginTop: 20 }}>
                                Visit Shop
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shop_listing py-4">
                <table border={0} className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Shop Performance</th>
                            <th>Total Views</th>
                            <th>Total Favourites</th>
                            <th>Total Orders</th>
                            <th>Total Revenue</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <a
                                    className="d-flex align-items-center justify-content-start text-dark"
                                    href="/service-details/63d3d90fec39ee4a941141f9"
                                >
                                    <img
                                        src="https://admin.astrophy.com/uploads/shop_services/download (2).jpeg"
                                        alt="img"
                                        style={{ height: 80, width: 80, borderRadius: 6 }}
                                    />
                                    <div className="ml-3">
                                        <h6 className="m-0 font-weight-normal">panna</h6>
                                    </div>
                                </a>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>₹0.00</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a
                                    className="d-flex align-items-center justify-content-start text-dark"
                                    href="/service-details/63d3e597a34cc8444b8b934d"
                                >
                                    <img
                                        src="https://admin.astrophy.com/uploads/shop_services/chart.jpg"
                                        alt="img"
                                        style={{ height: 80, width: 80, borderRadius: 6 }}
                                    />
                                    <div className="ml-3">
                                        <h6 className="m-0 font-weight-normal">Talk to Astrologer</h6>
                                    </div>
                                </a>
                            </td>
                            <td>
                                <p>4</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>1</p>
                            </td>
                            <td>
                                <p>₹0.00</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a
                                    className="d-flex align-items-center justify-content-start text-dark"
                                    href="/service-details/63d3e766a34cc8444b8ba39f"
                                >
                                    <img
                                        src="https://admin.astrophy.com/uploads/shop_services/istockphoto-639825782-612x612.jpg"
                                        alt="img"
                                        style={{ height: 80, width: 80, borderRadius: 6 }}
                                    />
                                    <div className="ml-3">
                                        <h6 className="m-0 font-weight-normal">Birth Chart Reading</h6>
                                    </div>
                                </a>
                            </td>
                            <td>
                                <p>9</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>1</p>
                            </td>
                            <td>
                                <p>₹0.00</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a
                                    className="d-flex align-items-center justify-content-start text-dark"
                                    href="/service-details/63da3815a34cc8444b8bd1c8"
                                >
                                    <img
                                        src="https://admin.astrophy.com/uploads/shop_services/palmist.jpg"
                                        alt="img"
                                        style={{ height: 80, width: 80, borderRadius: 6 }}
                                    />
                                    <div className="ml-3">
                                        <h6 className="m-0 font-weight-normal">Palimistry</h6>
                                    </div>
                                </a>
                            </td>
                            <td>
                                <p>4</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>₹0.00</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a
                                    className="d-flex align-items-center justify-content-start text-dark"
                                    href="/service-details/63db90fea34cc8444b8d1b06"
                                >
                                    <img
                                        src="https://admin.astrophy.com/uploads/shop_services/download (2).jpg"
                                        alt="img"
                                        style={{ height: 80, width: 80, borderRadius: 6 }}
                                    />
                                    <div className="ml-3">
                                        <h6 className="m-0 font-weight-normal">Birth Chart Reading </h6>
                                    </div>
                                </a>
                            </td>
                            <td>
                                <p>5</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>3</p>
                            </td>
                            <td>
                                <p>₹0.00</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a
                                    className="d-flex align-items-center justify-content-start text-dark"
                                    href="/service-details/63db9a0da34cc8444b8d502e"
                                >
                                    <img
                                        src="https://admin.astrophy.com/uploads/shop_services/gemstone02.jpg"
                                        alt="img"
                                        style={{ height: 80, width: 80, borderRadius: 6 }}
                                    />
                                    <div className="ml-3">
                                        <h6 className="m-0 font-weight-normal">Gemstone </h6>
                                    </div>
                                </a>
                            </td>
                            <td>
                                <p>5</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>2</p>
                            </td>
                            <td>
                                <p>₹0.00</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a
                                    className="d-flex align-items-center justify-content-start text-dark"
                                    href="/service-details/63ef696ed5b792d7ddb9d38d"
                                >
                                    <img
                                        src="https://admin.astrophy.com/uploads/shop_services/images.jpg"
                                        alt="img"
                                        style={{ height: 80, width: 80, borderRadius: 6 }}
                                    />
                                    <div className="ml-3">
                                        <h6 className="m-0 font-weight-normal">
                                            Relationship Astrology
                                        </h6>
                                    </div>
                                </a>
                            </td>
                            <td>
                                <p>5</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>₹0.00</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a
                                    className="d-flex align-items-center justify-content-start text-dark"
                                    href="/service-details/63ef6d78d5b792d7ddb9df7e"
                                >
                                    <img
                                        src="https://admin.astrophy.com/uploads/shop_services/CATCHING-THE-SUN.jpg"
                                        alt="img"
                                        style={{ height: 80, width: 80, borderRadius: 6 }}
                                    />
                                    <div className="ml-3">
                                        <h6 className="m-0 font-weight-normal">Future Aspects </h6>
                                    </div>
                                </a>
                            </td>
                            <td>
                                <p>2</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>0</p>
                            </td>
                            <td>
                                <p>₹0.00</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    )
}

export default Myshop