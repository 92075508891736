import React, { useState } from "react";
import Dustbin from "../../../Default/Assets/Images/Dustbin.png";
import HttpClient from "./../../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";

const Adds = (props) => {
  //console.log("image", props.cartImage);
  // //console.log("name",props.cartServicename);
  // //console.log("price",props.cartPrice);
  // //console.log("seller",props.sellerDetails);
  // //console.log("CartId",props.cartItemId);
  let cartItemId = props.cartItemId;
  // //console.log("CartId...",cartItemId);
  const [counter, setCounter] = useState(1);
  const userData = reactLocalStorage.getObject("userData");

  const deleteCart = async (cartItemId) => {
    //console.log("...", cartItemId);
    let result = await HttpClient.requestData(
      "delete-cart/" + cartItemId,
      "DELETE"
    );
    if (result && result.status) {
      props.successCall(result.status)
      toast.success(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
      }, 3000);
    }
  }
  return (
    <div className="CartAdds container-fluid mb-4">
      <ToastContainer />
      <div className="row">
        <div className="col-sm-2 px-3 text-md-center">
          <img
            // src={props.imgs}
            src={
              HttpClient.IMG_URL + props.cartImage[0].preview
            }
            className="img-fluid"
            alt="img"
            style={{ height: "150px", width: "265px" }}
          />
        </div>
        <div className="col-sm-7 px-3">
          <h4 className="mb-3">Service Name : {props.cartServicename}</h4>
          {/* <h5>{props.providerName}</h5> */}
          <h5>
            Seller :{" "}
            {props.sellerDetails[0].firstName +
              " " +
              props.sellerDetails[0].lastName}
          </h5>
          <h6 style={{ fontSize: "12px" }}>
            Booked At:{" "}
            {moment(props.cart.booking_date).format("ddd [-] MMM DD YYYY")}
          </h6>
          <h6 style={{ fontSize: "12px" }}>
            Booked For:{" "}
            {moment(props.cart.date_of_booking).format("ddd [-] MMM DD YYYY")}
          </h6>


          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center mr-3">
              {/* <button
                className="btn CounterBtn mr-2"
                onClick={() => setCounter(counter - 1)}
              >
                <i className="fas fa-minus"></i>
              </button>
              <span className="Amount">{counter}</span>
              <button
                className="btn CounterBtn ml-2"
                onClick={() => setCounter(counter + 1)}
              >
                <i className="fas fa-plus"></i>
              </button> */}
            </div>



            {/* <button
              onClick={() => {
                deleteCart(cartItemId)
              }}
              style={{ "border": "none", "background": "tranparent" }}
            >
              <img src={Dustbin} className="img-fluid sm-2" alt="img" />
            </button> */}
          </div>
        </div>

        <div className="col-md-3 d-flex justify-content-between" style={{alignItem: "center"}}>
          <h2 className="mb-3">
            {/* $  */}
            {userData.currency == "INR"
              ? "₹"
              : userData.currency == "CAD"
                ? "$"
                : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "GBP"
                    ? "£"
                    : userData.currency == "EUR"
                      ? "€"
                      : "$"}
            {props.cartPrice.toFixed(2)}
          </h2>
          <button
              onClick={() => {
                deleteCart(cartItemId)
              }}
              style={{ "border": "none", "background": "transparent", alignItems: "start", display: "flex" }}
            >
              <img src={Dustbin} className="img-fluid sm-2" alt="img" style={{height: "22px"}}/>
            </button>
        </div>
      </div>
    </div>
  );
};

export default Adds;
