import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Card, CardBody, Col, Input, Button, Spinner } from "reactstrap";
import HttpClient from '../../../utils/HttpClient';


const InstantModal = ({ modalOpen = false, onClose, user_Id, room_id,setMeeting }) => {
    const [price, setPrice] = useState('')
    console.log('price', moment().format('hh:mm'))

    const AddInstance=async()=>{
        if (
            price 
        ) {
            let sendData = {
                userId: user_Id,
                roomId: room_id,
                price: price,
                meetType: 'Instant',
                date: moment().format('YYYY-MM-DD'),
                time: moment().format('hh:mm')
            }
            console.log('sendData', sendData)
            let result = await HttpClient.requestData(
                "createMeetingPayment",
                "POST",
                sendData
            );
            console.log('addResult', result)
            if (result && result.status) {
                setPrice('')
                onClose();
                setMeeting(true)
                toast.success('Instant Meeting Added Successfully', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            // setLinkModalShow(true);

        }
        else {
            toast.warning('Please Enter Price', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    return (
        <div>
            <div
                className={modalOpen ? "modal show fade" : "modal fade"}
                id="add-new-task-modal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="add-new-task-modalLabel"
                aria-hidden="true"
                style={{ display: modalOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
                    <div className="modal-content" style={{ width: "51%" }}>

                        <Card className="mb-0">
                            <CardBody className="border-bottom">
                                <div className="user-chat-border">
                                    <Row>
                                        <Col md={10} xs={10}>
                                            <h5 style={{ textAlign: "center" }}>
                                                Create Instance Meeting
                                            </h5>
                                        </Col>
                                        <Col md={2}>
                                            <div className="d-flex  mr-4 chat-head">
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-hidden="true"
                                                    onClick={() => onClose()}
                                                >
                                                    ×
                                                </button>
                                            </div>


                                        </Col>
                                    </Row>
                                </div>
                                <div class="form-group">
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Price"
                                        value={price}
                                        onChange={(e) =>
                                            setPrice(e.target.value)
                                        }
                                    />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button
                                        style={{ backgroundColor: "#a024b7", color: '#fff' }}
                                        className='btn'
                                        onClick={AddInstance}
                                    >
                                        Add
                                    </button>
                                </div>
                            </CardBody>

                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstantModal