import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const BreadCrump = ({ main, sub }) => {
  return (
    <div className="m-3">
      <Breadcrumb>
        {main && (
          <BreadcrumbItem>
            <a>{main}</a>
          </BreadcrumbItem>
        )}
        {sub && <BreadcrumbItem active>{sub}</BreadcrumbItem>}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrump;
