// export const ICESERVER = [
//     {
//         "url": "stun:global.stun.twilio.com:3478",
//         "urls": "stun:global.stun.twilio.com:3478"
//     },
//     {
//         "url": "turn:global.turn.twilio.com:3478?transport=udp",
//         "username": "d91bf611f31b44a0571e8500fbfa889b8800c2c501128a0357b494b6697e242c",
//         "urls": "turn:global.turn.twilio.com:3478?transport=udp",
//         "credential": "1k53nAqesHXu2fpSC6TGSBmqN1t3C8tNkAuDpyeEWgw="
//     },
//     {
//         "url": "turn:global.turn.twilio.com:3478?transport=tcp",
//         "username": "d91bf611f31b44a0571e8500fbfa889b8800c2c501128a0357b494b6697e242c",
//         "urls": "turn:global.turn.twilio.com:3478?transport=tcp",
//         "credential": "1k53nAqesHXu2fpSC6TGSBmqN1t3C8tNkAuDpyeEWgw="
//     },
//     {
//         "url": "turn:global.turn.twilio.com:443?transport=tcp",
//         "username": "d91bf611f31b44a0571e8500fbfa889b8800c2c501128a0357b494b6697e242c",
//         "urls": "turn:global.turn.twilio.com:443?transport=tcp",
//         "credential": "1k53nAqesHXu2fpSC6TGSBmqN1t3C8tNkAuDpyeEWgw="
//     }
// ]

import React, { useEffect, useState } from 'react'
import HttpClient from './utils/HttpClient';

const ICESERVER = []
function Server() {
    // const [ICESERVER, setICESERVER] = useState()
    alert("j")
    const getNewServer = async () => {
        let result = await HttpClient.requestData1(
            "new-twilio-token-create",
            "POST"
        )
        // if (result && result.status) {
        //     console.log(result.data, "lllw")
        // }
        console.log(result, "lllw")
    }

    useEffect(() => {
        getNewServer()
    }, [])
    return (
        <div>Server</div>
    )
}
export { ICESERVER }
export default Server