import moment from 'moment/moment';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import HttpClient from '../../../utils/HttpClient';
import SubscriptionPopUp from '../SubscriptionPlan/SubscriptionPopUp';
import MeetingPopUp from './MeetingPopUp';

const PaymentMeeting = () => {
    const [subsPopUp, setSubsPopUp] = useState(false);
    const { roomName } = useParams();
    const [peymentDetails, setPaymentDetails] = useState({});
    const [paymentStatus, setPaymentStatus] = useState(false);
    const userData = reactLocalStorage.getObject('userData')
    const history = useHistory()
    console.log('userDatapp', userData);
    console.log('paymentStatus', paymentStatus)

    // const getPaymentDetails = async () => {
    //     const result = await HttpClient.requestData('viewMeetingPayment', 'GET')
    //     console.log('viewpaymentres', result.data)
    //     let arr = [];
    //     arr = result.data.filter((item) => item.roomId == roomName)
    //     // console.log('arr',arr)
    //     setPaymentDetails(arr[0]);
    // }

    const userPayDetail = async () => {
        // console.log('roomName',roomName)
        const result = await HttpClient.requestData('singleRoomeId/' + roomName, 'GET')
        console.log('singleRoomeId', result)
        if (result && result.status) {
            setPaymentDetails(result.data[0]);
        }
    }
    console.log('peymentDetails', peymentDetails)

    const payStatus = async () => {
        const result = await HttpClient.requestData('paymentStatus/' + roomName, 'GET')
        console.log('paymentStatus', result.data[0].userId)
        if (result && result.status) {
            let arr = [];
            arr = result.data.filter(item => item.userId == userData._id);
            if (arr.length) {
                setPaymentStatus(true)
            }
        }
    }

    useEffect(() => {
        // getPaymentDetails()
        userPayDetail();
        payStatus();
    }, [])

    useEffect(() => {
        if (!Object.keys(userData).length) {
            history.push('/')
        }
        else if (paymentStatus || userData._id == peymentDetails.userId) {
            history.push('/meeting', { roomId: roomName })
        }
    }, [peymentDetails, userData, paymentStatus])

    // console.log('peymentDetails', peymentDetails, userData)
    // console.log('peymentDetails', Object.keys(userData).length)

    return (
        <div className='my-5'>
            <div className="d-flex justify-content-center">
                <div className="card" style={{ backgroundColor: "#212529", color: '#fff' }}>
                    <div className="card-body">
                        {/* <h5 className="card-title">Special title treatment</h5> */}
                        <p className="card-text">To join the meeting please pay</p>
                        <div className='d-flex justify-content-center'>
                            <button
                                style={{ backgroundColor: '#a024b7', color: '#fff' }}
                                className="btn"
                                onClick={() => {
                                    setSubsPopUp(true)
                                }}
                            >
                                Pay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={
                    subsPopUp
                        ? "modal single_modal show fade"
                        : "modal show fade"
                }
                tabIndex="-1"
                style={{ display: subsPopUp ? "block" : "none" }}
            >
                <MeetingPopUp
                    setShow={setSubsPopUp}
                    roomName={roomName}
                    meetingPrice={peymentDetails.price}
                />
            </div>
        </div>
    )
}

export default PaymentMeeting