import React from 'react';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import { CardBody, Col, Row } from 'reactstrap';


const ShareModal = ({ modalOpen = false, onClose, link }) => {
    console.log('modalOpen',modalOpen)
    return (
        <div>
            <div
                className={modalOpen ? "modal show fade" : "modal fade"}
                id="add-new-task-modal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="add-new-task-modalLabel"
                aria-hidden="true"
                style={{ display: modalOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
                    <div className="modal-content" style={{ width: "51%" }}>
                        <CardBody className="border-bottom">
                            <div className="user-chat-border">
                                <Row>
                                    <Col md={10} xs={10}>
                                        {/* <h5 style={{ textAlign: "center" }}>
                                            Create Schedule Meeting
                                        </h5> */}
                                    </Col>
                                    <Col md={2}>
                                        <div className="d-flex  mr-4 chat-head">
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-hidden="true"
                                                onClick={() => onClose()}
                                            >
                                                ×
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                        <div className="p-3 chat-input-section border-top">
                            <FacebookShareButton url={link} className='m-1'>
                                <FacebookIcon size={40} />
                            </FacebookShareButton>

                            <WhatsappShareButton url={link} className='m-1'>
                                <WhatsappIcon size={40} />
                            </WhatsappShareButton>

                            <EmailShareButton url={link} className='m-1'>
                                <EmailIcon size={40} />
                            </EmailShareButton>

                            <TwitterShareButton url={link} className='m-1'>
                                <TwitterIcon size={40} />
                            </TwitterShareButton>

                            <TelegramShareButton url={link} className='m-1'>
                                <TelegramIcon size={40} />
                            </TelegramShareButton>

                            <LinkedinShareButton url={link} className='m-1'>
                                <LinkedinIcon size={40} />
                            </LinkedinShareButton>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ShareModal