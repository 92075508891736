import React from "react";
import { Link } from "react-router-dom";

// import logo from "../Assets/Images/logo.png";

function index() {
  return (
    <div>
      <div className="Footer second ">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-4 Brder p-4 ">
              {/* <img
                src={logo}
                className="img-fluid mb-4"
                alt="img"
                style={{ width: "234px" }}
              /> */}
              <h3 className="mb-4">MySpiritualConnect</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officiis eum alias tempore ullam distinctio
              </p>
              <div className="mb-4">
                <Link className="readMore" to="/">
                  Read More
                </Link>
              </div>
              <div className="d-flex justify-content-center">
                <div className="socialBox">
                  <i class="fab fa-facebook-f"></i>
                </div>
                <div className="socialBox">
                  <i class="fab fa-twitter"></i>
                </div>
                <div className="socialBox">
                  <i class="fab fa-youtube"></i>
                </div>
                <div className="socialBox">
                  <i class="fab fa-linkedin-in"></i>
                </div>
              </div>
            </div>
            <div className="col-md-4 Brder p-4">
              <h4 className="mb-4">NEED OUR HELP</h4>
              <p className="mb-5">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Reiciendis dignissimos totam animi cumque aut.
              </p>
              <div>
                <Link className="btn PrimaryBtn" to="/contact">
                  FREE QUOTE
                </Link>
              </div>
            </div>
            <div className="col-md-4 p-4">
              <h4 className="mb-4">CONTACT US TODAY</h4>
              <p className="mb-5">
                Call Us 666 777 888 OR 111 222 333 Send an Email on
                contact@example.com Visit Us 123 Fake Street- London 12358
              </p>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email Address..."
                  aria-describedby="emailHelp"
                />
                <i class="fas fa-envelope"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
