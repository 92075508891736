import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";

import Jumbotron from "../../Component/Jumbotron";
import ServiceCard from "../../Component/ServiceCard";
import Footer2 from "../../Default/Footer2/index";
import Navbar from "../../Default/Navbar2/index";
import ServicePagination from "./ServicePagination";
// import SideDrop from "./Component/SideDrop";

import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import service1 from "../../Default/Assets/Images/Service/service1.png";
import service2 from "../../Default/Assets/Images/Service/service2.png";
import service3 from "../../Default/Assets/Images/Service/service3.png";
import { useSelector, useDispatch } from "react-redux"
import { setCategoryName, setSelectedCategory } from "../../redux/reducer/ServiceData"

const Index = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //console.log("servicetttttttttttttttt Id", props.id);
  const dispatch = useDispatch()
  const categoryname = useSelector(state => state.ServiceData.categoryname)
  const selectcategory = useSelector(state => state.ServiceData.selectcategory)
  //console.log("redux",categoryname)
  const services = [
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
  ];
  const [category, setcategory] = useState([]);
  // const [selectcategory, setselectcategory] = useState("");
  // const [categoryname, setcategoryname] = useState("");
  const [serviceList, setServiceList] = useState([]);
  console.log(serviceList, "jjjjww")

  const [service_id, setService_id] = useState(props.match.params.id);
  const [user_id, setuser_id] = useState("");
  const [login, setlogin] = useState(false);
  const [productpaginator, setproductpaginator] = useState({});
  const user = reactLocalStorage.getObject("userData");

  useEffect(() => {
    scrollTop();
    fetchCategory();

    getUserData();
  }, []);

  const scrollTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchCategory = async () => {
    let result = await HttpClient.requestData("service-category", "GET");
    //console.log("servicecategory", result);
    if (result && result.status) {
      let data = result.data;
      //console.log("data", data);

      setcategory(data);
    } else {
    }
  };

  const getUserData = async () => {
    let getAccount = reactLocalStorage.getObject("userData");
    console.log(getAccount, "fgjhkllk")
    if (
      getAccount &&
      getAccount != null &&
      Object.keys(getAccount).length > 0
    ) {
      setlogin(true);
      setuser_id(getAccount._id);
      //console.log(getAccount);
      // if(categoryname ===""){
      fetchServiceList(selectcategory, getAccount._id, null);

      // }
    } else {
      //console.log("not logged in");
      fetchServiceList1(
        "61122e6129890f40806a0002",
        "61618d5ddbd041128049d4bb",
        null
      );
    }
  };

  const fetchServiceList = async (id = null, userid = null, next = null) => {
    //console.log("idd------------", id, userid, next);
    // setLoading(true);
    let sendData = next ? next : "1";
    let result = await HttpClient.requestData(
      "service-category/shop-services/" + userid + "/" + id + "/" + sendData + "?currency=" + user.currency,
      "GET"
    );
    //console.log("serviceList", result);
    if (result && result.status) {
      //console.log("fetch Data from Api----",result.data);
      if (result.data.length || Object.keys(result.data).length) {
        if (result.data.itemsList.length) {
          let cur_data = result.data.itemsList.filter(
            (item) => item.user_id != user._id
          );
          //console.log("datacur", cur_data);

          setServiceList(cur_data);
          setproductpaginator(result.data.paginator);
        }
        else {
          setServiceList([]);
          setproductpaginator({});
        }
      } else {
        setServiceList([]);
        setproductpaginator({});
      }
    } else {
    }
  };

  const fetchServiceList1 = async (id, userid, next) => {
    //console.log("idd", id);
    // setLoading(true);
    let sendData = next ? next : "1";
    let result = await HttpClient.requestData1(
      "service-category/shop-services/" + userid + "/" + id + "/" + sendData + "?currency=" + "USD",
      "GET"
    );
    //console.log("serviceList", result);
    if (result && result.status) {
      //console.log(result.data);
      if (result.data.length || Object.keys(result.data).length) {
        if (result.data.itemsList.length) {
          let cur_data = result.data.itemsList.filter(
            (item) => item.user_id != userid
          );
          //console.log("datacur", cur_data);

          setServiceList(cur_data);
          setproductpaginator(result.data.paginator);
        }
        else {
          setServiceList([]);
          setproductpaginator({});
        }
      } else {
        setServiceList([]);
        setproductpaginator({});
      }
    } else {
    }
  };

  const nextpage = (val) => {
    //console.log("next", val);
    fetchServiceList(
      selectcategory != "" ? selectcategory : "61122e6129890f40806a0002",
      user._id,
      val
    );
    fetchServiceList1(
      selectcategory != "" ? selectcategory : "61122e6129890f40806a0002",
      "61618d5ddbd041128049d4bb",
      val
    );
  };

  return (
    <div className="ServiceList">
      <ReactTitle title="MySpiritualConnent - Service List" />
      <section>
        <Jumbotron heading="Service Listing" img={serviceJumboBg} />
        {/* <h1>nnvghjhhhww</h1> */}


      </section>
      <section className="container py-2" style={{ maxWidth: "1200px" }}>
        <h3 className="text-center mb-4 category_Name">
          Category Name:
          {/* 
          <h>jhjkfhdklljkldjklj</h> */}
          <span>{categoryname}</span>
        </h3>
        <select
          className="form-control"
          onChange={(val) => {
            let cur_data = [];
            cur_data = category.filter((item) => item._id == val.target.value);

            if (cur_data.length > 0) {
              //console.log(cur_data[0].name);
              dispatch(setCategoryName(cur_data[0].name));
            }

            dispatch(setSelectedCategory(val.target.value));
            fetchServiceList(val.target.value, user_id, null);
            fetchServiceList1(
              val.target.value,
              "61618d5ddbd041128049d4bb",
              null
            );
          }}
          value={
            selectcategory != "" ? selectcategory : "61122e6129890f40806a0002"
          }
        >
          <option value="">Select Category</option>
          {category.map((item, index) => {
            return (
              <option value={item._id} key={index}>
                {item.name}
              </option>
            );
          })}


        </select>
        <div className="row justify-content-end mb-3"></div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="container-fluid pb-4">
              <div className="row">
                {/* <div className="col-lg-4 col-md-4 mb-4"> */}
                <ServiceCard data={serviceList} />
                {/* <div className="row">
                    
                  </div> */}
                {/* </div> */}
              </div>
              {/* {serviceList.length > 0 ? (
                <div className="row">
                  {serviceList.map((item, index) => {
                    let arr = [];
                    // //console.log("img arr", item.image);
                    item.image.length > 0 &&
                      item.image.forEach((element) => {
                        if (element.ismain) {
                          if (arr.length > 0) {
                            arr[0] = { preview: element.preview, ismain: true };

                            // //console.log("Local Arr with true 1", arr);
                          } else {
                            arr.push({
                              preview: element.preview,
                              ismain: element.ismain,
                            });
                            // //console.log("Local Arr with true 2", arr);
                          }
                        } else {
                          if (arr.length > 0) {
                            // //console.log("Already a true value inside loop");
                          } else {
                            arr.push({
                              preview: item.image[0].preview,
                              ismain: false,
                            });
                            // //console.log("Local Arr 3", arr);
                          }
                        }
                      });
                    return (
                      <div className="col-lg-3 col-md-6 mb-4" key={index}>
                        <ServiceCard
                          data={item}
                          title={categoryname}
                          image={arr}
                          userData={reactLocalStorage.getObject("userData")}
                          sellerdetails={
                            item.seller_details.length > 0
                              ? item.seller_details[0]
                              : ""
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="">
                  <div
                    className="text-center mb-4 category_Name pt-5 h2"
                    style={{ textAlign: "center" }}
                  >
                    No Service Found
                  </div>
                </div>
              )} */}
            </div>
            {/* <div className="py-4 text-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <i class="fas fa-angle-left"></i>
                      </span>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link " href="#">
                      01
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      02
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      03
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      04
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <i class="fas fa-angle-right"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}
            <section className="container">
              <div className="row">
                <div className="col-md-12 ">
                  <ServicePagination
                    productpaginator={productpaginator}
                    nextpage={nextpage}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
