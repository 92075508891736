import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb

import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";

import HttpClient from "../../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import BreadCrump from "../../../Component/BreadCrump";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";

class OffWeekDays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [

        { title: "Off Week Days", link: "#" },
        { title: "Add & Manage Off Days", link: "#" },
      ],
      isLoading: false,
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],
      subServiceAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",
      sub_srvc_id: "",
      market_price: "",
      selling_price: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],
      hashtag: "",
      personalisation: "",
      details: "",
      price: "",
      allService: [],
      shopService: [],
      allService_id: "",
      offDate: new Date(),
      allShopService_id: "",
      to_time: "",
      from_time: "",
      available_duration: 0,
      slot_duration_id: "",

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      selectcategory: "",
      selectshopserviceid: "",
      edit_id: "",
      weekDay: [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
      ],
      slot_duration: [
        { label: "10", value: "10" },
        { label: "15", value: "15" },
        { label: "30", value: "30" },
      ],
      language: [{ label: "en", value: "en" }],
      lang: "",
      slot_time: "",
      selectedWeek: "",
      selectWeekName: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      //console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchService();
    // this.fetchData();
    this.fetchServicecategory();
    let sellerData = reactLocalStorage.getObject("userData");
    //console.log(sellerData);
    // this.fetchCat();
  };

  fetchService = async () => {

    let result = await HttpClient.requestData("service-category", "GET");
    if (result && result.status > 0) {
      this.setState({ allService: result.data });
      this.setState({ isLoading: false })
    }
    //console.log("allService", result);
    // let resultsub = await HttpClient.requestData("admin/sub-category", "GET");
    // //console.log("resultsub", resultsub);
    // if (resultsub && resultsub.status > 0) {
    //   this.setState({ subCategoryAll: resultsub.data });
    // }
  };

  fetchServicecategory = async (id) => {
    //console.log(id);
    this.setState({ isLoading: true })
    let userData = reactLocalStorage.getObject("userData");
    //console.log(userData._id);
    let result = await HttpClient.requestData(
      "shop/view-shopservice/" + userData._id + "/" + id,
      "GET"
    );
    if (result && result.status > 0) {
      this.setState({ shopService: result.data });
      this.setState({ isLoading: false })
    }
    //console.log("shopService", result);
  };

  fetchData = async (id) => {
    //console.log("hiiiii", id);
    // //console.log();
    //console.log(this.state.selectshopserviceid);
    this.setState({ isLoading: true })
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "shop-service/off-timings/" + id,
      "GET"
    );
    //console.log("getSlotData", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          offDate: moment(element.offDate).format("YYYY-MM-DD"),
          day: moment(element.offDate).format("dddd"),

          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  // this.delete(element, index);
                  this.deleteConfirm(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              {/* <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button> */}
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
      this.setState({ isLoading: false })
    } else {

      this.setState({
        data: [],
      });
      this.setState({ isLoading: false })
    }
  };


  deleteConfirm = async (item, index) => {
    this.setState({ isLoading: true })
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.delete(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };
  delete = async (item, index) => {
    this.setState({ isLoading: true })
    let result = await HttpClient.requestData(
      "/shop-service/offtiming/" + item._id,
      "DELETE"
    );
    //console.log(result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Deleted Successfully",
        type: "success",
      });
      toast.success("Deleted Successfully")

      this.fetchData(this.state.selectshopserviceid);
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);

      this.setState({ isLoading: false })
    }
  };
  edit = async (item, index) => {

    document.getElementById("offweekscrl").scrollIntoView({ behavior: "smooth" })
    //console.log("edititems", item);
    this.setState({
      slot_time: item.slot_duration,
      //selectWeekName: item.day_name,
      from_time: item.from,
      to_time: item.to,
      available_duration: item.available_duration,
      allShopService_id: item.shop_service_id,
      allService_id: item.category_id,
      edit_enable: true,
      edit_id: item._id,
      lang: item.language,
      selectWeekName: {
        label: item.day_name,
        value: item.day_name,
      },
      // slot_time:
    });
    //console.log("day", this.state.selectWeekName);
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    this.setState({ isLoading: true })
    let result = await HttpClient.requestData(
      "admin/product/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      toast.success("Status Updated Succfully")
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      //console.log("chunk", this.state.selectshopserviceid);
      this.fetchData(this.state.selectshopserviceid);
      this.setState({ isLoading: false })
    }
  };

  submit = async () => {
    this.setState({ isLoading: true })
    let sellerData = reactLocalStorage.getObject("userData");
    //console.log(sellerData);
    if (
      //   this.state.selectWeekName != "" &&
      // this.state.available_duration != "" &&
      //   this.state.from_time != "" &&
      //   this.state.to_time != "" &&
      this.state.allService_id != "" &&
      this.state.allShopService_id != "" &&
      this.state.offDate != ""
      //   this.state.slot_time != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          //   slot_duration: parseInt(this.state.slot_time),
          //   day_name: this.state.selectWeekName,
          //   from: this.state.from_time,
          //   to: this.state.to_time,
          //   language: "en",
          offDate: moment(this.state.offDate).format("YYYY-MM-DD"),
          //available_duration: this.state.available_duration,
          shop_service_id: this.state.allShopService_id,
          category_id: this.state.allService_id,
          seller_id: sellerData._id,
          // language: this.state.lang
        };
        //console.log("add-----", data);
        // return false
        result = await HttpClient.requestData(
          "shop-service/offtiming",
          "POST",
          data
        );
        if (result.status) {
          this.setState({ isLoading: false })
        }
      } else {
        data = {
          //   slot_duration: parseInt(this.state.slot_time),
          // day_name: this.state.selectWeekName,
          //   from: this.state.from_time,
          //   to: this.state.to_time,
          offDate: moment(this.state.offDate).format("YYYY-MM-DD"),
          //   language: "en",
          //available_duration: this.state.available_duration,
          shop_service_id: this.state.allShopService_id,
          category_id: this.state.allService_id,
          seller_id: sellerData._id,
          // language: this.state.lang
        };
        //console.log("edit-----", data);
        result = await HttpClient.requestData(
          "shop-service/offtiming" + this.state.edit_id,
          "PUT",
          data
        );
      }
      //console.log("result1", result);
      if (result && result.status) {
        toast.success(this.state.edit_enable
          ? "Slot Updated Successfully"
          : "Slot Added Successfully",)
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Slot Updated Successfully"
            : "Slot Added Successfully",

          type: "success",
          selectWeekName: "",
          from_time: "",
          to_time: "",
          available_duration: "",
          allShopService_id: "",
          allService_id: "",
          slot_time: "",
          lang: "",
        });

        this.fetchData(this.state.selectshopserviceid);
        this.setState({ isLoading: false })
      } else {
        toast.error(result.error)
        this.setState({
          alert: true,
          message: result.error,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
      toast.error("Please Fill Up All Details.")
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleAcceptedFiles = (files) => {
    // //console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);

    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    //console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      images: files[0],
      uploaded_images: arr,
    });
    //console.log("uploaded_images", this.state.uploaded_images);
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
      //console.log(user.id);
    } else {
    }
    // this.fetchData();
  };

  handleChange = async (event) => {
    this.setState({ cat_id: event.target.value });
  };
  handleServiceChange = async (event) => {
    let selectedservice = event.target.value;
    //console.log(selectedservice);
    this.setState({
      allService_id: selectedservice,
      allShopService_id: "",
    });
    //console.log(this.state.allService_id);
    this.fetchServicecategory(selectedservice);
  };

  handleCat = async (event) => {
    this.state.selectcategory = event.target.value;
    this.setState({});
    this.servicefetch(event.target.value);
  };

  handleShopServiceChange = async (event) => {
    let selectedShopservice = event.target.value;
    this.setState({ allShopService_id: selectedShopservice });
  };
  hehe = (eve) => {
    //console.log(JSON.parse(eve.target.value), "hehee");
  };
  handelservice = async (event) => {
    this.state.selectshopserviceid = event.target.value;
    this.setState({});
    this.fetchData(event.target.value);
  };
  handleWeekChange = async (selected) => {
    // //console.log("day", event.target.value);
    // this.state.selectWeekName = event.target.value;
    // //console.log("select Week", this.state.selectWeekName);
    // this.setState({});

    //console.log("day", selected);
    this.state.selectWeekName = selected;
    //console.log("select Week", this.state.selectWeekName);
    this.setState({});
  };
  handleSlotChange = async (event) => {
    this.state.slot_time = event.target.value;
    this.setState({});
    //console.log("slot Time", this.state.slot_time);
  };
  handleLangChange = async (event) => {
    this.state.lang = event.target.value;
    this.setState({});
    //console.log("slot Time", this.state.lang);
  };

  servicefetch = async (id) => {
    //console.log(id);
    this.setState({ isLoading: true })
    let userData = reactLocalStorage.getObject("userData");
    //console.log(userData._id);
    let result = await HttpClient.requestData(
      "shop/view-shopservice/" + userData._id + "/" + id,
      "GET"
    );
    if (result && result.status > 0) {
      this.setState({ shopService: result.data });
      this.setState({ isLoading: false })
    }
    //console.log("shopService", result);
  };

  getDuration = async (endtime) => {
    let start_time = moment(this.state.from_time, "HH:mm");
    //console.log(start_time);
    let end_time = moment(endtime, "HH:mm");
    //console.log(end_time);
    if (end_time.isAfter(start_time)) {
      let duration = moment.duration(end_time.diff(start_time));
      var hours = parseInt(duration.asHours());
      //console.log(hours);
      this.state.available_duration = hours;
      this.setState({});
    } else {
      toast.error("Start time can't be greater than endtime.")
      this.setState({
        alert: true,
        message: "Start time can't be greater than endtime.",
        type: "warning",
        available_duration: 0,
      });
    }
  };
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        {
          label: "Date",
          field: "offDate",
          sort: "asc",
          width: 100,
        },
        {
          label: "Day",
          field: "day",
          sort: "asc",
          width: 100,
        },
        // {
        //   label: "Available Duration",
        //   field: "available_duration",
        //   sort: "asc",
        //   width: 270,
        // },
        //   {
        //     label: "Service",
        //     field: "service",
        //     sort: "asc",
        //     width: 270,
        //   },
        // {
        //   label: "Category",
        //   field: "category_id",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <>
        <div className=" page-content">
          <Container fluid>
            <div id="offweekscrl"></div>
            <BreadCrump main="Off Week Days" sub="Add & Manage Off Days" />
            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <div style={{ height: 50 }}></div>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleServiceChange}
                          value={this.state.allService_id}
                        >
                          <option value="">Select Category</option>
                          {this.state.allService.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Shop Service
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleShopServiceChange}
                          value={this.state.allShopService_id}
                        >
                          <option value="">Select Shop Service</option>
                          {this.state.shopService.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Select Date
                      </Label>
                      <Col md={10}>
                        <DatePicker
                          selected={this.state.offDate}
                          //   onSelect={handleDateSelect} when day is clicked
                          onChange={(date) => this.setState({ offDate: date })}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                    <div style={{ height: 70 }}></div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <FormGroup row>
                    <Label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Category
                    </Label>
                    <Col md={10}>
                      <select
                        className="form-control"
                        onChange={this.handleCat}
                        value={this.state.selectcategory}
                      >
                        <option value="">Select Category</option>
                        {this.state.allService.map((item, index) => {
                          return (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Select Service
                    </Label>
                    <Col md={10}>
                      <select
                        className="form-control"
                        onChange={this.handelservice}
                        value={this.state.selectshopserviceid}
                      >
                        <option value="">Select Shop Service</option>
                        {this.state.shopService.map((item, index) => {
                          return (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </FormGroup>

                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
          {this.state.isLoading ? <div className="Loader-Show" style={{ position: "absolute", bottom: 323, left: 380, }}><RotatingLines
            visible={true}
            height="96"
            width="120"
            color="red"
            strokeColor="violet"
            strokeWidth="5"
            animationDuration="1"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          /> </div> : null}
        </div>
        {

        }
      </>
    );
  }
}

export default OffWeekDays;
