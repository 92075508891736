import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "./../../../utils/HttpClient";
import Jumbotron from "../../../Component/Jumbotron";
import Footer2 from "../../../Default/Footer2/index";
import Navbar2 from "../../../Default/Navbar2/index";
import EditProfilePic from "../Component/EditProfilePic";
import ProfDelete from "../Component/ProfDelete";
import { ToastContainer, toast } from "react-toastify";

import serviceJumboBg from "../../../Default/Assets/Images/Service/serviceJumboBg.png";
import ProfilePic from "../../../Default/Assets/Images/ProfilePic.png";
import Dumy from "../../../Default/Assets/Images/Profile.png";
import ProfDelIcon from "../../../Default/Assets/Images/ProfDelIcon.png";
import Edit from "../../../Default/Assets/Images/logo/Edit.png";

const Index = () => {
  const [profilePic, setProfilePic] = useState(false);
  const [profileDelete, setProfileDelete] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [phoneCode, setphoneCode] = useState("");
  const [about, setabout] = useState("");
  const [UserId, setUserId] = useState("");
  const [currentpass, setcurrentpass] = useState("");
  const [newpass, setnewpass] = useState("");
  const [conpass, setconpass] = useState("");
  const [proimage, setproimage] = useState("");
  const [currency, setcurrency] = useState("");
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  const [isfirstNameEmpty, setisfirstNameEmpty] = useState(false);
  const [islastNameEmpty, setislastNameEmpty] = useState(false);
  const [isPasswordEmpty, setisPasswordEmpty] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [currencyvalid, setcurrencyvalid] = useState("");
  const countriesPhoneCode = [
    {
      "code": "+7 840",
      "name": "Abkhazia"
    },
    {
      "code": "+93",
      "name": "Afghanistan"
    },
    {
      "code": "+355",
      "name": "Albania"
    },
    {
      "code": "+213",
      "name": "Algeria"
    },
    {
      "code": "+1 684",
      "name": "American Samoa"
    },
    {
      "code": "+376",
      "name": "Andorra"
    },
    {
      "code": "+244",
      "name": "Angola"
    },
    {
      "code": "+1 264",
      "name": "Anguilla"
    },
    {
      "code": "+1 268",
      "name": "Antigua and Barbuda"
    },
    {
      "code": "+54",
      "name": "Argentina"
    },
    {
      "code": "+374",
      "name": "Armenia"
    },
    {
      "code": "+297",
      "name": "Aruba"
    },
    {
      "code": "+247",
      "name": "Ascension"
    },
    {
      "code": "+61",
      "name": "Australia"
    },
    {
      "code": "+672",
      "name": "Australian External Territories"
    },
    {
      "code": "+43",
      "name": "Austria"
    },
    {
      "code": "+994",
      "name": "Azerbaijan"
    },
    {
      "code": "+1 242",
      "name": "Bahamas"
    },
    {
      "code": "+973",
      "name": "Bahrain"
    },
    {
      "code": "+880",
      "name": "Bangladesh"
    },
    {
      "code": "+1 246",
      "name": "Barbados"
    },
    {
      "code": "+1 268",
      "name": "Barbuda"
    },
    {
      "code": "+375",
      "name": "Belarus"
    },
    {
      "code": "+32",
      "name": "Belgium"
    },
    {
      "code": "+501",
      "name": "Belize"
    },
    {
      "code": "+229",
      "name": "Benin"
    },
    {
      "code": "+1 441",
      "name": "Bermuda"
    },
    {
      "code": "+975",
      "name": "Bhutan"
    },
    {
      "code": "+591",
      "name": "Bolivia"
    },
    {
      "code": "+387",
      "name": "Bosnia and Herzegovina"
    },
    {
      "code": "+267",
      "name": "Botswana"
    },
    {
      "code": "+55",
      "name": "Brazil"
    },
    {
      "code": "+246",
      "name": "British Indian Ocean Territory"
    },
    {
      "code": "+1 284",
      "name": "British Virgin Islands"
    },
    {
      "code": "+673",
      "name": "Brunei"
    },
    {
      "code": "+359",
      "name": "Bulgaria"
    },
    {
      "code": "+226",
      "name": "Burkina Faso"
    },
    {
      "code": "+257",
      "name": "Burundi"
    },
    {
      "code": "+855",
      "name": "Cambodia"
    },
    {
      "code": "+237",
      "name": "Cameroon"
    },
    {
      "code": "+1",
      "name": "Canada"
    },
    {
      "code": "+238",
      "name": "Cape Verde"
    },
    {
      "code": "+ 345",
      "name": "Cayman Islands"
    },
    {
      "code": "+236",
      "name": "Central African Republic"
    },
    {
      "code": "+235",
      "name": "Chad"
    },
    {
      "code": "+56",
      "name": "Chile"
    },
    {
      "code": "+86",
      "name": "China"
    },
    {
      "code": "+61",
      "name": "Christmas Island"
    },
    {
      "code": "+61",
      "name": "Cocos-Keeling Islands"
    },
    {
      "code": "+57",
      "name": "Colombia"
    },
    {
      "code": "+269",
      "name": "Comoros"
    },
    {
      "code": "+242",
      "name": "Congo"
    },
    {
      "code": "+243",
      "name": "Congo, Dem. Rep. of (Zaire)"
    },
    {
      "code": "+682",
      "name": "Cook Islands"
    },
    {
      "code": "+506",
      "name": "Costa Rica"
    },
    {
      "code": "+385",
      "name": "Croatia"
    },
    {
      "code": "+53",
      "name": "Cuba"
    },
    {
      "code": "+599",
      "name": "Curacao"
    },
    {
      "code": "+537",
      "name": "Cyprus"
    },
    {
      "code": "+420",
      "name": "Czech Republic"
    },
    {
      "code": "+45",
      "name": "Denmark"
    },
    {
      "code": "+246",
      "name": "Diego Garcia"
    },
    {
      "code": "+253",
      "name": "Djibouti"
    },
    {
      "code": "+1 767",
      "name": "Dominica"
    },
    {
      "code": "+1 809",
      "name": "Dominican Republic"
    },
    {
      "code": "+670",
      "name": "East Timor"
    },
    {
      "code": "+56",
      "name": "Easter Island"
    },
    {
      "code": "+593",
      "name": "Ecuador"
    },
    {
      "code": "+20",
      "name": "Egypt"
    },
    {
      "code": "+503",
      "name": "El Salvador"
    },
    {
      "code": "+240",
      "name": "Equatorial Guinea"
    },
    {
      "code": "+291",
      "name": "Eritrea"
    },
    {
      "code": "+372",
      "name": "Estonia"
    },
    {
      "code": "+251",
      "name": "Ethiopia"
    },
    {
      "code": "+500",
      "name": "Falkland Islands"
    },
    {
      "code": "+298",
      "name": "Faroe Islands"
    },
    {
      "code": "+679",
      "name": "Fiji"
    },
    {
      "code": "+358",
      "name": "Finland"
    },
    {
      "code": "+33",
      "name": "France"
    },
    {
      "code": "+596",
      "name": "French Antilles"
    },
    {
      "code": "+594",
      "name": "French Guiana"
    },
    {
      "code": "+689",
      "name": "French Polynesia"
    },
    {
      "code": "+241",
      "name": "Gabon"
    },
    {
      "code": "+220",
      "name": "Gambia"
    },
    {
      "code": "+995",
      "name": "Georgia"
    },
    {
      "code": "+49",
      "name": "Germany"
    },
    {
      "code": "+233",
      "name": "Ghana"
    },
    {
      "code": "+350",
      "name": "Gibraltar"
    },
    {
      "code": "+30",
      "name": "Greece"
    },
    {
      "code": "+299",
      "name": "Greenland"
    },
    {
      "code": "+1 473",
      "name": "Grenada"
    },
    {
      "code": "+590",
      "name": "Guadeloupe"
    },
    {
      "code": "+1 671",
      "name": "Guam"
    },
    {
      "code": "+502",
      "name": "Guatemala"
    },
    {
      "code": "+224",
      "name": "Guinea"
    },
    {
      "code": "+245",
      "name": "Guinea-Bissau"
    },
    {
      "code": "+595",
      "name": "Guyana"
    },
    {
      "code": "+509",
      "name": "Haiti"
    },
    {
      "code": "+504",
      "name": "Honduras"
    },
    {
      "code": "+852",
      "name": "Hong Kong SAR China"
    },
    {
      "code": "+36",
      "name": "Hungary"
    },
    {
      "code": "+354",
      "name": "Iceland"
    },
    {
      "code": "+91",
      "name": "India"
    },
    {
      "code": "+62",
      "name": "Indonesia"
    },
    {
      "code": "+98",
      "name": "Iran"
    },
    {
      "code": "+964",
      "name": "Iraq"
    },
    {
      "code": "+353",
      "name": "Ireland"
    },
    {
      "code": "+972",
      "name": "Israel"
    },
    {
      "code": "+39",
      "name": "Italy"
    },
    {
      "code": "+225",
      "name": "Ivory Coast"
    },
    {
      "code": "+1 876",
      "name": "Jamaica"
    },
    {
      "code": "+81",
      "name": "Japan"
    },
    {
      "code": "+962",
      "name": "Jordan"
    },
    {
      "code": "+7 7",
      "name": "Kazakhstan"
    },
    {
      "code": "+254",
      "name": "Kenya"
    },
    {
      "code": "+686",
      "name": "Kiribati"
    },
    {
      "code": "+965",
      "name": "Kuwait"
    },
    {
      "code": "+996",
      "name": "Kyrgyzstan"
    },
    {
      "code": "+856",
      "name": "Laos"
    },
    {
      "code": "+371",
      "name": "Latvia"
    },
    {
      "code": "+961",
      "name": "Lebanon"
    },
    {
      "code": "+266",
      "name": "Lesotho"
    },
    {
      "code": "+231",
      "name": "Liberia"
    },
    {
      "code": "+218",
      "name": "Libya"
    },
    {
      "code": "+423",
      "name": "Liechtenstein"
    },
    {
      "code": "+370",
      "name": "Lithuania"
    },
    {
      "code": "+352",
      "name": "Luxembourg"
    },
    {
      "code": "+853",
      "name": "Macau SAR China"
    },
    {
      "code": "+389",
      "name": "Macedonia"
    },
    {
      "code": "+261",
      "name": "Madagascar"
    },
    {
      "code": "+265",
      "name": "Malawi"
    },
    {
      "code": "+60",
      "name": "Malaysia"
    },
    {
      "code": "+960",
      "name": "Maldives"
    },
    {
      "code": "+223",
      "name": "Mali"
    },
    {
      "code": "+356",
      "name": "Malta"
    },
    {
      "code": "+692",
      "name": "Marshall Islands"
    },
    {
      "code": "+596",
      "name": "Martinique"
    },
    {
      "code": "+222",
      "name": "Mauritania"
    },
    {
      "code": "+230",
      "name": "Mauritius"
    },
    {
      "code": "+262",
      "name": "Mayotte"
    },
    {
      "code": "+52",
      "name": "Mexico"
    },
    {
      "code": "+691",
      "name": "Micronesia"
    },
    {
      "code": "+1 808",
      "name": "Midway Island"
    },
    {
      "code": "+373",
      "name": "Moldova"
    },
    {
      "code": "+377",
      "name": "Monaco"
    },
    {
      "code": "+976",
      "name": "Mongolia"
    },
    {
      "code": "+382",
      "name": "Montenegro"
    },
    {
      "code": "+1664",
      "name": "Montserrat"
    },
    {
      "code": "+212",
      "name": "Morocco"
    },
    {
      "code": "+95",
      "name": "Myanmar"
    },
    {
      "code": "+264",
      "name": "Namibia"
    },
    {
      "code": "+674",
      "name": "Nauru"
    },
    {
      "code": "+977",
      "name": "Nepal"
    },
    {
      "code": "+31",
      "name": "Netherlands"
    },
    {
      "code": "+599",
      "name": "Netherlands Antilles"
    },
    {
      "code": "+1 869",
      "name": "Nevis"
    },
    {
      "code": "+687",
      "name": "New Caledonia"
    },
    {
      "code": "+64",
      "name": "New Zealand"
    },
    {
      "code": "+505",
      "name": "Nicaragua"
    },
    {
      "code": "+227",
      "name": "Niger"
    },
    {
      "code": "+234",
      "name": "Nigeria"
    },
    {
      "code": "+683",
      "name": "Niue"
    },
    {
      "code": "+672",
      "name": "Norfolk Island"
    },
    {
      "code": "+850",
      "name": "North Korea"
    },
    {
      "code": "+1 670",
      "name": "Northern Mariana Islands"
    },
    {
      "code": "+47",
      "name": "Norway"
    },
    {
      "code": "+968",
      "name": "Oman"
    },
    {
      "code": "+92",
      "name": "Pakistan"
    },
    {
      "code": "+680",
      "name": "Palau"
    },
    {
      "code": "+970",
      "name": "Palestinian Territory"
    },
    {
      "code": "+507",
      "name": "Panama"
    },
    {
      "code": "+675",
      "name": "Papua New Guinea"
    },
    {
      "code": "+595",
      "name": "Paraguay"
    },
    {
      "code": "+51",
      "name": "Peru"
    },
    {
      "code": "+63",
      "name": "Philippines"
    },
    {
      "code": "+48",
      "name": "Poland"
    },
    {
      "code": "+351",
      "name": "Portugal"
    },
    {
      "code": "+1 787",
      "name": "Puerto Rico"
    },
    {
      "code": "+974",
      "name": "Qatar"
    },
    {
      "code": "+262",
      "name": "Reunion"
    },
    {
      "code": "+40",
      "name": "Romania"
    },
    {
      "code": "+7",
      "name": "Russia"
    },
    {
      "code": "+250",
      "name": "Rwanda"
    },
    {
      "code": "+685",
      "name": "Samoa"
    },
    {
      "code": "+378",
      "name": "San Marino"
    },
    {
      "code": "+966",
      "name": "Saudi Arabia"
    },
    {
      "code": "+221",
      "name": "Senegal"
    },
    {
      "code": "+381",
      "name": "Serbia"
    },
    {
      "code": "+248",
      "name": "Seychelles"
    },
    {
      "code": "+232",
      "name": "Sierra Leone"
    },
    {
      "code": "+65",
      "name": "Singapore"
    },
    {
      "code": "+421",
      "name": "Slovakia"
    },
    {
      "code": "+386",
      "name": "Slovenia"
    },
    {
      "code": "+677",
      "name": "Solomon Islands"
    },
    {
      "code": "+27",
      "name": "South Africa"
    },
    {
      "code": "+500",
      "name": "South Georgia and the South Sandwich Islands"
    },
    {
      "code": "+82",
      "name": "South Korea"
    },
    {
      "code": "+34",
      "name": "Spain"
    },
    {
      "code": "+94",
      "name": "Sri Lanka"
    },
    {
      "code": "+249",
      "name": "Sudan"
    },
    {
      "code": "+597",
      "name": "Suriname"
    },
    {
      "code": "+268",
      "name": "Swaziland"
    },
    {
      "code": "+46",
      "name": "Sweden"
    },
    {
      "code": "+41",
      "name": "Switzerland"
    },
    {
      "code": "+963",
      "name": "Syria"
    },
    {
      "code": "+886",
      "name": "Taiwan"
    },
    {
      "code": "+992",
      "name": "Tajikistan"
    },
    {
      "code": "+255",
      "name": "Tanzania"
    },
    {
      "code": "+66",
      "name": "Thailand"
    },
    {
      "code": "+670",
      "name": "Timor Leste"
    },
    {
      "code": "+228",
      "name": "Togo"
    },
    {
      "code": "+690",
      "name": "Tokelau"
    },
    {
      "code": "+676",
      "name": "Tonga"
    },
    {
      "code": "+1 868",
      "name": "Trinidad and Tobago"
    },
    {
      "code": "+216",
      "name": "Tunisia"
    },
    {
      "code": "+90",
      "name": "Turkey"
    },
    {
      "code": "+993",
      "name": "Turkmenistan"
    },
    {
      "code": "+1 649",
      "name": "Turks and Caicos Islands"
    },
    {
      "code": "+688",
      "name": "Tuvalu"
    },
    {
      "code": "+1 340",
      "name": "U.S. Virgin Islands"
    },
    {
      "code": "+256",
      "name": "Uganda"
    },
    {
      "code": "+380",
      "name": "Ukraine"
    },
    {
      "code": "+971",
      "name": "United Arab Emirates"
    },
    {
      "code": "+44",
      "name": "United Kingdom"
    },
    {
      "code": "+1",
      "name": "United States"
    },
    {
      "code": "+598",
      "name": "Uruguay"
    },
    {
      "code": "+998",
      "name": "Uzbekistan"
    },
    {
      "code": "+678",
      "name": "Vanuatu"
    },
    {
      "code": "+58",
      "name": "Venezuela"
    },
    {
      "code": "+84",
      "name": "Vietnam"
    },
    {
      "code": "+1 808",
      "name": "Wake Island"
    },
    {
      "code": "+681",
      "name": "Wallis and Futuna"
    },
    {
      "code": "+967",
      "name": "Yemen"
    },
    {
      "code": "+260",
      "name": "Zambia"
    },
    {
      "code": "+255",
      "name": "Zanzibar"
    },
    {
      "code": "+263",
      "name": "Zimbabwe"
    }
  ]

  const [isEmailEmpty, setisEmailEmpty] = useState(false);
  const [ismobiletendigit, setismobiletendigit] = useState(false);
  const currencyType = [
    {
      name: "INR",
    },
    {
      name: "USD",
    },
    {
      name: "GBP",
    },
    {
      name: "EUR",
    },
    {
      name: "CAD",
    },
  ];

  useEffect(() => {
    window.scrollTo(0,0);
    let a = reactLocalStorage.getObject("propic");
    //console.log("grtpic", a);
    FetchProfile();
  }, []);

  const FetchProfile = async () => {
    let getAccount = reactLocalStorage.getObject("userData");
    //console.log("grt", getAccount);

    setfirstName(getAccount.firstName);
    setlastName(getAccount.lastName);
    setemail(getAccount.email);
    setUserId(getAccount._id);
    setmobile(getAccount.mobile ? getAccount.mobile : "");
    setphoneCode(getAccount.mobile_code ? getAccount.mobile_code : "")
    setabout(getAccount.about ? getAccount.about : "");
    setcurrency(getAccount.currency ? getAccount.currency : "");
  };

  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    //console.log("valid", valid);
    if (valid == true) {
      setisEmailFromatValid(false);
    } else {
      setisEmailFromatValid(true);
    }
  }

  const Editprofile = async () => {
    // if (firstName == "") {
    //   setisfirstNameEmpty(true)
    // }
    // if (lastName == "") {
    //   setislastNameEmpty(true)
    // }
    // if (email == "") {
    //   setisEmailEmpty(true)
    // }
    // if (isEmailFromatValid == true) {
    //   setisEmailFromatValid(true)
    // }

    if (firstName != "" && lastName != "" && email != "" && phoneCode != "") {
      let data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobile: mobile,
        mobile_code: phoneCode,
        profile: reactLocalStorage.getObject("userData").profile,
        about: about,
        currency: currency,
      };
      //console.log("data", data);
      let result = await HttpClient.requestData(
        "myaccount/update-profile/" + UserId,
        "PUT",
        data
      );
      //console.log("update-profile", result);
      if (result && result.status) {
        //console.log("update-profile", result.data);

        toast.success("Profile Edited Successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reactLocalStorage.setObject("userData", result.data);
      } else {
        toast.warning(result.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const ChangePass = async () => {
    if (currentpass != "" && newpass != "" && conpass != "") {
      if (newpass == conpass) {
        let data = {
          old_password: currentpass,
          new_password: newpass,
          cnf_password: conpass,
        };
        //console.log("data", data);
        let result = await HttpClient.requestData(
          "myaccount/update-password/" + UserId,
          "PUT",
          data
        );
        //console.log("serviceList", result);
        if (result && result.status) {
          //console.log("kk", result.data);

          toast.success("Password changed successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          reactLocalStorage.setObject("userData", result.data);
        } else {
          toast.warning(result.message, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.warning("newpassword and confirm password not matched", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("Please fill up all the fields", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function validatePassword(password) {
    let regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let valid = regex.test(password);
    setIsPasswordValid(valid ? false : true);
  }

  const CallBackProfilePic = (val, img) => {
    //console.log("imahe", img);

    setProfilePic(false);
    setproimage(img);
    let getAccount = reactLocalStorage.getObject("userData");
    getAccount.profile = img; //adding new key in object
    reactLocalStorage.setObject("userData", getAccount);
    //console.log("userdat", reactLocalStorage.getObject("userData"));
  };

  const CallBackProfileDel = (val2) => {
    setProfileDelete(val2);
  };
  return (
    <div className="EditProfile">
      <ToastContainer />
      <ReactTitle title="MySpiritualConnent - Profile" />
      <section>
        <Jumbotron heading=" Profile" img={serviceJumboBg} />
      </section>
      <section className="py-5">
        <h2>Edit Profile</h2>
        <div className="container pb-md-5 px-3">
          <div className="row ProfileBox mb-md-5 ">
            <div className="col-md-12 px-0">
              <div className="container-fluid">
                <div className="row pb-4 pt-2">
                  <div className="col-md-6 px-0">
                    <div className="d-flex align-items-center">
                      <div className="ProfileImgCon mr-4">
                        {reactLocalStorage.getObject("userData").profile &&
                        reactLocalStorage.getObject("userData").profile !=
                          "" ? (
                          <img
                            src={
                              HttpClient.IMG_URL +
                              reactLocalStorage.getObject("userData").profile
                            }
                            className="img-fluid rounded-cirle"
                            alt="img"
                            style={{
                              width: "126px",
                              height: "126px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <img
                            src={Dumy}
                            className="img-fluid rounded-cirle"
                            alt="img"
                            style={{
                              width: "126px",
                              height: "126px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        <img
                          src={Edit}
                          className="img-fluid EditIcon "
                          alt="img"
                          style={{ cursor: "pointer" }}
                          onClick={() => setProfilePic(true)}
                        />
                      </div>
                      <div className="pl-3">
                        <h5 className="mb-0">{firstName + " " + lastName}</h5>
                        <div>
                          <span className="YourMail">{email}</span>
                          <span style={{ color: "#a9acb0", fontSize: "18px" }}>
                            Registered user
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-end">
                    <div className="text-right">
                      <button
                        className="btn DeleteBtn my-4"
                        onClick={() => setProfileDelete(true)}
                      >
                      {/* Delete Account */}  
                        {/* <i class="fas fa-user-times ml-1"></i> */}
                        <img
                          src={ProfDelIcon}
                          className="img-fluid ml-1"
                          alt="img"
                          style={{ height: "40px" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row py-5">
                  <div className="col-md-7">
                    <h5 className="mb-4">Account Info</h5>
                    <div className="container-fluid">
                      <div className="row mb-4">
                        <div className="col-sm-3 pl-0">
                          <label for="exampleInputEmail1">First Name</label>
                        </div>
                        <div className="col-sm-9 pl-0">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={firstName}
                            onChange={(val) => {
                              setfirstName(val.target.value);
                              if (val.target.value != "") {
                                setisfirstNameEmpty(false);
                              } else {
                                setisfirstNameEmpty(true);
                              }
                            }}
                          />
                          {isfirstNameEmpty ? (
                            <small style={{ color: "red" }}>
                              {" "}
                              Please enter FirstName.{" "}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-sm-3 pl-0">
                          <label for="exampleInputEmail1">Last Name</label>
                        </div>
                        <div className="col-sm-9 pl-0">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={lastName}
                            onChange={(val) => {
                              setlastName(val.target.value);
                              if (val.target.value != "") {
                                setislastNameEmpty(false);
                              } else {
                                setislastNameEmpty(true);
                              }
                            }}
                          />
                          {islastNameEmpty ? (
                            <small style={{ color: "red" }}>
                              {" "}
                              Please enter LastName.{" "}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-sm-3 pl-0">
                          <label for="exampleInputEmail1">Email Id</label>
                        </div>
                        <div className="col-sm-9 pl-0">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={email}
                            onChange={(val) => {
                              setemail(val.target.value);
                              if (val.target.value != "") {
                                setisEmailEmpty(false);
                                validateEmail(val.target.value);
                              } else {
                                setisEmailEmpty(true);
                              }
                            }}
                            readOnly
                          />
                          {isEmailEmpty ? (
                            <small style={{ color: "red" }}>
                              {" "}
                              Please enter email.{" "}
                            </small>
                          ) : isEmailFromatValid ? (
                            <small style={{ color: "red" }}>
                              {" "}
                              Please enter a valid email.{" "}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-sm-3 pl-0">
                          <label for="exampleInputEmail1"> Mobile No.</label>
                        </div>
                        <div className="col-sm-2 pl-0">
                        <select
                            className="form-control"
                            onChange={(val) => {
                              setphoneCode(val.target.value);
                            }}
                            value={phoneCode}
                          >
                            <option value="">Code</option>
                            {countriesPhoneCode.map((item, index) => {
                              return (
                                <option value={item.code} key={index}>{item.code}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-sm-7 pl-0">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            maxLength={15}
                            value={mobile}
                            onChange={(val) => {
                              if (val.target.value.match("^[0-9 ]*$") != null) {
                                setmobile(val.target.value);
                                //console.log("val", val.target.value);
                              if (val.target.value.length > 15 || val.target.value.length < 10) {
                                setismobiletendigit(true);
                              } else {
                                setismobiletendigit(false);
                              }
                              }                              
                            }}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          {ismobiletendigit ? (
                            <small style={{ color: "red" }}>
                              {" "}
                              Please enter 15 or 10 digit mobile no.{" "}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-sm-3 pl-0">
                          <label for="exampleInputEmail1">Currency</label>
                        </div>
                        <div className="col-sm-9 pl-0">
                          {/* <select
                            className="form-control"
                            onChange={(val) => {
                              setcurrency(val.target.value);
                            }}
                            value={currency}
                            readOnly
                          >
                            <option value="">Select Currency</option>
                            {currencyType.map((item, index) => {
                              return (
                                <option value={item.name} key={index}>{item.name}</option>
                              );
                            })}
                          </select> */}
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={currency}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-sm-3 pl-0">
                          <label for="exampleInputEmail1">About</label>
                        </div>
                        <div className="col-sm-9 pl-0 ">
                          <textarea
                            className="form-control mb-4"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            value={about}
                            onChange={(val) => {
                              setabout(val.target.value);
                            }}
                          ></textarea>
                          <button
                            className="btn PrimaryBtn"
                            onClick={() => {
                              Editprofile();
                            }}
                          >
                            SAVE CHANGES
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <h5 className="mb-4">Change Password</h5>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12 pl-0">
                          <div class="form-group mb-4">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Current Password"
                              value={currentpass}
                              onChange={(val) => {
                                setcurrentpass(val.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-4">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="New Password"
                              value={newpass}
                              onChange={(val) => {
                                setnewpass(val.target.value);
                                if (val.target.value) {
                                  setisPasswordEmpty(false);
                                  validatePassword(val.target.value);
                                } else {
                                  setisPasswordEmpty(true);
                                  validatePassword(val.target.value);
                                }
                              }}
                            />
                            {isPasswordEmpty ? (
                              <small style={{ color: "red" }}>
                                {" "}
                                Please enter a password.{" "}
                              </small>
                            ) : isPasswordValid ? (
                              <small style={{ color: "red" }}>
                                {" "}
                                Password must contain minimum eight characters,
                                at least one letter, one number and one special
                                character.{" "}
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group mb-4">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Confirm Password"
                              value={conpass}
                              onChange={(val) => {
                                setconpass(val.target.value);
                              }}
                            />
                          </div>
                          <button
                            onClick={() => {
                              ChangePass();
                            }}
                            className="btn PrimaryBtn"
                          >
                            CHANGE PASSWORD
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer2 /> */}
      {profilePic ? (
        <div
          className={profilePic ? "modal single_modal fade show" : "modal fade"}
          tabindex="-1"
          style={{ display: profilePic ? "block" : "none" }}
        >
          <EditProfilePic CallBackProfilePic={CallBackProfilePic} />
        </div>
      ) : null}
      {profileDelete ? (
        <div
          className={
            profileDelete ? "modal single_modal fade show" : "modal fade "
          }
          tabindex="-1"
          style={{ display: profileDelete ? "block" : "none" }}
        >
          <ProfDelete CallBackProfileDel={CallBackProfileDel} />
        </div>
      ) : null}
    </div>
  );
};

export default Index;
