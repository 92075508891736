import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import BreadCrump from "../../../Component/BreadCrump";
import uniqid from "uniqid";
// import Jitsi from 'react-jitsi'
// import JistsiMeeting from './JistsiMeeting';
import { JaaSMeeting, JitsiMeeting } from "@jitsi/react-sdk";
import { reactLocalStorage } from "reactjs-localstorage";
import MeetingModal from "./MeetingModal";
import { useHistory } from "react-router-dom";
import MeetingFormModal from "./MeetingFormModal";
import HttpClient from "../../../utils/HttpClient";
import InstantModal from "./InstantModal";
import { toast } from "react-toastify";
import ShareModal from "./ShareModal";


const init = {
  displayName: "",
  roomName: "",
  password: "",
};
function Meeting() {
  const user = reactLocalStorage.getObject("userData");
  const [meetingInfo, setMeetingInfo] = useState(init);
  const apiRef = useRef();
  const history = useHistory();
  const [roomName, setRoomName] = useState("");

  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false)
  const [linkModalShow, setLinkModalShow] = useState(false)
  const [instantShow, setInstantShow] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  console.log('shareModalOpen', shareModalOpen)

  const [meetingList, setMeetingList] = useState([])
  console.log('meetinglist', meetingList.length)


  const [meetingLink, setMeetingLink] = useState("")
  const [breadcrumbItems, setBreaCrum] = useState([
    { title: "Meeting", link: "#" },
    { title: "Create Meeting", link: "#" },
  ]);

  const generateduniqueid = () => {
    const id = uniqid();
    setMeetingInfo({
      ...meetingInfo,
      roomName: id,
    });
    setRoomName(id);
  };

  const [meeting, setMeeting] = useState(false);
  const handleApiReady = (apiObj) => {
    apiRef.current = apiObj;
    console.log("api ready", apiObj);
  };
  useEffect(() => {
    //console.log(meetingInfo)
    window.scroll(0, 0);
  }, [meetingInfo]);
  const renderSpinner = () => (
    <div
      style={{
        fontFamily: "sans-serif",
        textAlign: "center",
      }}
    >
      Loading..
    </div>
  );

  const getPaymentDetails = async () => {
    console.log('date')
    const result = await HttpClient.requestData('viewMeetingPayment', 'GET')
    console.log('viewpaymentres', result.data)
    if (result && result.status) {
      let arr = result.data.filter((item) => item.userId == user._id)
      setMeetingList(arr)
      // console.log('arr',arr)
    }
  }

  useEffect(() => {
    getPaymentDetails()
  }, [])

  const handleJitsiIFrameRef1 = (iframeRef) => {
    iframeRef.style.border = "10px solid #3d3d3d";
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "400px";
    iframeRef.style.marginBottom = "20px";
  };
  const createLink = () => {
    // setMeeting(true);
    setInstantShow(true)

    // history.push('/meeting', {
    //   state: {
    //     name:'pallab',
    //     roll:14
    //   }
    // })
    // if(roomName.trim() !=""){
    //     alert(`https://meet.jit.si/${roomName}`)

    // }
    // else{
    //     alert("Please enter a Room name")
    // }
  };

  // let link = 'http://localhost:3000/payment-meeting/'
  let link = 'https://myspiritual.page.link/payment-meeting/'
  const handleCopy = () => {
    navigator.clipboard.writeText(link + meetingInfo.roomName);

    toast.success('Copied Successfully', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const handleShare = () => {
    console.log('first')
    setShareModalOpen(true)
  }





  const createMeetingForLater = (roomId) => {
    // let link = `https://jitsi.myspiritualconnect.com/${meetingInfo.roomName}`
    // let link = `http://localhost:3000/payment-meeting/${meetingInfo.roomName}`
    // let link=`https://myspiritualconnect.com/payment-meeting/${meetingInfo.roomName}`

    // let link = `http://localhost:3000/payment-meeting/${roomId}`
    let link = `https://myspiritual.page.link/${roomId}`


    setMeetingLink(link)
    setLinkModalShow(true)

    // setModalShow(true)
  };
  if (meeting) {
    // console.log("meetingInfo.roomName", meetingInfo.roomName);
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrump main={"Create Meeting"} />
            <Card>
              <CardBody>
                <JitsiMeeting
                  roomName={meetingInfo.roomName}
                  domain={"jitsi.myspiritualconnect.com"}
                  userInfo={{
                    displayName: user?.firstName,
                    email: user?.email,
                  }}
                  spinner={renderSpinner}
                  configOverwrite={{
                    subject: "Spritual Meeting",
                    hideConferenceSubject: false,
                  }}
                  onApiReady={(externalApi) => handleApiReady(externalApi)}
                  onReadyToClose={() => {
                    setShow(false);
                    setMeeting(false);
                    setMeetingInfo(init);
                  }}
                  getIFrameRef={handleJitsiIFrameRef1}
                />
                <div>
                  <button className='btn btn-secondary mr-1' onClick={handleCopy}>Copy</button>
                  <button className='btn btn-secondary' onClick={handleShare}>Share</button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
        <ShareModal
          modalOpen={shareModalOpen}
          onClose={() => {
            setShareModalOpen(false)
          }}
          link={link + meetingInfo.roomName}
        />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrump main={"Create Meeting"} />
          <Card>
            <CardBody>
              <FormGroup row>
                <Label
                  htmlFor="example-text-input"
                  className="col-md-2 col-form-label"
                >
                  Room name
                </Label>
                <Col md={10}>
                  <InputGroup>
                    <Input
                      className="form-control"
                      type="text"
                      value={meetingInfo.roomName}
                      id="example-text-input"
                      placeholder="Enter Room name"
                      onChange={(e) => {
                        // setRoomName(e.target.value);
                        setMeetingInfo({
                          ...meetingInfo,
                          roomName: e.target.value,
                        });
                      }}
                    />
                    <InputGroupText
                      className="generate"
                      onClick={generateduniqueid}
                      style={{ cursor: 'pointer' }}
                    >
                      Generate room id
                    </InputGroupText>
                  </InputGroup>
                </Col>
              </FormGroup>

              <Button
                color="success"
                disabled={meetingInfo.roomName !== "" ? false : true}
                // onClick={createLink}
                onClick={() =>
                  setShow(true)
                }
              >
                Create Meeting link
              </Button>

              {show && (
                <CardBody className="md-4" style={{ width: "30%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button style={{ margin: "5px" }} onClick={createLink}>
                      Start an instant meeting
                    </Button>
                    <Button
                      style={{ margin: "5px" }}
                      // onClick={createMeetingForLater}
                      onClick={() => setModalShow(true)}
                    >
                      Create a meeting for later
                    </Button>
                  </div>
                </CardBody>
              )}
            </CardBody>
          </Card>
        </Container>

        <Container fluid>

          <div className="d-flex justify-content-center">
            <div className="row">
              {
                meetingList.length && meetingList.map((item, i) =>

                  <div className="row w-100 mt-3 bg-secondary p-2 text-light" key={i}>
                    <div className="col-md-10 col-sm-10">
                      <p>Room Id: {item.roomId}</p>
                      <p>Date: {item.date}</p>
                      <p>Time: {item.time}</p>
                      <p>price: {item.price}</p>
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <div
                        style={{ cursor: 'pointer' }}
                        className="text-success"
                        onClick={() => {
                          createMeetingForLater(item.roomId)
                          // setLinkModalShow(true)
                        }}
                      >
                        Get Link
                      </div>
                    </div>
                  </div>

                )
              }
            </div>
          </div>
        </Container>
      </div>


      <MeetingModal
        link={meetingLink}
        modalOpen={linkModalShow}
        onClose={() => {
          setShow(false)
          setMeeting(false);
          setMeetingInfo(init);
          setLinkModalShow(!linkModalShow)
        }}
      />

      {/* for later meeting */}
      <MeetingFormModal
        // link={meetingLink}
        modalOpen={modalShow}
        onClose={() => {
          setShow(false)
          setMeeting(false);
          // setMeetingInfo(init);
          setModalShow(!modalShow)
        }}
        setLinkModalShow={setLinkModalShow}
        user_Id={user._id}
        room_id={meetingInfo.roomName}
        getPaymentDetails={getPaymentDetails}
      />

      {/* for instant meeting */}
      <InstantModal
        modalOpen={instantShow}
        onClose={() => {
          setShow(false)
          setMeeting(false);
          // setMeetingInfo(init);
          setInstantShow(!instantShow)
        }}
        setLinkModalShow={setLinkModalShow}
        user_Id={user._id}
        room_id={meetingInfo.roomName}
        setMeeting={setMeeting}
      />

      {/* modal for Share */}

    </React.Fragment>
  );
}

export default Meeting;
