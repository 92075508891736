import React, { useState, useEffect } from "react";
import { ReactTitle } from "react-meta-tags";

import Jumbotron from "../../Component/Jumbotron";
import ServiceCard2 from "../../Component/serviceCard2";
import Footer2 from "../../Default/Footer2/index";
import Navbar from "../../Default/Navbar2/index";
// import SideDrop from "./Component/SideDrop";

import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import service1 from "../../Default/Assets/Images/Service/service1.png";
import service2 from "../../Default/Assets/Images/Service/service2.png";
import service3 from "../../Default/Assets/Images/Service/service3.png";

const Index = ({props,servicearr}) => {
 
  const services = [
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
    { imags: service1 },
    { imags: service2 },
    { imags: service3 },
  ];
  const [categoryname, setcategoryname] = useState("");
  const [searchList, setsearchList] = useState(reactLocalStorage.getObject("searcharr"));
  
 
  const [user_id, setuser_id] = useState("");
    const [login, setlogin] = useState(false);
  
  useEffect(() => {
    getUserData();
   //console.log("abc",reactLocalStorage.getObject("searcharr"));
  }, []);

 const getUserData = async ()=>
  {
      let getAccount = reactLocalStorage.getObject("userData");
      if (
      getAccount &&
      getAccount != null &&
      Object.keys(getAccount).length > 0
    ) {  
           
          setuser_id(getAccount._id)
        
    }
    else{
    
    }
  }


  
  return (
    <div className="ServiceList">
      <ReactTitle title="MySpiritualConnent - Service List" />
      <section>
        <Jumbotron heading="Search Listing" img={serviceJumboBg} />
      </section>
      <section className="container py-5">
        <h3 className="text-center mb-4 category_Name">Services</h3>
        <div className="row justify-content-end mb-3">
          {/* <div className="col-md-4 ">
            <div className="d-flex align-items-center pr-3">
              <p className="mb-0" style={{ minWidth: "5rem" }}>
                Sort by
              </p>
              <select class="form-control">
                <option>Newly Added</option>
                <option>old Adds</option>
              </select>
            </div>
          </div> */}
        </div>
        <div className="row">
        
          <div className="col-md-12 ">
            <div className="container-fluid pb-4">
              {reactLocalStorage.getObject("searcharr").length>0?
              <div className="row">
                {reactLocalStorage.getObject("searcharr").map((item, index) => {
                  return (
                    <div className="col-lg-3 col-md-6 mb-4" key={index}>
                      <ServiceCard2
                      data={item} 
                        userData={reactLocalStorage.getObject("userData")}
                      
                    
                      />
                    </div>
                  );
                })}
              </div>:<div className="">
               
                
                     <div
                     className="text-center mb-4 category_Name pt-5 h2"
                      style={{textAlign:"center"}}
                       >
                     No Search Found
                    </div>
                 
              </div>}
            </div>
            <div className="py-4 text-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <i class="fas fa-angle-left"></i>
                      </span>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link " href="#">
                      01
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      02
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      03
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      04
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <i class="fas fa-angle-right"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
