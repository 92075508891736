import React, { useEffect, useState } from "react";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Jumbotron from "../../Component/Jumbotron";
import Footer from "../../Default/Footer/index";
import Navbar2 from "../../Default/Navbar2/index";

import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import PhoneCall from "../../Default/Assets/Images/Contact/PhoneCall.png";
import Mail from "../../Default/Assets/Images/Contact/Mail.png";
import Location from "../../Default/Assets/Images/Contact/Location.png";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const Index = () => {
  const [result, setresult] = useState({});
  const [firstname, setfirstname] = useState({});
  const [lastname, setlastname] = useState({});
  const [emaill, setemaill] = useState({});
  const [reason, setreason] = useState({});
  const [message, setmessage] = useState({});
  const [image, setimage] = useState("");
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  const [clearimage, setclearimage] = useState("");
  useEffect(() => {
    getContact();
    window.scrollTo(0, 0);
  }, []);

  const getContact = async () => {
    let result = await HttpClient.requestData("contact-us-info", "GET");
    //console.log("object", result);
    if (result && result.status) {
      setresult(result.data[0]);
    }
  };
  const changePic = async (event) => {
    //console.log("files", event.target.files[0]);
    setimage(event.target.files[0]);
  };
  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    //console.log("valid", valid);
    if (valid == true) {
      setisEmailFromatValid(false);
    } else {
      setisEmailFromatValid(true);
    }
  }

  const Submit = async () => {
    let userData = reactLocalStorage.getObject("userData");
    if (
      firstname != "" &&
      lastname != "" &&
      emaill != "" &&
      reason != "" &&
      message != "" &&
      image != "" &&
      isEmailFromatValid == false
    ) {
      let data = new FormData();

      data.append("firstname", firstname);
      data.append("lastname", lastname);
      data.append("user_email", emaill);
      data.append("reason", reason);
      data.append("message", message);
      data.append("file", image);
      //console.log("data", data);
      let result = await HttpClient.fileUplode("contact-us", "POST", data);
      //console.log("POST", result);
      if (result && result.status) {
        let randomString = Math.random().toString(36);
        setclearimage(randomString);
        document.getElementById("firstName").value = "";
        document.getElementById("lastName").value = "";
        document.getElementById("Email").value = "";
        document.getElementById("Reason").value = "";
        document.getElementById("Message").value = "";
        toast.success("Data Saved Successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setfirstname("");
        setlastname("");
        setemaill("");
        setreason("");
        setmessage("");
        setimage("");
      } else {
        toast.warning(result.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else if (isEmailFromatValid) {
      toast.warning("Please enter valid email", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      toast.warning("Please fill up all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="ContactUs">
      <ToastContainer />
      <ReactTitle title="MySpiritualConnent - Contact Us" />
      <section>
        <Jumbotron heading="CONTACT US" img={serviceJumboBg} />
      </section>
      {/* <section className="py-5 ContactInfo">
        <h2 className="text-center AfterEffect">Need Help Contact Us</h2>
        <p className="text-center mb-4">
          Lorem ipsum dolor sit amet consectetur adipisicing quae odit elit.
          <br /> Eveniet rerum doloremque aliquid blanditiis.
        </p>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mx-auto mx-lg-0 mb-4 mb-lg-0">
              <div className="ContactBox">
                <img src={PhoneCall} className="img-fluid mb-5" alt="img" />
                <p className="mb-0">{result.phone}</p>

              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto mx-lg-0 mb-4 mb-lg-0">
              <div className="ContactBox">
                <img src={Mail} className="img-fluid mb-5" alt="img" />
                <p className="mb-0">{result.email}</p>

              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto mx-lg-0 mb-4 mb-lg-0">
              <div className="ContactBox">
                <img src={Location} className="img-fluid mb-5" alt="img" />
                <p className="mb-0">{result.address}</p>

              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="pt-3 pb-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7368.016535469472!2d88.3282178!3d22.5787941!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a027789468cd993%3A0x22b904376bd6f25!2sKalibabur%20Bazar!5e0!3m2!1sen!2sin!4v1623933029254!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: "0px" }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </section> */}
      {/* <section className=" FreeEnquiry">
        <h2 className="text-center mb-4">Fill Free Enquiry Form</h2>
        <p className="text-center">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          <br /> Saepe, totam in perferendis neque delectus dolore?
        </p>
      </section> */}
      <section className="ContactForm">
        <div className="container">
          <div className="col-md-5 offset-md-4">
          <div className="row">
            <div className="col-md-12 mb-4">
              <label className="message">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="First Name"
                // value={firstname}
                onChange={(val) => {
                  setfirstname(val.target.value);
                }}
              />
            </div>

            <div className="col-md-12 mb-4">
              <label className="message">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Last Name"
                onChange={(val) => {
                  setlastname(val.target.value);
                }}
              // value={lastname}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4">
              <label className="message">Email</label>
              <input
                type="email"
                className="form-control"
                id="Email"
                placeholder="Email"
                onChange={(val) => {
                  setemaill(val.target.value);
                  if (val.target.value != "") {
                    validateEmail(val.target.value);
                  } else {
                  }
                }}
              // value={emaill}
              />
              {isEmailFromatValid ? (
                <small style={{ color: "red" }}>
                  {" "}
                  Please enter a valid email Id.{" "}
                </small>
              ) : null}
            </div>
            <div className="col-md-12 mb-4">
              <label className="message">Reason</label>
              <div className="form-group">
                {/* <select className="form-control" id="exampleFormControlSelect1">
                  <option>Quote for What</option>
                  <hr></hr>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select> */}
                <input
                  type="email"
                  className="form-control"
                  id="Reason"
                  placeholder="Reason"
                  onChange={(val) => {
                    setreason(val.target.value);
                  }}
                // value={reason}
                />
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label for="Message" className="message">
                Message
              </label>
              <textarea
                class="form-control"
                id="Message"
                rows="5"
                onChange={(val) => {
                  setmessage(val.target.value);
                }}
              // value={message}
              ></textarea>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <input
                type="file"
                className="form-control-file"
                id="exampleFormControlFile1"
                onChange={changePic}
                key={clearimage || ""}
              />
            </div>
          </div>
          <div className="text-center pt-3">
            <button className="btn PrimaryBtn" onClick={Submit}>
              Send a Message
            </button>
          </div>
          </div>
          
        </div>
      </section>
      {/* <section>
        <Footer />
      </section> */}
    </div>
  );
};

export default Index;
