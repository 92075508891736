import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Default/Assets/Images/logo.png";
import forgotPassword from "../Default/Assets/Images/forgotPassword.png";
import validator from "validator";
// import HttpClient from "../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Firebase from "../Pages/Chat/firebase";
import firebase from 'firebase';


// import firebase from 'firebase/compat/app';

const Index = (props) => {
  const [register, setRegister] = useState(false);
  const [password, setPassword] = useState(false);
  // const db =getDatabase(Firebase)

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  const [currency, setcurrency] = useState("");
  const [confirmPass, setConfirmpass] = useState("");

  const [isFirstNameEmpty, setIsFirstNameEmpty] = useState(false);
  const [isLastNameEmpty, setIsLastNameEmpty] = useState(false);
  const [isPasswordEmpty, setisPasswordEmpty] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isConfirmPasswordEmpty, setIsConfirmPasswordEmpty] = useState(false);
  const [isConfirmPasswordMatch, setIsConfirmPasswordMatch] = useState(true);
  const [isEmailEmpty, setisEmailEmpty] = useState(false);
  const [terms, setterms] = useState(false);
  const [checkboxval, setcheckboxval] = useState("");
  const [ischecked, setischecked] = useState(false);

  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  const [passvalid, setpassvalid] = useState(false);
  const [conpassvalid, setConpassvalid] = useState(false);
  const [currencyvalid, setcurrencyvalid] = useState("");


  const [hidden, sethidden] = useState(true);
  const [hiddenl, sethiddenl] = useState(false);
  const [isGooogleLogin, setIsGoogleLogin] = useState(false);

  const initGoogleRegForm = {
    "email": "",
    "firstName": "",
    "lastName": "",
    "currency": "",
    "term": false
  }

  const [googleRegForm, setGoogleRegForm] = useState(initGoogleRegForm)

  console.log("isGooogleLogin", isGooogleLogin)

  // handle google Change
  const handleGooogleChange = (e) => {
    const { name, value } = e.target
    setGoogleRegForm(prev => ({ ...prev, [name]: value }))
  }

  // validate
  const validate = () => {
    let error = {}
    if (!googleRegForm?.firstName) {
      error.firstName = "First Name is Required"
      toast.error("First Name is Required")
      return true
    }

    if (!googleRegForm?.lastName) {
      error.lastName = "Last Name is Required"
      toast.error("Last Name is Required")
      return true
    }

    if (!googleRegForm?.currency) {
      error.currency = "Currency is Required"
      toast.error("Currency is Required")
      return true
    }

    if (!googleRegForm?.term) {
      error.term = "Please Check The Term & Condition"
      toast.error("Please Check The Term & Condition")
      return true
    }

    return false
  }

  // handle google register
  const handleGoogleRegister = async () => {
    if (validate()) return

    const data = {
      "email": googleRegForm.email,
      "password": "12345678",
      "firstName": googleRegForm.firstName,
      "lastName": googleRegForm.lastName,
      "currency": googleRegForm.currency
    }
    const result = await HttpClient.requestData("login-via-social", "POST", data)
    if (result && result?.status) {
      var starCountRef = Firebase.database().ref(
        "validUsers/" + result.data._id
      );
      starCountRef.on("value", (snapshot) => {
        const data = snapshot.val();
        //console.log("llll", data);
        if (data == null) {
          loginNow(result);
        } else if (data.valid == true) {
          loginNow(result);
        } else {
          toast.error("Your account has been deleted by Admin", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } else {
      toast.error(result?.message)
    }
  }

  const currencyType = [
    {
      name: "INR",
    },
    {
      name: "USD",
    },
    {
      name: "GBP",
    },
    {
      name: "EUR",
    },
    {
      name: "CAD",
    },
  ];

  const toggleShow = async () => {
    sethidden(!hidden);
    // //console.log("object",hidden)
  };

  const LoginDone = async (e) => {
    e.preventDefault()
    let passwordPattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let testPassword = passwordPattern.test(pass);
    if (email == "") {
      setisEmailEmpty(true);
    } else if (pass == "") {
      setisPasswordEmpty(true);
    } else if (testPassword !== true) {
      setIsPasswordValid(true);
    } else {
      let sendData = {
        email: email,
        password: pass,
      };
      // //console.log("object", sendData);
      let result = await HttpClient.requestData("login", "POST", sendData);
      // //console.log("result", result);
      //console.log("eee", result);
      if (result && result.status) {
        var starCountRef = Firebase.database().ref(
          "validUsers/" + result.data._id
        );
        starCountRef.on("value", (snapshot) => {
          const data = snapshot.val();
          //console.log("llll", data);
          if (data == null) {
            loginNow(result);
          } else if (data.valid == true) {
            loginNow(result);
          } else {
            toast.error("Your account has been deleted by Admin", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      }

      //       if (result && result.status) {
      //         // //console.log("result", result);
      //         // setwarning(false);
      //         // setwarningg(false);
      //         // //console.log("www", result.data);

      // //         var starCountRef = Firebase.database().ref('validUsers/'+"2222fhdlfdkjh");
      // // starCountRef.on('value', (snapshot) => {
      // //   const data = snapshot.val();
      // //   //console.log("llll",data);
      // // });
      //         reactLocalStorage.setObject("userData", result.data);

      //         reactLocalStorage.setObject("loginstatus", true);
      //         reactLocalStorage.setObject("loginToken", result.data.token);
      //         // setsuccess(true);
      //         props.callBackLogin(false);

      //         toast.success(result.message, {
      //           position: "bottom-right",
      //           autoClose: 2000,
      //           hideProgressBar: false,
      //           closeOnClick: true,
      //           pauseOnHover: true,
      //           draggable: true,
      //           progress: undefined,
      //         });
      //         setTimeout(function () {
      //           window.location.href = "/home";
      //         }, 30);
      //       }
      else {
        // setwarning(true);
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  function loginNow(result) {
    if (result && result.status) {
      //console.log("result", result);
      // setwarning(false);
      // setwarningg(false);
      // //console.log("www", result.data);

      //         var starCountRef = Firebase.database().ref('validUsers/'+"2222fhdlfdkjh");
      // starCountRef.on('value', (snapshot) => {
      //   const data = snapshot.val();
      //   //console.log("llll",data);
      // });
      reactLocalStorage.setObject("userData", result.data);

      reactLocalStorage.setObject("loginstatus", true);
      reactLocalStorage.setObject("loginToken", result.data.token);
      // setsuccess(true);
      props.callBackLogin(false);

      toast.success(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(function () {
        window.location.href = "/home";
      }, 30);
    } else {
      // setwarning(true);
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  // const LoginDone = async () => {
  //   if (email == "") {
  //     setisEmailEmpty(true);
  //   }
  //   if (pass == "") {
  //     setisPasswordEmpty(true);
  //   }
  //   if (pass != /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/) {
  //     setIsPasswordValid(true)
  //   }

  //   if (email != "" && pass != "") {
  //     let sendData = {
  //       email: email,
  //       password: pass,
  //     };
  //     //console.log("object", sendData);
  //     let result = await HttpClient.requestData("login", "POST", sendData);
  //     //console.log("result", result);
  //     if (result && result.status) {
  //       // //console.log("result", result);
  //       // setwarning(false);
  //       // setwarningg(false);
  //       //console.log("www", result.data);
  //       reactLocalStorage.setObject("userData", result.data);
  //       reactLocalStorage.setObject("loginstatus", true);
  //       reactLocalStorage.setObject("loginToken", result.data.token);
  //       // setsuccess(true);
  //       props.callBackLogin(false);

  //       toast.success(result.message, {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       setTimeout(function () {
  //         window.location.href = "/home";
  //       }, 30);
  //     } else {
  //       // setwarning(true);
  //       toast.error(result.message, {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   }
  // };

  const SignUp = async (e) => {
    e.preventDefault()
    let passwordPattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let testPassword = passwordPattern.test(pass);
    if (firstname == "") {
      setIsFirstNameEmpty(true);
    } else if (lastname == "") {
      setIsLastNameEmpty(true);
    } else if (email == "") {
      setisEmailEmpty(true);
    } else if (pass == "") {
      setisPasswordEmpty(true);
    } else if (testPassword !== true) {
      setIsPasswordValid(true);
    } else if (ischecked == false) {
      setterms(true);
    } else if (currency == "") {
      setcurrencyvalid(true);
    } else {
      let sendData = {
        firstName: firstname,
        lastName: lastname,
        // phone: phone,
        email: email,
        password: pass,
        currency: currency,
      };

      let result = await HttpClient.requestData("register", "POST", sendData);
      //console.log("signUp----", result);
      if (result && result.status) {
        //console.log("eee", result);
        let time4 = new Date().getTime().toString();
        Firebase.database()
          .ref("validUsers/" + result.data._id)
          .set({
            email: email,
            valid: true,
          });
        var starCountRef = Firebase.database().ref(
          "validUsers/" + result.data._id
        );
        starCountRef.on("value", (snapshot) => {
          const data = snapshot.val();
          //console.log("llll", data);
        });

        // set(ref(Firebase, 'validUsers/' + email), {
        //   valid:true
        // });
        toast.success(result.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        reactLocalStorage.setObject("userData", result.data);
        reactLocalStorage.setObject("loginstatus", true);
        reactLocalStorage.setObject("loginToken", result.data.token);
        // setsuccess(true);
        props.callBackLogin(false);

        setTimeout(function () {
          window.location.href = "/home";
        }, 3000);

        // setSignin(true);
        // setSignup(false);
      } else {
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // const SignUp = async () => {
  //   if (firstname == "") {
  //     setIsFirstNameEmpty(true);
  //   }
  //   if (lastname == "") {
  //     setIsLastNameEmpty(true);
  //   }
  //   if (email == "") {
  //     setisEmailEmpty(true);
  //   }
  //   if (pass == "") {
  //     setisPasswordEmpty(true);
  //   }
  //   if (ischecked == false) {
  //     setterms(true);
  //   }

  //   // let patternn =
  //   //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;

  //   // let passresult = patternn.test(pass);

  //   // if (!validator.isEmail(email)) {
  //   //   setisEmailFromatValid(true);
  //   // }

  //   // if (passresult !== true) {
  //   //   setpassvalid(true);
  //   // }

  //   if (
  //     firstname != "" &&
  //     lastname != "" &&
  //     email != "" &&
  //     pass != "" &&
  //     ischecked
  //   ) {
  //     let sendData = {
  //       firstName: firstname,
  //       lastName: lastname,
  //       // phone: phone,
  //       email: email,
  //       password: pass,
  //     };

  //     let result = await HttpClient.requestData("register", "POST", sendData);
  //     //console.log("rrr", result);
  //     if (result && result.status) {
  //       //console.log("eee");

  //       toast.success(result.message, {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });

  //       reactLocalStorage.setObject("userData", result.data);
  //       reactLocalStorage.setObject("loginstatus", true);
  //       reactLocalStorage.setObject("loginToken", result.data.token);
  //       // setsuccess(true);
  //       props.callBackLogin(false);

  //       setTimeout(function () {
  //         window.location.href = "/home";
  //       }, 3000);

  //       // setSignin(true);
  //       // setSignup(false);
  //     } else {
  //       toast.error(result.message, {
  //         position: "bottom-right",
  //         autoClose: 1000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   }
  // };

  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    //console.log(valid);
    setisEmailFromatValid(valid ? false : true);
  }

  function validatePassword(password) {
    let regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let valid = regex.test(password);
    setIsPasswordValid(valid ? false : true);
  }


  // google login
  const googleLogin = async (email, name) => {
    const nameArr = name?.split(" ");
    const fname = nameArr?.slice(0, nameArr?.length - 1).join(" ");
    const lname = nameArr?.slice(-1);

    const sendData = {
      "email": email,
      "password": "12345678",
      "firstName": "",
      "lastName": "",
      "currency": ""
    }

    console.log("sendDatafgg", sendData)
    // setGoogleEmail(email)
    const result = await HttpClient.requestData("login-via-social", "POST", sendData);
    console.log("glogin", result)
    if (result && result?.status) {
      var starCountRef = Firebase.database().ref(
        "validUsers/" + result.data._id
      );
      starCountRef.on("value", (snapshot) => {
        const data = snapshot.val();
        //console.log("llll", data);
        if (data == null) {
          loginNow(result);
        } else if (data.valid == true) {
          loginNow(result);
        } else {
          toast.error("Your account has been deleted by Admin", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } else {
      // toast.error(res?.message);
      setRegister(true)
      setIsGoogleLogin(true)
    }
  }

  // google login function click
  const handleGoogleLogin = () => {
    // if (!formValue.userType) {
    //   toast.error(`Please select ${language.business} or ${language.personal}`);
    //   return
    // }


    const provider = new firebase.auth.GoogleAuthProvider()
    // console.log("googlrr")

    // const auth = getAuth();
    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        setGoogleRegForm(prev => ({ ...prev, email: user?.email }))
        googleLogin(user?.email, user?.displayName)
        // console.log("userggg", user)
        // IdP data available in result.additionalUserInfo.profile.
        // ...

      }).catch((error) => {

        // Handle Errors here.
        console.log("errordfff", error)
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });
  }


  // function validateConfirmPassword(confirmPassword) {
  //   if (pass.length > 0 && isPasswordValid) {
  //     if (pass === confirmPassword) {
  //       setIsConfirmPasswordMatch(true);
  //     } else {
  //       setIsConfirmPasswordMatch(false);
  //     }
  //   }
  // }

  return (
    <div className="Auth">
      <div className="modal-dialog">
        <div className="modal-content single_content">

          {
            isGooogleLogin
              ?
              <main className="main">
                <div className="container">
                  <ToastContainer />
                  <section className="wrapper row">
                    <div className="col-md-12">

                      <div className="d-flex justify-content-between">
                        <span></span>
                        <span
                          className=" single_cross "
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => props.callBackLogin(false)}
                        >
                          ×
                        </span>
                      </div>

                      <form onSubmit={SignUp}>
                        <div class="form-group mb-4 pt-2">
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="First name"
                            name="firstName"
                            value={googleRegForm.firstName}
                            onChange={(val) => handleGooogleChange(val)}
                          />
                          {isFirstNameEmpty ? (
                            <small style={{ color: "red" }}>
                              {" "}
                              Please enter first Name.{" "}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                        <div class="form-group mb-4 pt-2">
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Last Name"
                            name="lastName"
                            value={googleRegForm.lastName}
                            onChange={(val) => handleGooogleChange(val)}
                          />
                          {isLastNameEmpty ? (
                            <small style={{ color: "red" }}>
                              {" "}
                              Please enter last Name.{" "}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>

                        <div class="form-group mb-4">
                          <select
                            className="form-control"
                            name="currency"
                            value={googleRegForm.currency}
                            onChange={(val) => handleGooogleChange(val)}
                          >
                            <option value="">Select Currency</option>
                            {currencyType.map((item, index) => {
                              return (
                                <option value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          {currencyvalid ? (
                            <small style={{ color: "red" }}>
                              {" "}
                              Please select currency.{" "}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>

                        <div class="form-group form-check mb-4">
                          <input
                            type="checkbox"
                            checked={googleRegForm.term ? true : false}
                            class="form-check-input"
                            id="exampleCheck1"
                            name="term"
                            value={googleRegForm.term}
                            onChange={(val) => setGoogleRegForm(prev => ({ ...prev, term: val.target.checked }))}
                          />
                          <label
                            class="form-check-label font-weight-bold"
                            for="exampleCheck1"
                          >
                            I accept the{" "}
                            <span style={{ color: "#6899E1" }}
                              onClick={() => {
                                window.location.href = "/item-condition";
                              }}
                            >
                              Terms & Condition
                            </span>{" "}
                            and{" "}
                            <span style={{ color: "#6899E1" }}
                              onClick={() => {
                                window.location.href = "/privacy";
                              }}
                            >
                              Privacy Policy
                            </span>
                          </label>
                          {terms ? (
                            <small style={{ color: "red" }}>
                              {" "}
                              accept the Terms & condition.{" "}
                            </small>
                          ) : null}
                        </div>
                        <div className="mb-4">
                          <button
                            type="button"
                            class="btn w-100 active AuthTab "
                            onClick={() => handleGoogleRegister()}
                          >
                            Sign Up
                          </button>
                        </div>
                      </form>

                    </div>
                  </section>
                </div>
              </main>
              :
              <main className="main">
                <div className="container">
                  <ToastContainer />
                  <section className="wrapper row">
                    {/* <div className="col-md-5 sideBg">
                  <div className="SideContent">
                    <img src={logo} className="img-fluid mb-3" alt="img" />
                    {register ? (
                      <p>
                        Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor
                        sit amet adipisicing elit Nihil expedita.
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div> */}
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between">
                        <span></span>
                        <span
                          className=" single_cross "
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => props.callBackLogin(false)}
                        >
                          ×
                        </span>
                      </div>
                      {password ? (
                        <div className="p-4 ">
                          <div className="text-center">
                            <img
                              src={forgotPassword}
                              className="img-fluid"
                              alt="img"
                              style={{ height: "230px" }}
                            />
                          </div>
                          <h3 className="mb-4">Forgot Your Password?</h3>
                          <p className="mb-3">
                            Enter the email addres you've used to register with us
                            and we will send you pasword reset instruction
                          </p>
                          <form className="pb-3">
                            <input
                              type="email"
                              className="form-control mb-3"
                              placeholder="Email"
                            />
                            <button
                              type="submit"
                              className="btn w-100 active AuthTab"
                              style={{ fontSize: "18px" }}
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      ) : (
                        <div className="p-4">
                          <div className="mb-4">
                            <span
                              className={
                                register ? "AuthTab w-50" : "active AuthTab w-50"
                              }
                              onClick={() => {
                                //console.log("login");
                                setRegister(false);
                                setisEmailEmpty(false);
                                setisEmailFromatValid(false);
                                setisPasswordEmpty(false);
                                // setIsPasswordValid(false);
                              }}
                            >
                              Login
                            </span>
                            <span
                              className={
                                register ? "active AuthTab w-50" : "AuthTab w-50"
                              }
                              onClick={() => {
                                //console.log("register");
                                setRegister(true);
                                setIsFirstNameEmpty(false);
                                setIsLastNameEmpty(false);
                                setisEmailEmpty(false);
                                setisEmailFromatValid(false);
                                setisPasswordEmpty(false);
                                // setIsPasswordValid(false);
                              }}
                            >
                              Sign Up
                            </span>
                          </div>
                          <div>

                            {register ? (
                              <form onSubmit={SignUp}>
                                <div class="form-group mb-4 pt-2">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="First name"
                                    value={firstname}
                                    onChange={(val) => {
                                      setFirstname(val.target.value);
                                      if (val.target.value) {
                                        setIsFirstNameEmpty(false);
                                      } else {
                                        setIsFirstNameEmpty(true);
                                      }
                                    }}
                                  />
                                  {isFirstNameEmpty ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Please enter first Name.{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div class="form-group mb-4 pt-2">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Last Name"
                                    value={lastname}
                                    onChange={(val) => {
                                      setLastname(val.target.value);
                                      if (val.target.value) {
                                        setIsLastNameEmpty(false);
                                      } else {
                                        setIsLastNameEmpty(true);
                                      }
                                    }}
                                  />
                                  {isLastNameEmpty ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Please enter last Name.{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div class="form-group mb-4">
                                  <input
                                    type="Email"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(val) => {
                                      setemail(val.target.value);
                                      if (val.target.value) {
                                        setisEmailEmpty(false);
                                        validateEmail(val.target.value);
                                      } else {
                                        setisEmailEmpty(true);
                                        validateEmail(val.target.value);
                                      }
                                    }}
                                  />
                                  {isEmailEmpty ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Please enter email.{" "}
                                    </small>
                                  ) : isEmailFromatValid ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Please enter a valid email.{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  class="form-group mb-4"
                                  style={{ position: "relative" }}
                                >
                                  <input
                                    type={hidden ? "password" : "text"}
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Password"
                                    value={pass}
                                    onChange={(val) => {
                                      setpass(val.target.value);
                                      if (val.target.value) {
                                        setisPasswordEmpty(false);
                                        validatePassword(val.target.value);
                                      } else {
                                        setisPasswordEmpty(true);
                                        validatePassword(val.target.value);
                                      }
                                    }}
                                  />
                                  {isPasswordEmpty ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Please enter a password.{" "}
                                    </small>
                                  ) : isPasswordValid ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Password must contain minimum eight
                                      characters, at least one letter, one number
                                      and one special character.{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                  <span
                                    className="my-auto mr-2 text-muted"
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    {/* <i className="far fa-eye"></i> */}
                                    <button
                                      className="my-auto mr-0 btn"
                                      type="button"
                                      onClick={toggleShow}
                                    >
                                      <i
                                        className={
                                          hidden ? "fa fa-eye-slash" : "far fa-eye"
                                        }
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </span>
                                </div>
                                <div class="form-group mb-4">
                                  <select
                                    className="form-control"
                                    onChange={(val) => {
                                      setcurrency(val.target.value);

                                      if (val.target.value == "") {
                                        setcurrencyvalid(true);
                                      } else {
                                        setcurrencyvalid(false);
                                      }
                                    }}
                                    value={currency}
                                  >
                                    <option value="">Select Currency</option>
                                    {currencyType.map((item, index) => {
                                      return (
                                        <option value={item.name}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {currencyvalid ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Please select currency.{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {/* <div class="form-group mb-4">
                              <input
                                type="password"
                                class="form-control"
                                id="exampleInputPassword1"
                                placeholder="Confirm Password"
                                value={confirmPass}
                                onChange={(val) => {
                                  setConfirmpass(val.target.value);
                                  if (val.target.value.length === 0) {
                                    setIsConfirmPasswordEmpty(true);
                                  } else {
                                    setIsConfirmPasswordEmpty(false);
                                    validateConfirmPassword(val.target.value);
                                  }
                                }}
                              />
                              {isConfirmPasswordEmpty ? (
                                <small style={{ color: "red" }}>
                                  {" "}
                                  Please enter the password again to confirm.{" "}
                                </small>
                              ) : (
                                ""
                              )}
                              {!isConfirmPasswordEmpty &&
                              !isConfirmPasswordMatch ? (
                                <small style={{ color: "red" }}>
                                  {" "}
                                  Password and confirm password does not match.{" "}
                                </small>
                              ) : (
                                ""
                              )}
                            </div> */}
                                <div class="form-group form-check mb-4">
                                  <input
                                    type="checkbox"
                                    checked={ischecked ? true : false}
                                    class="form-check-input"
                                    id="exampleCheck1"
                                    onChange={(val) => {
                                      //console.log(val.target.value);
                                      setischecked(!ischecked);
                                      // setcheckboxval("checked");
                                      if (val.target.value) {
                                        setterms(false);
                                      } else {
                                        setterms(true);
                                      }
                                    }}
                                  />
                                  <label
                                    class="form-check-label font-weight-bold"
                                    for="exampleCheck1"
                                  >
                                    I accept the{" "}
                                    <span style={{ color: "#6899E1" }}
                                      onClick={() => {
                                        window.location.href = "/item-condition";
                                      }}
                                    >
                                      Terms & Condition
                                    </span>{" "}
                                    and{" "}
                                    <span style={{ color: "#6899E1" }}
                                      onClick={() => {
                                        window.location.href = "/privacy";
                                      }}
                                    >
                                      Privacy Policy
                                    </span>
                                  </label>
                                  {terms ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      accept the Terms & condition.{" "}
                                    </small>
                                  ) : null}
                                </div>
                                <div className="mb-4">
                                  <button
                                    type="submit"
                                    class="btn w-100 active AuthTab "
                                    onClick={SignUp}
                                  // disabled={
                                  //   email.length === 0 ||
                                  //   !isPasswordValid ||
                                  //   pass.length === 0 ||
                                  //   !isEmailFromatValid ||
                                  //   firstname.length === 0 ||
                                  //   lastname.length === 0 ||
                                  //   pass !== confirmPass
                                  //     ? "disabled"
                                  //     : ""
                                  // }
                                  >
                                    Sign Up
                                  </button>
                                </div>
                                <div className="socialAuth">
                                  <h5 className="text-center mb-3">
                                    Login via a social Network
                                  </h5>

                                  <div className="pb-3">
                                    <Link to="/..">
                                      <span className=" FBbtn  socialBtn">
                                        <span className="FbIconBrdr Icon">
                                          <i class="fab fa-facebook-f"></i>
                                        </span>

                                        <span className="pl-1 d-inline-block">
                                          Sign in with Facebook
                                        </span>
                                      </span>
                                    </Link>

                                    <Link>
                                      <span className=" Googlebtn socialBtn" onClick={() => handleGoogleLogin()}>
                                        <span className="Icon GoogleIconBrdr">
                                          <i class="fab fa-google-plus-g"></i>
                                        </span>

                                        <span className="pl-1 d-inline-block">
                                          Sign in with Google
                                        </span>
                                      </span>
                                    </Link>

                                  </div>
                                </div>
                              </form>
                            ) : (
                              <form onSubmit={LoginDone}>
                                <div class="form-group mb-4 pt-2">
                                  <input
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(val) => {
                                      setemail(val.target.value);
                                      if (val.target.value) {
                                        setisEmailEmpty(false);
                                        validateEmail(val.target.value);
                                      } else {
                                        setisEmailEmpty(true);
                                        validateEmail(val.target.value);
                                      }
                                    }}
                                  />
                                  {isEmailEmpty ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Please enter an email.{" "}
                                    </small>
                                  ) : isEmailFromatValid ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Please enter a valid email.{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div class="form-group mb-4">
                                  <div
                                    class="form-group mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    <input
                                      type={hiddenl ? "text" : "password"}
                                      class="form-control"
                                      id="loginPassword"
                                      placeholder="Password"
                                      value={pass}
                                      onChange={(val) => {
                                        setpass(val.target.value);
                                        if (val.target.value) {
                                          setisPasswordEmpty(false);
                                          validatePassword(val.target.value);
                                        } else {
                                          setisPasswordEmpty(true);
                                          validatePassword(val.target.value);
                                        }
                                      }}
                                    />
                                    <div style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer" }}>
                                      <a onClick={() => sethiddenl(!hiddenl)} style={{ boxShadow: "none", backgroundColor: "transparent", border: "none" }}><i className={hiddenl ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                                    </div>
                                  </div>

                                  {isPasswordEmpty ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Please enter a password.{" "}
                                    </small>
                                  ) : isPasswordValid ? (
                                    <small style={{ color: "red" }}>
                                      {" "}
                                      Password must contain minimum eight
                                      characters, at least one letter, one number
                                      and one special character.{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}


                                </div>
                                <div className="mb-4 pt-2">
                                  <button
                                    type="submit"
                                    class="btn w-100 active AuthTab "
                                    onClick={LoginDone}
                                  // disabled={
                                  //   email.length === 0 ||
                                  //   !isPasswordValid ||
                                  //   pass.length === 0 ||
                                  //   !isEmailFromatValid
                                  //     ? "disabled"
                                  //     : ""
                                  // }
                                  >
                                    Login
                                  </button>
                                </div>
                                <div className="mb-4">
                                  <button
                                    type="button"
                                    class="btn w-100 active AuthTab "
                                    onClick={() => setPassword(true)}
                                  >
                                    Forgot Password
                                  </button>
                                </div>
                                <div className="socialAuth">
                                  <h5 className="text-center mb-3">
                                    Login Via a social Network
                                  </h5>
                                  <div className="pb-4">
                                    <span className=" FBbtn  socialBtn">
                                      <span className="FbIconBrdr Icon">
                                        <i class="fab fa-facebook-f"></i>
                                      </span>

                                      <span className="pl-1 d-inline-block">
                                        Sign in with Facebook
                                      </span>
                                    </span>

                                    <span className=" Googlebtn socialBtn" onClick={() => handleGoogleLogin()}>
                                      <span className="Icon GoogleIconBrdr">
                                        <i class="fab fa-google-plus-g"></i>
                                      </span>

                                      <span className="pl-1 d-inline-block">
                                        Sign in with Google
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      )}


                    </div>
                  </section>
                </div>
              </main>
          }
        </div>
      </div>
    </div>
  );
};

export default Index;
