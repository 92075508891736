import React, { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../../utils/HttpClient";
import { Card, CardGroup, Col, Container, Row } from "reactstrap";

// import Card from 'react-bootstrap/Card';
// import CardGroup from 'react-bootstrap/CardGroup';
import DescriptionPopup from "./DescriptionPopup";
import SubscriptionPopUp from "./SubscriptionPopUp";
import Swal from "sweetalert2";
import BreadCrump from "../../../Component/BreadCrump";
import { useUserFetch } from "../../../Providers/User";
import BodyModal from "../../../Component/BodyModal";
import { RotatingLines } from "react-loader-spinner";
const SubscriptionPlan = () => {
  const history = useHistory();
  const { subscriptionTaken, setSubscriptionTaken } = useUserFetch();

  const pack = [
    { time: "1 MONTH", price: "15" },
    { time: "3 MONTHS", price: "40" },
    { time: "6 MONTHS", price: "70" },
  ];

  const [subscription, setSubscription] = useState([]);
  const [subsDetail, setSubdetail] = useState("");
  const [servicemodal, setServicemodal] = useState(false);
  const [approvedStatus, setapprovedStatus] = useState([]);
  const sellerApprovalId = reactLocalStorage.getObject("seller_approval_id");
  const [isLoading, setLoading] = useState(false);
  const userdata = reactLocalStorage.getObject("userData");
  const [subsPopUp, setSubsPopUp] = useState(false);
  const [subsDetails, setSubdetails] = useState({});
  const [descriptionShow, setDescriptionShow] = useState(false);
  const [desDetail, setDesDetail] = useState("");
  const [isClicked, setClicked] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(false);

  useEffect(() => {
    //console.log(reactLocalStorage.getObject("userData"));
    fetchSublist();
    fetchStatus();
    window.scrollTo(0, 0);
  }, []);

  const callBackService = (val) => {
    setServicemodal(val);
    fetchSublist();
  };

  const fetchStatus = async () => {
    let obj = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller-approval-status/" + reactLocalStorage.getObject("userData")._id,
      "GET"
    );
    console.log("seller-approval-status", result);
    if (result && result.status) {
      setapprovedStatus(result.data);
      obj.type = result.data.type;
      reactLocalStorage.setObject("userData", obj);
    } else {
    }
  };
  console.log("ppooww", isLoading)
  const fetchSublist = async () => {
    //console.log("idd", userdata._id);
    setLoading(true);
    let data = {
      currency: userdata.currency,
    };
    let result = await HttpClient.requestData(
      "listSubs/" + userdata._id,
      "GET",
      data
    );
    console.log("subscriptionList", result);
    if (result && result.status) {
      let data = result.data;
      console.log("dataaaa", data);

      data.forEach((sub) => {
        if (sub.speakers.length > 0) {
          setIsSubscribe(true);
        } else {
        }
      });

      setSubscription(data);


      setTimeout(() => {
        setLoading(false);
      }, 100);
      //console.log("subscription", subscription);
    } else {
    }
  };

  const buySubscription = async (item) => {
    let sendData = {
      userid: userdata._id,
      subscr_id: item._id,
      seller_comission: item.seller_comission,
      price: item.price,
      no_of_listing: item.no_of_listing,
      currency: userdata.currency,
    };
    //console.log("object", sendData);

    let result = await HttpClient.requestData(
      "subscription-purchase",
      "POST",
      sendData
    );
    //console.log("result", result);
    if (result && result.status) {
      reactLocalStorage.setObject("userData", result.userData);
      const type = reactLocalStorage.getObject("userData").type;
      if (type === "Seller") {
        reactLocalStorage.setObject("sellerType", { status: true });
      } else if (type === "User") {
        reactLocalStorage.setObject("sellerType", { status: false });
      }
      toast.success(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchSublist();

      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 3000);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleDetails = (data) => {

    setSubdetail(data);
  };
  const unSubscribe = async (item) => {
    // //console.log("object", sendData);
    let result = await HttpClient.requestData(
      "subscription/" + userdata._id,
      "PUT"
    );
    //console.log("result", result);
    if (result && result.status) {
      reactLocalStorage.setObject("userData", result.userData);
      const type = reactLocalStorage.getObject("userData").type;
      if (type === "Seller") {
        reactLocalStorage.setObject("sellerType", { status: true });
      } else if (type === "User") {
        reactLocalStorage.setObject("sellerType", { status: false });
      }
      setSubscriptionTaken(false);
      // history.push("/dashboard");
      // location.reload();
      // window.location.href=

      toast.warning(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      fetchSublist();

      setTimeout(() => {
        // window.location.href = "/seller";
      }, 3000);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleUnsubscribe = (subs) => {
    Swal.fire({
      title: "Unsubscribe?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        unSubscribe(subs).then(() => {
          Swal.fire(
            "Unsubscrubed!",
            "You have unsubscribed Successfully ",
            "success"
          );
        });
      }
    });
  };

  return (
    <>
      <div className="subscription">
        <BreadCrump main="subscription-plan" sub="Purchase Subscription" />
        <div>
          <div className="row my-3">
            <div className="col-md-12 text-center">
              <h1>Subscription Plan</h1>
              <br />
              <h4>Subscribe First to Access the Seller Panel</h4>
            </div>
          </div>
          <div className="row subscription_sec" style={{ margin: "0 10px" }}>
            <div className="Toastify" />
            {subscription.map((subs, index) => {
              return (
                <div className="col-md-4 my-3">
                  <div className="card Subs_card">
                    <div className="card-body text-center">
                      <h5
                        className="card-title"
                        style={{ fontWeight: "bolder" }}
                      >
                        {subs.name[0].toUpperCase() + subs.name.substring(1)}
                      </h5>
                      <h6 className="card-subtitle mb-2 ">
                        {" "}
                        {userdata.currency == "INR"
                          ? "₹"
                          : userdata.currency == "CAD"
                            ? "$"
                            : userdata.currency == "USD"
                              ? "$"
                              : userdata.currency == "GBP"
                                ? "£"
                                : userdata.currency == "EUR"
                                  ? "€"
                                  : "$"}
                        {subs.price}
                      </h6>
                      <h6 className="card-subtitle mb-2 pt-2 ">{subs.duration} Month</h6>
                      <h6 className="card-subtitle mb-2 pt-2 ">Comission: {subs.seller_comission}%</h6>
                      <p
                        onClick={() => handleDetails(subs.description)}
                        className="card-text mt-3"
                      >
                        {subs.description.slice(0, 30)}
                        <BodyModal buttonLabel="Read More..." body={subsDetail} />
                      </p>
                    </div>
                    <div className="px-2">
                      <div className="text-center">
                        <button className="btn buy_btn mb-3">
                          {subs.speakers.length > 0 ? (
                            <div
                              style={{ width: "fit-content" }}
                              onClick={(subs) => handleUnsubscribe(subs)}
                              disabled={
                                approvedStatus.seller_approval == false
                                  ? true
                                  : false
                              }
                              id="-btn-"
                              className="-btn- -btn-clicked-"
                            >
                              Subscribed
                            </div>
                          ) : (
                            <div
                              // onClick={handleClickn}
                              onClick={() => {
                                if (!subscriptionTaken) {
                                  setSubsPopUp(true);
                                  setSubdetails(subs);
                                }

                                // buySubscription(subs);
                                // setServicemodal(true);
                              }}
                              disabled={
                                subscriptionTaken
                                // approvedStatus.seller_approval == false
                                //   ? true
                                //   : false
                              }
                              id="-btn-"
                              className={`-btn- ${isSubscribe && "dis-able"}`}
                              style={{ cursor: subscriptionTaken && "none" }}
                            >
                              Subscribe
                            </div>
                          )}
                        </button>
                      </div>
                      <div
                        className={
                          subsPopUp
                            ? "modal single_modal show fade"
                            : "modal show fade"
                        }
                        tabIndex="-1"
                        style={{ display: subsPopUp ? "block" : "none" }}
                      >
                        <SubscriptionPopUp
                          item={subsDetails}
                          setShow={setSubsPopUp}
                          fetchSublist={fetchSublist}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {isLoading ? <div className="Loader-Show" style={{ position: "absolute", bottom: 323, left: 380, }}><RotatingLines
          visible={true}
          height="96"
          width="120"
          color="red"
          strokeColor="violet"
          strokeWidth="5"
          animationDuration="1"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        /> </div> : null}
      </div>
      {/* <div className="page-content">
        <ToastContainer />
        subs page
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {subscription.map((subs, index) => {
            return (
              <>
                <div className="-card-bg-xl">
                  <div className="-card-title-">
                    <h3 className="-anim-scale-down">
                      {subs.name[0].toUpperCase() + subs.name.substring(1)}{" "}
                      Subscription
                    </h3>
                    <h5 className={`-title-anim-fade- ${subs.name}`}>
                      {" "}
                      
                      {userdata.currency == "INR"
                        ? "₹"
                        : userdata.currency == "CAD"
                          ? "$"
                          : userdata.currency == "USD"
                            ? "$"
                            : userdata.currency == "GBP"
                              ? "£"
                              : userdata.currency == "EUR"
                                ? "€"
                                : "$"}
                      {subs.price}
                    </h5>
                    <div
                      onClick={() => {
                        //console.log("clicked");
                        setDesDetail(subs.description);
                        setDescriptionShow(true);
                      }}
                      className="-description-"
                    >
                      <p className="-desc-text-">
                        {" "}
                        {subs.description.slice(0, 30)}
                        ...ReadMore
                      </p>
                    </div>
                  </div>
                  {subs.speakers.length > 0 ? (
                    <div
                      onClick={(subs) =>
                        handleUnsubscribe(subs)
                      }
                      disabled={
                        approvedStatus.seller_approval == false ? true : false
                      }
                      id="-btn-"
                      className="-btn- -btn-clicked-"
                    >
                      Subscribed
                    </div>
                  ) : (
                    <div
                      // onClick={handleClickn}
                      onClick={() => {
                        setSubsPopUp(true)
                        setSubdetails(subs)
                        // buySubscription(subs);
                        // setServicemodal(true);
                      }}
                      disabled={
                        approvedStatus.seller_approval == false ? true : false
                      }
                      id="-btn-"
                      className={`-btn- ${isSubscribe && 'dis-able'}`}
                    >
                      Subscribe
                    </div>
                  )}
                  
                  <div
                    className={
                      descriptionShow
                        ? "modal single_modal show fade"
                        : "modal show fade"
                    }
                    tabIndex="-1"
                    style={{ display: descriptionShow ? "block" : "none" }}
                  >
                    <DescriptionPopup
                      description={desDetail}
                      setShow={setDescriptionShow}
                    />
                  </div>
                  <div
                    className={
                      subsPopUp
                        ? "modal single_modal show fade"
                        : "modal show fade"
                    }
                    tabIndex="-1"
                    style={{ display: subsPopUp ? "block" : "none" }}
                  >
                    <SubscriptionPopUp
                      item={subsDetails}
                      setShow={setSubsPopUp}
                    />
                  </div>
                </div>
              </>
              // <MyCard cardType={subs.name} price={subs.price}  desc={subs.description}/>
            );
          })}
        </div>
      </div> */}
    </>
  );
};

export default SubscriptionPlan;
