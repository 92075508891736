import React, { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../utils/HttpClient";

const UserContext = React.createContext();

export const useUserFetch = () => React.useContext(UserContext);


export const UserContextProvider = ({ children }) => {
  const [userCredential, setUserCredential] = useState(null);
  const [iceServerData, setIceserverData] = useState([])

  const [subscriptionTaken, setSubscriptionTaken] = useState(false)
  const getNewServer = async () => {
    let result = await HttpClient.requestData(
      "new-twilio-token-create",
      "POST"
    )
    if (result && result.status) {
      setIceserverData(result.data)
    }
    console.log(result, "llr4lw")
  }

  useEffect(() => {
    getNewServer()
  }, [])

  useEffect(() => {
    // alert("hgbh")
    fetchUser();
    // if(user.email)
  }, []);
  async function fetchUser() {
    // alert("")
    const user = reactLocalStorage.getObject("userData");
    //console.log(user);
    if (user._id) {
      let result = await HttpClient.requestData(
        `singleUserProfile/${user._id}`,
        "GET"
      );
      //console.log("userData1", result);
      if (result.status) {
        setUserCredential(result.result);
      }
    }

  };
  return (
    <UserContext.Provider
      value={{
        userCredential,
        setUserCredential,
        fetchUser,
        subscriptionTaken,
        setSubscriptionTaken,
        iceServerData

      }}
    >
      {children}
    </UserContext.Provider>
  );
};
