import React, { useEffect, useState } from "react";
import { ReactTitle } from "react-meta-tags";

import Footer2 from "../../Default/Footer2/index";
import Navbar from "../../Default/Navbar2/index";
import Jumbotron from "../../Component/Jumbotron";
import { Link } from "react-router-dom";
import serviceJumboBg from "../../Default/Assets/Images/Service/serviceJumboBg.png";
import PsychicIcon from "../../Default/Assets/Images/logo/PsychicIcon.png";
import ReikiIcon from "../../Default/Assets/Images/logo/ReikiIcon.png";
import EnergyHealing from "../../Default/Assets/Images/logo/EnergyHealing.png";
import SpiritualCoach from "../../Default/Assets/Images/logo/SpiritualCoach.png";
import MeditationIcon from "../../Default/Assets/Images/logo/MeditationIcon.png";
import OracleReaderIcon from "../../Default/Assets/Images/logo/OracleReaderIcon.png";
import TarrorCardIcon from "../../Default/Assets/Images/logo/TarrorCardIcon.png";
import MediumIcon from "../../Default/Assets/Images/logo/MediumIcon.png";
import NumerologyIcon from "../../Default/Assets/Images/logo/NumerologyIcon.png";
import FaceReadingIcon from "../../Default/Assets/Images/logo/FaceReadingIcon.png";
import GemologyIcon from "../../Default/Assets/Images/logo/GemologyIcon.png";
import PalmistryIcon from "../../Default/Assets/Images/logo/PalmistryIcon.png";
import HttpClient from "../../utils/HttpClient";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchService();
  }, []);
  const forecasts = [
    { img: PsychicIcon, title: "Psychic" },
    { img: ReikiIcon, title: "Reiki" },
    { img: EnergyHealing, title: "Distance Energy Healing" },
    { img: SpiritualCoach, title: "Spiritual Coach" },
    { img: MeditationIcon, title: "Meditation" },
    { img: OracleReaderIcon, title: "Oracle Reader" },
    { img: TarrorCardIcon, title: "Tarror Card Reader" },
    { img: MediumIcon, title: "Medium" },
    { img: NumerologyIcon, title: "Numerology" },
    { img: FaceReadingIcon, title: "Face Reading" },
    { img: GemologyIcon, title: "Gemology" },
    { img: PalmistryIcon, title: "Palmistry" },
  ];
  const [service, setService] = useState([]);

  const fetchService = async () => {
    let result = await HttpClient.requestData("service-category", "GET");
    //console.log("servicecategory", result);
    if (result && result.status) {
      let data = result.data;
      setService(data);
    } else {
    }
  };
  return (
    <div className="category_List">
      <ReactTitle title="MySpiritualConnent - Category List" />
      <section>
        <Jumbotron heading="Category Listing" img={serviceJumboBg} />
      </section>
      <section className="SpiritualConnect mt-5">
        <div className="container">
        <div className="row">
          {service.map((item, index) => {
            return (
              <div
                className=" col-md-3 col-sm-6 mb-5 px-3 text-center test"
                key={index}
              >
                <Link to={"/service-list/" + item._id}>
                  <img
                    src={HttpClient.IMG_URL + item.image}
                    className="img-fluid mb-3 category-listingImage"
                    alt="img"
                  />
                </Link>
                <Link to={"/service-list/" + item._id}>
                  <h4 className="mb-0">{item.name}</h4>{" "}
                  <p className="mt-1">{item.description}</p>
                </Link>
              </div>
            );
          })}
        </div>
        </div>
        
      </section>
      {/* <Footer2 /> */}
    </div>
  );
};

export default Index;
